import React, { useMemo, useState } from "react"
import { Box, Button, Typography } from "src/UILibrary"
import { useForm, Controller } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as Yup from "yup"
import axios, { Axios, AxiosError } from "axios"
import { useSetRecoilState, useRecoilState } from "recoil"

import { TextFieldWithLabel } from "src/components/textfieldWithLabel"
import { AgreeBox } from "./agreeBox"

import * as Validator from "src/modules/validation"
import { IUserSignUpFormInputs } from "src/types/signup"
import {
  registeredEmailState,
  signupEmailState,
  signupFirstNameState,
  signupPasswordState,
  signupLastNameState,
} from "src/states/signup"
import { checkEmailDuplication } from "src/api/signupService"
import { MAX_INPUT_LENGTH } from "src/constants/common"
import { useRecoilValue } from "recoil"
import { currentLangState } from "src/states/signup"
import { getStatusCode } from "src/pages/home/utils"
import { responseCodes } from "src/modules/axios"

const validationSchema = Yup.object().shape({
  firstName: Validator.fullNameSchema(),
  lastName: Validator.fullNameSchema(),
  email: Validator.emailSchema(),
  password: Validator.passwordSchema(),
  confirmPassword: Validator.confirmPasswordSchema(),
  accTermsNConditions: Validator.accTermsNConditionsScheme(),
})

interface CreateIndividualProps {
  setStep: Function
}

export const CreateIndividual: React.FC<CreateIndividualProps> = ({ setStep }) => {
  const currentLang = useRecoilValue(currentLangState)
  const [isRegistering, setIsRegistering] = useState<boolean>(false)
  const [accTermsNConditions, setAccTermsNConditions] = useState(false)
  const [email, setEmail] = useRecoilState(signupEmailState)
  const [firstName, setFirstName] = useRecoilState(signupFirstNameState)
  const [lastName, setLastName] = useRecoilState(signupLastNameState)
  const [password, setPassword] = useRecoilState(signupPasswordState)
  const [errorMessage, setErrorMessage] = useState<string>("")
  const {
    handleSubmit,
    control,
    watch,
    getValues,
    setError,
    formState: { errors },
  } = useForm<IUserSignUpFormInputs>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      firstName: firstName || "",
      lastName: lastName || "",
      email: email || "",
      password: password || "",
      confirmPassword: password || "",
      accTermsNConditions: accTermsNConditions,
    },
  })

  const watchAllFields = watch()

  const onSubmit = (data: IUserSignUpFormInputs) => {
    setIsRegistering(true)
    checkEmailDuplication(data)
      .then((res) => {
        setStep()
      })
      .catch((e: any) => {
        const status_code = getStatusCode(e)
        let message = ""
        switch (status_code) {
          case responseCodes.email_is_not_valid_for_registration:
            message = "email_is_not_valid_for_registration"
            break
          default:
            message = "email_already_exists"
            break
        }
        setError("email", {
          type: "email",
          message,
        })
      })
      .finally(() => setIsRegistering(false))
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "320px",
          gap: "12px",
        }}
      >
        <Controller
          name="firstName"
          control={control}
          render={({ field: { onChange, value } }) => (
            <TextFieldWithLabel
              label={currentLang["first_name"]}
              placeholder={currentLang["type_first_name"]}
              value={value}
              onChange={(e) => {
                if (e.target.value.length > MAX_INPUT_LENGTH) return
                onChange(e.target.value)
                setFirstName(e.target.value)
              }}
              error={!!errors.firstName}
              helperText={errors.firstName && "invalid first name"}
            />
          )}
        />

        <Controller
          name="lastName"
          control={control}
          render={({ field: { onChange, value } }) => (
            <TextFieldWithLabel
              label={currentLang["last_name"]}
              placeholder={currentLang["type_last_name"]}
              value={value}
              onChange={(e) => {
                if (e.target.value.length > MAX_INPUT_LENGTH) return
                onChange(e.target.value)
                setLastName(e.target.value)
              }}
              error={!!errors.lastName}
              helperText={errors.lastName && "invalid last name"}
            />
          )}
        />

        <Controller
          name="email"
          control={control}
          render={({ field: { onChange, value } }) => (
            <TextFieldWithLabel
              type="email"
              label={currentLang["email"]}
              placeholder={currentLang["email_placeholder"]}
              value={value}
              onChange={(e) => {
                if (e.target.value.length > MAX_INPUT_LENGTH) return
                onChange(e.target.value)
                setEmail(e.target.value)
              }}
              error={!!errors.email}
              helperText={errors.email ? currentLang[`${errors.email.message}`] : undefined}
            />
          )}
        />

        <Controller
          name="password"
          control={control}
          render={({ field: { onChange, value } }) => (
            <TextFieldWithLabel
              label={currentLang["password"]}
              placeholder={currentLang["must_be_8_characters"]}
              type="password"
              value={value}
              onChange={(e) => {
                if (e.target.value.length > MAX_INPUT_LENGTH) return
                onChange(e.target.value)
                setPassword(e.target.value)
              }}
              error={!!errors.password}
              helperText={
                errors.password
                  ? currentLang[`${errors.password.message}`]
                  : currentLang["password_requirments"]
              }
            />
          )}
        />
        <Controller
          name="confirmPassword"
          control={control}
          render={({ field: { onChange, value } }) => (
            <TextFieldWithLabel
              label={currentLang["confirm_password"]}
              placeholder={currentLang["repeat_password"]}
              type="password"
              value={value}
              onChange={(e) => {
                if (e.target.value.length > MAX_INPUT_LENGTH) return
                onChange(e.target.value)
              }}
              error={!!errors.confirmPassword}
              helperText={
                errors.confirmPassword
                  ? currentLang[`${errors.confirmPassword.message}`]
                  : undefined
              }
            />
          )}
        />

        {!!errorMessage && (
          <Typography.Detail color="error" mt={-3} mb={1.5} align="center">
            {currentLang[`${errorMessage}`]}
          </Typography.Detail>
        )}

        <Controller
          name="accTermsNConditions"
          control={control}
          render={({ field: { onChange, value } }) => (
            <AgreeBox
              onChange={(e) => {
                onChange(e.target.checked)
                setAccTermsNConditions(e.target.checked)
              }}
            />
          )}
        />

        <Button
          role="submit"
          fullWidth
          type="submit"
          isLoading={isRegistering}
          disabled={Object.values(getValues()).some((value) => !value) || isRegistering}
          sx={{ marginTop: "12px" }}
        >
          {currentLang["continue"]}
        </Button>
      </Box>
    </form>
  )
}

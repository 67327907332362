import React from "react"
import { axiosInstance } from "src/modules/axios"
import { useEffect, useState } from "react"
import { USER_TYPE } from "src/constants/enums"
import { useRecoilState } from "recoil"
import { currentUserAvatarState } from "src/states/signup"
import { getUserInitialsFromFullName } from "../utils"
import Ai from "src/assets/icons/ai.png"
interface IUserAvatar {
  userId: string
  user_name: string
  user_name_initials: string
  userType: USER_TYPE
  small?: boolean
}
export const UserAvatar = ({
  userId,
  user_name_initials,
  userType,
  small = false,
  user_name,
}: IUserAvatar) => {
  const [userAvatarCache, setUserAvatarCache] = useRecoilState(currentUserAvatarState)

  const [src, setSrc] = useState<string>("")
  const isAI = userType === USER_TYPE.ai

  useEffect(() => {
    console.log("userAvatarCache", userAvatarCache)
  }, [userAvatarCache])

  useEffect(() => {
    if (isAI) {
      return
    }

    if (userAvatarCache[userId]) {
      console.log("chached")
      setSrc(userAvatarCache[userId])
      return
    }

    axiosInstance
      .post(
        "authenticated/settings/get_profile_photo",
        {
          user_id: userId,
        },
        {
          responseType: "blob",
        }
      )
      .then((data: any) => {
        console.log("not chached")
        const url = URL.createObjectURL(data.data)
        setSrc(url)
        setUserAvatarCache({ ...userAvatarCache, ...{ [userId]: url } })
      })
      .catch((err) => console.log(err))
  }, [userId])

  const initials = isAI
    ? "AI"
    : user_name_initials
    ? user_name_initials
    : getUserInitialsFromFullName(user_name)

  if (isAI) {
    return (
      <div className={small ? "smallAvatar" : "avatar"}>
        <img src={Ai} alt="ai" />
      </div>
    )
  }

  if (!src) {
    return <div className={small ? "smallAvatar" : "avatar"}>{initials}</div>
  }

  return (
    <div
      className={small ? "smallAvatar" : "avatar"}
      style={{ backgroundImage: `url(${src})`, backgroundSize: "cover" }}
    />
  )
}

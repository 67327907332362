
import React, {  } from 'react';
import { Route, Routes, useLocation } from "react-router-dom"
import HomeAnalytics from 'src/components/backoffice/Analytics/not_used/HomeAnalytics';
import AnalyticsPage from 'src/pages/backoffice/Analytics';
import BillingPage from 'src/pages/backoffice/Billing';
import HomePage from 'src/pages/backoffice/HomePage';
// import Clinics from 'src/pages/backoffice/clinics/Clinics';
// import ControlSystemPage from 'src/pages/backoffice/controlsystem/ControlSystemPage';
// import Patients from 'src/pages/backoffice/patients/Patients';
// import Physicians from 'src/pages/backoffice/physicians/Physicians';
import HomeDemoPage from 'src/pages/backoffice/HomeDemo';
// import Physician from 'src/pages/backoffice/single physician/SinglePhysicican';
// import Patient from 'src/pages/backoffice/single patient/SinglePatient';
import ViewConversationsPage from 'src/pages/backoffice/conversations_demo/ConversationsDemo';
import Instructions from 'src/pages/backoffice/instructions_demo/InstructionDemo';
import Users from 'src/pages/backoffice/all_users/all_users'
import User from 'src/pages/backoffice/single_user/SingleUser';
import InstructionsPage from 'src/components/backoffice/Instructions/InstructionPage';
import { setAuthHeaders } from 'src/modules/axios';
import PhysicianInstructions from 'src/pages/backoffice/instructions_demo/PhysicianInstructions';
import DataGridPremiumDemo from 'src/components/backoffice/Analytics/AnalyticsTableAggr';
import { Analytics } from '@mui/icons-material';
import AnalyticsBO from 'src/pages/backoffice/analytics/AnalyticsBO';
import InstructionsDemoPage from 'src/components/backoffice/Instructions/InstructionsDemo';
import InstructionsDemoTomer from 'src/pages/backoffice/instructions_demo/InstructionDemoTomer';
import DemoAlphaPage from 'src/pages/backoffice/Demo-Alpha';

const BackOfficeRoute = () => {
  console.log("BackOfficeRoute")
  const location = useLocation()
  console.log("LOCATION STATE HOME:", location)

  const jwtToken = localStorage.getItem("access_token") as string
  const csrfToken = localStorage.getItem("csrf_token") as string
  if (jwtToken && csrfToken) {
    console.log("CALLING SETAUTHHEADERS")
    setAuthHeaders({
      jwtToken,
      csrfToken,
    })
  }
  console.log("JWT TOKEN", jwtToken)
  console.log("CSRF TOKEN", csrfToken)

    
  return (
    <Routes>
    {/* <Route
        path="/"
        element={<Login setLoggedIn={setLoggedIn} />}
    />
    {isLoggedIn ? (
        <> */}
            <Route path="/alpha-demo" element={<DemoAlphaPage />} />
            {/* <Route path="/" element={<DemoAlphaPage />} /> */}
            <Route path="/home" element={<HomePage />} />
            {/* <Route path="/homedemo" element={<HomeDemoPage />} /> */}
            <Route path="/conversations" element={<ViewConversationsPage />} />
            <Route path="/instructions" element={<InstructionsDemoTomer />} />
            <Route path="/billing" element={<BillingPage />} />
            {/* <Route path="/billinganalytics" element={<BillingAnalyticsPage />} /> */}
            {/* <Route path="/controlsystem" element={<ControlSystemPage />} /> */}
            {/* <Route path="/analytics" element={<AnalyticsPage />}> */}
            {/* <Route path="/users" element={<Users />} />
            <Route path="/user/:email" element={<User />} /> */}
            {/* <Route path="/clinic/:email" element={<Physicians />} /> */}
            {/* <Route path="/physician/:email" element={<Patients />} /> */}
            {/* <Route path="/users" element={<AnalyticsPage />}> */}
                {/* <Route index element={<Users/>}/> */}
                {/* <Route path="clinics" element={<Clinics />} />
                <Route path="physicians" element={<Physicians />} /> */}
                {/* <Route path="users" element={<Users />} /> */}
                {/* <Route path="user/:id" element={<User />} /> */}
                {/* <Route path="physician/:id" element={<PhysicianInstructions />} /> */}
                {/* <Route path="analytics" element={<AnalyticsBO />} /> */}
                <Route path="/instructions" element={<InstructionsDemoPage />} />
                {/* <Route path="patients" element={<Patients />} /> */}
                {/* <Route path="patients/:id" element={<Patient />} /> */}
                {/* <Route path="physicians/:id" element={<Physician />} /> */}
            {/* </Route> */}
        {/* </>
    ) : (
        <Route path="/*" element={<Navigate to="/malkishua" />} />
    )} */}
</Routes>
  )
}

export default BackOfficeRoute

import React from "react";
import { Icon } from "@mui/material";
import { SvgIconProps } from "@mui/material";

interface ISvgIcon extends SvgIconProps {
  src: string;
  alt: string;
}

export const SvgIcon: React.FC<ISvgIcon> = ({ src, alt, sx, ...rest }) => {
  return (
    <Icon sx={sx}>
      <img style={{ objectFit: "contain", height: "100%", width: "100%" }} src={src} alt={alt} />
    </Icon>
  )
}

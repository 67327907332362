import { DatePicker as MuiDatePicker, LocalizationProvider, StaticDatePicker } from "@mui/x-date-pickers"
import "./conversationsDemo.scss"
import React, { useState, useEffect } from "react"
import { fetchConversations } from "src/api/backoffice"
import DataTable from "src/components/backoffice/Analytics/DataTableUser"
import dayjs from "dayjs"
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid';
import SingleDialog from "src/components/backoffice/Single/SingleDialog"
import { Link } from "react-router-dom"
import { IoMdArrowRoundBack } from "react-icons/io"

const columns: GridColDef[] = [
  { field: "id", headerName: "ID", width: 90 },
  {
    field: "patientMessages",
    headerName: "Patient Messages",
    type: "string[]",
    width: 400,
    editable: true,
    valueGetter: (params) => {
      const messages: Array<{ "created at": Date, text: string, sender: string }> = params.row.patientMessages || [];
      return messages.map((message) => {
        const formattedTime = new Date(message["created at"]).toLocaleTimeString();
        return `${formattedTime} ${message.sender}: ${message.text}`;
      }).join('\n');
    },
  },
  {
    field: "doctorMessages",
    headerName: "Physician Messages",
    type: "string[]",
    width: 400,
    editable: true,
    valueGetter: (params) => {
      const messages: Array<{ "created at": Date, text: string, sender: string }> = params.row.doctorMessages || [];
      return messages.map((message) => {
        const formattedTime = new Date(message["created at"]).toLocaleTimeString();
        return `${formattedTime} ${message.sender}: ${message.text}`;
      }).join('\n');
    },
  },
//   {
//     field: "clinic_name",
//     headerName: "Clinic name",
//     type: "string",
//     width: 150,
//     editable: true,
//   },
  {
    field: "date",
    headerName: "Date",
    type: "date",
    width: 110,
    editable: true,
    valueGetter: (params) => new Date(params.row.date),
  }
]

// interface Conversation {
//   id: number;
//   date: string;
//   patientMessages: {created_at: string; sender: string; text: string}[];
//   doctorMessages: {created_at: string; sender: string; text: string}[]  
// };

const ViewConversationsPage = () => {
  const [conversations, setConversations] = useState([])
  const [selectedDate, setSelectedDate] = useState <Date | null>(new Date());
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [currentRowData, setCurrentRowData] = useState(null);

  const getConversations = async (selectedDate: Date | null) => {
    try {
      const data = await fetchConversations(selectedDate)
      const fetchedConversationsRows = data.map((conversation:any, index:any) => ({
        id: index + 1,
        ...conversation,
      }));
      console.log(fetchedConversationsRows)
      setConversations(fetchedConversationsRows)
      const singleConversation = fetchedConversationsRows
      console.log(singleConversation)
    } catch (error) {
      console.error("Error fetching conversations")
    }
  }

  useEffect(() => {
    getConversations(selectedDate)
  }, [selectedDate])

  const handleRowClick = (rowData:any) => {
    setCurrentRowData(rowData);
    setDialogOpen(true);
  };

  const actionColumn: GridColDef = {
    field: "action",
    headerName: "Action",
    width: 20,
    renderCell: (params) => {
      return (
        <div className="action flex">
          <img src="/view.svg" onClick={() => handleRowClick(params.row)} />
          {isDialogOpen && currentRowData && (
            <SingleDialog {...(currentRowData as any)} setDialogOpen={setDialogOpen} />
          )}
        </div>
      );
    }
  };

  return (
    <>
      {/* <Link to="/malkishua/homedemo" style={{ position: "absolute", left: "20px", top: "5px" }}>
        <p> <IoMdArrowRoundBack />to HomeDemo page</p>
      </Link> */}
      <div className="main">
      <div className="conversations m-5 p-5 bg-white p-5 rounded-2xl border-4 border-blue-200">
        <div className="bg-white p-5 rounded shadow">
          <div className="physicians-control">
          <StaticDatePicker
            orientation="landscape"
            defaultValue={dayjs(selectedDate)}
            onChange={(date) => (date ? setSelectedDate(date.toDate()) : null)}
          />
            <div className="info">
              <h1>Conversations</h1>
            </div>
            <div className="dataTable">
              <DataGrid
                  className="dataGrid"
                  rows={conversations}
                  columns={[...columns, actionColumn]}
                  initialState={{
                  pagination: {
                      paginationModel: {
                      pageSize: 10,
                      },
                  },
                  }}
                  slots={{toolbar: GridToolbar}}
                  slotProps={{
                      toolbar:{
                          showQuickFilter: true,
                          quickFilterProps: { debounceMs: 500 },
                      },
                  }}
                  pageSizeOptions={[10]}
                  checkboxSelection
                  disableRowSelectionOnClick
                  disableColumnFilter
                  disableDensitySelector
                  disableColumnSelector
              />
          </div>
            {/* <DataTable slug="conversations" columns={columns} rows={conversations} /> */}
          </div>
        </div>
      </div>
      </div>
    </>
  )
}
export default ViewConversationsPage 

import React from "react"
import { useRecoilState } from "recoil"
import { Box, Typography } from "src/UILibrary"
import { CodeInput } from "src/components/codeInput"
import { changePasswordPhoneNumberState, signinEmailState } from "src/states/signin"
import { sendVerificationCodeToEmail, sendVerificationCodeToPhone, submitEmailVerificationCode, submitEmailVerificationPhone } from "src/api/forgotPasswordService"
import { VerificationType } from "src/types/forgotPassword"
import { useRecoilValue } from "recoil"
import { currentLangState } from "src/states/signup"

interface ICodeInputForm {
  setStep: Function
  setOtp: Function
  verificationType: VerificationType
  onSwitch: (type: VerificationType) => void;

}

export const CodeInputForm: React.FC<ICodeInputForm> = ({ setStep, verificationType, onSwitch, setOtp }) => {
  const currentLang = useRecoilValue(currentLangState)
  const [email] = useRecoilState(signinEmailState)
  const [phoneNumber] = useRecoilState(changePasswordPhoneNumberState)


  // functions
  const onSubmitCode = async (otp: string) => {
    if (verificationType === "email") {
      await submitEmailVerificationCode({ email, otp });
    } else {
      await submitEmailVerificationPhone({ phone_number: phoneNumber, otp });
    }
  }

  const handleResend = () => {
    if (verificationType === "email") {
      sendVerificationCodeToEmail(email);
    } else {
      sendVerificationCodeToPhone(phoneNumber);
    }
  }
  const redirectUrl = (otp: string = "") => {
    setStep((step: number) => step + 1)
    setOtp(otp)
  }

  const handleSwitch = () => {
    if (verificationType === "email") {
      onSwitch("phone");
    } else {
      onSwitch("email");
    }
  }

  return (
    <Box>
      <Typography.SubHeading sx={{ textAlign: "center", color: "text.primary", mb: "2rem" }}>
        {currentLang["enter_code"]}
      </Typography.SubHeading>
      <CodeInput
        verificationType={verificationType}
        target={email}
        serviceCall={onSubmitCode}
        redirectUrl={redirectUrl}
        onResend={handleResend}
        switchToOther={handleSwitch}
      />
    </Box>
  )
}

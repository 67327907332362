import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { getUserInitialsFromFullName } from "../../utils"
import { axiosInstance } from "src/modules/axios"
import { IUseMeContext } from "../chatContext.types"
import { useUserAuth } from "src/pages/context/userAuth/userAuthContext"
import { USER_TYPE } from "src/constants/enums"
import { currentLangIDtate } from "src/states/signup"
import { useRecoilValue } from "recoil"

export const useMe = (): IUseMeContext => {
  const { user } = useUserAuth()
  const currentLangID = useRecoilValue(currentLangIDtate)
  const { email, id: userID, type: userType } = user
  const [userName, setUserName] = useState("")
  const [signature, setSignature] = useState<any>(undefined)
  const userNameInitials = getUserInitialsFromFullName(userName)

  useEffect(() => {
    fetchUserName()
    if (userType === USER_TYPE.physician) {
      getSignature()
    }
  }, [])

  const getSignature = () => {
    axiosInstance
      .post(
        "authenticated/settings/get_signature",
        {
          user_id: userID,
        },
        {
          responseType: "blob",
        }
      )
      .then((data: any) => {
        const url = URL.createObjectURL(data.data)
        setSignature(url)
      })
      .catch((err) => {
        setSignature(null)
        console.error(err)
      })
  }

  const fetchUserName = async () => {
    axiosInstance
      .post("authenticated/conversation/get_info_by_user_id", {
        user_id: userID,
        lang: currentLangID === 1 ? "en" : "ar",
      })
      .then((data) => {
        if (data.data.first_name && data.data.last_name) {
          setUserName(data.data.first_name.trim() + " " + data.data.last_name.trim())
        } else if (data.data.first_name && !data.data.last_name) {
          setUserName(data.data.first_name.trim())
        } else if (!data.data.first_name && data.data.last_name) {
          setUserName(data.data.last_name.trim())
        }
      })
      .catch((err) => console.log(err))
  }

  return {
    email,
    userID,
    userType,
    userName,
    userNameInitials,
    signature,
    getSignature,
  }
}

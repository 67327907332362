import React, { useState } from "react"
import * as Validator from "src/modules/validation"
import * as Yup from "yup"
import { useRecoilState } from "recoil"
import { Box, Button } from "src/UILibrary"
import { Controller, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { changePasswordPhoneNumberState } from "src/states/signin"
import {
  sendVerificationCodeToPhone,
} from "src/api/forgotPasswordService"
import { MAX_INPUT_PHONE_LENGTH } from "src/constants/common"
import { PhoneInput } from "src/UILibrary/input/PhoneInput"
import { useRecoilValue } from "recoil"
import { currentLangState } from "src/states/signup"


interface IForgotPhoneNumberProps {
  onSubmit: () => void;
}

const validationSchema = Yup.object().shape({
  phoneNumber: Validator.phoneNumberSchema(),
})

type TForgotForm = {
  phoneNumber: string;
}
export const ForgotPhoneNumber: React.FC<IForgotPhoneNumberProps> = ({
  onSubmit
}) => {
  const currentLang = useRecoilValue(currentLangState)
  const [phoneNumber, setPhoneNumber] = useRecoilState(changePasswordPhoneNumberState)
  const [isSending, setIsSending] = useState(false)

  const {
    handleSubmit,
    control,
    setError,
    watch,
    getValues,
    formState: { errors },
  } = useForm<TForgotForm>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      phoneNumber: phoneNumber || "",
    },
  })

  const watchPhoneNumber = watch("phoneNumber", "")

  
  const onSendSMS = (data: TForgotForm) => {
    setIsSending(true)
    sendVerificationCodeToPhone(data.phoneNumber)
      .then((res: any) => {
        onSubmit();
      })
      .catch((res) => {
        setError("phoneNumber", {
          type: "phoneNumber",
          message: "phone_not_exist",
        })
      })
      .finally(() => setIsSending(false))
  }


  const handleError = (a: any) => {
    setError("phoneNumber", {
      type: "phoneNumber",
      message: "invalid_phone_number_shape"
    })
    console.error(a);
  }

  const isDisabled = Object.values(getValues()).some((value) => !value) || isSending;

  return (
    <form
      onSubmit={handleSubmit(onSendSMS, handleError)}
    >
      <Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Controller
            name="phoneNumber"
            control={control}
            render={({ field: { onChange, value } }) => (
              <PhoneInput
                label={currentLang["phone_number"]}
                placeholder={currentLang["phone_number_example"]}
                value={watchPhoneNumber || ""}
                onChange={(val: string) => {
                  if (val.length > MAX_INPUT_PHONE_LENGTH) return
                  onChange(val)
                  setPhoneNumber(val)
                }}
                setPhoneNumber={(val: string) => {
                  onChange(val)
                  setPhoneNumber(val)
                }}
                error={!!errors.phoneNumber}
                helperText={
                  errors.phoneNumber ? currentLang[`${errors.phoneNumber.message}`] : undefined
                }
                sx={{ width: "320px" }}
              />
            )}
          />

          <Button
            role="submit"
            fullWidth
            type="submit"
            sx={{ mt: "3.375rem", mb: "1.375rem" }}
            disabled={isDisabled}
          >
            {currentLang["send"]}
          </Button>

        </Box>
      </Box>
    </form>
  )
}

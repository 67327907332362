import React from "react"
import {
  Select as MuiSelect,
  SelectProps,
  InputBase,
  styled,
  FormControl,
  InputLabel,
  FormControlOwnProps,
} from "@mui/material"
import { BASE_COLORS } from "src/themes/colors"
import { Typography } from "../typography"
import Container from "src/components/container"
import useIsRtl from "src/hooks/useIsRtl"

const Input = styled(InputBase)(({ theme }) => ({
  height: "2.75rem",
  width: "100%",
  "& .MuiInputBase-input": {
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: BASE_COLORS.gray[300],
    borderRadius: "0.5rem",
    fontSize: "1rem",
    lineHeight: "1.5rem",
    padding: "0.625rem 0.875rem",
    fontWeight: 500,
    color: BASE_COLORS.gray[900],
    backgroundColor: "white",
    "&:hover": {},
    "&:focus": {},
  },
  "&.Mui-selected": { color: "#ffffff" },
}))

export const Select = ({
  label,
  children,
  value,
  placeholder,
  wrapperStyles,
  ...rest
}: SelectProps & { wrapperStyles?: FormControlOwnProps["sx"] }) => {
  const isRtl = useIsRtl()

  return (
    <Container.Column sx={{ width: "100%", ...wrapperStyles }}>
      <Typography.Detail
        sx={{
          fontSize: 14,
          fontWeight: 510,
          lineHeight: "1.25rem",
          color: "text.secondary",
          ml: "1rem",
          mb: "0.25rem",
        }}
      >
        {label}
      </Typography.Detail>
      <FormControl
        sx={{
          display: "flex",
          width: "100%",
        }}
      >
        {!value && (
          <InputLabel
            htmlFor="my-select"
            sx={{
              fontSize: 18,
              color: BASE_COLORS.gray["500"],
            }}
            shrink={false}
          >
            {placeholder}
          </InputLabel>
        )}

        <MuiSelect
          sx={{
            direction: "ltr",
            color: "inherit", // Set to 'inherit' or 'transparent' to remove font color
            fontSize: "1rem",
            borderRadius: "50px",
            fontWeight: 400,
            lineHeight: "1.5rem",
            bgcolor: "white",
            "& .MuiSelect-select": {
              p: "0.625rem 0.875rem",
              borderRadius: "50px",
              backgroundColor: "#F2F4F7 !important",
              textAlign: isRtl ? "end" : "start",
            },

            "& fieldset": {
              border: "none !important",
              borderWidth: "0",
              borderStyle: "solid",
              borderColor: BASE_COLORS.gray[300],
            },

            "& input": {
              fontSize: "1rem",
              p: "0.625rem 0.875rem",
              borderRadius: "0.5rem",
              fontWeight: 400,
              lineHeight: "1.5rem",
              bgcolor: "white",
              color: "black",
            },

            ...rest.sx, // Allow additional custom styles via the 'sx' prop
          }}
          value={value}
          {...rest}
        >
          {children}
        </MuiSelect>
      </FormControl>
    </Container.Column>
  )
}

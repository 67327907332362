import illustration from "./illustration.svg"
import backgroundFlow from "./background-flow.svg"
import doctor from './doctor.png'

const Images = {
  illustration,
  backgroundFlow,
  doctor
}

export default Images

import React, { useState } from "react"
import Icons from "src/assets/icons"
import Container from "src/components/container"
import { useRecoilValue } from "recoil"
import { Box, Button, StaticImage, Typography } from "src/UILibrary"
import { Dialog } from "src/UILibrary/Dialog/Dialog"
import { TextFieldWithLabel } from "src/components/textfieldWithLabel"
import { currentLangIDtate, currentLangState } from "src/states/signup"
import { axiosInstance } from "src/modules/axios"
import { useChat } from "../context/chatContext"
import { ConversationHandle, ConversationMessageTypes } from "src/constants/enums"
import { useSocketIo } from "../context/hooks/useSocketIO"
import useIsRtl from "src/hooks/useIsRtl"
import { TChatMessage } from "../context/chatContext.types"

interface IPrescriptionDialogProps {
  group_one_name: string
  group_two_name: string
  active_group_name: string
  patient_name: string
  physician_name: string
  closeDialog: () => void
}

export function PrescriptionDialog({
  closeDialog,
  group_one_name,
  group_two_name,
  patient_name,
  physician_name,
  active_group_name,
}: IPrescriptionDialogProps) {
  const currentLang = useRecoilValue(currentLangState)
  const currentLangID = useRecoilValue(currentLangIDtate)
  const isRtl = useIsRtl()
  const { userName, userID } = useChat()
  const { sendMessage } = useSocketIo()
  const [medicationName, setMedicationName] = useState("")
  const [quantity, setQuantity] = useState("")
  const [strength, setStrength] = useState("")
  const [dosage, setDosage] = useState("")
  const [additionalInstructions, setAdditionalInstructions] = useState("")

  const sendPrescription = async () => {
    try {
      const body = {
        group_name: group_one_name,
        patient_name: patient_name,
        physician_name: physician_name,
        dosage: dosage,
        strength: strength,
        quantity: quantity,
        medication_name: medicationName,
        additional_instructions: additionalInstructions,
      } as any
      const res = await axiosInstance.post("/authenticated/conversation/prescription_creator", body)
      console.log("sendPrescription res", res)
      delete body.group_name
      sendFileToSocketIO(res.data.file_name)
      sendMessageToAI(body)
      closeDialog()
    } catch (e) {
      console.log("err:" + e)
    }
  }

  const sendFileToSocketIO = async (file_name: string) => {
    const groupOneMessage = {
      user_name: userName,
      user_id: userID,
      group_name: group_one_name,
      message_type_id: ConversationMessageTypes.requestPrescription,
      message: file_name,
      file_name: "Prescription.pdf",
      conversation_handler: ConversationHandle.SKIP_AI,
    } as TChatMessage
    sendMessage(groupOneMessage)
    const groupTwoMessage = {
      user_name: userName,
      user_id: userID,
      group_name: group_two_name,
      message_type_id: ConversationMessageTypes.requestPrescription,
      message: file_name,
      file_name: "Prescription.pdf",
      conversation_handler: ConversationHandle.SKIP_AI,
    } as TChatMessage
    sendMessage(groupTwoMessage)
  }

  const sendMessageToAI = async (body: any) => {
    const messageToServer = {
      date_time: new Date().toUTCString(),
      user_id: userID,
      user_name: userName,
      group_name: active_group_name,
      message_type_id: ConversationMessageTypes.prescription,
      message: JSON.stringify(body),
      file_name: "Prescription.pdf",
      conversation_handler: ConversationHandle.SKIP_UI,
    } as TChatMessage
    if (currentLangID === 2) messageToServer.lang = "he"
    sendMessage(messageToServer)
  }

  return (
    <Dialog>
      <div className="physcianCodeInput">
        <StaticImage
          className="prescriptionRefrealDialogCloseButton"
          onClick={closeDialog}
          src={Icons.xBlack}
          alt="logo"
          width={24}
          height={24}
        />
        <Container.Column sx={{ gap: "16px" }}>
          <div className="userPlusBlueWrapper">
            <div className="userPlusBlueContainer">
              <StaticImage src={Icons.prescriptionBlue} alt="logo" width={24} height={24} />
            </div>
          </div>
          <div>
            <p className="Body2 semibold">{currentLang["new_prescription"]}</p>
          </div>
        </Container.Column>
        <Container.Column sx={{ gap: "16px" }}>
          <TextFieldWithLabel
            type="text"
            label={currentLang["medication_name"]}
            value={medicationName}
            onChange={(evt) => setMedicationName(evt.target.value)}
            fullWidth
            className="Body2 messageInput"
          />
          <TextFieldWithLabel
            type="text"
            label={currentLang["quantity"]}
            value={quantity}
            onChange={(evt) => setQuantity(evt.target.value)}
            fullWidth
            className="Body2 messageInput"
          />
          <TextFieldWithLabel
            type="text"
            label={currentLang["strength"]}
            value={strength}
            onChange={(evt) => setStrength(evt.target.value)}
            fullWidth
            className="Body2 messageInput"
          />
          <TextFieldWithLabel
            type="text"
            label={currentLang["dosage"]}
            value={dosage}
            onChange={(evt) => setDosage(evt.target.value)}
            fullWidth
            className="Body2 messageInput"
          />
          <div className="PrescriptionRefrealTextAreaWrapper">
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                justifyContent: isRtl ? "flex-end" : "flex-start",
              }}
            >
              <Typography.Detail
                sx={{
                  fontSize: 14,
                  fontWeight: 510,
                  lineHeight: "1.25rem",
                  color: "text.secondary",
                  ml: "1rem",
                  mb: "0.25rem",
                }}
              >
                <p>{currentLang["additional_instructions"]}</p>
              </Typography.Detail>
            </Box>
            <textarea
              value={additionalInstructions}
              onChange={(evt) => setAdditionalInstructions(evt.target.value)}
            />
          </div>
        </Container.Column>
        <Button fullWidth role="submit" onClick={sendPrescription}>
          {currentLang["create_prescription"]}
        </Button>
      </div>
    </Dialog>
  )
}

import { InputAdornment } from "@mui/material"
import React from "react"
import { StaticImage, TextField } from "src/UILibrary"
import Icons from "src/assets/icons"
import Container from "src/components/container"
import { getLastMessagePrefix } from "../utils"
import { useRecoilValue } from "recoil"
import { currentLangState } from "src/states/signup"

export function MessagesGallery({
  isRtl,
  setShowSearchMessages,
  searchMessage,
  handleSearchMessages,
  foundMessages,
  goToMessage,
}: any) {
  const currentLang = useRecoilValue(currentLangState)

  return (
    <Container.Column className="searchMessagesContainer">
      <Container.Row alignItems="center" sx={{ width: "100%", gap: ".5rem" }}>
        <button onClick={() => setShowSearchMessages(false)}>
          <StaticImage src={Icons.xBlack} alt="logo" width={16} height={16} />
        </button>
        <p className="Body3Semibold">{currentLang["search_messages"]}</p>
      </Container.Row>
      <div className="searchInputContainer">
        <TextField
          type="search"
          initialType="search"
          placeholder={currentLang["search"]}
          value={searchMessage}
          onChange={(evt) => handleSearchMessages(evt.target.value)}
          fullWidth
          sx={{
            "& input": {
              fontSize: "1rem",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "1.5rem",
              padding: "0.4rem 0.875rem",
              borderRadius: "50px",
              bgcolor: "white",
              color: "#98A2B3",
              direction: isRtl ? "rtl" : "ltr",
            },
          }}
          // InputProps={{
          //   endAdornment: (
          //     <InputAdornment position="end">
          //       <StaticImage src={Icons.search} alt="logo" width={16} height={16} />
          //     </InputAdornment>
          //   ),
          // }}
        />
      </div>
      <ul className="foundMessages">
        {searchMessage.length > 0 &&
          foundMessages.map((foundMessage: any, index: number) => (
            <li key={index} onClick={() => goToMessage(index)}>
              <p className="foundMessageTime">{foundMessage.time}</p>
              <div className="foundMessageTextContainer">
                {foundMessage.message.split(" ").map((word: string) => (
                  <span
                    className={
                      searchMessage.toLowerCase().includes(word.toLowerCase())
                        ? "h5BoldPrimary ellipsis"
                        : "Body3 ellipsis"
                    }
                  >
                    {word + " "}
                  </span>
                ))}
              </div>
            </li>
          ))}
      </ul>
    </Container.Column>
  )
}

import React from "react"
import LeftArrow from "src/assets/icons/left-arrow.svg"
import RightArrow from "src/assets/icons/right-arrow.svg"
import { IconButton } from "src/components/iconButton"

interface IProps {
  onClick: Function
}

const BackArrow = ({ onClick }: IProps) => {
  const _onClick = () => onClick()

  return <IconButton src={LeftArrow} alt="left-arrow" onClick={_onClick} />
}

export const NextArrow = ({ onClick }: IProps) => {
  const _onClick = () => onClick()

  return <IconButton src={RightArrow} alt="right-arrow" onClick={_onClick} />
}

export default BackArrow

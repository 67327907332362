import React from "react"
import { MenuItemProps, MenuItem as MuiMenuItem } from "@mui/material"
import { BASE_COLORS } from "src/themes/colors"

export const MenuItem = ({ sx, ...rest }: MenuItemProps) => {
  return (
    <MuiMenuItem
      sx={{
        color: BASE_COLORS.gray[900],
        fontWeight: 500,
        lineHeight: "1.5rem",
        fontSize: "1rem",
        //         color: var(--gray-900, #101828);
        // font-size: 16px;
        // font-style: normal;
        // font-weight: 500;
        // line-height: 24px; /* 150% */

        ...sx,
      }}
      {...rest}
    />
  )
}

import React, { useEffect, useState } from "react"
import { Button, StaticImage } from "src/UILibrary"
import SignatureCanvas from "react-signature-canvas"
import { axiosInstance } from "src/modules/axios"
import { useChat } from "../context/chatContext"
import Icons from "src/assets/icons"
import { LoadingBar } from "./LoadingBar"
import { Dialog } from "src/UILibrary/Dialog/Dialog"
import { useRecoilValue } from "recoil"
import { currentLangState } from "src/states/signup"

export function Signature({ closeCanvas }: any) {
  const [canvas, setCanvas] = useState<any>()
  const [file, setFile] = useState<any>()
  const [mode, setMode] = useState<string>("draw")
  const [error, setError] = useState<string>("")
  const [disabled, setDisabled] = useState<boolean>(true)
  const [loadedLoadingPrecentage, setLoadedLoadingPrecentage] = useState<number>(0)
  const [stage, setStage] = useState(0)
  const { userID, getSignature } = useChat()
  const currentLang = useRecoilValue(currentLangState)

  const handleClear = () => {
    canvas.clear()
    setDisabled(true)
  }

  function dataURLtoFile(dataurl: any, filename: string) {
    const arr = dataurl.split(",")
    const mime = arr[0].match(/:(.*?);/)[1]
    const bstr = atob(arr[1])
    let n = bstr.length
    const u8arr = new Uint8Array(n)
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }
    return new File([u8arr], filename, { type: mime })
  }

  // const onUploadProgress = (progressEvent: any) => {
  //   const { loaded, total } = progressEvent

  //   const normalizedLoaded = normalize(loaded, 0, total, 0, 100)

  //   console.log("loaded", loaded)
  //   console.log("normalizedLoaded", normalizedLoaded)

  //   setLoadedLoadingPrecentage(normalizedLoaded)

  //   // if (normalizedLoaded >= 100) {
  //   //   setStage((prev) => prev + 1)
  //   // }
  // }

  const handleGenerate = () => {
    if (canvas.isEmpty()) return
    const url = canvas.getCanvas().toDataURL("image/png")
    const final = dataURLtoFile(url, "signature.png")
    const formData = new FormData()
    formData.append("file", final)
    formData.append("user_id", userID)
    const headers = {
      "Content-Type": "multipart/form-data",
    }
    setStage((prev) => prev + 1)
    setError("")
    axiosInstance
      .post("authenticated/conversation/upload_signature", formData, {
        headers: headers,
        onUploadProgress: (progressEvent) => {
          // Calculate upload percentage
          if (progressEvent.total) {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
            console.log("percentCompleted", percentCompleted)
            setLoadedLoadingPrecentage(percentCompleted)
          }
        },
      })
      .then((_) => {
        getSignature()
      })
      .catch((err) => {
        console.error(err)
        setError(currentLang["something_went_wrong_please_try_again"])
        setStage(0)
      })
  }

  const handleFile = (event: any) => {
    setFile(event.target.files[0])
  }

  const updateSignatureFromFile = () => {
    const formData = new FormData()
    formData.append("file", file)
    formData.append("user_id", userID)
    const headers = {
      "Content-Type": "multipart/form-data",
    }
    axiosInstance
      .post("authenticated/conversation/upload_signature", formData, {
        headers: headers,
      })
      .then((_) => {
        closeCanvas()
        getSignature()
      })
      .catch((err) => {
        console.error(err)
        setError(currentLang["something_went_wrong_please_try_again"])
        setStage(0)
      })
  }

  return (
    <div className="signatureCanvas">
      {mode === "upload" ? (
        <div className="signatureCanvasWrapper">
          <p>{currentLang["please_choose_a_signature"]}</p>
          <p>{currentLang["file_formats_and_size_limit"]}</p>
          <input type="file" onChange={handleFile} />
          <Button role="submit" onClick={updateSignatureFromFile}>
            {currentLang["submit"]}
          </Button>
          <Button onClick={() => setMode("draw")}>Draw signature instead</Button>
        </div>
      ) : (
        <div className="signatureCanvasWrapper">
          {stage === 0 ? (
            <>
              <button onClick={closeCanvas} className="closeButton">
                <StaticImage src={Icons.xBlack} alt="logo" width={24} height={24} />
              </button>
              <div className="userPlusBlueWrapper">
                <div className="userPlusBlueContainer">
                  <StaticImage src={Icons.signature} alt="logo" width={24} height={24} />
                </div>
              </div>
              <h4 className="bold">{currentLang["add_signature"]}</h4>
              <p className="Body2Semibold">{currentLang["please_draw_your_signature_below"]}</p>
              {error && <p className="Body2Semibold errorMessage">{error}</p>}
              <div className="signatureCanvasContainer">
                <SignatureCanvas
                  canvasProps={{ width: 364, height: 250 }}
                  velocityFilterWeight={0.1} // default 0.7
                  minWidth={0.35} // default 0.5
                  maxWidth={2} // default 2.5
                  minDistance={7} //default 5
                  dotSize={3} // default 2
                  throttle={24} // default 16
                  ref={(ref) => {
                    setCanvas(ref)
                  }}
                  onBegin={() => setDisabled(false)}
                />
                <Button onClick={handleClear} disabled={disabled} sx={{ marginBottom: "1rem" }}>
                {currentLang["try_again"]}
                </Button>
              </div>
              <p className="Caption2">
                {
                  currentLang[
                    "your_life_medical_ai_assistant_can_send_signed_documents_on_your_behalf_per_your_request_and_never_without_your_approval_to_do_so_you_will_need_your_hand_signature"
                  ]
                }
              </p>
              <Button fullWidth onClick={handleGenerate} disabled={disabled} role="submit">
                {currentLang["add_signature"]}
              </Button>
            </>
          ) : (
            <Dialog>
              <div className="physcianCodeInput" style={{ position: "relative" }}>
                <div className="userPlusBlueWrapper">
                  <div className="userPlusBlueContainer">
                    <StaticImage src={Icons.signature} alt="logo" width={24} height={24} />
                  </div>
                </div>
                {loadedLoadingPrecentage < 100 ? (
                  <>
                    <h4 className="bold">Uploading</h4>
                    <LoadingBar loadedLoadingPrecentage={loadedLoadingPrecentage} />
                  </>
                ) : (
                  <>
                    <h4 className="bold">{currentLang["upload_signature"]}</h4>
                    <p className="Body2Semibold">{currentLang["file_uploaded_successfully"]}</p>
                  </>
                )}
                <Button
                  role="submit"
                  onClick={closeCanvas}
                  disabled={loadedLoadingPrecentage < 100}
                >
                  {currentLang["continue"]}
                </Button>
              </div>
            </Dialog>
          )}
        </div>
      )}
    </div>
  )
}

import { IconButton, InputAdornment } from "@mui/material"
import React, { useState } from "react"
import { Box, TextField, Typography, TextFieldProps, SvgIcon } from "src/UILibrary"
import Icons from "src/assets/icons"
import useIsRtl from "src/hooks/useIsRtl"
interface TextFieldWithLabelProps {
  label: string
  description?: string
  action?: React.ReactNode
  forceLtr?: boolean
  startIcon?: any
}

export const TextFieldWithLabel: React.FC<TextFieldProps & TextFieldWithLabelProps> = ({
  label,
  placeholder,
  type,
  description,
  action,
  value,
  forceLtr,
  error,
  autoComplete = "on",
  startIcon,
  inputProps,
  ...rest
}) => {
  const [showPassword, setShowPassword] = useState(false)
  const isRtl = useIsRtl()

  const defaultInputProps = {
    endAdornment: type === "password" && !isRtl && (
      <InputAdornment position="end" sx={{ marginRight: "2px" }}>
        <IconButton
          aria-label="toggle password visibility"
          onClick={() => setShowPassword(!showPassword)}
          edge="end"
        >
          {showPassword ? (
            <SvgIcon
              src={Icons.visibilityOff}
              alt="visibility-off"
              sx={{ width: "16px", height: "16px" }}
            />
          ) : (
            <SvgIcon
              src={Icons.visibilityOn}
              alt="visibility-on"
              sx={{ width: "16px", height: "16px" }}
            />
          )}
        </IconButton>
      </InputAdornment>
    ),
    startAdornment:
      type === "password" && isRtl ? (
        <InputAdornment position="start" sx={{ marginRight: "2px" }}>
          <IconButton
            aria-label="toggle password visibility"
            onClick={() => setShowPassword(!showPassword)}
            edge="start"
          >
            {showPassword ? (
              <SvgIcon
                src={Icons.visibilityOff}
                alt="visibility-off"
                sx={{ width: "16px", height: "16px" }}
              />
            ) : (
              <SvgIcon
                src={Icons.visibilityOn}
                alt="visibility-on"
                sx={{ width: "16px", height: "16px" }}
              />
            )}
          </IconButton>
        </InputAdornment>
      ) : startIcon ? (
        <InputAdornment position="start">{startIcon}</InputAdornment>
      ) : null,
  } as any

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: isRtl ? "flex-end" : "flex-start",
        }}
      >
        <Typography.Detail
          sx={{
            fontSize: 14,
            fontWeight: 510,
            lineHeight: "1.25rem",
            color: "text.secondary",
            ml: "1rem",
            mb: "0.25rem",
          }}
        >
          {label}
        </Typography.Detail>
        {!!description && (
          <Typography.Detail sx={{ color: "secondary.light" }}>{description}</Typography.Detail>
        )}
      </Box>
      <TextField
        type={type !== "password" || showPassword ? "text" : "password"}
        initialType={type}
        placeholder={placeholder}
        value={value}
        forceLtr={forceLtr}
        fullWidth
        InputProps={inputProps || defaultInputProps}
        {...rest}
        startIcon={startIcon}
        error={error}
        autoComplete={autoComplete}
      />
      {!!action && action}
    </Box>
  )
}

import { io } from "socket.io-client"
import { IUseSocketIO, TChatMessage } from "../chatContext.types"
import { getUserStorage } from "src/pages/context/userAuth/storage"
import { useRecoilValue } from "recoil"
import { currentLangIDtate } from "src/states/signup"
import { useNavigate } from "react-router-dom"

const { credentials} = getUserStorage()
const token = credentials.token
const socket = io(process.env.REACT_APP_REDIS_SERVER_URL!, {
  query: { token },
})

export const useSocketIo = (): IUseSocketIO => {
  const currentLangID = useRecoilValue(currentLangIDtate)
  const navigate = useNavigate()

  socket.on("connect_error", (err) => {
    console.error(`socket IO connect_error due to ${err.message}`);
    navigate("/error/hub")
  });

  const socketOn = (evt: any) => {
    socket.on("chatMessagesClient", evt)
  }

  const joinUser = (userID: string) => {
    socket.emit("joinUser", userID)
  }

  const createGroup = (group_name: string) => {
    socket.emit("createGroup", group_name)
  }

  const joinGroup = (group_name: string) => {
    socket.emit("joinGroup", group_name)
  }

  const sendMessage = (message: TChatMessage) => {
    message.lang = currentLangID === 1 ? "en" : "he"
    socket.emit("chatMessage", message)
  }

  const sendEvent = (event: string, message: TChatMessage) => {
    socket.emit(event, message)
  }

  
  const socketDisconnect = () => {
    socket.removeAllListeners("chatMessagesClient")
    socket.disconnect()
  }

  return {
    sendMessage,
    joinGroup,
    createGroup,
    joinUser,
    socketOn,
    sendEvent,
    socketDisconnect
  }
}

import React, { useState, useEffect } from "react"
import { Button, StaticImage } from "src/UILibrary"
import { UserAvatar } from "./UserAvatar"
import { VoiceIndicator } from "./VoiceIndicator"
import { ChatPointOfView, USER_TYPE } from "src/constants/enums"
import { useChat } from "../context/chatContext"
import { VoiceIndicatorAI } from "./VoiceIndicatorAI"
import { useRecoilValue } from "recoil"
import { currentLangIDtate, currentLangState } from "src/states/signup"
import LoadingSpinner from "src/assets/icons/spinner-blue.svg"
import * as speechsdk from "microsoft-cognitiveservices-speech-sdk"
interface IConversationModeMyAI {
  setHideAvatar: React.Dispatch<React.SetStateAction<boolean>>
  sendMessage: (message: string) => Promise<void>
  theirName: string
  theirNameInitials: string
  theirId: string
  userNameInitials: string
  userName: string
  userType: USER_TYPE
  userID: string
  hideAvatar: boolean
  showAvatar: boolean
  pointOfView: ChatPointOfView
  group_name: string
  isAITalking: boolean
}

const ConversationModeMyAI = ({
  setHideAvatar,
  sendMessage,
  theirName,
  theirNameInitials,
  theirId,
  userNameInitials,
  userType,
  userID,
  hideAvatar,
  showAvatar,
  pointOfView,
  group_name,
}: IConversationModeMyAI) => {
  const {
    playBeep,
    isAudioPlaying,
    stopCurrentAudio,
    setAudioMode,
    getGroup,
    isRecordingConversationMode,
    setIsRecordingConversationMode,
    isRecordingAudioMode,
    audioMode,
    tokenObj
  } = useChat()
  const currentLangID = useRecoilValue(currentLangIDtate)
  const [isUserTalking, setIsUserTalking] = useState<boolean>(false)
  const [showYouCanTalkMessage, setShowYouCanTalkMessage] = useState(true)
  const [isAITalking, setIsAITalking] = useState(false)
  const currentLang = useRecoilValue(currentLangState)
  const [speechRecognizer, setSpeechRecognizer] = useState<any>(null)
  const group = getGroup(group_name)

  const setupRecognizer = async () => {
    try {
      if (!tokenObj) {
        console.error("Error fetching token")
        return
      }
      const speechConfig = speechsdk.SpeechConfig.fromAuthorizationToken(
        tokenObj.authToken,
        tokenObj.region
      )
      speechConfig.speechRecognitionLanguage = currentLangID === 2 ? "he-IL" : "en-US"

      const audioConfig = speechsdk.AudioConfig.fromDefaultMicrophoneInput()
      const recognizer = new speechsdk.SpeechRecognizer(speechConfig, audioConfig)

      recognizer.recognizing = (s, e) => {
        console.log(`RECOGNIZING: Text=${e.result.text}`)
        setIsAITalking(false)
        setShowYouCanTalkMessage(false)
        setIsUserTalking(true)
      }

      recognizer.recognized = (s, e) => {
        if (e.result.reason === speechsdk.ResultReason.RecognizedSpeech) {
          console.log(`RECOGNIZED: Text=${e.result.text}`)
          sendMessage(e.result.text)
          setIsAITalking(false)
          setShowYouCanTalkMessage(false)
          setIsUserTalking(false)
          setIsRecordingConversationMode(false)
        }
      }

      recognizer.canceled = (s, e) => {
        console.log(`CANCELED: Reason=${e.reason}`)
        if (e.reason === speechsdk.CancellationReason.Error) {
          console.log(`CANCELED: ErrorCode=${e.errorCode}`)
          console.log(`CANCELED: ErrorDetails=${e.errorDetails}`)
          console.log(`CANCELED: Did you update the subscription info?`)
        }

        recognizer.stopContinuousRecognitionAsync()
        setIsRecordingConversationMode(false)
      }

      recognizer.sessionStopped = (s, e) => {
        console.log("Session stopped.")
        recognizer.stopContinuousRecognitionAsync()
        setIsRecordingConversationMode(false)
      }

      setSpeechRecognizer(recognizer)
    } catch (err) {
      console.error("Error setting up recognizer", err)
    }
  }

  useEffect(() => {
    setupRecognizer()

    return () => {
      speechRecognizer?.close()
    }
  }, [currentLangID])

  useEffect(() => {
    return () => {
      speechRecognizer?.close()
    }
  }, [])

  useEffect(() => {
    if (isAudioPlaying) {
      setIsAITalking(true)
      setShowYouCanTalkMessage(false)
      setIsUserTalking(false)
    } else if (!isAudioPlaying && isAITalking && audioMode) {
      setIsAITalking(false)
      setShowYouCanTalkMessage(true)
      setIsRecordingConversationMode(true)
    }
  }, [isAudioPlaying])

  useEffect(() => {
    if (isRecordingConversationMode) {
      speechRecognizer?.startContinuousRecognitionAsync()
      if (!speechRecognizer) {
        setupRecognizer();
      }
    } else {
      speechRecognizer?.stopContinuousRecognitionAsync()
    }
  }, [isRecordingConversationMode, speechRecognizer])

  const toggleListening = () => {
    setIsRecordingConversationMode(!isRecordingConversationMode)
    setAudioMode(!isRecordingConversationMode)
    isRecordingConversationMode ? playBeep("end") : playBeep("start")
    if (!isRecordingConversationMode) {
      stopCurrentAudio()
    }
  }

  return (
    <>
      {showAvatar &&
        (hideAvatar && pointOfView === ChatPointOfView.MY_AI && userType === USER_TYPE.physician ? (
          <div className="showAvatarButtonMyAiWrapper">
            <Button
              role="voiceMode"
              // endIcon={<img src={Icons.voice} />}
              sx={{
                borderRadius: "8px",
              }}
              disabled={group?.status === "pause" || isRecordingAudioMode || false}
              onClick={() => {
                setHideAvatar(false)
                toggleListening()
              }}
            >
              {currentLang["voice_mode"]}
            </Button>
          </div>
        ) : !hideAvatar &&
          pointOfView === ChatPointOfView.MY_AI &&
          userType === USER_TYPE.physician ? (
          <div className="conversation">
            <Button
              role="error"
              disabled={!isRecordingConversationMode}
              // endIcon={<img src={Icons.mute} />}
              onClick={() => {
                setHideAvatar(true)
                toggleListening()
              }}
            >
              {currentLang["stop_voice_mode"]}
            </Button>
            <p>
              {
                currentLang[
                  "for_best_experience_interact_with_the_ai_only_after_it_has_finished_speaking"
                ]
              }
            </p>
            {showYouCanTalkMessage && !isAITalking && !isUserTalking && (
              <div className="conversationUserContainer">
                <UserAvatar
                  user_name={theirName}
                  user_name_initials={theirNameInitials || ""}
                  userType={USER_TYPE.ai}
                  userId={theirId || ""}
                />
                <VoiceIndicatorAI on={false} />
              </div>
            )}
            {!showYouCanTalkMessage && isAITalking && !isUserTalking && (
              <div className="conversationUserContainer">
                <UserAvatar
                  user_name={theirName}
                  user_name_initials={theirNameInitials || ""}
                  userType={USER_TYPE.ai}
                  userId={theirId || ""}
                />
                <VoiceIndicatorAI on={true} />
              </div>
            )}
            {!showYouCanTalkMessage && !isAITalking && isUserTalking && (
              <div className="conversationAIContainer">
                <UserAvatar
                  user_name={userID}
                  user_name_initials={userNameInitials || ""}
                  userType={userType}
                  userId={userID || ""}
                />
                <VoiceIndicator on={true} />
              </div>
            )}
            {!isUserTalking && !showYouCanTalkMessage && !isAITalking && (
              <div className="conversationUserContainer">
                <UserAvatar
                  user_name={theirName}
                  user_name_initials={theirNameInitials || ""}
                  userType={USER_TYPE.ai}
                  userId={theirId || ""}
                />
                <StaticImage src={LoadingSpinner} alt="spinner" />
              </div>
            )}
          </div>
        ) : null)}
    </>
  )
}

export default ConversationModeMyAI

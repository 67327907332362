import React, { useEffect, useState } from "react"
import { MenuItem, Select, SvgIcon, Typography } from "src/UILibrary"
import { svgExports } from "src/assets/icons/flags"
import Container from "../container"
import { axiosInstance } from "src/modules/axios"
import { Language } from "src/constants/types"
import Icons from "src/assets/icons"
import { useRecoilState, useRecoilValue } from "recoil"
import {
  currentLangState,
  currentIsRtlState,
  currentLangSelectedState,
  currentLangIDtate,
  langsArrayState,
} from "src/states/signup"

export const LanguageMenu = ({ staticMode }: any) => {
  const [lang, setLang] = useRecoilState(currentLangSelectedState)
  const [, setLangID] = useRecoilState(currentLangIDtate)
  const [, setIsRtl] = useRecoilState(currentIsRtlState)
  const [, setCurrentLang] = useRecoilState(currentLangState)
  const langsArray = useRecoilValue(langsArrayState)

  useEffect(() => {
    const localStorageLang = localStorage.getItem("lang") || "English"
    // alert("localStorageLang: " + localStorageLang)
    changeLanguageWithValue(localStorageLang);
  }, [lang])

  const getSelectedLang = (value: string) => {
    return langsArray.find((lang) => lang.display_name === value)
  }

  const getTranslation = async (index: number) => {
    const res = await axiosInstance.post("/languages/translations", {
      language_id: index,
    })
    setCurrentLang(res.data)
  }

  const changeLanguage = (e: any) => {
    const selectedLang = getSelectedLang(e.target.value)
    if (!selectedLang) return
    localStorage.setItem("lang", e.target.value)
    getTranslation(selectedLang.language_id)
    setLang(e.target.value)
    setIsRtl(selectedLang.isrtl)
    setLangID(selectedLang.language_id)
  }

  const changeLanguageWithValue = (value: string) => {
    const selectedLang = getSelectedLang(value)
    if (!selectedLang) return
    getTranslation(selectedLang.language_id)
    setLang(value)
    setIsRtl(selectedLang.isrtl)
    setLangID(selectedLang.language_id)
  }

  const renderLabel = (val: Language, isNoChecked?: boolean) => {
    return (
      <Container.Row>
        <SvgIcon
          sx={{ marginRight: "10px", width: "25px" }}
          src={svgExports[val.flag_name as keyof typeof svgExports]}
          alt={`${val}-flag`}
        />
        <Typography.SelectLabel sx={{ alignSelf: "center" }}>
          {val.display_name}
        </Typography.SelectLabel>
        {val.display_name === lang && (
          <SvgIcon
            sx={{
              width: "15px",
              position: "absolute",
              right: "10px",
              opacity: isNoChecked ? 0 : 1,
            }}
            src={Icons.check}
            alt={`${val}-flag`}
          />
        )}
      </Container.Row>
    )
  }

  const renderOption = (val: Language, isNoChecked?: boolean) => (
    <MenuItem
      key={val.language_id}
      sx={{ direction: "ltr" }}
      value={val.display_name}
      className="LanguageMenuItem"
    >
      {renderLabel(val, isNoChecked)}
    </MenuItem>
  )

  if (!langsArray) return <></>
  
  return (
    <Container.Flex
      className="LanguageMenu"
      style={{
        position: !staticMode ? "absolute" : "static",
        right: !staticMode ? "15px" : "0",
        top: !staticMode ? "15px" : "0",
      }}
    >
      <Select
        sx={{
          direction: "ltr",
          width: "135px",
          height: "44px",
          borderRadius: "50px",
        }}
        onChange={changeLanguage}
        defaultValue={localStorage.getItem("lang") || "English"}
        renderValue={(val) => {
          const selectedLang = getSelectedLang(val as string)
          if (selectedLang) {
            return renderLabel(selectedLang, true)
          }
          return ""
        }}
      >
        {langsArray
          .slice()
          .sort((a: Language, b: Language) => {
            if (a.display_name > b.display_name) {
              return 1
            } else if (a.display_name < b.display_name) {
              return -1
            } else {
              return 0
            }
          })
          .map((val: Language) => renderOption(val))}
      </Select>
    </Container.Flex>
  )
}

import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import { TbHexagonLetterA, TbHexagonLetterD } from "react-icons/tb"
import {Button} from "src/UILibrary"
import "src/styles/backoffice/backoffice.scss"
import InstructionsDemoTomer from "./instructions_demo/InstructionDemoTomer"
import InstructionsPage from "src/components/backoffice/Instructions/InstructionPage"

const DemoAlphaPage = () => {
  
  const [demo, setDemo] = useState(false);
  const [alpha, setAlpha] = useState(false);

  const buttonStyle = {
    width: "200px",
  }
  const iconStyle = {
    marginTop: "10px",
    marginBottom: "10px",
    fontSize: "2em",
  }
  if (demo) {
    return <InstructionsDemoTomer setDemo={setDemo} setAlpha={setAlpha}/>
  }
  if (alpha) {
    return <InstructionsPage setDemo={setDemo} setAlpha={setAlpha}/>
  }

  return (
    <>
      <div className="justify-center w-full flex items-center lg:flex-row md:flex-row sm:flex-col gap-10 ">
          <Button role="submit" type="submit" onClick={() => {setAlpha(true); setDemo(false)}} sx={{ marginBottom: "1rem" }}>
            <div className="flex items-center gap-2"><TbHexagonLetterA style={{ ...iconStyle }} /> Alpha</div>
          </Button>
          <Button role="submit" type="submit" onClick={() => {setAlpha(false); setDemo(true)}} sx={{ marginBottom: "1rem" }}>
          <div className="flex items-center gap-2"><TbHexagonLetterD style={{ ...iconStyle }} />Demo</div>
          </Button>
      </div>
    </>
  )
}
export default DemoAlphaPage

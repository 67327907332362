import React, { useEffect, useRef, useState } from "react"
import { StaticImage, TextField } from "src/UILibrary"
import Icons from "src/assets/icons"
import Container from "src/components/container"
import {
  ChatPointOfView,
  ConversationHandle,
  ConversationMessageTypes,
  USER_TYPE,
} from "src/constants/enums"
import { ChatMessage } from "./ChatMessage"
import { TypingIndicator } from "./TypingIndicator"
import { axiosInstance } from "src/modules/axios"
import { Avatar } from "./Avatar"
import { useLazyScroll } from "src/hooks/useLazyScroll"
import { useChat } from "../context/chatContext"
import { IChatWindow } from "../context/chatContext.types"
import useIsRtl from "src/hooks/useIsRtl"
import {
  extractIdsFromGroupName,
  getLastMessagePrefix,
  getUserInitialsFromFullName,
} from "../utils"
import { useActions } from "../context/hooks/useActions"
import ConversationMode from "./ConversationMode"
import { FilesGallery } from "./FilesGallery"
import { MessagesGallery } from "./MessagesGallery"
import { UserAvatar } from "./UserAvatar"
import { LoadingChat } from "./loadingChat"
import { useSocketIo } from "../context/hooks/useSocketIO"
import { useRecoilValue } from "recoil"
import { currentLangIDtate, currentLangState } from "src/states/signup"
import { IconButton, InputAdornment } from "@mui/material"
import VoiceMode from "./VoiceMode"
import { DynamicActions } from "./DynamicActions"
import { PrescriptionDialog } from "./PrescriptionDialog"
import { ReferalDialog } from "./ReferalDialog"
import useClickOutside from "src/hooks/useClickOutside"
import ConversationModeMyAI from "./ConversationModeMyAi"

const FETCH_LIMIT = 15

export function ChatMessages({
  theirName,
  theirNameInitials,
  theirId,
  group_name,
  pointOfView,
  pointOfViews,
  showAvatar = true,
  mode = "normal",
  index = 0,
  examRoomId,
  physicianRoomId,
  setTyping,
  setPinnedPhysician,
  setPinnedPatient,
  setMaximizedChat,
}: IChatWindow) {
  const {
    getChatFeed,
    clearChatMessages,
    appendChatMessage,
    chatFeeds,
    setPaused,
    userID,
    userType,
    userName,
    userNameInitials,
    setChatWindow,
    getGroup,
    setGroup,
    isAudioPlaying,
    setSelectedChatWindow,
    isSelectedChatWindow,
    setShowPhysicianCodeInput,
    chatGroups,
    audioMode,
    setAudioMode,
    appendAudioMessage,
    isRecordingAudioMode,
  } = useChat()
  const currentLang = useRecoilValue(currentLangState)
  const currentLangID = useRecoilValue(currentLangIDtate)
  const isRtl = useIsRtl()
  const { send } = useActions()
  const [messagesByDay, setMessagesByDay] = useState<any>()
  const [isHeaderDisabled, setIsHeaderDisabled] = useState(false)
  const [isChatDisabled, setIsChatDisabled] = useState(false)
  const [hideAvatar, setHideAvatar] = useState(true)
  const [avatarExpanded, setAvatarExpanded] = useState(false)
  const [showFileSelectMenu, setShowFileSelectMenu] = useState(false)
  const [chatInput, setChatInput] = useState("")
  const chatMessagesListRef = useRef<HTMLUListElement>(null)
  const chatInputRef = useRef<HTMLInputElement>(null)
  const [searchMessage, setSearchMessage] = useState<string>("")
  const [searchFile, setSearchFile] = useState<string>("")
  const [files, setFiles] = useState<any>([])
  const [showSearchMessages, setShowSearchMessages] = useState<boolean>(false)
  const [showFiles, setShowFiles] = useState<boolean>(false)
  const [foundMessages, setFoundMessages] = useState<any[]>([])
  const pagingRef = useRef<number>(0)
  const [selectedPOV, setSelectedPOV] = useState<ChatPointOfView | null>(pointOfView)
  const chatMessages = getChatFeed(group_name)?.messages || []
  const shouldLazyFetch = pagingRef.current * FETCH_LIMIT < chatMessages.length
  const group = getGroup(group_name)
  const relatedGroupID = pointOfView === ChatPointOfView.MY_AI ? examRoomId : physicianRoomId
  const relatedGroup = getGroup(relatedGroupID || "")
  const examRoomGroup = getGroup(examRoomId || "")
  const groupIsAiPaused = examRoomGroup?.isAIPaused
  const groupCreatedAt = group?.createdAt
  const groupStatus = group?.status
  const isTyping = group?.typing
  const typingUserID = group?.typingUserID
  const typingUserName = group?.typingUserName
  const isPinnedPatient = group?.isPinnedPatient
  const isPinnedPhysician = group?.isPinnedPhysician
  const isActive = group ? group.isActive : true
  const unreadMessagesPatient =
    pointOfView === ChatPointOfView.AI_AND_PATIENT
      ? group?.unreadMessages || 0
      : relatedGroup?.unreadMessages || 0
  const unreadMessagesAI =
    pointOfView === ChatPointOfView.MY_AI
      ? group?.unreadMessages || 0
      : relatedGroup?.unreadMessages || 0
  const isConnected = group?.isConnected
  const typingTimerRef = useRef<NodeJS.Timeout>()
  const typingRef = useRef<boolean>(false)
  const [fileName, setFileName] = useState("")
  const [fileSize, setFileSize] = useState("")
  const [loaded, setLoaded] = useState<string>("0")
  const [total, setTotal] = useState<string>("100")
  const loadingMessageRef = useRef<any>()
  const [fakeMessageText, setFakeMessageText] = useState<string>("")
  const [loadedFakeMessage, setLoadedFakeMessage] = useState<boolean>(false)
  const [isLoadingMessage, setIsLoadingMessage] = useState(false)
  const { sendMessage: sendSocketIOMessage } = useSocketIo()
  const [isLoading, setIsLoading] = useState(false)
  const [showReferalDialog, setShowReferalDialog] = useState(false)
  const [showPrescriptionDialog, setShowPrescriptionDialog] = useState(false)
  const aiId = extractIdsFromGroupName(group_name).aiId
  const [init, setInit] = useState(true)
  const dropdownRef = useClickOutside(() => {
    setShowFileSelectMenu(false)
  })

  useLazyScroll(chatMessagesListRef, lazyFetchMessages, shouldLazyFetch)

  useEffect(() => {
    let message = ""
    const firstGroup = getGroup(examRoomId || "")
    if (pointOfView === ChatPointOfView.AI_AND_PATIENT) {
      message =
        userType === USER_TYPE.patient
          ? `${currentLang["hello"]} ${userName.split(" ")[0]}, ${
              currentLang["this_is_the_ai_assistant_for_dr"]
            } ${theirName.split(" ")[1]}. ${currentLang["how_may_i_assist_you_today"]}`
          : `${currentLang["hello"]} ${theirName.split(" ")[1]}, ${
              currentLang["this_is_the_ai_assistant_for_dr"]
            } ${userName.split(" ")[1]}. ${currentLang["how_may_i_assist_you_today"]}`
    }

    if (userType === USER_TYPE.physician && pointOfView === ChatPointOfView.MY_AI) {
      message = `${currentLang["hello"]} ${currentLang["dr"]} ${userName.split(" ")[1]}. ${
        currentLang["note_that_im_may_still_collecting"]
      } ${firstGroup?.theirName} ${
        currentLang["case_info_i_suggest_you_wait_until_i_send_the_initial_summary"]
      }`
    }
    setFakeMessageText(message)
  }, [])

  const handleSendMessage = (evt: any) => {
    if (evt.key !== "Enter") return
    sendMessage(chatInput)
  }

  const sendMessage = async (message: string) => {
    if (!message) return
    setChatInput("")
    sendClientEvent(ConversationMessageTypes.text, message)
  }

  const sendClientEvent = (
    typeId: ConversationMessageTypes,
    message?: string,
    conversation_handler?: ConversationHandle
  ) => {
    const socketIoMessage = {
      user_name: userName,
      user_id: userID,
      group_name: group_name,
      message_type_id: typeId,
      message,
      file_name: "",
      conversation_handler: conversation_handler || ConversationHandle.DEFAULT,
    }
    sendSocketIOMessage(socketIoMessage)
  }

  useEffect(() => {
    handleScroll()
    if (chatMessages.length > 0) {
      const reducedChatMessages = Object.entries(
        [...chatMessages].reverse().reduce((acc: { [key: string]: any[] }, data: any) => {
          const date = new Date(data.date_time).toLocaleDateString()
          if (!acc[date]) {
            acc[date] = []
          }
          acc[date].push(data)
          return acc
        }, {})
      ).map(([date, messages]) => ({ date, messages }))

      console.log("reducedChatMessages chatMessages", chatMessages)
      console.log("reducedChatMessages", reducedChatMessages)

      setMessagesByDay(reducedChatMessages)
      setAudioIfNeeded(chatMessages[0])
      if (!init) {
        setTypingIfNeeded(chatMessages[0])
      }
      setInit(false)
    }
  }, [chatMessages])

  const setTypingIfNeeded = (messageData: any) => {
    if (
      [
        ConversationMessageTypes.text,
        ConversationMessageTypes.requestPrescription,
        ConversationMessageTypes.requestReferal,
        ConversationMessageTypes["image/png"],
        ConversationMessageTypes["image/jpg"],
        ConversationMessageTypes["image/jpeg"],
        ConversationMessageTypes["image/gif"],
        ConversationMessageTypes.referral,
        ConversationMessageTypes.prescription,
        ConversationMessageTypes.pdf,
        ConversationMessageTypes.word,
        ConversationMessageTypes.docx,
        ConversationMessageTypes.doc,
        ConversationMessageTypes.sendToPhysician,
        ConversationMessageTypes.billing,
        ConversationMessageTypes.requestMoreDetails,
        ConversationMessageTypes.sendMoreDetails,
        ConversationMessageTypes.sendFileOcr,
        ConversationMessageTypes.sendGuidelines,
        ConversationMessageTypes.summary,
        ConversationMessageTypes.finalize,
      ].includes(messageData.message_type_id)
    ) {
      // both the ai paused and thr typing here does not work
      if (setTyping && !groupIsAiPaused && messageData.user_id === userID) {
        setTyping({ user_id: aiId, userName: "Artificial Intelegence" }, group_name, true)
      }
      if (setTyping && isTyping && messageData.user_id === aiId) {
        setTyping({ user_id: aiId, userName: "Artificial Intelegence" }, group_name, false)
      }
    }
  }

  const setAudioIfNeeded = (messageData: any) => {
    // alert(messageData.user_id + " " + aiId + " " + messageData.message_type_id + " " + audioMode)
    if (
      messageData.user_id === aiId &&
      messageData.message_type_id === ConversationMessageTypes.text &&
      audioMode
    ) {
      appendAudioMessage(messageData.message)
    }
  }

  useEffect(() => {
    fetchFiles()
    handleScroll()
  }, [messagesByDay])

  const handleScroll = () => {
    const chatRef = chatMessagesListRef.current
    if (chatRef) {
      chatRef.dataset.pos = "top"
      if (chatRef.scrollHeight === chatRef.clientHeight) {
        chatRef.dataset.pos = "bottom"
      }

      if (chatRef?.dataset?.state == "scroll") {
        chatRef.scrollTop = chatRef.scrollHeight - +(chatRef?.dataset.top || 0)
        chatRef.dataset.state = "idle"
      } else {
        chatRef.scrollTop = chatRef.scrollHeight
      }
    }
  }

  useEffect(() => {
    if (!group_name) {
      return
    }
    setInit(true)
    setAudioMode(false)
    handleUnreadAllMessages()
    clearChatMessages(group_name)
    pagingRef.current = 0
    setSelectedPOV(
      extractIdsFromGroupName(group_name).phId
        ? ChatPointOfView.AI_AND_PATIENT
        : ChatPointOfView.MY_AI
    )
    setIsLoading(true)
    fetchMessages().then(() => {
      initWindow()
      setIsLoading(false)
    })
  }, [group_name])

  const dateLineSeperator = (date: string) => {
    const dateObj = new Date(date)
    const now = new Date().getTime()
    const timeElapsed = now - dateObj.getTime()
    // Today use case
    if (timeElapsed < 86400000) {
      return currentLang["today"]
      // Yesterday use case
    } else if (timeElapsed >= 86400000 && timeElapsed < 172800000) {
      return currentLang["yesterday"]
      // Weekday use case
    } else if (timeElapsed >= 172800000 && timeElapsed < 604800000) {
      const dayNames = [
        currentLang["sunday"],
        currentLang["monday"],
        currentLang["tuesday"],
        currentLang["wednesday"],
        currentLang["thursday"],
        currentLang["friday"],
        currentLang["saturday"],
      ]
      return dayNames[dateObj.getDay()]
      // Date use case
    } else {
      return dateObj.toLocaleDateString()
    }
  }

  const fetchFiles = () => {
    const foundLis = document.querySelectorAll(`.message`)
    if (!foundLis) return
    const temp: any[] = []
    foundLis.forEach((li: any) => {
      if (li.querySelector(".fileType")) {
        const fileNameText = li.querySelector(".fileNameText")
        const chatMessageTimeStamp = li.querySelector(".chatMessageTimeStamp")
        const hidden = li.querySelector(".hidden")
        const fileNameTextInnerText = fileNameText ? fileNameText.innerText : ""
        const chatMessageTimeStampInnerText = chatMessageTimeStamp
          ? chatMessageTimeStamp.innerText
          : ""
        const hiddenInnerText = hidden ? hidden.innerText : ""
        temp.push({
          message: fileNameTextInnerText,
          time: chatMessageTimeStampInnerText,
          downloadFileName: hiddenInnerText,
          element: li,
        })
      }
    })
    setFiles(temp)
  }

  const initWindow = () => {
    setChatInput("")

    setTimeout(() => {
      if (chatInputRef.current) {
        chatInputRef.current.focus()
      }
    }, 1)
  }

  const handleUnreadAllMessages = () => {
    if (group) {
      setGroup(group, (chatGroup) => {
        if (chatGroup.unreadMessages) {
          send("unread_all_messages", group_name)
        }
        chatGroup.unreadMessages = 0
      })
    }
  }

  async function lazyFetchMessages() {
    pagingRef.current++
    fetchMessages()
  }

  async function fetchMessages() {
    try {
      const resp = await axiosInstance.post("authenticated/conversation/get_chat_message", {
        group_name: group_name,
        skip: pagingRef.current,
        limit: FETCH_LIMIT,
        lang: currentLangID === 1 ? "en" : "he",
      })
      appendChatMessage(group_name, resp.data.messages, true)
      if (resp.data.messages.length < FETCH_LIMIT) {
        setLoadedFakeMessage(true)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const handlePaused = () => {
    if (selectedPOV === ChatPointOfView.MY_AI) {
      handlePauseAction(!groupIsAiPaused)
    } else if (selectedPOV === ChatPointOfView.AI_AND_PATIENT) {
      handlePauseAction(!groupIsAiPaused)
    }
  }

  const handleViewSelect = (selectedView: ChatPointOfView) => {
    const selected = pointOfViews.find((v) => v.view === selectedView)
    if (selected) {
      const group = getGroup(selected.groupId)
      if (group) {
        setChatWindow(group, index)
      }
    }
  }

  const handleSetPinned = () => {
    const pinnedValue = userType === USER_TYPE.patient ? isPinnedPatient : isPinnedPhysician
    const pinnedValueString =
      userType === USER_TYPE.patient ? "isPinnedPatient" : "isPinnedPhysician"
    const newState = !pinnedValue
    const action = newState ? "pin_chat" : "unpin_chat"
    // const pinnedChatGroupsCount = chatGroups.filter((group) => {
    //   if (userType === USER_TYPE.patient) {
    //     return group.isPinnedPatient
    //   } else {
    //     return group.isPinnedPhysician
    //   }
    // }).length / 2;
    // if (pinnedChatGroupsCount >= 10 && action === "pin_chat") {
    //   alert('maximun pinned chats reached')
    //   return;
    // }
    if (group) {
      setGroup(group, (chatGroup) => {
        chatGroup[pinnedValueString] = newState
        pinnedValueString === "isPinnedPatient"
          ? setPinnedPatient(pointOfViews[0].groupId, newState)
          : setPinnedPhysician(pointOfViews[0].groupId, newState)
        pinnedValueString === "isPinnedPatient"
          ? setPinnedPatient(pointOfViews[1].groupId, newState)
          : setPinnedPhysician(pointOfViews[1].groupId, newState)
      })
      const updateExamRoomPayload = {
        user_name: userName,
        user_id: userID,
        group_name: pointOfViews[0].groupId,
        message_type_id:
          action === "pin_chat" ? ConversationMessageTypes.pin : ConversationMessageTypes.unpin,
        message: "",
        file_name: "",
      }
      sendSocketIOMessage(updateExamRoomPayload)
      const updatePhysicianRoomPayload = {
        user_name: userName,
        user_id: userID,
        group_name: pointOfViews[1].groupId,
        message_type_id:
          action === "pin_chat" ? ConversationMessageTypes.pin : ConversationMessageTypes.unpin,
        message: "",
        file_name: "",
      }
      sendSocketIOMessage(updatePhysicianRoomPayload)
    }
  }

  const handleChatInput = (newVal: string) => {
    handlePause()
    handleTyping()
    setChatInput(newVal)
  }

  const handleTyping = async () => {
    if (!typingRef.current) {
      typingRef.current = true
      sendClientEvent(ConversationMessageTypes.typing_start, "", ConversationHandle.DEFAULT)
    }

    clearTimeout(typingTimerRef.current)
    typingTimerRef.current = setTimeout(() => {
      typingRef.current = false
      sendClientEvent(ConversationMessageTypes.typing_end, "", ConversationHandle.DEFAULT)
    }, 1000)
  }

  const handlePause = () => {
    if (
      userType === USER_TYPE.physician &&
      !typingRef.current &&
      pointOfView === ChatPointOfView.AI_AND_PATIENT
    ) {
      handlePauseAction(true)
    }
  }

  const handlePauseAction = (paused: boolean) => {
    if (!group_name) {
      return
    }

    if (pointOfView === ChatPointOfView.AI_AND_PATIENT && userType === USER_TYPE.physician) {
      const action = paused ? "mute_ai" : "unmute_ai"
      // send(action, group_name)
      sendClientEvent(
        action === "mute_ai" ? ConversationMessageTypes.pause : ConversationMessageTypes.unpause,
        "",
        ConversationHandle.DEFAULT
      )
      setPaused(group_name, paused)
    }
  }

  const handleSearchMessages = (value: string) => {
    setSearchMessage(value)
    const foundLis = document.querySelectorAll(`.message`)
    console.log("foundLis", foundLis)
    if (!foundLis) return
    clearSearchMessages()
    if (!value) return
    const temp: any[] = []
    foundLis.forEach((li: any) => {
      if (
        li.querySelector(".textContent") &&
        li.querySelector(".textContent").innerHTML.toLowerCase().includes(value.toLowerCase())
      ) {
        temp.push({
          message: li.querySelector(".textContent").innerText,
          time: li.querySelector(".chatMessageTimeStamp").innerText,
          element: li,
        })
      }
    })
    console.log("temp", temp)
    setFoundMessages(temp)
  }

  const scrollIntoView = (element: any) => {
    element.classList.add("found")
    element.scrollIntoView({ behavior: "smooth", block: "center" })
  }

  const clearSearchMessages = () => {
    const foundLis = document.querySelectorAll(`.chatMessageContentWrapper`)
    if (!foundLis) return
    foundLis.forEach((li: any) => {
      li.classList.remove("found")
    })
  }

  const goToMessage = (index: any) => {
    clearSearchMessages()
    scrollIntoView(foundMessages[index].element)
  }

  const downloadFile = (
    file_name: string | undefined,
    group_name: string | undefined,
    user_id: string | undefined
  ) => {
    if (!file_name || !user_id || !group_name) return
    const formData = new FormData()
    formData.append("file_name", file_name)
    formData.append("user_id", user_id)
    formData.append("group_name", group_name)
    const headers = {
      "Content-Type": "multipart/form-data",
    }
    axiosInstance
      .post("authenticated/conversation/recieve_file", formData, {
        headers: headers,
        responseType: "blob",
      })
      .then((data) => {
        console.log("download data", data.data)
        const url = URL.createObjectURL(data.data)
        console.log("url", url)
        const a = document.createElement("a")
        a.href = url
        a.download = file_name
        a.click()
      })
      .catch((err) => console.error(err))
  }

  const sendFileToServer = (
    file: any,
    message_type_id: ConversationMessageTypes,
    file_size: number
  ) => {
    const formData = new FormData()
    formData.append("file", file)
    formData.append("user_id", userID)
    formData.append("group_name", group_name)
    formData.append("OCR", "True")
    formData.append("lang", currentLangID === 1 ? "en" : "he")

    const headers = {
      "Content-Type": "multipart/form-data",
    }
    setIsLoadingMessage(true)
    setIsChatDisabled(true)

    axiosInstance
      .post("authenticated/conversation/upload", formData, {
        headers: headers,
        onUploadProgress: (progressEvent) => {
          setLoaded(progressEvent.loaded.toString())
          setTotal(progressEvent.total?.toString() || "100")
        },
      })
      .then(async (data: any) => {
        const socketIoMessage = {
          date_time: new Date().toUTCString(),
          user_name: userName,
          user_id: userID,
          group_name: group_name,
          message_type_id: message_type_id,
          message: file.name,
          file_name: data.data.file_name,
          lang: currentLangID === 1 ? "en" : "he",
          size: file_size,
          conversation_handler: ConversationHandle.SKIP_AI,
        }
        sendSocketIOMessage(socketIoMessage)
        console.log("data sendFileToServer", data)
        if (data.data.ocr_response) {
          const ocrMessage = {
            date_time: new Date().toUTCString(),
            user_name: userName,
            user_id: userID,
            group_name: group_name,
            message_type_id: ConversationMessageTypes.sendFileOcr,
            message: data.data.ocr_response,
            file_name: data.data.file_name,
            lang: currentLangID === 1 ? "en" : "he",
            size: file_size,
            conversation_handler: ConversationHandle.SKIP_UI,
          }
          sendSocketIOMessage(ocrMessage)
        }
      })
      .catch(async (err) => {
        console.error(err)

        const socketIoMessage = {
          user_name: userName,
          user_id: userID,
          group_name: group_name,
          message_type_id: ConversationMessageTypes.fileFailed,
          message: file.name,
          file_name: file.name,
          conversation_handler: ConversationHandle.DEFAULT,
        }

        sendSocketIOMessage(socketIoMessage)
      })
      .finally(() => {
        setIsChatDisabled(false)
      })
  }

  useEffect(() => {
    if (!loadingMessageRef.current) return
    const progress = loadingMessageRef.current.querySelector("progress")
    if (progress) {
      progress.setAttribute("max", total)
      progress.setAttribute("value", loaded)
    }
    if (total === loaded) {
      setTimeout(() => {
        loadingMessageRef.current = null
        setIsLoadingMessage(false)
        setLoaded("0")
        setTotal("100")
      }, 1000)
    }
  }, [chatFeeds, total, loaded])

  useEffect(() => {
    handleScroll()
  }, [isLoadingMessage])

  useEffect(() => {
    // const zeroMessages = chatMessages.length === 0
    const hasActiveGroup = group?.isActive || false
    setIsHeaderDisabled(!hasActiveGroup)
    setIsChatDisabled(groupStatus === "pause" || !hasActiveGroup)
    // (groupStatus === "pause" && userType === USER_TYPE.patient) ||
    // (groupStatus === "pause" &&
    //   userType === USER_TYPE.physician &&
    //   pointOfView === ChatPointOfView.MY_AI)
    // || !hasActiveGroup // not sure if this correct
  }, [groupStatus, chatMessages])

  const handleSelectFile = () => {
    let file
    const input = document.createElement("input")
    input.type = "file"
    input.accept = ".png, .jpeg, .jpg, .gif, .pdf, .doc, .docx"
    input.onchange = (e: any) => {
      file = e.target.files[0]
      setFileName(file.name)
      setFileSize(file.size.toString())
      switch (file.type) {
        // do i need both jpg and jpeg?
        case "image/jpeg":
          sendFileToServer(file, ConversationMessageTypes["image/jpeg"], file.size)
          break
        case "image/jpg":
          sendFileToServer(file, ConversationMessageTypes["image/jpg"], file.size)
          break
        case "image/png":
          sendFileToServer(file, ConversationMessageTypes["image/png"], file.size)
          break
        case "image/gif":
          sendFileToServer(file, ConversationMessageTypes["image/gif"], file.size)
          break
        case "application/pdf":
          sendFileToServer(file, ConversationMessageTypes.pdf, file.size)
          break
        case "application/word":
          sendFileToServer(file, ConversationMessageTypes.word, file.size)
          break
        case "application/doc":
          sendFileToServer(file, ConversationMessageTypes.doc, file.size)
          break
        case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
          sendFileToServer(file, ConversationMessageTypes.docx, file.size)
          break
        default:
          break
      }
    }
    input.click()
  }

  async function sendBilling() {
    sendSocketIOMessage({
      date_time: new Date().toUTCString(),
      user_id: userID,
      user_name: userName,
      message: "",
      message_type_id: ConversationMessageTypes.billing,
      group_name: group_name,
      conversation_handler: ConversationHandle.SKIP_UI,
      file_name: "",
    })
    if (setTyping) {
      setTyping({ user_id: aiId, userName: "Artificial Intelegence" }, group_name, true)
    }
  }

  async function sendSummary() {
    sendSocketIOMessage({
      date_time: new Date().toUTCString(),
      user_id: userID,
      user_name: userName,
      message: "",
      message_type_id: ConversationMessageTypes.summary,
      group_name: group_name,
      conversation_handler: ConversationHandle.SKIP_UI,
      file_name: "",
    })
    if (setTyping) {
      setTyping({ user_id: aiId, userName: "Artificial Intelegence" }, group_name, true)
    }
  }

  const setSelectedChat = () => {
    if (setSelectedChatWindow && group) {
      setSelectedChatWindow({
        groupOne: group.pointOfViews[0].groupId,
        groupTwo: group.pointOfViews[1].groupId,
      })
    }
  }

  return (
    <div
      className={
        isSelectedChatWindow(group_name) && mode === "multi"
          ? "chatAreaContainer chatAreaContainerSelected"
          : "chatAreaContainer"
      }
      onClick={setSelectedChat}
    >
      <div className="chatArea">
        <Container.Row
          className="chatHeader"
          style={{ height: userType === USER_TYPE.patient ? "60px" : "85px" }}
        >
          <Container.Row sx={{ gap: ".5rem" }} alignItems="center">
            {!isHeaderDisabled ? (
              <>
                <div className="avatarWrapper">
                  <div
                    className={
                      isConnected &&
                      userType === USER_TYPE.physician &&
                      pointOfView === ChatPointOfView.AI_AND_PATIENT
                        ? "avatarOnline"
                        : ""
                    }
                  />
                  <UserAvatar
                    small={true}
                    user_name={theirName}
                    user_name_initials={theirNameInitials}
                    userId={theirId}
                    userType={
                      pointOfView === ChatPointOfView.AI_AND_PATIENT &&
                      userType === USER_TYPE.physician
                        ? USER_TYPE.patient
                        : pointOfView === ChatPointOfView.AI_AND_PATIENT &&
                          userType === USER_TYPE.patient
                        ? USER_TYPE.physician
                        : USER_TYPE.ai
                    }
                  />
                </div>
                <p
                  className="chatHeaderTitle Body3Medium"
                  style={{ direction: currentLangID === 2 ? "rtl" : "ltr" }}
                >
                  {
                    userType === USER_TYPE.patient
                      ? currentLang["ai_avatar_of_dr"] + " " + theirName
                      : currentLang["ai_avatar_of_dr"] + " " + userName

                    // if (userType === USER_TYPE.physician) {
                    //   theirName = currentLang["ai_avatar_of_dr"] + " " + userName
                    // } else {
                    //   theirName = currentLang["ai_avatar_of_dr"] + " " + firstName + " " + lastName
                    // }
                    // theirNameInitials = "AI"
                  }
                </p>
              </>
            ) : (
              <div className="smallAvatar empty"></div>
            )}
          </Container.Row>
          {!isHeaderDisabled && userType === USER_TYPE.physician && (
            <div className="chatHeaderMessageContainer">
              {userType === USER_TYPE.physician && (
                <>
                  <Container.Row sx={{ gap: "0.38rem" }}>
                    <div
                      onClick={() => {
                        if (audioMode || isRecordingAudioMode) return
                        handleViewSelect(ChatPointOfView.AI_AND_PATIENT)
                      }}
                      style={{
                        gap: "0.5rem",
                        opacity: audioMode || isRecordingAudioMode ? 0.5 : 1,
                      }}
                      className={
                        selectedPOV === ChatPointOfView.AI_AND_PATIENT
                          ? "physicianChatHeaderAiAndPatientButton physicianChatHeaderSelected Body3Medium"
                          : "physicianChatHeaderAiAndPatientButton Body3Medium"
                      }
                    >
                      {groupIsAiPaused && userType === USER_TYPE.physician && (
                        <StaticImage
                          className="hasPause pauseButton"
                          src={Icons.pausedRed}
                          alt="logo"
                          width={22}
                          height={22}
                        />
                      )}
                      {currentLang["exam_room"]}
                      {unreadMessagesPatient > 0 && (
                        <div className="unReadMessages">
                          <p>{unreadMessagesPatient}</p>
                        </div>
                      )}
                    </div>
                    <div
                      onClick={() => {
                        if (audioMode || isRecordingAudioMode) return
                        handleViewSelect(ChatPointOfView.MY_AI)
                      }}
                      style={{
                        gap: "0.5rem",
                        opacity: audioMode || isRecordingAudioMode ? 0.5 : 1,
                      }}
                      className={
                        selectedPOV === ChatPointOfView.MY_AI
                          ? "physicianChatHeaderMyAiButton physicianChatHeaderSelected Body3Medium"
                          : "physicianChatHeaderMyAiButton Body3Medium"
                      }
                    >
                      {currentLang["physician_room"]}
                      {unreadMessagesAI > 0 && (
                        <div className="unReadMessages">
                          <p>{unreadMessagesAI}</p>
                        </div>
                      )}
                    </div>
                  </Container.Row>
                </>
              )}
            </div>
          )}
          <Container.Row sx={{ gap: "1.5rem", paddingRight: "1rem" }}>
            {mode === "normal" ? (
              <>
                <div
                  onClick={() => {
                    setShowFiles(false)
                    setShowSearchMessages(!showSearchMessages)
                  }}
                  style={{ zIndex: "2" }}
                  className={
                    isHeaderDisabled
                      ? "searchButton disabled cursorHover"
                      : "searchButton cursorHover"
                  }
                >
                  <StaticImage src={Icons.search} alt="logo" />
                </div>

                <div
                  onClick={handleSetPinned}
                  style={{ zIndex: "2" }}
                  className={
                    isHeaderDisabled
                      ? "searchButton disabled cursorHover"
                      : "searchButton cursorHover"
                  }
                >
                  <StaticImage
                    src={
                      (isPinnedPatient && userType === USER_TYPE.patient) ||
                      (isPinnedPhysician && userType === USER_TYPE.physician)
                        ? Icons.pinSelected
                        : Icons.pin
                    }
                    alt="logo"
                  />
                </div>
                <div
                  onClick={() => {
                    setShowSearchMessages(false)
                    setShowFiles(!showFiles)
                  }}
                  style={{ zIndex: "2" }}
                  className={
                    isHeaderDisabled
                      ? "searchButton disabled cursorHover"
                      : "searchButton cursorHover"
                  }
                >
                  <StaticImage src={showFiles ? Icons.folderFilled : Icons.folder} alt="logo" />
                </div>
              </>
            ) : (
              <>
                <StaticImage
                  src={
                    (isPinnedPatient && userType === USER_TYPE.patient) ||
                    (isPinnedPhysician && userType === USER_TYPE.physician)
                      ? Icons.pinSelected
                      : Icons.pin
                  }
                  alt="logo"
                  onClick={handleSetPinned}
                  sx={{ zIndex: "2" }}
                  className={isHeaderDisabled ? "disabled cursorHover" : "cursorHover"}
                />
                <StaticImage
                  src={Icons.maximize}
                  alt="logo"
                  onClick={() => {
                    if (setMaximizedChat) {
                      setMaximizedChat(group)
                    }
                  }}
                  sx={{ zIndex: "2" }}
                  width={20}
                  height={20}
                  className={isHeaderDisabled ? "disabled cursorHover" : "cursorHover"}
                />
              </>
            )}
          </Container.Row>
        </Container.Row>
        {isLoading ? (
          <div className="chatWrapper">
            <LoadingChat />
          </div>
        ) : chatGroups.length === 0 ? (
          <Container.Column justifyContent="center" alignItems="center" className="illustration">
            <StaticImage src={Icons.personIllustration} alt="logo" width={175} />
            <p className="illustrationTitle">
              {userType === USER_TYPE.patient
                ? currentLang["add_physicians"]
                : currentLang["add_patients"]}
            </p>
            <p className="illustrationParagrath">
              {userType === USER_TYPE.patient
                ? currentLang["no_physicians_added_yet"]
                : currentLang["no_patients_added_yet"]}
            </p>
            {/* {userType === USER_TYPE.physician && (
              <StaticImage src={Icons.users} className="cursorHover" alt="logo" />
            )} */}
            {userType === USER_TYPE.patient && (
              <button onClick={() => setShowPhysicianCodeInput(true)}>
                <StaticImage src={Icons.userPlus} className="cursorHover" alt="logo" />
                {currentLang["add_physician_code"]}
              </button>
            )}
          </Container.Column>
        ) : (
          <div
            className="chatWrapper"
            style={{ borderBottomRightRadius: avatarExpanded ? "20px" : "0" }}
          >
            <div
              className="chatInformationArea"
              style={{
                height:
                  !hideAvatar &&
                  pointOfView === ChatPointOfView.MY_AI &&
                  userType === USER_TYPE.physician
                    ? "100%"
                    : "max-content",
              }}
            >
              {groupIsAiPaused &&
                selectedPOV === ChatPointOfView.AI_AND_PATIENT &&
                userType === USER_TYPE.physician && (
                  <p className="chatHeaderMessage" style={{ direction: isRtl ? "rtl" : "ltr" }}>
                    {currentLang["ai_was_paused_tap_play_to_turn_it_on"]}
                  </p>
                )}
              {selectedPOV === ChatPointOfView.MY_AI && (
                <p className="chatHeaderMessage" style={{ backgroundColor: "#15788E" }}>
                  {currentLang["you_are_now_chatting_exclusively_with_your_ai"]}
                </p>
              )}
              {groupIsAiPaused && userType === USER_TYPE.patient && (
                <p className="chatHeaderMessage">
                  {currentLang["coversation_with"] + " " + currentLang["human_caregiver"]}
                </p>
              )}
              {!isChatDisabled &&
                selectedPOV === ChatPointOfView.AI_AND_PATIENT &&
                userType === USER_TYPE.physician && (
                  <div
                    className="playPauseChatBottonContainer"
                    style={{ backgroundColor: groupIsAiPaused ? "#f04438" : "#7A5AF8" }}
                    onClick={() => handlePaused()}
                  >
                    <StaticImage
                      className="pauseButton"
                      src={Icons.pause}
                      alt="logo"
                      width={24}
                      height={24}
                    />
                  </div>
                )}
              {showAvatar && hideAvatar && userType === USER_TYPE.patient && (
                <>
                  {!groupIsAiPaused && (
                    <button onClick={() => setHideAvatar(false)} className="showAvatarButton">
                      {currentLang["show_assistant"]}
                    </button>
                  )}
                </>
              )}
              {!hideAvatar && userType === USER_TYPE.patient && (
                <div
                  className={avatarExpanded ? "chatAvatarContainerExpanded" : "chatAvatarContainer"}
                >
                  <Avatar />
                  <button onClick={() => setHideAvatar(true)} className="closeAvatarButton">
                    X
                  </button>
                  <ConversationMode
                    avatarExpanded={avatarExpanded}
                    setAvatarExpanded={setAvatarExpanded}
                    sendMessage={sendMessage}
                    group_name={group_name}
                  />
                </div>
              )}

              {showAvatar &&
                pointOfView === ChatPointOfView.MY_AI &&
                userType === USER_TYPE.physician && (
                  <ConversationModeMyAI
                    setHideAvatar={setHideAvatar}
                    sendMessage={sendMessage}
                    theirNameInitials={theirNameInitials}
                    theirId={theirId}
                    userNameInitials={userNameInitials}
                    userType={userType}
                    userID={userID}
                    hideAvatar={hideAvatar}
                    showAvatar={showAvatar}
                    pointOfView={pointOfView}
                    group_name={group_name}
                    userName={userName}
                    theirName={theirName}
                    isAITalking={isAudioPlaying}
                  />
                )}
            </div>
            <div className="chat">
              <ul className="chatMessagesList" ref={chatMessagesListRef}>
                {loadedFakeMessage && (
                  <>
                    {/* <ChatMessage
                      group_name={group_name}
                      examRoomId={examRoomId || ""}
                      physicianRoomId={physicianRoomId || ""}
                      message={dateLineSeperator(groupCreatedAt?.toString() || new Date().toString())}
                      message_type_id={ConversationMessageTypes.seperator}
                      conversation_handler={ConversationHandle.DEFAULT}
                    /> */}
                    <ChatMessage
                      user_id="Assistant"
                      user_name="Assistant"
                      user_name_initials="AI"
                      date_time={groupCreatedAt}
                      message_type_id={ConversationMessageTypes.fakeMessage}
                      group_name={group_name}
                      examRoomId={examRoomId || ""}
                      physicianRoomId={physicianRoomId || ""}
                      message={fakeMessageText}
                      conversation_handler={ConversationHandle.DEFAULT}
                    />
                  </>
                )}
                {messagesByDay &&
                  messagesByDay.map((group: any) => (
                    <>
                      <ChatMessage
                        group_name={group_name}
                        examRoomId={examRoomId || ""}
                        physicianRoomId={physicianRoomId || ""}
                        message={dateLineSeperator(group.date)}
                        message_type_id={ConversationMessageTypes.seperator}
                        conversation_handler={ConversationHandle.DEFAULT}
                      />
                      {group.messages.map((data: any, index: number) => (
                        <div className="chatMessageWrapper" key={index}>
                          <ChatMessage
                            date_time={data.date_time}
                            message={data.message}
                            message_type_id={data.message_type_id}
                            size={data.size || 0}
                            user_id={data.user_id}
                            user_name_initials={
                              data.user_id === userID ? userNameInitials : theirNameInitials
                            }
                            group_name={group_name}
                            examRoomId={examRoomId || ""}
                            physicianRoomId={physicianRoomId || ""}
                            file_name={data.file_name}
                            conversation_handler={data.conversation_handler}
                          />
                          {data.message_type_id === ConversationMessageTypes.finalize &&
                            data.conversation_handler !== ConversationHandle.SKIP_UI && (
                              <ChatMessage
                                group_name={group_name}
                                examRoomId={examRoomId || ""}
                                physicianRoomId={physicianRoomId || ""}
                                message="Issue Finalized"
                                message_type_id={ConversationMessageTypes.seperator}
                                conversation_handler={ConversationHandle.DEFAULT}
                              />
                            )}
                        </div>
                      ))}
                    </>
                  ))}

                {isLoadingMessage && (
                  <div className="chatMessageWrapper">
                    <ChatMessage
                      date_time=""
                      message=""
                      message_type_id={ConversationMessageTypes.loadingFile}
                      user_id={userID}
                      size={fileSize}
                      user_name_initials={userNameInitials}
                      group_name={group_name}
                      file_name={fileName}
                      examRoomId={examRoomId || ""}
                      physicianRoomId={physicianRoomId || ""}
                      ref={loadingMessageRef}
                      conversation_handler={ConversationHandle.DEFAULT}
                    />
                  </div>
                )}
              </ul>

              {isTyping && !audioMode && (
                <TypingIndicator
                  userId={typingUserID || ""}
                  user_name={typingUserName || ""}
                  user_name_initials={getUserInitialsFromFullName(typingUserName || "") || ""}
                  userType={
                    typingUserID === aiId
                      ? USER_TYPE.ai
                      : userType === USER_TYPE.patient
                      ? USER_TYPE.physician
                      : USER_TYPE.patient
                  }
                />
              )}
            </div>
          </div>
        )}
        <Container.Row className="chatInputWrapper">
          {avatarExpanded ? (
            <></>
          ) : isActive ? (
            <div className="chatInputContainer">
              <div className="chatInputInnerContainer">
                {group && (
                  <DynamicActions
                    pointOfView={pointOfView}
                    status={group.status}
                    userType={userType}
                    setShowReferalDialog={setShowReferalDialog}
                    setShowPrescriptionDialog={setShowPrescriptionDialog}
                    examRoomId={examRoomId || ""}
                    physicianRoomId={physicianRoomId || ""}
                  />
                )}
                <div className="chatInputAndMenuContainer">
                  <Container.Row sx={{ paddingRight: ".5rem" }}>
                    {userType === USER_TYPE.physician && pointOfView === ChatPointOfView.MY_AI && (
                      <div className="selectFileMenuButton">
                        <StaticImage
                          src={Icons.plus}
                          onClick={() => setShowFileSelectMenu(!showFileSelectMenu)}
                          alt="logo"
                          width={20}
                          height={20}
                          className={
                            isChatDisabled ||
                            (!isSelectedChatWindow(group_name) && mode === "multi") ||
                            (isTyping && typingUserID === aiId)
                              ? "disabled cursorHover"
                              : "cursorHover"
                          }
                        />
                        {showFileSelectMenu && (
                          <div className="selectFileMenuWrapper" ref={dropdownRef}>
                            <div
                              className="selectFileMenuItem"
                              onClick={() => {
                                handleSelectFile()
                                setShowFileSelectMenu(false)
                              }}
                            >
                              <StaticImage
                                src={Icons.paperclip}
                                alt="logo"
                                width={20}
                                height={20}
                              />
                              {currentLang["file"]}
                            </div>
                            <div
                              className="selectFileMenuItem"
                              onClick={() => {
                                sendBilling()
                                setShowFileSelectMenu(false)
                              }}
                            >
                              <StaticImage src={Icons.billing} alt="logo" width={20} height={20} />
                              {currentLang["billing"]}
                            </div>
                            <div
                              className="selectFileMenuItem"
                              onClick={() => {
                                sendSummary()
                                setShowFileSelectMenu(false)
                              }}
                            >
                              <StaticImage src={Icons.summary} alt="logo" width={20} height={20} />
                              {currentLang["summary"]}
                            </div>
                            {/* <div
                              className="selectFileMenuItem"
                              onClick={() => {
                                sendPrescription()
                                setShowFileSelectMenu(false)
                              }}
                            >
                              <StaticImage
                                src={Icons.prescription}
                                alt="logo"
                                width={20}
                                height={20}
                              />
                              {currentLang["prescription"]}
                            </div> */}
                          </div>
                        )}
                      </div>
                    )}
                    {pointOfView === ChatPointOfView.AI_AND_PATIENT && (
                      <div className="roundedIcon">
                        <StaticImage
                          src={Icons.paperclip}
                          onClick={handleSelectFile}
                          alt="logo"
                          width={20}
                          height={20}
                          className={
                            isChatDisabled ||
                            (!isSelectedChatWindow(group_name) && mode === "multi") ||
                            (isTyping && typingUserID === aiId)
                              ? "disabled cursorHover"
                              : "cursorHover"
                          }
                        />
                      </div>
                    )}
                  </Container.Row>
                  <TextField
                    inputRef={chatInputRef}
                    type="text"
                    initialType="text"
                    placeholder={
                      currentLang["type_your_message_here_or_click_the_microphone_to_dictate"]
                    }
                    value={chatInput}
                    onChange={(evt) => handleChatInput(evt.target.value)}
                    onKeyDown={handleSendMessage}
                    onFocus={handleUnreadAllMessages}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment className="noHover" position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => sendMessage(chatInput)}
                            edge="start"
                            className={
                              isChatDisabled ||
                              chatInput.length === 0 ||
                              (!isSelectedChatWindow(group_name) && mode === "multi") ||
                              (isTyping && typingUserID === aiId)
                                ? "disabled cursorHover"
                                : "cursorHover"
                            }
                          >
                            <StaticImage src={Icons.send} alt="logo" width={20} height={20} />
                          </IconButton>
                          <VoiceMode
                            setChatInput={setChatInput}
                            isDisabled={
                              audioMode ||
                              isChatDisabled ||
                              (!isSelectedChatWindow(group_name) && mode === "multi") ||
                              (isTyping && typingUserID === aiId)
                            }
                            sendMessage={sendMessage}
                            chatInput={chatInput}
                            group_name={group_name}
                          />
                        </InputAdornment>
                      ),
                    }}
                    fullWidth
                    disabled={isChatDisabled || (isTyping && typingUserID === aiId)}
                    className={
                      isChatDisabled || (!isSelectedChatWindow(group_name) && mode === "multi")
                        ? "disabled Body2 messageInput"
                        : "Body2 messageInput"
                    }
                  />
                </div>
              </div>
            </div>
          ) : (
            <p className="pleaseContactToReactivate">
              {currentLang["please_contact_your_clinic_to_reactivate_the_service"]}
            </p>
          )}
        </Container.Row>
      </div>
      {showReferalDialog && (
        <ReferalDialog
          closeDialog={() => setShowReferalDialog(false)}
          group_one_name={pointOfViews[0].groupId}
          group_two_name={pointOfViews[1].groupId}
          patient_name={examRoomGroup?.theirName!}
          active_group_name={group_name}
        />
      )}
      {showPrescriptionDialog && (
        <PrescriptionDialog
          closeDialog={() => setShowPrescriptionDialog(false)}
          group_one_name={pointOfViews[0].groupId}
          group_two_name={pointOfViews[1].groupId}
          patient_name={examRoomGroup?.theirName!}
          physician_name={userName}
          active_group_name={group_name}
        />
      )}
      {showSearchMessages && (
        <MessagesGallery
          isRtl={isRtl}
          setShowSearchMessages={setShowSearchMessages}
          searchMessage={searchMessage}
          handleSearchMessages={handleSearchMessages}
          foundMessages={foundMessages}
          getLastMessagePrefix={getLastMessagePrefix}
          goToMessage={goToMessage}
        />
      )}
      {showFiles && (
        <FilesGallery
          searchFile={searchFile}
          setSearchFile={setSearchFile}
          setShowFiles={setShowFiles}
          isRtl={isRtl}
          files={files}
          downloadFile={downloadFile}
          group_name={group_name}
          userID={userID}
        />
      )}
    </div>
  )
}

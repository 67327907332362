import React from "react"
import { Link } from "react-router-dom"
import { FaFileInvoiceDollar, FaMoneyBillTrendUp } from "react-icons/fa6"
import { IoPersonAddSharp } from "react-icons/io5"
import { TbDeviceDesktopAnalytics } from "react-icons/tb"
import Button from "src/components/backoffice/Analytics/not_used/Button"
import "src/styles/backoffice/backoffice.scss"
import { IoMdArrowRoundBack } from "react-icons/io"

const HomePage = () => {
  const buttonStyle = {
    width: "200px",
  }
  const iconStyle = {
    marginTop: "10px",
    fontSize: "2em",
  }
  return (
    <>
    <Link to="/malkishua/alpha-demo" style={{ position: "absolute", left: "20px", top: "5px" }}>
      <p> <IoMdArrowRoundBack />to AlphaDemo page</p>
    </Link>
      <div className="button-container w-full">
        <Link to="/malkishua/billing" style={{ marginLeft: "10px" }}>
          <Button
            className="button-item bg-green-500 ml-5 text-white font-bold py-2 px-8 rounded shadow border-2 border-green-500 hover:bg-transparent hover:text-green-500 transition-all duration-300 gap-5"
            text="Billing"
            icon={<FaFileInvoiceDollar style={{ ...iconStyle }} />}
            style={buttonStyle}
          />
        </Link>
        {/* <Link to="/malkishua/analytics" style={{ marginLeft: "10px" }}> */}
        <Link to="/malkishua/users" style={{ marginLeft: "10px" }}>
          <Button
            className="button-item bg-red-500 ml-5 text-white font-bold py-2 px-8 rounded shadow border-2 border-red-500 hover:bg-transparent hover:text-red-500 transition-all duration-300 gap-5"
            text="Analytics"
            icon={<TbDeviceDesktopAnalytics style={{ ...iconStyle }} />}
            style={buttonStyle}
          />
        </Link>
        {/* <Link to="/malkishua/billinganalytics" style={{ marginLeft: "10px" }}>
          <Button
            className="button-item bg-blue-500 ml-5 text-white font-bold py-2 px-8 rounded shadow border-2 border-blue-500 hover:bg-transparent hover:text-blue-500 transition-all duration-300 gap-5"
            text="Billing Analytics"
            icon={<FaMoneyBillTrendUp style={{ ...iconStyle }} />}
            style={buttonStyle}
          />
        </Link> */}
        {/* <Link to="/malkishua/controlsystem" style={{ marginLeft: "10px" }}>
          <Button
            className="button-item bg-purple-500 ml-5 text-white font-bold py-2 px-8 rounded shadow border-2 border-purple-500 hover:bg-transparent hover:text-purple-500 transition-all duration-300 gap-5"
            text="Control System"
            icon={<IoPersonAddSharp style={{ ...iconStyle }} />}
            style={buttonStyle}
          />
        </Link> */}
      </div>
    </>
  )
}
export default HomePage

import React, { createContext, useContext, useRef, useState } from "react"
import { IChatGroup, ISelectedChatContext, ISelectedChatProvider } from "./chatContext.types"
import { useChatFeeds } from "./hooks/useChatFeeds"
import { useChatWindows } from "./hooks/useChatWindows"
import { useMe } from "./hooks/useMe"
import { useChatGroups } from "./hooks/useChatGroups"
import { useAudio } from "./hooks/useAudio"
import { CHAT_SIDE_MENU_OPTIONS } from "src/constants/enums"

export const selectedChatContext = createContext<ISelectedChatContext | null>(null)

const Provider = selectedChatContext.Provider

export const ChatProvider = ({ children }: ISelectedChatProvider) => {
  const chatContext = useChatFeeds()
  const chatWindowsContext = useChatWindows()
  const me = useMe()
  const chatGroupsContext = useChatGroups()
  const audioContext = useAudio()
  const [selectedSideMenu, setSelectedSideMenu] = useState<CHAT_SIDE_MENU_OPTIONS>(
    CHAT_SIDE_MENU_OPTIONS.chat
  )
  const [showPhysicianCodeInput, setShowPhysicianCodeInput] = useState(false);

  const value = {
    ...chatContext,
    ...chatWindowsContext,
    ...me,
    ...chatGroupsContext,
    ...audioContext,
    selectedSideMenu,
    setSelectedSideMenu,
    showPhysicianCodeInput,
    setShowPhysicianCodeInput
  }

  return <Provider value={value}>{children}</Provider>
}

export const useChat = () => {
  const state = useContext(selectedChatContext)
  if (!state) {
    throw Error("useChat cannot be null")
  }

  return state
}

export function requestNotifications() {
  Notification.requestPermission();
  // .then((perm) => {
  //   if (perm === "granted") {
  //     handleAllowNotifications(userID, true)
  //   } else if (perm === "denied") {
  //     handleAllowNotifications(userID, false)
  //   }
  // })
}

// const handleAllowNotifications = (user_id: string, enabled: boolean) => {
//   axiosInstance
//     .post("authenticated/settings/push_notifications_enabling", {
//       user_id: user_id,
//       enabled: enabled,
//     })
//     .catch((err) => console.error(err))
// }

export function showNotification(
  message: string,
  from: string,
  handleChatWindow: () => void
) {
  if (Notification.permission === "granted" && document.visibilityState === "hidden") {
    const options = {
      body: message,
    }
    let notification
    if (from) {
      notification = new Notification(from, options)
    } else {
      notification = new Notification("Incoming message", options)
    }

    notification.addEventListener("click", () => {
      handleChatWindow()
      window.parent.focus() // like window.focus but works for safari
    })
  }
}

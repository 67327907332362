import React from "react"

export default function Table({ list, total, fee }) {
  return (
    <>
      <table width="100%" className="mb-10">
              <thead>
                  <tr className="bg-gray-100 p-1">
                      <td className="font-bold">Treatment description</td>
                      <td className="font-bold">Quantity</td>
                      <td className="font-bold">Price</td>
                      <td className="font-bold">Amount</td>
                      <td className="font-bold">20% Fee</td>
                  </tr>
              </thead>
              {list.map(({id, description, quantity, price, amount}) => (
                  <React.Fragment key={id}>
                      <tbody>
                          <tr>
                              <td>{description}</td>
                              <td>{quantity}</td> 
                              <td>{price}</td>
                              <td>{amount}</td>
                              <td>{amount * fee}</td>
                          </tr>
                      </tbody>
                  </React.Fragment>
              ))}
          </table>
          <div>
                <h2 className="flex items-end justify-end text-gray-800 text-4xl font-bold">
                  NIS. {total.toLocaleString()}
                </h2>
            </div>
    </>
  )
}
import React from "react"
import { Box, Checkbox, Typography, Link, CheckboxProps } from "src/UILibrary"
import Container from "src/components/container"
import { useRecoilValue } from "recoil"
import { currentLangState } from "src/states/signup"
import Icons from "src/assets/icons"

export const AgreeBox: React.FC<CheckboxProps & { error?: boolean; helperText?: string }> = ({
  error,
  helperText,
  ...rest
}) => {
  const currentLang = useRecoilValue(currentLangState)

  return (
    <>
      <Container.Flex
        sx={{
          alignItems: "start",
          justifyContent: "center",
        }}
      >
        <Checkbox {...rest} sx={{ mr: "0.25rem" }} />
        <Typography.Detail>
        <p>{currentLang["i_have_read_and_agree_with_the"]}</p>
          <a href="https://www.life-medic.com/terms-clinic" target="_blank" className="link flex gap-1">
            {currentLang["terms_and_conditions"]} 
            <img src={Icons.externalIcon} width="15px"></img>
          </a>  
        
          {/* <br /> */}
          {/* {" / "}
          <a href="privacypolicy" target="_blank">
            {currentLang["privacy_and_policy"]}
          </a> */}
          {/* {t("signup.agree_to")} */}
        </Typography.Detail>
      </Container.Flex>
      <Box>
        {error && !!helperText && (
          <Typography.Action sx={{ color: "error.main", textAlign: "center" }}>
            {helperText}
          </Typography.Action>
        )}
      </Box>
    </>
  )
}

import React from "react"
import { Button } from "src/UILibrary"
import { useSocketIo } from "../../context/hooks/useSocketIO"
import { ConversationHandle, ConversationMessageTypes } from "src/constants/enums"
import { useChat } from "../../context/chatContext"
import { useRecoilValue } from "recoil"
import { currentLangIDtate, currentLangState } from "src/states/signup"
import { TChatMessage } from "../../context/chatContext.types"
import { extractIdsFromGroupName } from "../../utils"

interface ISendToPhysicianActionProps {
  examRoomID: string
  physicianRoomID: string
}

export const SendToPhysicianAction = ({ examRoomID, physicianRoomID }: ISendToPhysicianActionProps) => {
  const currentLang = useRecoilValue(currentLangState)
  const currentLangID = useRecoilValue(currentLangIDtate)
  const { selectedChatGroupName, getGroup, userName, setGroupStatus } = useChat()
  const { sendMessage } = useSocketIo()
  const group = getGroup(selectedChatGroupName)

  const handleSendMessage = () => {
    if (!group) return
    const user_id_physician = extractIdsFromGroupName(examRoomID).phId
    const messageToServer: TChatMessage = {
      date_time: new Date().toUTCString(),
      user_id: user_id_physician,
      user_name: userName,
      message: "",
      message_type_id: ConversationMessageTypes.sendToPhysician,
      group_name: group.group_name,
      conversation_handler: ConversationHandle.SKIP_UI,
      file_name: "",
    }
    if (currentLangID === 2) messageToServer.lang = "he"
    setGroupStatus(group.group_name, "pause")
    sendMessage(messageToServer)
  }

  return <Button className="dynamicActionButton Body3Medium" onClick={handleSendMessage}>{currentLang["send_the_information_to_my_physician"]}</Button>
}

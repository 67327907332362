import AC from "./AC.svg"
import AD from "./AD.svg"
import AE from "./AE.svg"
import AF from "./AF.svg"
import AG from "./AG.svg"
import AI from "./AI.svg"
import AL from "./AL.svg"
import AM from "./AM.svg"
import AO from "./AO.svg"
import AQ from "./AQ.svg"
import AR from "./AR.svg"
import AS from "./AS.svg"
import AT from "./AT.svg"
import AU from "./AU.svg"
import AW from "./AW.svg"
import AX from "./AX.svg"
import AZ from "./AZ.svg"
import BA from "./BA.svg"
import BB from "./BB.svg"
import BD from "./BD.svg"
import BE from "./BE.svg"
import BF from "./BF.svg"
import BG from "./BG.svg"
import BH from "./BH.svg"
import BI from "./BI.svg"
import BJ from "./BJ.svg"
import BL from "./BL.svg"
import BM from "./BM.svg"
import BN from "./BN.svg"
import BO from "./BO.svg"
import BQ from "./BQ.svg"
import BR from "./BR.svg"
import BS from "./BS.svg"
import BT from "./BT.svg"
import BV from "./BV.svg"
import BW from "./BW.svg"
import BY from "./BY.svg"
import BZ from "./BZ.svg"
import CA from "./CA.svg"
import CC from "./CC.svg"
import CD from "./CD.svg"
import CF from "./CF.svg"
import CG from "./CG.svg"
import CH from "./CH.svg"
import CI from "./CI.svg"
import CK from "./CK.svg"
import CL from "./CL.svg"
import CM from "./CM.svg"
import CN from "./CN.svg"
import CO from "./CO.svg"
import CR from "./CR.svg"
import CU from "./CU.svg"
import CV from "./CV.svg"
import CW from "./CW.svg"
import CX from "./CX.svg"
import CY from "./CY.svg"
import CZ from "./CZ.svg"
import DE from "./DE.svg"
import DJ from "./DJ.svg"
import DK from "./DK.svg"
import DM from "./DM.svg"
import DO from "./DO.svg"
import DZ from "./DZ.svg"
import EC from "./EC.svg"
import EE from "./EE.svg"
import EG from "./EG.svg"
import EH from "./EH.svg"
import ER from "./ER.svg"
import ES from "./ES.svg"
import ET from "./ET.svg"
import EU from "./EU.svg"
import FI from "./FI.svg"
import FJ from "./FJ.svg"
import FK from "./FK.svg"
import FM from "./FM.svg"
import FO from "./FO.svg"
import FR from "./FR.svg"
import GA from "./GA.svg"
import GB from "./GB.svg"
import GD from "./GD.svg"
import GE from "./GE.svg"
import GF from "./GF.svg"
import GG from "./GG.svg"
import GH from "./GH.svg"
import GI from "./GI.svg"
import GL from "./GL.svg"
import GM from "./GM.svg"
import GN from "./GN.svg"
import GP from "./GP.svg"
import GQ from "./GQ.svg"
import GR from "./GR.svg"
import GS from "./GS.svg"
import GT from "./GT.svg"
import GU from "./GU.svg"
import GW from "./GW.svg"
import GY from "./GY.svg"
import HK from "./HK.svg"
import HM from "./HM.svg"
import HN from "./HN.svg"
import HR from "./HR.svg"
import HT from "./HT.svg"
import HU from "./HU.svg"
import ID from "./ID.svg"
import IE from "./IE.svg"
import IL from "./IL.svg"
import IM from "./IM.svg"
import IN from "./IN.svg"
import IO from "./IO.svg"
import IQ from "./IQ.svg"
import IR from "./IR.svg"
import IS from "./IS.svg"
import IT from "./IT.svg"
import JE from "./JE.svg"
import JM from "./JM.svg"
import JO from "./JO.svg"
import JP from "./JP.svg"
import KE from "./KE.svg"
import KG from "./KG.svg"
import KH from "./KH.svg"
import KI from "./KI.svg"
import KM from "./KM.svg"
import KN from "./KN.svg"
import KP from "./KP.svg"
import KR from "./KR.svg"
import KW from "./KW.svg"
import KY from "./KY.svg"
import KZ from "./KZ.svg"
import LA from "./LA.svg"
import LB from "./LB.svg"
import LC from "./LC.svg"
import LI from "./LI.svg"
import LK from "./LK.svg"
import LR from "./LR.svg"
import LS from "./LS.svg"
import LT from "./LT.svg"
import LU from "./LU.svg"
import LV from "./LV.svg"
import LY from "./LY.svg"
import MA from "./MA.svg"
import MC from "./MC.svg"
import MD from "./MD.svg"
import ME from "./ME.svg"
import MF from "./MF.svg"
import MG from "./MG.svg"
import MH from "./MH.svg"
import MK from "./MK.svg"
import ML from "./ML.svg"
import MM from "./MM.svg"
import MN from "./MN.svg"
import MO from "./MO.svg"
import MP from "./MP.svg"
import MQ from "./MQ.svg"
import MR from "./MR.svg"
import MS from "./MS.svg"
import MT from "./MT.svg"
import MU from "./MU.svg"
import MV from "./MV.svg"
import MW from "./MW.svg"
import MX from "./MX.svg"
import MY from "./MY.svg"
import MZ from "./MZ.svg"
import NA from "./NA.svg"
import NC from "./NC.svg"
import NE from "./NE.svg"
import NF from "./NF.svg"
import NG from "./NG.svg"
import NI from "./NI.svg"
import NL from "./NL.svg"
import NO from "./NO.svg"
import NP from "./NP.svg"
import NR from "./NR.svg"
import NU from "./NU.svg"
import NZ from "./NZ.svg"
import OM from "./OM.svg"
import PA from "./PA.svg"
import PE from "./PE.svg"
import PF from "./PF.svg"
import PG from "./PG.svg"
import PH from "./PH.svg"
import PK from "./PK.svg"
import PL from "./PL.svg"
import PM from "./PM.svg"
import PN from "./PN.svg"
import PR from "./PR.svg"
import PS from "./PS.svg"
import PT from "./PT.svg"
import PW from "./PW.svg"
import PY from "./PY.svg"
import QA from "./QA.svg"
import RE from "./RE.svg"
import RO from "./RO.svg"
import RS from "./RS.svg"
import RU from "./RU.svg"
import RW from "./RW.svg"
import SA from "./SA.svg"
import SB from "./SB.svg"
import SC from "./SC.svg"
import SD from "./SD.svg"
import SE from "./SE.svg"
import SG from "./SG.svg"
import SH from "./SH.svg"
import SI from "./SI.svg"
import SJ from "./SJ.svg"
import SK from "./SK.svg"
import SL from "./SL.svg"
import SM from "./SM.svg"
import SN from "./SN.svg"
import SO from "./SO.svg"
import SR from "./SR.svg"
import SS from "./SS.svg"
import ST from "./ST.svg"
import SV from "./SV.svg"
import SX from "./SX.svg"
import SY from "./SY.svg"
import SZ from "./SZ.svg"
import TA from "./TA.svg"
import TC from "./TC.svg"
import TD from "./TD.svg"
import TF from "./TF.svg"
import TG from "./TG.svg"
import TH from "./TH.svg"
import TJ from "./TJ.svg"
import TK from "./TK.svg"
import TL from "./TL.svg"
import TM from "./TM.svg"
import TN from "./TN.svg"
import TO from "./TO.svg"
import TR from "./TR.svg"
import TT from "./TT.svg"
import TV from "./TV.svg"
import TW from "./TW.svg"
import TZ from "./TZ.svg"
import UA from "./UA.svg"
import UG from "./UG.svg"
import UM from "./UM.svg"
import US from "./US.svg"
import UY from "./UY.svg"
import UZ from "./UZ.svg"
import VA from "./VA.svg"
import VC from "./VC.svg"
import VE from "./VE.svg"
import VG from "./VG.svg"
import VI from "./VI.svg"
import VN from "./VN.svg"
import VU from "./VU.svg"
import WF from "./WF.svg"
import WS from "./WS.svg"
import XK from "./XK.svg"
import YE from "./YE.svg"
import YT from "./YT.svg"
import ZA from "./ZA.svg"
import ZM from "./ZM.svg"
import ZW from "./ZW.svg"

export const svgExports = {
  US,
  AC,
  AD,
  AE,
  AF,
  AG,
  AI,
  AL,
  AM,
  AO,
  AQ,
  AR,
  AS,
  AT,
  AU,
  AW,
  AX,
  AZ,
  BA,
  BB,
  BD,
  BE,
  BF,
  BG,
  BH,
  BI,
  BJ,
  BL,
  BM,
  BN,
  BO,
  BQ,
  BR,
  BS,
  BT,
  BV,
  BW,
  BY,
  BZ,
  CA,
  CC,
  CD,
  CF,
  CG,
  CH,
  CI,
  CK,
  CL,
  CM,
  CN,
  CO,
  CR,
  CU,
  CV,
  CW,
  CX,
  CY,
  CZ,
  DE,
  DJ,
  DK,
  DM,
  DO,
  DZ,
  EC,
  EE,
  EG,
  EH,
  ER,
  ES,
  ET,
  EU,
  FI,
  FJ,
  FK,
  FM,
  FO,
  FR,
  GA,
  GB,
  GD,
  GE,
  GF,
  GG,
  GH,
  GI,
  GL,
  GM,
  GN,
  GP,
  GQ,
  GR,
  GS,
  GT,
  GU,
  GW,
  GY,
  HK,
  HM,
  HN,
  HR,
  HT,
  HU,
  ID,
  IE,
  IL,
  IM,
  IN,
  IO,
  IQ,
  IR,
  IS,
  IT,
  JE,
  JM,
  JO,
  JP,
  KE,
  KG,
  KH,
  KI,
  KM,
  KN,
  KP,
  KR,
  KW,
  KY,
  KZ,
  LA,
  LB,
  LC,
  LI,
  LK,
  LR,
  LS,
  LT,
  LU,
  LV,
  LY,
  MA,
  MC,
  MD,
  ME,
  MF,
  MG,
  MH,
  MK,
  ML,
  MM,
  MN,
  MO,
  MP,
  MQ,
  MR,
  MS,
  MT,
  MU,
  MV,
  MW,
  MX,
  MY,
  MZ,
  NA,
  NC,
  NE,
  NF,
  NG,
  NI,
  NL,
  NO,
  NP,
  NR,
  NU,
  NZ,
  OM,
  PA,
  PE,
  PF,
  PG,
  PH,
  PK,
  PL,
  PM,
  PN,
  PR,
  PS,
  PT,
  PW,
  PY,
  QA,
  RE,
  RO,
  RS,
  RU,
  RW,
  SA,
  SB,
  SC,
  SD,
  SE,
  SG,
  SH,
  SI,
  SJ,
  SK,
  SL,
  SM,
  SN,
  SO,
  SR,
  SS,
  ST,
  SV,
  SX,
  SY,
  SZ,
  TA,
  TC,
  TD,
  TF,
  TG,
  TH,
  TJ,
  TK,
  TL,
  TM,
  TN,
  TO,
  TR,
  TT,
  TV,
  TW,
  TZ,
  UA,
  UG,
  UM,
  UY,
  UZ,
  VA,
  VC,
  VE,
  VG,
  VI,
  VN,
  VU,
  WF,
  WS,
  XK,
  YE,
  YT,
  ZA,
  ZM,
  ZW,
}
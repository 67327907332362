import React from "react"

export const VoiceIndicator = ( { on }: any ) => {
    return (
        <div className={on ? "voiceIndicatorOn" : "voiceIndicatorOff"}>
            <div></div>
            <div></div>
            <div></div>
        </div>
    )
}

import React, { useEffect, useState } from 'react';
import { GridColDef } from "@mui/x-data-grid";
import DataGridPremiumDemo from "src/components/backoffice/Analytics/AnalyticsTableAggr";
import { fetchActiveChatAmount, fetchChatWordCount, fetchSpeechWordCount, fetchUser } from 'src/api/backoffice';
import { useParams } from 'react-router-dom';
import { useUserAuth } from 'src/pages/context/userAuth/userAuthContext';
import { useRecoilValue } from 'recoil';
import { currentLangIDtate, currentLangState } from 'src/states/signup';
import { createTheme } from '@mui/material';
import rtlPlugin from 'stylis-plugin-rtl';
import { arSD } from '@mui/x-data-grid/locales';
import { CacheProvider, useTheme } from "@emotion/react";
import { createCatchClause } from "typescript";
import { prefixer } from 'stylis';
import createCache from '@emotion/cache';
import { ThemeProvider } from "styled-components";
import { heIL } from '@mui/x-data-grid/locales';
import ViewConversationsPage from '../conversations_demo/ConversationsDemo';
import ViewConversationsPageDaily from '../conversations_demo/ConversationsDaily';

interface Speech {
    id: number;
    clinic_name: string;
    physician_name: string;
    patient_name: string;
    word_count: number;
};

interface ChatStatistic {
    active: number;
    closed: number;
    total: number;
};

// const cacheRtl = createCache({
//     key: 'data-grid-rtl-demo',
//     stylisPlugins: [prefixer, rtlPlugin],
//   });

const AnalyticsBO = () => {
    const currentLang = useRecoilValue(currentLangState)
    const currentLangID = useRecoilValue(currentLangIDtate)
    const {user} = useUserAuth();
    const currentUserId = user.id;
    const currentUserType = user.type;
    const [chat, setChat] = useState<Speech[]>([]);
    const [speech, setSpeech] = useState<Speech[]>([]);
    const [chatStatistic, setChatStatistic] = useState<ChatStatistic>({ active: 0, closed: 0, total: 0 });

  const columns: GridColDef[] = [
    {
      field: "clinic_name",
      headerName: currentLang["clinic_name"],
      type: "string",
      width: 150,
      editable: false,
    },
    {
      field: "word_count",
      headerName: currentLang["word_count"],
      type: "number",
      width: 150,
      editable: false,
    },
    {
      field: "physician_name",
      headerName: currentLang["physician_name"],
      type: "string",
      width: 150,
      editable: false,
    },
    {
      field: "patient_name",
      headerName: currentLang["patient_name"],
      type: "string",
      width: 150,
      editable: false,
    },
];

// const existingTheme = useTheme();
// const theme = React.useMemo(
//     () =>
//       createTheme({}, arSD, existingTheme, {
//         direction: 'rtl',
//       }),
//     [existingTheme],
//   );

    const getChats = async () => {
        try {
            const originalObject: any = await fetchChatWordCount(currentUserId)
            console.log(originalObject)
            const reformattedArray = [];
            let idCounter = 1;
            for (const clinicName in originalObject) {
                const physicians = originalObject[clinicName];
                for (const physicianId in physicians) {
                    const patients = physicians[physicianId];
                    for (const patientId in patients) {
                        const wordCount = patients[patientId];
                        const physician_name = await fetchUser(physicianId);
                        const patient_name = await fetchUser(patientId);
                        console.log(physician_name, patient_name)
                        reformattedArray.push({
                            id: idCounter++,
                            clinic_name: clinicName,
                            physician_name: `${physician_name["first_name"]} ${physician_name["last_name"]}`,
                            patient_name: `${patient_name["first_name"]} ${patient_name["last_name"]}`,
                            word_count: wordCount,
                        });
                    }
                }
            }
            console.log(reformattedArray);
            setChat(reformattedArray)
        } catch (error) {
          console.error("Error fetching chats")
        }
    }

    const getSpeech = async () => {
        try {
            const originalObject: any = await fetchSpeechWordCount(currentUserId)
            console.log(originalObject)
            const reformattedArray = [];
            let idCounter = 1;
            for (const clinicName in originalObject) {
                const physicians = originalObject[clinicName];
                for (const physicianId in physicians) {
                    const patients = physicians[physicianId];
                    for (const patientId in patients) {
                        const wordCount = patients[patientId];
                        const physician_name = await fetchUser(physicianId);
                        const patient_name = await fetchUser(patientId);
                        console.log(physician_name, patient_name)
                        reformattedArray.push({
                            id: idCounter++,
                            clinic_name: clinicName,
                            physician_name: `${physician_name["first_name"]} ${physician_name["last_name"]}`,
                            patient_name: `${patient_name["first_name"]} ${patient_name["last_name"]}`,
                            word_count: wordCount,
                        });
                    }
                }
            }
            console.log(reformattedArray);
            setSpeech(reformattedArray)
        } catch (error) {
          console.error("Error fetching chats")
        }
    }

    const getChatStatistics = async () => {
        try {
            const fetchedChatStatistic: any = await fetchActiveChatAmount(currentUserId)
            setChatStatistic(fetchedChatStatistic)
        } catch (error) {
          console.error("Error fetching chat statistic")
        }
    }
      
    
      useEffect(() => {
        getChats()
        getSpeech()
        getChatStatistics()
      }, [])

    return (
        
        <div className={`users ${currentLangID === 2 ? 'rtl' : ''}`}>
            <div className="sectionStyles flex flex-col gap-14">
            <div className="divStyles flex justify-between gap-14">
            <div className="containerStyles_2">
                        <div className="info">
                            <h1>{currentLang["chat_statistics"]}</h1>
                        </div>
                        <table>
                            <thead>
                                <tr>
                                    <th>{currentLang["active"]}</th>
                                    <th>{currentLang["closed"]}</th>
                                    <th>{currentLang["total"]}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{chatStatistic.active}</td>
                                    <td>{chatStatistic.closed}</td>
                                    <td>{chatStatistic.total}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    </div>
                <div className="divStyles flex flex-col justify-between gap-14">
                    <div className="containerStyles_2">
                        <div className="info">
                            <h1>{currentLang["total"]}{" "}{currentLang["chat_word_count_analytics"]}</h1>
                        </div>
                        {/* <CacheProvider value={cacheRtl}> */}
                            {/* <ThemeProvider theme={theme}> */}
                                <div dir={currentLangID === 2 ? "rtl" : "ltr"} className="dataTable">
                                    <DataGridPremiumDemo columns={columns} rows={chat} aggr_column={["clinic_name"]} aggr_func={"word_count"} {...(currentLangID === 2 && {localeText: heIL.components.MuiDataGrid.defaultProps.localeText})}/>
                                </div>
                            {/* </ThemeProvider> */}
                        {/* </CacheProvider> */}
                    </div>

                    <div className="containerStyles_2">
                        <div className="info">
                            <h1>{currentLang["total"]}{" "}{currentLang["speech_word_count_analytics"]}</h1>
                        </div>
                        {/* <CacheProvider value={cacheRtl}> */}
                            {/* <ThemeProvider theme={theme}> */}
                                <div dir={currentLangID === 2 ? "rtl" : "ltr"} className="dataTable">
                                    <DataGridPremiumDemo columns={columns} rows={speech} aggr_column={["clinic_name"]} aggr_func={"word_count"} {...(currentLangID === 2 && {localeText: heIL.components.MuiDataGrid.defaultProps.localeText})}/>
                                </div>
                            {/* </ThemeProvider> */}
                        {/* </CacheProvider> */}
                    </div>
                </div>
                <ViewConversationsPageDaily/>
            </div> 
        </div>
        
    );
};

export default AnalyticsBO
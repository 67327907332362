import React from 'react';

const PrivacyPolicy = () => {
  return (
    <>
      <h1 style={{ margin: 'auto' }}>Privacy Policy</h1>
    </>
  )

};


export default PrivacyPolicy;
import React, { useEffect, useRef, useState } from "react";
import { useUserAuth } from "src/pages/context/userAuth/userAuthContext";
import { ShowDialog } from "../showDialog/ShowDialog";
import { currentLangState } from "src/states/signup";
import { useRecoilValue } from "recoil";

export const Idle = () => {
    const { user, logout } = useUserAuth();

    const isLogin = !!user.id;

    const warningTimeout = 1000 * 60 * 60;// 1 hour;
    const timeoutNow = 1000 * 60 * 5; // 5 minutes
    const warningTimerID = useRef<NodeJS.Timeout>();
    const timeoutTimerID = useRef<NodeJS.Timeout>();
    const [isWarning, setIsWarning] = useState<boolean>(false);
    const currentLang = useRecoilValue(currentLangState)

    function startTimer() {
        warningTimerID.current = setTimeout(warningInactive, warningTimeout);
    }

    function warningInactive() {
        clearTimeout(warningTimerID.current);
        timeoutTimerID.current = setTimeout(idleTimeout, timeoutNow);
        handleWarning();
    }

    function resetTimer() {
        clearTimeout(timeoutTimerID.current);
        clearTimeout(warningTimerID.current);
        if (isLogin) {
            startTimer();
        }
    }

    // Logout the user.
    function idleTimeout() {
        setIsWarning(false);
        logout();
    }

    const handleWarning = () => {
        setIsWarning(true);
    }

    function setupTimers() {
        document.addEventListener("mousemove", resetTimer, false);
        document.addEventListener("mousedown", resetTimer, false);
        document.addEventListener("keypress", resetTimer, false);
        document.addEventListener("touchmove", resetTimer, false);
        window.addEventListener("scroll", resetTimer, false);
        startTimer();
    }

    useEffect(() => {
        if (isLogin) {
            setupTimers();
        } else {
            resetTimer();
        }

        return () => {
            document.removeEventListener("mousemove", resetTimer, false);
            document.removeEventListener("mousedown", resetTimer, false);
            document.removeEventListener("keypress", resetTimer, false);
            document.removeEventListener("touchmove", resetTimer, false);
            window.removeEventListener("scroll", resetTimer, false);
        }
    }, [isLogin])

 

    if (isWarning) {
        return (
            <ShowDialog
                title={currentLang["are_you_still_there"]}
                msg={currentLang["we_noticed_inactive"]}
                onOk={() => setIsWarning(false)}
                okText={currentLang["keep_me_logged_in"]}
            />
        )
    }

    return <></>
}
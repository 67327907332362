import React from "react"
import { useRecoilValue } from "recoil"
import { Button } from "src/UILibrary"
import { currentLangState } from "src/states/signup"

interface IReferralAction {
  setShowReferalDialog: (show: boolean) => void
}

export const ReferralAction = ({ setShowReferalDialog }: IReferralAction) => {
  const currentLang = useRecoilValue(currentLangState)

  return <Button className="dynamicActionButton Body3Medium" onClick={() => {setShowReferalDialog(true)}}>{currentLang["referral"]}</Button>
}

import { createTheme } from "@mui/material/styles"
import { BASE_COLORS } from "./colors"

export const DefaultTheme = createTheme({
  components: {
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: '0.9rem',
          fontWeight: 'bold'
        },
      },
    },
  },
  palette: {
    background: {
      default: "#20B3D5",
      paper: "#F5F5F5",
    },
    primary: {
      main: "#02B0E6",
      dark: "#CDECF5",
      light: "#D9D9D9",
      contrastText: "#02B0E6",
    },
    secondary: {
      main: "#86CE11",
      light: "#BDBDBD",
    },
    text: {
      primary: BASE_COLORS.primary[500],
      secondary: BASE_COLORS.gray[700],
      disabled: BASE_COLORS.gray[400],
    },
    error: {
      main: "#E60202",
    },
    warning: {
      main: "#FFE604",
    },
    success: {
      main: "#02B0E6",
    },
    info: {
      main: "#FFFFFF",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 430,
      md: 900,
      lg: 1194,
      xl: 1400,
    },
  },
})

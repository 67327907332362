import React, { useState } from "react"
import { Link, Typography } from "src/UILibrary"
import { ForgotPasswordForm } from "./components/forgotPasswordForm"
import { CodeInputForm } from "./components/codeInputForm"
import { ResetPasswordForm } from "./components/resetPasswordForm"
import { ResetSuccessForm } from "./components/resetSuccessForm"
import { VerificationType } from "src/types/forgotPassword"
import { useRecoilValue } from "recoil"
import { currentLangState } from "src/states/signup"
import LoginReg from "src/components/cards/LoginReg"
import BackArrow from "../signup/components/BackArrow"
import Container from "src/components/container"

const ForgotPassword = () => {
  const currentLang = useRecoilValue(currentLangState)
  const [step, setStep] = useState(1)
  const [otp, setOtp] = useState("")
  const [verificationType, setVerificationType] = useState<VerificationType>("email")

  const _setStep = (addition: number) => () => setStep((val) => val + addition)
  const onBackClick = _setStep(-1)
  const onContinue = _setStep(1)

  const handleSwitch = (type: VerificationType) => {
    setVerificationType(type);
    setStep(1);
  }

  return (
    <LoginReg showLanguageMenu>
      <Container.Row
        sx={{
          maxWidth: "320px",
          width: "100%",
          height: "65px",
          alignSelf: "center",
          mb: "40px",
          mt: "40px",
        }}
      >
        {step > 1 && step < 4 && <BackArrow onClick={onBackClick} />}
      </Container.Row>
      {step === 1 ? (
        <ForgotPasswordForm setStep={onContinue} verificationType={verificationType} setVerificationType={setVerificationType} />
      ) : step === 2 ? (
        <CodeInputForm setStep={onContinue} setOtp={setOtp} onSwitch={handleSwitch} verificationType={verificationType} />
      ) : step === 3 ? (
        <ResetPasswordForm setStep={onContinue} verificationType={verificationType} otp={otp} />
      ) : (
        <ResetSuccessForm />
      )}
      {step !== 4 && (
        <Typography.Detail sx={{ textAlign: "center", mt: "8rem", display: 'flex', alignItems: 'center' }}>
          {currentLang["remember_password"]}
          <Link.FormLink href="/signin-patient" sx={{ ml: "0.375rem", textDecoration: "underline" }}>
            {currentLang["log_in"]}
          </Link.FormLink>
        </Typography.Detail>
      )}
    </LoginReg>
  )
}

export default ForgotPassword

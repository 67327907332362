import React, { useEffect, useRef, useState } from "react"
import Container from "src/components/container"
import { TextFieldWithLabel } from "src/components/textfieldWithLabel"
import dialCodes from "./dial_codes.json"
import "./phoneInput.scss"
import { SvgIcon } from "../svgIcon"
import { svgExports } from "src/assets/icons/flags"
import { IconButton, InputAdornment } from "@mui/material"
import { position } from "stylis"
import { MAX_INPUT_LENGTH } from "src/constants/common"

interface IPhoneInputProps {
  label: string
  value: string
  onChange: Function
  setPhoneNumber: Function
  error: boolean
  helperText: string | undefined
  sx: any
  placeholder?: string
  disabled?: boolean
}
interface IDialCode {
  name: string
  dial_code: string
  code: string
}

export function PhoneInput({
  label,
  value,
  onChange,
  setPhoneNumber,
  placeholder,
  error,
  helperText,
  sx,
  disabled = false,
}: IPhoneInputProps) {
  const [flagCode, setFlagCode] = useState<string>("US")
  const [dialCodeMenuOpened, setDialCodeMenuOpened] = useState(false)

  useEffect(() => {
    let flagFound = dialCodes.codes.find((arrayItem) => value.includes(arrayItem.dial_code))
    if (!flagFound) {
      setFlagCode("US")
      return
    }
    setFlagCode(flagFound.code)
  }, [value])

  const handleOnClick = (index: number) => {
    setFlagCode(dialCodes.codes[index].code)
    setPhoneNumber(dialCodes.codes[index].dial_code)
    setDialCodeMenuOpened(false)
  }

  return (
    <Container.Column>
      <TextFieldWithLabel
        type={"tel"}
        label={label}
        value={value}
        forceLtr={true}
        disabled={disabled}
        placeholder={placeholder}
        onChange={(e) => {
          if (e.target.value.length > MAX_INPUT_LENGTH) return
          onChange(e.target.value)
        }}
        error={error}
        helperText={helperText}
        sx={sx}
        InputProps={{
          startAdornment: (
            <InputAdornment
              position="start"
              onClick={() => setDialCodeMenuOpened(!dialCodeMenuOpened)}
              sx={{ cursor: "pointer", marginLeft: "5px"}}
            >
              <SvgIcon src={svgExports[flagCode as keyof typeof svgExports]} alt="flag icon" sx={{ marginRight: "14px"}}/>
            </InputAdornment>
          ),
        }}
      />
      {dialCodeMenuOpened && (
        <div className="countryContainer">
          {dialCodes.codes.map((dialCode: IDialCode, index: number) => {
            return (
              <div key={dialCode.name} onClick={() => handleOnClick(index)}>
                <SvgIcon
                  sx={{ marginRight: "13px", width: "25px" }}
                  src={svgExports[dialCode.code as keyof typeof svgExports]}
                  alt={dialCode.code}
                />
                <p>{dialCode.dial_code}</p>
              </div>
            )
          })}
        </div>
      )}
    </Container.Column>
  )
}

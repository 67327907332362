import React from "react"
import { Typography as MuiTypography, TypographyProps } from "@mui/material"
import { BASE_COLORS } from "src/themes/colors"

const TypographyComponentWithStyles = (defaultStyles: TypographyProps) => {
  const TextComponent = ({ children, ...rest }: TypographyProps) => {
    
    return (
      <MuiTypography {...defaultStyles} {...rest}>
        {children}
      </MuiTypography>
    )
  }

  return TextComponent
}

export const Typography = {
  Logo: TypographyComponentWithStyles({
    fontSize: "2.5rem",
    lineHeight: "normal",
    fontWeight: 590,
    color: "white",
  }),

  Title: TypographyComponentWithStyles({
    fontSize: "3rem",
    lineHeight: "4.375rem",
    fontWeight: 700,
  }),

  PageTitle: TypographyComponentWithStyles({
    fontSize: "2.25rem",
    lineHeight: "3.25rem",
    fontWeight: 700,
  }),

  Section: TypographyComponentWithStyles({
    fontSize: "1.75rem",
    lineHeight: "1.25rem",
    fontWeight: 700,
  }),

  Heading: TypographyComponentWithStyles({
    fontSize: "1.875rem",
    lineHeight: "125%",
    fontWeight: 700,
  }),

  SubTitle: TypographyComponentWithStyles({
    fontSize: "1.875rem",
    lineHeight: "normal",
    fontWeight: 590,
  }),

  SubHeading: TypographyComponentWithStyles({
    fontSize: "1.125rem",
    lineHeight: "1.625rem",
    fontWeight: 700,
    color: "text.primary",
  }),

  DetailHeading: TypographyComponentWithStyles({
    fontSize: "1rem",
    lineHeight: "1.25rem",
    fontStyle: "normal",
    fontWeight: 510,
    color: BASE_COLORS.gray[800],
  }),

  Detail: TypographyComponentWithStyles({
    fontSize: "0.875rem",
    lineHeight: "1.09rem",
    letterSpacing: 0,
    fontWeight: 400,
    color: "#000000B2",
  }),

  Action: TypographyComponentWithStyles({
    fontSize: "1rem",
    lineHeight: "1.25rem",
    letterSpacing: 0,
    fontWeight: 600,
  }),

  Subscription: TypographyComponentWithStyles({
    fontSize: "1.25",
    lineHeight: "normal",
    fontWeight: 400,
  }),

  InputFormLabel: TypographyComponentWithStyles({
    fontSize: "0.875rem",
    lineHeight: "1.25rem",
    fontWeight: 500,
  }),

  normalLabel: TypographyComponentWithStyles({
    fontSize: "0.875rem",
    lineHeight: "1.1rem",
    fontWeight: 600,
  }),

  SelectLabel: TypographyComponentWithStyles({
    fontSize: "1rem",
    lineHeight: "1.1rem",
    color: BASE_COLORS.gray["500"],
    fontWeight: 400,
  }),
}

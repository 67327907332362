import { useNavigate } from "react-router-dom"
import { USER_TYPE } from "src/constants/enums"
import { useUserAuth } from "src/pages/context/userAuth/userAuthContext"
import { useSocketIo } from "src/pages/home/context/hooks/useSocketIO"

export const useLogOut = () => {
  const navigate = useNavigate()
  const { logout } = useUserAuth()
  const { socketDisconnect } = useSocketIo()

  const cb = (userType: number, csrfToken: string) => {
    logout()
    socketDisconnect()
    if (userType === USER_TYPE.patient) {
      navigate("/signin-patient")
    } else if (userType === USER_TYPE.physician) {
      navigate("/signin-physician")
    }
  }

  return cb
}

import React, { useEffect, useState } from "react"
import { useChat } from "../context/chatContext"

export function Avatar() {
  const { isAudioPlaying } = useChat()
  const [videoSource, setVideoSource] = useState<string>(
    "https://lifemedicpublic.blob.core.windows.net/assets/silentDoctor.mp4"
  )

  useEffect(() => {
    if (isAudioPlaying) {
      const random = Math.floor(Math.random() * 3)
      console.log("isAudioPlaying!", random)
      if (random === 0) {
        setVideoSource(
          "https://lifemedicpublic.blob.core.windows.net/assets/silentDoctorTalkingOne.mp4"
        )
      } else if (random === 1) {
        setVideoSource(
          "https://lifemedicpublic.blob.core.windows.net/assets/silentDoctorTalkingTwo.mp4"
        )
      } else {
        setVideoSource(
          "https://lifemedicpublic.blob.core.windows.net/assets/silentDoctorTalkingThree.mp4"
        )
      }
    } else {
      setVideoSource("https://lifemedicpublic.blob.core.windows.net/assets/silentDoctor.mp4")
    }
  }, [isAudioPlaying])

  return (
    <>
      <video
        className="invisible"
        src={"https://lifemedicpublic.blob.core.windows.net/assets/silentDoctor.mp4"}
        muted
        preload="auto"
      />
      <video
        className="invisible"
        src={"https://lifemedicpublic.blob.core.windows.net/assets/silentDoctorTalkingOne.mp4"}
        muted
        preload="auto"
      />
      <video
        className="invisible"
        src={"https://lifemedicpublic.blob.core.windows.net/assets/silentDoctorTalkingTwo.mp4"}
        muted
        preload="auto"
      />
      <video
        className="invisible"
        src={"https://lifemedicpublic.blob.core.windows.net/assets/silentDoctorTalkingThree.mp4"}
        muted
        preload="auto"
      />
      <video
        className="chatAvatar"
        src={videoSource}
        muted
        autoPlay
        playsInline
        loop
        preload="auto"
      />
    </>
  )
}

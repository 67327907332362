import React from "react"
import { UserAvatar } from "./UserAvatar"
import { USER_TYPE } from "src/constants/enums"

interface ITypingIndicator {
  userId: string
  user_name: string
  user_name_initials: string
  userType: USER_TYPE
}

export const TypingIndicator = ({
  userId,
  userType,
  user_name,
  user_name_initials,
}: ITypingIndicator) => {
  return (
    <div className="typingIndicatorWrapper">
      <UserAvatar
        userId={userId}
        user_name={user_name}
        user_name_initials={user_name_initials}
        userType={userType}
      />
      <div className="typingIndicator">
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  )
}

import { useEffect, useState } from "react"
import {
  CHAT_SIDE_MENU_OPTIONS,
  ChatPointOfView,
  ConversationHandle,
  ConversationMessageTypes,
  USER_TYPE,
} from "src/constants/enums"
import { axiosInstance } from "src/modules/axios"
import { IUseConnectContext, TChatMessage, IChatGroup } from "../chatContext.types"
import {
  extractIdsFromGroupName,
  getDatePlusTime,
  getLastMessagePrefix,
  getUserGroupName,
  getUserInitialsFromFullName,
  isUserOnline,
  removePaFromGroupName,
} from "../../utils"
import { useChat } from "../chatContext"
import { useActions } from "./useActions"
import { showNotification } from "../../notifications"
import { useSocketIo } from "./useSocketIO"
import { currentLangIDtate, currentLangState } from "src/states/signup"
import { useRecoilValue } from "recoil"
import { useNavigate } from "react-router-dom"

export const useConnect = (): IUseConnectContext => {
  const currentLang = useRecoilValue(currentLangState)
  const currentLangID = useRecoilValue(currentLangIDtate)
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { createGroup, joinGroup, socketOn, socketDisconnect } = useSocketIo()
  const {
    chatGroups: currChatGroups,
    appendChatMessage,
    appendChatFeeds,
    isActiveWindow,
    setChatGroups,
    setGroup,
    userID,
    userType,
    appendAudioMessage,
    setPaused,
    setChatWindow,
    getGroup,
    setSelectedSideMenu,
    setShowPhysicianCodeInput,
    setGroupStatus,
  } = useChat()

  const { send } = useActions()

  useEffect(() => {
    if (userType === USER_TYPE.patient || userType === USER_TYPE.physician) {
      socketOn((data: any) => {
        handleMessage(data)
      })
      connect(getUserGroupName(userID))
    }
    return () => {
      socketDisconnect()
    }
  }, [])

  const fetchChatGroups = async () => {
    try {
      setIsLoading(true)
      await buildChatGroups(false)
      setIsLoading(false)
      setShowPhysicianCodeInput(false)
    } catch (error) {
      navigate("/error/hub")
    }
  }

  const fetchChatGroupsNoLoading = async () => {
    try {
      await buildChatGroups(true)
      setShowPhysicianCodeInput(false)
    } catch (error) {
      navigate("/error/hub")
    }
  }

  const buildChatGroups = async (skipExisting: boolean) => {
    const chatGroups = skipExisting ? [] : currChatGroups
    const groups = await fetchGroups()
    for (let group of groups) {
      if (!skipExisting) {
        if (currChatGroups.find((g) => g.group_name === group.group_name)) {
          continue
        }
      }
      connect(group.group_name)
      chatGroups.push({
        ...group,
      })
    }
    console.log("chatGroups", chatGroups)

    setChatGroups(chatGroups)
    appendChatFeeds(chatGroups.map((g) => g.group_name))
  }

  const fetchGroups = async (): Promise<IChatGroup[]> => {
    let group_names
    if (userType === USER_TYPE.patient) {
      group_names = await axiosInstance
        .post("authenticated/conversation/get_patient_group_names", {
          user_id: userID,
          lang: currentLangID === 1 ? "en" : "he",
        })
        .then((res) => {
          return res.data.final_all_groups
        })
        .catch((err) => console.error(err))
    } else {
      group_names = await axiosInstance
        .post("authenticated/conversation/get_group_names_new", {
          user_id: userID,
          lang: currentLangID === 1 ? "en" : "he",
        })
        .then((res) => {
          return res.data.final_all_groups
        })
        .catch((err) => console.error(err))
    }
    const groups: IChatGroup[] = []
    console.log("group_names", group_names)
    for (let i = 0; i < group_names.length; i++) {
      const element = group_names[i]
      const { group, firstMessage, first_name, last_name } = element
      const {
        group_name,
        membersArea,
        isPinnedPatient,
        isPinnedPhysician,
        unread_messages: unreadMessages,
        isAIPaused,
        created_at,
        isActive,
        status,
      } = group
      const ids = extractIdsFromGroupName(group_name)
      const lastMessage = firstMessage.length > 0 ? firstMessage[0] : {}
      lastMessage.date = lastMessage?.date_time ? new Date(lastMessage?.date_time) : new Date()
      lastMessage.date_time_prefix = getLastMessagePrefix(lastMessage.date)
      let firstName = first_name.trim() || ""
      let lastName = last_name.trim() || ""

      let theirName
      let theirNameInitials
      //set their id
      const theirId =
        userType === USER_TYPE.patient && ids.phId
          ? ids.phId
          : userType === USER_TYPE.physician && ids.paId
          ? ids.paId
          : ids.aiId

      let name

      if (firstName && lastName) {
        name = firstName + " " + lastName
      } else if (firstName && !lastName) {
        name = firstName
      } else if (!firstName && lastName) {
        name = lastName
      }

      theirName = name
      theirNameInitials = getUserInitialsFromFullName(name)

      const otherMember = membersArea.find(
        (member: any) => member.user_id !== userID && member.user_id !== ids.aiId
      )
      const isConnected = otherMember ? isUserOnline(otherMember.last_use_member_area) : false
      const groupMap: IChatGroup = {
        group_name: group_name,
        status: status,
        createdAt: created_at,
        isConnected,
        pointOfView: ids.phId ? ChatPointOfView.AI_AND_PATIENT : ChatPointOfView.MY_AI,
        pointOfViews: [
          {
            groupId: group_name,
            view: ChatPointOfView.AI_AND_PATIENT,
          },
          {
            groupId: removePaFromGroupName(group_name),
            view: ChatPointOfView.MY_AI,
          },
        ],
        isPinnedPatient,
        isPinnedPhysician,
        isAIPaused,
        unreadMessages,
        lastOnline:
          otherMember && isConnected
            ? new Date(otherMember.last_use_member_area)
            : getDatePlusTime(-10),
        theirName,
        theirNameInitials,
        typing: false,
        typingUserID: "",
        typingUserName: "",
        chatGroupSelection: ids.phId ? ChatPointOfView.AI_AND_PATIENT : ChatPointOfView.MY_AI,
        lastMessage,
        theirId,
        audioMode: false,
        isActive,
        setPinnedPatient: () => {},
        setPinnedPhysician: () => {},
      }
      groups.push(groupMap)
    }

    for (let i = 0; i < groups.length; i++) {
      const group = groups[i]
      let firstGroupName = group.group_name
      const ids = extractIdsFromGroupName(group.group_name)
      if (!ids.phId) {
        for (let j = 0; j < groups.length; j++) {
          const groupTemp = groups[j]
          const groupTempIds = extractIdsFromGroupName(groupTemp.group_name)
          if (groupTempIds.aiId === ids.aiId && groupTempIds.phId) {
            firstGroupName = groupTemp.group_name
          }
        }
      }
      groups[i].pointOfViews[0].groupId = firstGroupName
    }
    return groups
  }

  async function connect(group_name: string) {
    try {
      createGroup(group_name)
      joinGroup(group_name)
      if (userType === USER_TYPE.patient) {
        createGroup(removePaFromGroupName(group_name))
        joinGroup(removePaFromGroupName(group_name))
      }
    } catch (err) {
      console.error(err)
    }
  }

  const handleMessage = (data: any) => {
    const group_name = data.group_name
    console.log("handleMessage data", data)
    switch (data.message_type_id) {
      case ConversationMessageTypes.last_online:
        setLastOnline(data, group_name)
        break
      case ConversationMessageTypes.typing_start:
        setTyping(data, group_name, true)
        break
      case ConversationMessageTypes.typing_end:
        setTyping(data, group_name, false)
        break
      case ConversationMessageTypes.pause:
        setPaused(group_name, true)
        break
      case ConversationMessageTypes.unpause:
        setPaused(group_name, false)
        break
      case ConversationMessageTypes.changeGroupStatus:
        // alert("changeGroupStatus" + data.message)
        setGroupStatus(group_name, data.message)
        break
      // case ConversationMessageTypes.pin:
      //   if (userType === USER_TYPE.patient) {
      //     setPinnedPatient(group_name, true)
      //   } else {
      //     setPinnedPhysician(group_name, true)
      //   }
      //   break
      // case ConversationMessageTypes.unpin:
      //   if (userType === USER_TYPE.patient) {
      //     setPinnedPatient(group_name, false)
      //   } else {
      //     setPinnedPhysician(group_name, false)
      //   }
      //   break
      case ConversationMessageTypes.fetchGroups:
        fetchChatGroupsNoLoading()
        break
      case ConversationMessageTypes.text:
      case ConversationMessageTypes.requestPrescription:
      case ConversationMessageTypes.requestReferal:
      case ConversationMessageTypes["image/png"]:
      case ConversationMessageTypes["image/jpg"]:
      case ConversationMessageTypes["image/jpeg"]:
      case ConversationMessageTypes["image/gif"]:
      case ConversationMessageTypes.pdf:
      case ConversationMessageTypes.word:
      case ConversationMessageTypes.docx:
      case ConversationMessageTypes.doc:
      case ConversationMessageTypes.sendToPhysician:
      case ConversationMessageTypes.billing:
      case ConversationMessageTypes.requestMoreDetails:
      case ConversationMessageTypes.sendMoreDetails:
      case ConversationMessageTypes.sendFileOcr:
      case ConversationMessageTypes.sendGuidelines:
      case ConversationMessageTypes.summary:
      case ConversationMessageTypes.finalize:
      case ConversationMessageTypes.referral:
      case ConversationMessageTypes.prescription:
        appendMessage(data, group_name)
        break
      default:
        break
    }
  }

  const appendMessage = (messageData: any, group_name: string) => {
    const now = new Date()
    const chatGroup = currChatGroups.find((c) => c.group_name === group_name)
    if (messageData.conversation_handler === ConversationHandle.SKIP_UI) return
    const newChatMessage: TChatMessage = {
      user_id: messageData.user_id,
      date: now,
      date_time: now.toUTCString(),
      date_time_prefix: getLastMessagePrefix(now),
      message_type_id: messageData.message_type_id,
      message: messageData.message,
      size: messageData.size || 0,
      file_name: messageData.file_name,
      user_name: messageData.user_name,
      user_name_initials: getUserInitialsFromFullName(messageData.user_name),
    }

    const handleChatWindow = () => {
      if (chatGroup) {
        setSelectedSideMenu(CHAT_SIDE_MENU_OPTIONS.chat)
        setChatWindow(chatGroup)
      }
    }

    if (newChatMessage.user_id !== userID) {
      showNotification(messageData.message, newChatMessage.user_name || "", () =>
        handleChatWindow()
      )
    }

    console.log("newChatMessage", newChatMessage)
    if (chatGroup) {
      if (userType === USER_TYPE.patient) {
        const examRoomGroup = getGroup(chatGroup.pointOfViews[0].groupId);
        setLastMessage(newChatMessage, examRoomGroup || chatGroup)
      } else {
        const physicianRoomGroup = getGroup(chatGroup.pointOfViews[1].groupId);
        console.log('physicianRoomGroup', physicianRoomGroup)
        setLastMessage(newChatMessage, physicianRoomGroup || chatGroup)
      }
    }
    appendChatMessage(group_name, newChatMessage)
  }

  const setLastMessage = (newChatMessage: TChatMessage, group: IChatGroup) => {
    setGroup(group, (chatGroup) => {
      chatGroup.lastMessage = newChatMessage

      if (!isActiveWindow(group.group_name) && newChatMessage.user_id !== userID) {
        chatGroup.unreadMessages = chatGroup.unreadMessages + 1
      } else if (isActiveWindow(group.group_name)) {
        // todo: optimize
        send("unread_all_messages", group.group_name)
      }
    })
  }

  const setTyping = (messageData: any, group_name: string, typing: boolean) => {
    if (messageData.user_id === userID) {
      return
    }

    const group = getGroup(group_name)
    if (group) {
      setGroup(group, (chatGroup) => {
        chatGroup.typing = typing
        chatGroup.typingUserID = messageData.user_id
        chatGroup.typingUserName = messageData.user_name
        chatGroup.lastOnline = new Date()
      })
    }

    if (typing) {
      setTimeout(() => {
        setTyping(messageData, group_name, false)
      }, 10000) // 10 seconds
    }
  }

  const setLastOnline = (messageData: any, group_name: string) => {
    const group = getGroup(group_name)
    if (group) {
      setGroup(group, (chatGroup) => {
        if (messageData.user_id !== userID) {
          chatGroup.lastOnline = new Date()
        }
      })
    }
  }

  return {
    isLoading,
    fetchChatGroups,
    fetchChatGroupsNoLoading,
    setTyping,
  }
}

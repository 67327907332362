import React from "react"
import { Checkbox as MuiCheckbox, CheckboxProps } from "@mui/material"

export const Checkbox: React.FC<CheckboxProps> = ({ sx, ...rest }) => {
  return (
    <MuiCheckbox
      sx={{
        color: "text.primary",
        padding: "0.125rem",
        "& .MuiSvgIcon-root": { fontSize: "16px" },
        "&.Mui-checked": {
          color: "primary.main",
        },
        ...sx,
      }}
      {...rest}
    />
  )
}

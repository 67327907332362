import { Theme } from "@emotion/react"
import { Box, BoxProps, SxProps } from "@mui/material"
import React from "react"

const flexStyles: SxProps<Theme> = {
  display: "flex",
}

const Flex = ({ sx = {}, children, ...rest }: BoxProps) => {
  return (
    <Box {...rest} sx={{ ...flexStyles, ...sx }}>
      {children}
    </Box>
  )
}

export default Flex

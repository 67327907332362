export enum ConversationMessageTypes {
  "text" = "1",  // i need this
  "image/png" = "2", // maybe only image
  "image/jpg" = "3", // maybe only image
  "image/jpeg" = "4", // maybe only image
  "image/gif" = "5", // maybe only image
  "pdf" = "6", // i need this
  "word" = "7", // not sure if needed
  "voice" = "8", // THIS IS FOR URI
  "seperator" = "9",  // i need this
  "typing_start" = "10",  // i need this
  "typing_end" = "11",  // i need this
  "last_online" = "12",  // i need this
  "read_message" = "13",  // i need this
  "loadingFile" = "14",  // i need this
  "pause" = "15",  // i need this
  "unpause" = "16",  // i need this
  "fetchGroups" = "17",  // i need this
  "fileFailed" = "18", // i need this
  "summary" = "19",
  "pin" = "20",
  "unpin" = "21",
  "changeGroupStatus" = "22",
  "requestMoreDetails" = "23",
  "sendMoreDetails" = "24",
  "requestPrescription" = "25",
  "sendPrescription" = "26",
  "requestReferal" = "27",
  "sendReferal" = "28",
  "billing" = "29",
  "finalize" = "30",
  "prescription" = "31",
  "referral" = "32",
  "docx" = "33",
  "sendToPhysician" = "34",
  "doc" = "35",
  "sendGuidelines" = "36",
  "medicalHistory" = "37",
  "sendFileOcr" = "38",
  "fakeMessage" = "100"
}

export enum ConversationHandle {
  "DEFAULT"= "1",
  "SKIP_UI" = "2",
  "SKIP_MONGO" = "3",
  "SKIP_AI" = "4",
  "SKIP_ALL" = "5"
}

export enum ChatPointOfView {
  "AI_AND_PATIENT" = 1,
  "MY_AI" = 2
} 

export enum USER_TYPE {
  patient = 1,
  physician = 2,
  admin = 3,
  ai = 4,
  moderator = 5
}

export enum CHAT_SIDE_MENU_OPTIONS {
  chat = "chat",
  multiChat = "multiChat",
  settings = "settings",
  users = "users",
  analytics = "analytics",
  instructions = "instructions",
  logout = "logout"
}
import "./instructionsDemo.scss";
import React from "react"
import { useState, useEffect } from "react"
import { useRecoilValue } from "recoil";
import { fetchInstructionsAlpha, UpdateInstructionsAlpha } from "src/api/backoffice"
import { currentLangIDtate, currentLangState } from "src/states/signup";
import { Button } from "src/UILibrary"

interface Instructions {
  [key: string]: string;
}

const Instructions = ({setGeneralInstructions}:any) => {
  const currentLang = useRecoilValue(currentLangState)
  const currentLangID = useRecoilValue(currentLangIDtate)
  const [open, setOpen] = useState(false)
  const [instructions, setInstructions] = useState<Instructions>({});
  const [values, setValues] = useState(instructions);
  const [editingInstructions, setEditingInstructions] = useState<{ [key: string]: boolean }>({});

  const formatFieldNameToLabel = (fieldName: string, currentLang: Record<string, string>) => {
    if (fieldName === 'doctorInstruction') {
      return currentLang['doctor_instruction'] || 'Doctor Instruction';
    }
    const translatedFieldName = currentLang[fieldName.toLowerCase()] || fieldName;
    return translatedFieldName
  };
  
  const handleEditClick = (fieldName: string) => {
    setEditingInstructions((prevEditing) => ({
      ...prevEditing,
      [fieldName]: true,
    }));
  };

  const handleSaveClick = (fieldName: string) => {
    const updatedText = values[fieldName];
    setEditingInstructions((prevEditing) => ({
      ...prevEditing,
      [fieldName]: !editingInstructions[fieldName],
    }));
    updateInstructions(fieldName, updatedText);
    console.log(`Saved ${fieldName}`);
  };
  

  const getInstructions = async () => {
    try {
      const data = await fetchInstructionsAlpha("general")
      console.log(data)
      setInstructions(data)
      setValues(data)
    } catch (error) {
      console.error("Error fetching instructions")
    }
  }

  const updateInstructions = async (fieldName: string, text: string) => {
    try {
      const data = await UpdateInstructionsAlpha(fieldName ,text, "general")
    } catch (error) {
      console.error("Error fetching instructions")
    }
  }

  const handleChange = (fieldName: string, value: string) => {
    setValues({
      ...values,
      [fieldName]: value,
    });
    setInstructions((prevInstructions) => ({
      ...prevInstructions,
      [fieldName]: value,
    }));
  };
  useEffect(() => {
    getInstructions();
  }, [])

  return (
    <>
      <div className="main w-5/6">
      <div className={`users ${currentLangID === 2 ? 'rtl' : ''}`}>
        <div className="sectionStyles">
          <div className="divStyles">
          <div className="containerStyles_2">
          <div className={`info flex justify-between ${currentLangID === 2 ? 'rtl' : ''}`}>
          <h1 className="pb-2">{currentLang["general_instructions"]}</h1>
          <Button role="normal" type="submit" onClick={() => setGeneralInstructions(false)}>
          {currentLang["close"]}
          </Button>
          </div>
          <div>
        {Object.keys(instructions).map((fieldName) => (
          <div className='flex flex-col pb-8'key={fieldName}>
            <div className="pb-2">
            <p className="pl-2" style={{color:"#344054"}}>{formatFieldNameToLabel(fieldName, currentLang)}</p>
              </div>
            <textarea className="rounded-lg"
              name={fieldName}
              id={fieldName}
              cols={30}
              rows={15}
              placeholder={`Enter ${fieldName}`}
              value={
                Array.isArray(instructions[fieldName])
                  ? instructions[fieldName][0]
                  : instructions[fieldName]
              }
              onChange={(e) => {
                handleChange(fieldName, e.target.value);
                handleEditClick(fieldName);
              }}
            />
            <div className="flex flex-col justify-end self-end">
            <Button 
                role="submit"
                type="submit" 
                onClick={() => handleSaveClick(fieldName)}
                disabled={!editingInstructions[fieldName]}
              >
                {currentLang["save"]} {formatFieldNameToLabel(fieldName, currentLang)}
              </Button>
            </div>
          </div>
        ))}
      </div>
        </div>
        
      </div>
      </div>
      </div>
      </div>
    </>
  )
}
export default Instructions

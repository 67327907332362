
import React, { createContext, useContext, useEffect, useState } from "react";
import { IAuthRoute, IUserAuthContext, IUserAuthProvider, IUserAuthState, TCredentials, TUser } from "./userAuthContext.types";
import { deleteUserStorage, getUserStorage, setUserStorage } from "./storage";
import { Navigate, useNavigate } from "react-router-dom";
import { USER_TYPE } from "src/constants/common";


const userAuthContext = createContext<IUserAuthContext | null>(null);

const Provider = userAuthContext.Provider;


export const UserAuthProvider = ({ children }: IUserAuthProvider) => {
    const storage = getUserStorage();

    const [state, setState] = useState<IUserAuthState>(storage);
    const navigate = useNavigate();

    const updateUserState = (user: TUser) => {
        const newState: IUserAuthState = { ...state, user };

        setUserStorage(newState);
        setState(newState);
    }

    const updateCredentialsState = (credentials: TCredentials) => {
        const newState: IUserAuthState = { ...state, credentials };

        setUserStorage(newState);
        setState(newState);
    }

    const login = (credentials: TCredentials, user: TUser) => {
        const newState: IUserAuthState = { credentials, user };

        setUserStorage(newState);
        setState(newState);
        navigate("/home")
    }

    const logout = () => {
        const userType = state.user.type;

        deleteUserStorage()
        setState(getUserStorage());

        if (userType === USER_TYPE.patient) {
            navigate("/signin-patient")
        } else if (userType === USER_TYPE.physician) {
            navigate("/signin-physician")
        }
    }
    const value = {
        ...state,
        updateUserState,
        updateCredentialsState,
        logout,
        login
    }

    return (
        <Provider value={value}>
            {children}
        </Provider>
    )
}


export const useUserAuth = () => {
    const state = useContext(userAuthContext);
    if (!state) {
        throw Error("useUserAuth cannot be null")
    }

    return state;
}

export const SiteRoute = ({ children, auth }: IAuthRoute): JSX.Element => {
    const [init, setInit] = useState<boolean>(false);
    const { user } = useUserAuth();

    useEffect(() => setInit(true), []);

    if (!user.id && auth) {
        return <Navigate to='/' />
    }

    if (!auth && !init) {
        deleteUserStorage();
    }

    return children;
}
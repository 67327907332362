import React from "react"
import { useRecoilValue } from "recoil"
import { currentLangState } from "src/states/signup"

export const VoiceIndicatorAI = ({ on }: any) => {
  const currentLang = useRecoilValue(currentLangState)
  
  return (
    <>
      {on ? (
        <div className="voiceIndicatorOn">
          <div></div>
          <div></div>
          <div></div>
        </div>
      ) : (
        <p className="voiceIndicatorOff">{currentLang["you_can_speak_now"]}</p>
      )}
    </>
  )
}

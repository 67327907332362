import { FormControlLabel, Switch } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { fetchUserStatus, muteAI, unmuteAI } from 'src/api/backoffice';
import { styled } from '@mui/system';
import { useRecoilValue } from 'recoil';
import { currentLangState } from 'src/states/signup';
import { USER_TYPE } from 'src/constants/common';

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color:
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === 'light' ? '#F63D68' : '#12B76A',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
}));

const StatusAI = ({ currentUserType, userType, currentUserId, id, refresh }) => {
  const currentLang = useRecoilValue(currentLangState)
  const [status, setStatus] = useState("");


  const getStatus = async (patient_id, physician_id) => {
    try {
      if (patient_id && physician_id) {
        const data = await fetchUserStatus(patient_id, physician_id);
        if (data?.status_code === 1031) {
          setStatus('Activated');
        } else if (data?.status_code === 1032) {
          setStatus('Deactivated');
        } else {
          console.error("Error fetching user status");
        }
      }
    } catch (error) {
      console.error("Error fetching user status", error);
    }
  };

  const handleStatusClick = async () => {
    if (status === 'Activated') {
      try {
        await muteAI(currentUserId, id);
        setStatus('Deactivated');
      } catch (error) { 
        console.error("Error deactivating user", error);
      }
    } else if (status === 'Deactivated') {
      try {
        await unmuteAI(currentUserId, id);
        setStatus('Activated');
      } catch (error) { 
        console.error("Error activating user", error);
      }
    }
  };

  useEffect(() => {
    if (userType && !isNaN(userType)) {
      if (currentUserType == USER_TYPE.physician && userType == USER_TYPE.patient) {
        getStatus(id, currentUserId);
      }
    }
  }, [userType, currentUserType, id, currentUserId]);

  useEffect(() => {
  }, [refresh]);

  return (
    (!isNaN(currentUserType) && parseInt(currentUserType) === USER_TYPE.physician && userType === USER_TYPE.patient) ? (
      <div className="flex flex-row gap-5 mt-6 pr-5 w-28">
        <h2 className="flex" style={{fontWeight:"600", fontSize:"16px", color:"#344054"}}> {currentLang["status:"] }
          {/* <p className={status === 'Activated' ? 'green-text font-bold' : 'red-text font-bold'}>{status}</p> */}
        </h2>            
        {/* <button className={status === 'Activated' ? 'status-deact-btn ' : 'status-act-btn '} onClick={handleStatusClick}>
          {status === 'Activated' ? 'Deactivate' : 'Activate'}
        </button> */}
        <FormControlLabel
          control={
            <IOSSwitch
              checked={status === 'Activated'}
              onChange={handleStatusClick}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          }
          label={
            <span style={{ padding:"10px", fontWeight:"600", fontSize:"16px", color: status === 'Activated' ? '#12B76A' : '#F63D68' }}>
              {status === 'Activated' ? currentLang['activated'] : currentLang['deactivated']}
            </span>
          }
          sx={{
            '& .MuiSwitch-track': {
              backgroundColor: status === 'Activated' ? '#12B76A' : '#F63D68'
            },
            '& .MuiFormControlLabel-label': {
              marginLeft: '10px',
            },
          }}
        />
      </div>
    ) : null
  );
};

export default StatusAI;

import { USER_TYPE } from "src/constants/common"
import { axiosInstance } from "src/modules/axios"

export const loginByAdmin = async ({ email, password }: Record<string, string>) =>
  axiosInstance.post("/backoffice/login", {
    email,
    password,
  })

export const addMedicalHistory = async (patient_id: string, physician_id: string, file: File) => {
  try {
    const formData = new FormData()
    formData.append("patient_id", patient_id)
    formData.append("physician_id", physician_id)
    formData.append("file", file)
    const headers = {
      "Content-Type": "multipart/form-data",
    }
    const response = await axiosInstance.post(
      "/authenticated/conversation/add_medical_history_file",
      formData,
      {
        headers: headers,
      }
    )
    return response.data
  } catch (error) {
    console.error("Error:", error)
    throw error
  }
}

export const checkPatientPhysicianGroupExistence = async (
  patient_id: string,
  physician_id: string,
  currentLangID: number
) => {
  try {
    const response = await axiosInstance.post(
      "/authenticated/conversation/get_patient_group_names",
      {
        user_id: patient_id,
        lang: currentLangID === 1 ? "en" : "he"
      }
    )

    if (response.data.status_code === 1014) {
      let result = false
      for (let physician of response.data.final_all_groups) {
        if (physician === physician_id) {
          result = true
          break
        }
      }

      if (!result) {
        try {
          const response = await axiosInstance.post("/authenticated/conversation/create_chat", {
            user_id_physician: physician_id,
            user_id_patient: patient_id,
            visible: false,
          })
        } catch (error) {
          console.error("Error creating chat:", error)
          throw error
        }
      }
    }
  } catch (error) {
    console.error("Error fetching group names:", error)
    throw error
  }
}

export const showMedicalHistory = async (user_id: string) => {
  try {
    const response = await axiosInstance.post(
      "/authenticated/backoffice/recieve_files_names_medical_history",
      {
        user_id,
      }
    )
    const file_names = []
    for (const name of response.data.names) {
      file_names.push(name["file_name"])
    }
    return file_names
  } catch (error) {
    console.error("Error:", error)
    throw error
  }
}

export const fetchClinics = async () => {
  try {
    const response = await axiosInstance.post("/authenticated/backoffice/all_clinics")
    return response.data
  } catch (error) {
    console.error("Error:", error)
    throw error
  }
}

export const fetchPatients = async () => {
  try {
    const response = await axiosInstance.post("/authenticated/backoffice/all_patients")
    return response.data.patients.length
  } catch (error) {
    console.error("Error:", error)
    throw error
  }
}

export const fetchAdmins = async () => {
  try {
    const response = await axiosInstance.post("/authenticated/backoffice/all_admins")
    return response.data
  } catch (error) {
    console.error("Error:", error)
    throw error
  }
}

export const fetchPhysicians = async () => {
  try {
    const response = await axiosInstance.post("/authenticated/backoffice/all_physicians")
    return response.data
  } catch (error) {
    console.error("Error:", error)
    throw error
  }
}

export const fetchUsers = async () => {
  try {
    const response = await axiosInstance.post("/authenticated/backoffice/all_users")
    return response.data
  } catch (error) {
    console.error("Error:", error)
    throw error
  }
}

export const fetchUserByEmail = async (email: string) => {
  try {
    const response = await axiosInstance.post(
      `/authenticated/backoffice/fetch_user_by_email/${encodeURIComponent(email)}`
    )
    return response.data
  } catch (error) {
    console.error("Error:", error)
    throw error
  }
}

export const fetchUserByPhone = async (phone: string) => {
  try {
    const response = await axiosInstance.post(
      `/authenticated/backoffice/fetch_user_by_phone/${encodeURIComponent(phone)}`
    )
    return response.data
  } catch (error) {
    console.error("Error:", error)
    throw error
  }
}

export const fetchUsersByModeratorID = async (moderator_id: string) => {
  try {
    const response = await axiosInstance.post(
      `/authenticated/backoffice/fetch_users_by_clinic_name_taken_from_moderator_id/${encodeURIComponent(
        moderator_id
      )}`
    )
    return response.data.users
  } catch (error) {
    console.error("Error:", error)
    throw error
  }
}

export const fetchPatientsByPhysicianID = async (physician_id: string) => {
  try {
    const response = await axiosInstance.post("/authenticated/conversation/get_patients", {
      physician_id: physician_id,
    })
    console.log("in backoffice", response.data)
    const fetchPatientRows = []
    try {
      const physician = await fetchUser(physician_id)
      fetchPatientRows.push(physician)
    } catch (error) {
      console.error("Error fetching physician:", error)
    }
    for (const id of response.data.ids_patients) {
      try {
        console.log(id)
        const patient = await fetchUser(id)
        fetchPatientRows.push(patient)
      } catch (error) {
        console.error("Error fetching patient:", error)
      }
    }
    console.log(fetchPatientRows)
    return fetchPatientRows
  } catch (error) {
    console.error("Error:", error)
    throw error
  }
}

export const fetchUserStatus = async (patient_id: string, physician_id: string) => {
  console.log("fetch user status", physician_id, patient_id)
  try {
    const response = await axiosInstance.post("/authenticated/conversation/status_ai_enabled", {
      physician_id: physician_id,
      patient_id: patient_id,
    })
    console.log("fetch user status", physician_id, patient_id)
    return response.data
  } catch (error) {
    console.error("Error:", error)
    throw error
  }
}

export const fetchPhysiciansFromPatient = async (patient_id: string) => {
  try {
    const response = await axiosInstance.post(
      `/authenticated/conversation/get_patient_physicians/${encodeURIComponent(patient_id)}`,
      {
        patient_id: patient_id,
      }
    )
    return response.data["user's physicians"]
  } catch (error) {
    console.error("Error:", error)
    throw error
  }
}

export const fetchChatWordCount = async (user_id: string) => {
  try {
    const response = await axiosInstance.post(`/authenticated/conversation/chat_word_count`, {
      user_id: user_id,
    })
    return response.data
  } catch (error) {
    console.error("Error:", error)
    throw error
  }
}

export const fetchChatWordCountByDate = async (user_id: string, selectedDate: Date | null) => {
  if (selectedDate === null) {
    selectedDate = new Date()
  }
  const year = selectedDate.getFullYear()
  const month = String(selectedDate.getMonth() + 1).padStart(2, "0") // Months are zero-based
  const day = String(selectedDate.getDate()).padStart(2, "0")
  try {
    const response = await axiosInstance.post(`/authenticated/conversation/chat_word_count_daily`, {
      user_id: user_id,
      date: `${year}-${month}-${day}`,
    })
    return response.data
  } catch (error) {
    console.error("Error:", error)
    throw error
  }
}

export const fetchSpeechWordCount = async (user_id: string) => {
  try {
    const response = await axiosInstance.post(`/authenticated/conversation/speech_word_count`, {
      user_id: user_id,
    })
    return response.data
  } catch (error) {
    console.error("Error:", error)
    throw error
  }
}

export const fetchSpeechWordCountByDate = async (user_id: string, selectedDate: Date | null) => {
  if (selectedDate === null) {
    selectedDate = new Date()
  }
  const year = selectedDate.getFullYear()
  const month = String(selectedDate.getMonth() + 1).padStart(2, "0") // Months are zero-based
  const day = String(selectedDate.getDate()).padStart(2, "0")
  try {
    const response = await axiosInstance.post(`/authenticated/conversation/speech_word_count_daily`, {
      user_id: user_id,
      date: `${year}-${month}-${day}`,
    })
    return response.data
  } catch (error) {
    console.error("Error:", error)
    throw error
  }
}

export const fetchConversations = async (selectedDate: Date | null) => {
  if (selectedDate === null) {
    selectedDate = new Date()
  }
  const year = selectedDate.getFullYear()
  const month = String(selectedDate.getMonth() + 1).padStart(2, "0") // Months are zero-based
  const day = String(selectedDate.getDate()).padStart(2, "0")
  try {
    const response = await axiosInstance.post("/demo/get_conversations", {
      secret_code: "aw27mo6ois&n1#2498askUIAu3E90!#",
      date: `${year}-${month}-${day}`,
    })
    console.log(response.data)
    return response.data
  } catch (error) {
    console.error("Error:", error)
    throw error
  }
}

export const fetchActiveChatAmount = async (user_id: string) => {
  try {
    const response = await axiosInstance.post(`/authenticated/conversation/active_chats`, {
      user_id: user_id,
    })
    return response.data
  } catch (error) {
    console.error("Error:", error)
    throw error
  }
}

export const muteAI = async (physician_id: string, patient_id: string) => {
  try {
    const response = await axiosInstance.post("/authenticated/conversation/deactivate", {
      physician_id: physician_id,
      user_id: physician_id,
      patient_id: patient_id,
    })
    console.log("deactivate user", physician_id, patient_id)
    return response.data
  } catch (error) {
    console.error("Error:", error)
    throw error
  }
}

export const unmuteAI = async (physician_id: string, patient_id: string) => {
  try {
    const response = await axiosInstance.post("/authenticated/conversation/activate", {
      physician_id: physician_id,
      user_id: physician_id,
      patient_id: patient_id,
    })
    console.log("activate user", physician_id, patient_id)
    return response.data
  } catch (error) {
    console.error("Error:", error)
    throw error
  }
}

export const fetchPhysicianInstructions = async (physician_id: string) => {
  try {
    const response = await axiosInstance.post(
      "/authenticated/conversation/get_physician_instructions",
      {
        physician_id: physician_id,
      }
    )
    console.log("instructions are fetched")
    if (response.data.status_code === 1040) return response.data.instructions
    if (response.data.status_code === 2085)
      return "No instructions exist for this physician.\nYou can add some."
  } catch (error) {
    console.error("Error:", error)
    throw error
  }
}

export const addPhysicianInstructions = async (physician_id: string, instructions: string) => {
  try {
    const response = await axiosInstance.post(
      "/authenticated/conversation/add_physician_instructions",
      {
        user_id_physician: physician_id,
        instructions: instructions,
      }
    )
    console.log("instructions are added")
  } catch (error) {
    console.error("Error:", error)
    throw error
  }
}

export const fetchInstructions = async () => {
  try {
    const response = await axiosInstance.post("/demo/get_all_instructions", {
      secret_code: "aw27mo6ois&n1#2498askUIAu3E90!#",
    })
    return response.data["doctor general"]
    // return response.data["doctor uri"]
  } catch (error) {
    console.error("Error:", error)
    throw error
  }
}

// export const fetchInstructionsAlpha = async (id:string) => {
//   try {
//     const response = await axiosInstance.post(`/backoffice/get_general_instructions/${encodeURIComponent(id)}`,)
//     console.log(response)
//     return response.data
//     // return response.data["doctor uri"]
//   } catch (error) {
//     console.error("Error:", error)
//     throw error
//   }
// }

export const fetchInstructionsAlpha = async (id: string) => {
  try {
    const response = await axiosInstance.post(`authenticated/backoffice/get_general_instructions`, {
      id,
    })
    if (id === "general") return response.data[`doctor ${id}`]
    if (id !== "general") return response.data[`doctor ${id}`]
  } catch (error) {
    console.error("Error:", error)
    throw error
  }
}

export const UpdateInstructionsAlpha = async (
  field: string,
  text: string | [string],
  id: string
) => {
  try {
    const response = await axiosInstance.post(`authenticated/backoffice/instruction_update`, {
      field: field,
      update: text,
      id: id,
    })
    return response.data
  } catch (error) {
    console.error("Error:", error)
    throw error
  }
}

export const UpdateInstructions = async (field: string, text: string | [string]) => {
  try {
    const doctor = "general" // Tomer asked to use just doctor = general
    // const doctor = 'uri'
    const response = await axiosInstance.post(
      `/demo/instruction_update/${field}?doctor=${doctor}`,
      {
        secret_code: "aw27mo6ois&n1#2498askUIAu3E90!#",
        field: field,
        update: text,
      }
    )
    return response.data
  } catch (error) {
    console.error("Error:", error)
    throw error
  }
}

export const UpdateUserData = async (
  id: string,
  firstName: string,
  lastName: string,
  newEmail: string,
  phone: string,
  clinicName: string | undefined,
  userType: number | undefined
) => {
  try {
    const response = await axiosInstance.post(
      `/authenticated/backoffice/update_user/${encodeURIComponent(id)}`,
      {
        first_name: firstName,
        last_name: lastName,
        email: newEmail,
        phone_number: phone,
        clinic_name: clinicName,
        user_type_id: userType,
      }
    )
    console.log(response.data)
    return response.data
  } catch (error) {
    console.error("Error:", error)
    throw error
  }
}

export const validateCode = async (id: string, randomCode: string) => {
  try {
    const response = await axiosInstance.post("/authenticated/conversation/is_valid_patient_code", {
      // user_id:id,
      patient_code: randomCode,
    })
    console.log(response.data)
    return response.data
  } catch (error) {
    console.error("Error:", error)
    throw error
  }
}

export const addPatientCode = async (
  user_id_physician: string,
  user_id_patient: string,
  randomCode: string
) => {
  try {
    var createFlag = true
    const patient_info = await axiosInstance.post("/authenticated/conversation/get_all_user_info", {
      user_id: user_id_patient,
    })
    const assigned_physicians = JSON.parse(patient_info.data.user_info).assigned_physicians
    console.log(assigned_physicians)
    for (let physician in assigned_physicians) {
      console.log(assigned_physicians[physician])
      if (user_id_physician === assigned_physicians[physician]) {
        createFlag = false
        break
      }
    }
    if (createFlag === true) {
      try {
        await axiosInstance.post("/authenticated/conversation/create_chat", {
          user_id_physician: user_id_physician,
          user_id_patient: user_id_patient,
          visible: false,
        })
      } catch (error) {
        console.error("Error creating chat:", error)
        throw error
      }
      try {
        assigned_physicians.push(user_id_physician)
        await axiosInstance.post("/authenticated/conversation/update_user_info", {
          user_id: user_id_patient,
          user_info: {
            assigned_physicians: assigned_physicians,
          },
        })
      } catch (error) {
        console.error("Error updating user info:", error)
        throw error
      }
    }
  } catch (error) {
    console.error("Error:", error)
    throw error
  }

  try {
    const token_response = await axiosInstance.post(
      "/authenticated/conversation/add_patient_code",
      {
        user_id_physician: user_id_physician,
        user_id_patient: user_id_patient,
        patient_code: randomCode,
      }
    )
    console.log("Token created", token_response.data)
  } catch (error) {
    console.error("Error adding patient code:", error)
    throw error
  }
}

export const processPatientCode = async (id: string, randomCode: string) => {
  console.log(id, randomCode)
  try {
    const response = await axiosInstance.post("/authenticated/conversation/process_patient_code", {
      user_id_patient: id,
      patient_code: randomCode,
    })
    console.log(response.data)
    return response.data
  } catch (error) {
    console.error("Error:", error)
    throw error
  }
}


export const addUser = async (
  email: string,
  firstName: string,
  lastName: string,
  phone: string,
  clinicName: string,
  gender: string,
  clinicId: string | null,
  birthday: null | Date,
  user_type_id: number,
  assignedPhysicians: string[],
  password: string
) => {
  try {
    // const user_type_id = slug === 'admin' ? 3 : slug === 'physician' ? 2 : slug === 'moderator' ? 5 : 1;
    const user_permission_id =
      user_type_id === USER_TYPE.admin ? 1 : user_type_id === USER_TYPE.patient ? 2 : 3
    const response = await axiosInstance.post("/authenticated/backoffice/add_user", {
      email,
      first_name: firstName,
      last_name: lastName,
      phone_number: phone,
      clinic_name: clinicName,
      gender,
      id_social_number: clinicId,
      birthday,
      user_type_id,
      user_permission_id,
      assigned_physicians: assignedPhysicians,
      password: password
    })
    const inserted_patient_id = response.data.user_id
    for (let physician of assignedPhysicians) {
      try {
        await axiosInstance.post("/authenticated/conversation/create_chat", {
          user_id_physician: physician,
          user_id_patient: inserted_patient_id,
          visible: false,
        })
      } catch (error) {
        console.error("Error creating chat:", error)
        throw error
      }
    }
    return response.data.user_id
  } catch (error) {
    console.error("Error:", error)
    throw error
  }
}
// };

export const deleteUser = async (email: string, slug: string) => {
  if (slug === "clinic") {
    try {
      const response = await axiosInstance.delete(
        `/authenticated/backoffice/delete_clinic/${encodeURIComponent(email)}`
      )
      return response.data
    } catch (error) {
      console.error("Error:", error)
      throw error
    }
  } else {
    try {
      const response = await axiosInstance.delete(
        `/authenticated/backoffice/delete_user/${encodeURIComponent(email)}`
      )
      return response.data
    } catch (error) {
      console.error("Error:", error)
      throw error
    }
  }
}

export const fetchUser = async (id: string) => {
  // if (slug === 'clinic') {
  //   try {
  //     const response = await axiosInstance.post(`/authenticated/backoffice/fetch_physicians_by_clinic_id/${encodeURIComponent(clinic_id)}`);
  //     return response.data;
  //   } catch (error) {
  //     console.error("Error:", error);
  //     throw error;
  //   }
  // } else {
  //   try {
  //     const response = await axiosInstance.post(`/authenticated/backoffice/fetch_patient/${encodeURIComponent(clinic_id)}`);
  //     return response.data;
  //   } catch (error) {
  //     console.error("Error:", error);
  //     throw error;
  //   }
  // }
  try {
    const response = await axiosInstance.post(
      `/authenticated/backoffice/fetch_user_by_id/${encodeURIComponent(id)}`
    )
    return response.data
  } catch (error) {
    console.error("Error:", error)
    return { first_name: "Undefined", last_name: "Undefined" }
  }
}

export const fetchBills = async () => {
  try {
    const response = await axiosInstance.post("/authenticated/backoffice/get_bills")
    return response.data
  } catch (error) {
    console.error("Error:", error)
    throw error
  }
}

export const addBill = async (
  clientEmail: string,
  clientName: string,
  billNumber: string,
  billDate: Date,
  total: number
) => {
  try {
    const response = await axiosInstance.post("/authenticated/backoffice/add_bill", {
      client_email: clientEmail,
      client_name: clientName,
      bill_number: billNumber,
      bill_date: billDate,
      total: total,
    })
    return response.data
  } catch (error) {
    console.error("Error:", error)
    throw error
  }
}

export const sendEmailBill = async (
  clientEmail: string,
  clientName: string,
  billNumber: string
) => {
  try {
    const response = await axiosInstance.post("/authenticated/backoffice/send_email", {
      client_email: clientEmail,
      client_name: clientName,
      bill_number: billNumber,
    })
    return response.data
  } catch (error) {
    console.error("Error:", error)
    throw error
  }
}

export const fetchPrice = async (description: string) => {
  try {
    const response = await axiosInstance.post(
      `/authenticated/backoffice/prices?description=${encodeURIComponent(description)}`
    )
    return response.data
  } catch (error) {
    console.error("Error:", error)
    throw error
  }
}

import React from "react"
import { Typography } from "src/UILibrary"
import { LoginPatientForm } from "./components/loginPatientForm"
import { useLocation } from "react-router"
import LoginReg from "src/components/cards/LoginReg"
import Container from "src/components/container"
import { useRecoilValue } from "recoil"
import { currentLangState } from "src/states/signup"
import { BASE_COLORS } from "src/themes/colors"
import { LoginPhysicianForm } from "./components/loginPhysicianForm"

const SignIn: React.FC = () => {
  const currentLang = useRecoilValue(currentLangState)
  const location = useLocation()

  return (
    <LoginReg showLanguageMenu>
      <Container.Column style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
        <Typography.SubHeading
          sx={{ textAlign: "center", color: BASE_COLORS.primary[500], mb: "2rem" }}
        >
          {currentLang["log_in"]}
        </Typography.SubHeading>
        {location.pathname === "/signin-physician" ? (
          <LoginPhysicianForm />
        ) : (
          <LoginPatientForm />
        )}
      </Container.Column>
    </LoginReg>
  )
}

export default SignIn

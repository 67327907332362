import React from "react"
import { Button } from "src/UILibrary"
import { useSocketIo } from "../../context/hooks/useSocketIO"
import { ConversationHandle, ConversationMessageTypes } from "src/constants/enums"
import { useChat } from "../../context/chatContext"
import { useRecoilValue } from "recoil"
import { currentLangIDtate, currentLangState } from "src/states/signup"
import { TChatMessage } from "../../context/chatContext.types"

export const FinalizeIssueAction = () => {
  const currentLang = useRecoilValue(currentLangState)
  const currentLangID = useRecoilValue(currentLangIDtate)
  const { selectedChatGroupName, getGroup, userID, userName, setGroupStatus } = useChat()
  const { sendMessage } = useSocketIo()
  const group = getGroup(selectedChatGroupName)

  const handleSendMessage = () => {
    if (!group) return
    setGroupStatus(group.group_name, "active")
    const messageToServer: TChatMessage = {
      date_time: new Date().toUTCString(),
      user_id: userID,
      user_name: userName,
      message: "Issue was finalized",
      message_type_id: ConversationMessageTypes.finalize,
      group_name: group.group_name,
      conversation_handler: ConversationHandle.SKIP_UI,
      file_name: "",
    }
    if (currentLangID === 2) messageToServer.lang = "he"
    sendMessage(messageToServer)
  }

  return <Button className="dynamicActionButton Body3Medium" onClick={handleSendMessage}>{currentLang["finalize"]}</Button>
}

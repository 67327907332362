import React, { useEffect, useState } from "react"
import Home from "./home"
import { ChatProvider } from "./context/chatContext"
import { useUserAuth } from "../context/userAuth/userAuthContext"
import { axiosInstance, responseCodes } from "src/modules/axios"
import { useNavigate } from "react-router-dom"
import { Splash } from "src/components/splash"
import { MaximizeChatProvider } from "src/hooks/useMaximizeChat"

const HomeIndex = () => {
  // const { user } = useUserAuth();
  // const [init, setInit] = useState<boolean>(false);
  // const navigate = useNavigate();

  // useEffect(() => {
  //   axiosInstance.post("/registration/gettos", {
  //     email: user.email,
  //   }).then(resp => {
  //     if (resp.data["status_code"] === responseCodes["tos_is_false"]) {
  //       navigate("/TermsOfUse")
  //     } else {
  //       setInit(true);
  //     }
  //   })
  // }, [])

  // if (!init) {
  //   return <Splash />
  // }

  return (
    <ChatProvider>
      <MaximizeChatProvider>
        <Home />
      </MaximizeChatProvider>
    </ChatProvider>
  )
}

export default HomeIndex

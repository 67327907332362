import { useEffect, useRef } from "react"

export const useLazyScroll = (
    ref: React.RefObject<HTMLElement>,
    onUpdate: () => Promise<void>, 
    shouldLazyFetch: boolean,
    distance: number = 200
) => {
    const currTopRef = useRef<number>(0);
    const isOnUpdateRef = useRef<boolean>(false);
    const shouldLazyFetchRef = useRef<boolean>(false);

    shouldLazyFetchRef.current = shouldLazyFetch;

    useEffect(() => {
        setIdle()

        if(ref.current) {
            ref.current.addEventListener('scroll', handleScroll);
        }

        return () => {
            if(ref.current) {
                ref.current.removeEventListener('scroll', handleScroll);
            }
        }
    }, [ref?.current])

    const setIdle = () => {
        if(!ref.current) return;

        ref.current.dataset.state = 'idle';
    }

    const handleScroll = (e: any) => {
        if(!e?.target) { return }
        if(!shouldLazyFetchRef.current) { 
            setIdle();
            return;
        }
        
        const currTop = currTopRef?.current || 0;
        const isOnUpdate = isOnUpdateRef?.current || false;
       
        const { scrollTop, scrollHeight, clientHeight } = e.target;

        if(scrollHeight === clientHeight) { return }

       

        if(scrollTop <= distance) {
            if(scrollTop < currTop && !isOnUpdate) {
                e.target.dataset.top = e.target.scrollHeight;
                e.target.dataset.state = 'scroll';
                onUpdate();
                isOnUpdateRef.current = true;
            } else if(scrollTop > currTop) {
                isOnUpdateRef.current = false;
            }
            currTopRef.current = distance;
        } else {
            currTopRef.current = scrollTop;
            if(scrollTop > currTop) {
                isOnUpdateRef.current = false;
            }
        }
    } 
}
import check from "./check.svg"
import chat from "./chat.svg"
import settings from "./settings.svg"
import activity from "./activity.svg"
import folder from "./folder.svg"
import grid from "./grid.svg"
import mic from "./mic.svg"
import paperclip from "./paperclip.svg"
import personIllustration from "./personIllustration.svg"
import search from "./search.svg"
import userPlus from "./userPlus.svg"
import avatar from "./Avatars.png"
import x from "./x.svg"
import xBlack from "./xBlack.svg"
import maximize from "./maximize.svg"
import file from "./file.svg"
import send from "./send.svg"
import pausedRed from "./pause-red.svg"
import pause from "./pause.svg"
import play from "./play.svg"
import user from "./user.svg"
import userPlusBlue from "./userPlusBlue.svg"
import logOut from "./log-out.svg"
import logout from "./logout.svg"
import uploadCloud from "./upload-cloud.svg"
import threeLayers from "./three-layers.svg"
import backArrow from "./back-arrow.svg"
import pin from "./pin.svg"
import pinSelected from "./pin-selected.svg"
import pinSelectedChatGroup from "./pin-selected-chat-group.svg"
import addFile from "./addFile.svg"
import signature from "./signature.svg"
import plus from "./plus.svg"
import billing from "./billing.svg"
import summary from "./summary.svg"
import prescription from "./prescription.svg"
import voice from "./voice.svg"
import mute from "./mute.svg"
import email from "./email.svg"
import signatureBlack from "./signatureBlack.svg"
import failedUpload from "./failedUpload.svg"
import visibilityOff from "./visibility-off.svg"
import visibilityOn from "./visibility-on.svg"
import users from "./users.svg"
import analytics from "./analytics.svg"
import instructions from "./instructions.svg"
import userAddPlus from "./user-plus.svg"
import deleteBin from "./delete-bin.svg"
import flash from "./flash.svg"
import bigFlash from "./bigFlash.svg"
import pen from "./pen.svg"
import upload from "./upload.svg"
import userBig from "./userBig.svg"
import filePlus from "./filePlus.svg"
import closecross from "./closecross.svg"
import folderFilled from './folderFilled.svg'
import externalIcon from './externalIcon.svg'
import prescriptionBlue from './prescriptionBlue.svg'
import referal from './referal.svg'
import refresh from './refresh.svg'
import emptyMultiChat from './emptyMultiChat.svg'
import unsupported from './unsupported.svg'
import appleStore from './appleStore.svg'
import googleStore from './googleStore.svg'

const Icons = {
  check,
  chat,
  settings,
  activity,
  folder,
  grid,
  mic,
  paperclip,
  personIllustration,
  search,
  userPlus,
  avatar,
  x,
  maximize,
  file,
  send,
  pausedRed,
  play,
  pause,
  user,
  userPlusBlue,
  uploadCloud,
  logOut,
  logout,
  threeLayers,
  backArrow,
  pin,
  pinSelected,
  pinSelectedChatGroup,
  addFile,
  signature,
  plus,
  billing,
  summary,
  prescription,
  voice,
  xBlack,
  mute,
  email,
  signatureBlack,
  failedUpload,
  visibilityOff,
  visibilityOn,
  users,
  analytics,
  instructions,
  userAddPlus,
  deleteBin,
  flash,
  bigFlash,
  pen,
  upload,
  userBig,
  filePlus,
  closecross,
  folderFilled,
  externalIcon,
  prescriptionBlue,
  referal,
  refresh,
  emptyMultiChat,
  unsupported,
  appleStore,
  googleStore
}

export default Icons

import React, { useState } from "react"
import Icons from "src/assets/icons"
import Container from "src/components/container"
import { useRecoilValue } from "recoil"
import { Box, Button, StaticImage, Typography } from "src/UILibrary"
import { Dialog } from "src/UILibrary/Dialog/Dialog"
import { TextFieldWithLabel } from "src/components/textfieldWithLabel"
import { currentLangIDtate, currentLangState } from "src/states/signup"
import { axiosInstance } from "src/modules/axios"
import { useSocketIo } from "../context/hooks/useSocketIO"
import { ConversationHandle, ConversationMessageTypes } from "src/constants/enums"
import { useChat } from "../context/chatContext"
import useIsRtl from "src/hooks/useIsRtl"
import { TChatMessage } from "../context/chatContext.types"

interface IReferalDialogProps {
  group_one_name: string
  group_two_name: string
  active_group_name: string
  patient_name: string
  closeDialog: () => void
}

export function ReferalDialog({
  closeDialog,
  group_one_name,
  group_two_name,
  patient_name,
  active_group_name,
}: IReferalDialogProps) {
  const currentLang = useRecoilValue(currentLangState)
  const currentLangID = useRecoilValue(currentLangIDtate)
  const isRtl = useIsRtl()
  const { userName, userID } = useChat()
  const { sendMessage } = useSocketIo()
  const [referedBy, setReferedBy] = useState("")
  const [referedTo, setRefredTo] = useState("")
  const [reasonForReferal, setReasonForReferal] = useState("")

  const sendReferral = async () => {
    try {
      const body = {
        group_name: group_one_name,
        patient_name: patient_name,
        referral_by: referedBy,
        referral_to: referedTo,
        reason_for_referral: reasonForReferal,
      } as any
      const res = await axiosInstance.post("/authenticated/conversation/referral_creator", body)
      delete body.group_name
      sendFileToSocketIO(res.data.file_name)
      sendMessageToAI(body)
      closeDialog()
    } catch (e) {
      console.log("err:" + e)
    }
  }

  const sendFileToSocketIO = async (file_name: string) => {
    const groupOneMessage = {
      user_name: userName,
      user_id: userID,
      group_name: group_one_name,
      message_type_id: ConversationMessageTypes.requestReferal,
      message: file_name,
      file_name: "Referral.pdf",
      conversation_handler: ConversationHandle.SKIP_AI,
    } as TChatMessage
    sendMessage(groupOneMessage)
    const groupTwoMessage = {
      user_name: userName,
      user_id: userID,
      group_name: group_two_name,
      message_type_id: ConversationMessageTypes.requestReferal,
      message: file_name,
      file_name: "Referral.pdf",
      conversation_handler: ConversationHandle.SKIP_AI,
    } as TChatMessage
    sendMessage(groupTwoMessage)
  }

  const sendMessageToAI = async (body: any) => {
    const messageToServer = {
      date_time: new Date().toUTCString(),
      user_id: userID,
      user_name: userName,
      group_name: active_group_name,
      message_type_id: ConversationMessageTypes.referral,
      message: JSON.stringify(body),
      file_name: "Referral.pdf",
      conversation_handler: ConversationHandle.SKIP_UI,
    } as any  
    if (currentLangID === 2) messageToServer.lang = "he"
    sendMessage(messageToServer)
  }

  return (
    <Dialog>
      <div className="physcianCodeInput">
        <StaticImage
          className="prescriptionRefrealDialogCloseButton"
          onClick={closeDialog}
          src={Icons.xBlack}
          alt="logo"
          width={24}
          height={24}
        />
        <Container.Column sx={{ gap: "16px" }}>
          <div className="userPlusBlueWrapper">
            <div className="userPlusBlueContainer">
              <StaticImage src={Icons.referal} alt="logo" width={24} height={24} />
            </div>
          </div>
          <div>
            <p className="Body2 semibold">{currentLang["new_referral"]}</p>
          </div>
        </Container.Column>
        <Container.Column sx={{ gap: "16px" }}>
          <TextFieldWithLabel
            type="text"
            label={currentLang["referred_by"]}
            value={referedBy}
            onChange={(evt) => setReferedBy(evt.target.value)}
            fullWidth
            className="Body2 messageInput"
          />
          <TextFieldWithLabel
            type="text"
            label={currentLang["referred_to"]}
            value={referedTo}
            onChange={(evt) => setRefredTo(evt.target.value)}
            fullWidth
            className="Body2 messageInput"
          />
          <div className="PrescriptionRefrealTextAreaWrapper">
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                justifyContent: isRtl ? "flex-end" : "flex-start",
              }}
            >
              <Typography.Detail
                sx={{
                  fontSize: 14,
                  fontWeight: 510,
                  lineHeight: "1.25rem",
                  color: "text.secondary",
                  ml: "1rem",
                  mb: "0.25rem",
                }}
              >
                 <p>{currentLang["reason_for_referral"]}</p>
              </Typography.Detail>
            </Box>
            <textarea
              value={reasonForReferal}
              onChange={(evt) => setReasonForReferal(evt.target.value)}
            />
          </div>
        </Container.Column>
        <Button fullWidth role="submit" onClick={sendReferral}>
          {currentLang["create_referral"]}
        </Button>
      </div>
    </Dialog>
  )
}

// import "./users.scss"
import { GridColDef } from "@mui/x-data-grid"
import React, { useState, useEffect } from "react"
import { useLocation, useParams } from "react-router-dom"
import { fetchPhysicians } from "src/api/backoffice"
import DataTable from "src/components/backoffice/Analytics/DataTableUser"
import PhysicianInstructions from "src/pages/backoffice/instructions_demo/PhysicianInstructions"
import { useUserAuth } from "src/pages/context/userAuth/userAuthContext"


interface User {
  id: string;
  first_name: string;
  last_name: string;
  // avatar: string;
  user_type_id: number;
  // phone_number: string;
  // email: string;
  // clinic_name: string;
  // signature: boolean;
  // gender: string;
}

const columns: GridColDef[] = [
  {
    field: "user_type_id",
    headerName: "User Type",
    type: "string",
    width: 150,
    editable: false,
    valueFormatter: (params) => {
        if (params.value === 3) return "Admin";
        if (params.value === 2) return "Physician";
        if (params.value === 1) return "Patient";
        if (params.value === 5) return "Moderator";
        return params.value;
      },
  },
  { field: "id", headerName: "ID", width: 90 },
  // {
  //   field: "avatar",
  //   headerName: "Avatar",
  //   width: 100,
  //   renderCell: (params) => {
  //     return <img src={params.row.img || "/noavatar.png"} alt="" />
  //   },
  // },
  {
    field: "first_name",
    headerName: "First name",
    type: "string",
    width: 150,
    editable: false,
  },
  {
    field: "last_name",
    headerName: "Last name",
    type: "string",
    width: 150,
    editable: false,
  },

  // {
  //   field: "phone_number",
  //   headerName: "Phone number",
  //   description: "This column has a value getter and is not sortable.",
  //   type: "string",
  //   sortable: false,
  //   width: 160,
  // },
  // {
  //   field: "email",
  //   headerName: "Email",
  //   description: "This column has a value getter and is not sortable.",
  //   type: "string",
  //   sortable: false,
  //   width: 160,
  // },
  // {
  //   field: "clinic_name",
  //   headerName: "Clinic Name",
  //   description: "This column has a value getter and is not sortable.",
  //   type: "string",
  //   sortable: true,
  //   width: 160,
  // },
  // {
  //   field: "signature",
  //   headerName: "Signature",
  //   width: 80,
  //   type: "boolean",
  //   valueGetter: (params) => params.row.signature ? true : false
  // },
  // {
  //   field: "gender",
  //   headerName: "Gender",
  //   description: "This column has a value getter and is not sortable.",
  //   type: "singleSelect",
  //   valueOptions: ["male", "female", "other"],
  //   editable: true,
  //   valueFormatter: (params) => {
  //     if (params.value === ("male" || "Male")) return "M";
  //     if (params.value === ("female" || "Female")) return "F";
  //     if (params.value === ("other" || "Other")) return "O";
  //     return params.value;
  //   },
  // },
]
const InstructionsDemoPage = () => {
  const { user } = useUserAuth();
  const currentUserId = user.id;
  const currentUserType = user.type;
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState<User[]>([]);
  const [selectedUserId, setSelectedUserId] = useState();

  const getUsers = async () => {
    try {
      setLoading(true);
      const data: any = await fetchPhysicians();
      console.log(data);
      if (data.status === "success") {
        const fetchedUserRows = data.physicians;
        setUsers(fetchedUserRows);
      } else {
        console.error("Error fetching users:", data.message);
      }
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getUsers();
  }, [currentUserType]);

  if (selectedUserId) {
    return <PhysicianInstructions id={selectedUserId} setSelectedUserId={setSelectedUserId}/>
  }

  return (
    <div className="users">
      <div className="sectionStyles">
        <div className="divStyles">
          <div className="containerStyles_2">
            <div className="info">
              <h1>Physicians</h1>
            </div>
            {loading ? (
              <div className="loading-spinner"></div>
            ) : (
              <DataTable
                setSelectedUserId={setSelectedUserId}
                currentUserId={currentUserId}
                currentUserType={currentUserType}
                slug="physician"
                columns={columns}
                rows={users}
                onDelete={getUsers}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InstructionsDemoPage

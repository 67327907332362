import React from "react"
import { Box, StaticImage, Typography } from "src/UILibrary"
import LogoWhite from "src/assets/icons/logo-white.svg"
import LoadingSpinner from "src/assets/icons/spinner.svg"
import { useRecoilValue } from "recoil"
import { currentLangState } from "src/states/signup"
import { BASE_COLORS } from "src/themes/colors"

export const Splash: React.FC = () => {
  const currentLang = useRecoilValue(currentLangState)
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        backgroundColor: BASE_COLORS.primary[400],
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "1rem",
        borderRadius: "20px",
      }}
    >
      <Box
        sx={{
          position: "relative",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <StaticImage src={LoadingSpinner} alt="spinner" width={86} height={86} />
        <StaticImage
          src={LogoWhite}
          alt="logo"
          width={70}
          height={70}
          sx={{ position: "absolute" }}
        />
      </Box>
      <Typography.Logo fontSize="26px">
        {currentLang["welcome"] ? currentLang["welcome"] : "Welcome"}
      </Typography.Logo>
    </Box>
  )
}

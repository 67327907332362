import React, { useEffect, useState } from "react"
import { GridColDef } from "@mui/x-data-grid"
import { LocalizationProvider, DatePicker as MuiDatePicker } from "@mui/x-date-pickers"
import "./add.scss"
import { addUser, fetchUser, fetchUserByEmail, fetchUserByPhone } from "src/api/backoffice"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { TextFieldWithLabel } from "src/components/textfieldWithLabel"
import { Button, FormControl, SvgIcon, Typography } from "src/UILibrary"
import {
  Box,
  Checkbox,
  IconButton,
  InputAdornment,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from "@mui/material"
import { Dialog } from "src/UILibrary/Dialog/Dialog"
import { useRecoilValue } from "recoil"
import { currentLangIDtate, currentLangState } from "src/states/signup"
import { useUserAuth } from "src/pages/context/userAuth/userAuthContext"
import { USER_TYPE } from "src/constants/common"
import Icons from "src/assets/icons"
import { AxiosError } from "axios"
import useIsRtl from "src/hooks/useIsRtl"

interface User {
  id: string
  first_name: string
  last_name: string
  avatar: string
  user_type_id: number
  phone_number: string
  email: string
  clinic_name: string
  signature: boolean
  gender: string
}
interface PhysicianOption {
  id: string
  name: string
}
type Props = {
  slug: string
  columns: GridColDef[]
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  onAdd?: Function
  checkbox?: User[]
}

const Add = (props: Props) => {
  const { user } = useUserAuth()
  const isRtl = useIsRtl()
  const currentUserId = user.id
  const currentUserType = user.type
  const currentLang = useRecoilValue(currentLangState)
  const currentLangID = useRecoilValue(currentLangIDtate)
  const [email, setEmail] = useState("")
  const [firstName, setFirstName] = useState("")
  const [clinicName, setClinicName] = useState("")
  const [lastName, setLastName] = useState("")
  const [phone, setPhone] = useState("")
  const [avatar, setAvatar] = useState("")
  const [userTypeId, setUserTypeId] = useState("")
  const [password, setPassword] = useState("")
  const [gender, setGender] = useState("")
  const [clinicId, setClinicId] = useState("")
  const [birthday, setBirthday] = React.useState<Date | null>(null)
  const [assignedPhysicians, setAssignedPhysicians] = useState<string[]>([])
  const [options, setOptions] = useState<PhysicianOption[]>([])
  const [formErrors, setFormErrors] = useState({ phone: "", email: "" });

  const validatePhone = (value:string) => {
    const regex = /^[+][0-9]*$/;
    return regex.test(value);
  };

  const validateEmail = (email:string) => {
    const regex = /^[a-zA-Z0-9._%+-]*@[a-zA-Z0-9.-]*\.[a-zA-Z]{2,}$/;
    return regex.test(email);
  };

  const validateEmail_notExist = async (email:string) => {
    try{
      const response:any = await fetchUserByEmail(email)
      console.log(response)
      return response.status
    } catch (error) {
      console.error("Error checking if email is already in use", error)
    } 
  };

  const validatePhone_notExist = async (phone_number:string) => {
    try{
      const response:any = await fetchUserByPhone(phone_number)
      console.log(response)
      return response.status
    } catch (error) {
      console.error("Error checking if phone is already in use", error)
    } 
  };


  const handlePhoneChange = async (phone: string) => {
    try {
      const isValidPhone = validatePhone(phone);
      const phoneExists = await validatePhone_notExist(phone);
  
      if (isValidPhone && !phoneExists) {
        setFormErrors(prev => ({ ...prev, phone: "" }));
        return 0;
      } else if (!isValidPhone && phoneExists) {
        setFormErrors(prev => ({ ...prev, phone: currentLang["phone_number_should_start_with_+"] }));
        return 1;
      } else if (isValidPhone && phoneExists) {
        console.log("phone number already in use");
        setFormErrors(prev => ({ ...prev, phone: currentLang["phone_is_already_used_by_another_user"] }));
        return 1;
      }
    } catch (error) {
      console.error("Error checking if phone is already in use", error);
    }
  };

  const handleEmailChange = async (email: string) => {
    try {
      const isValidEmail = validateEmail(email);
      const emailExists = await validateEmail_notExist(email);
  
      if (isValidEmail && !emailExists) {
        setFormErrors(prev => ({ ...prev, email: "" }));
        return 0;
      } else if (!isValidEmail && emailExists) {
        setFormErrors(prev => ({ ...prev, email: currentLang["invalid_email_address"] }));
        return 1;
      } else if (isValidEmail && emailExists) {
        console.log("email exists")
        setFormErrors(prev => ({ ...prev, email: currentLang["email_is_already_used_by_another_user"] }));
        return 1;
      }
    } catch (error) {
      console.error("Error checking if email is already in use", error);
    }
  };


  const defaultInputProps = {
    endAdornment: (
      <InputAdornment position="end" sx={{ marginRight: "2px" }}>
        <IconButton
          aria-label="toggle password visibility"
          onClick={() => handleGenerateRandomCode()}
          edge="end"
        >
          <SvgIcon src={Icons.refresh} alt="visibility-on" sx={{ width: "16px", height: "16px" }} />
        </IconButton>
      </InputAdornment>
    ),
  } as any

  const generateRandomCode = () => {
    const characters = "abcdefghijklmnopqrstuvwxyz"
    const capitalCharacters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ"
    const numbers = "0123456789"
    const specialLetters = "#?!@$ %^&*-"
    let code = ""

    for (let i = 0; i < 4; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length)
      code += characters.charAt(randomIndex)
    }
    for (let i = 0; i < 4; i++) {
      const randomIndex = Math.floor(Math.random() * capitalCharacters.length)
      code += capitalCharacters.charAt(randomIndex)
    }
    for (let i = 0; i < 4; i++) {
      const randomIndex = Math.floor(Math.random() * numbers.length)
      code += numbers.charAt(randomIndex)
    }
    for (let i = 0; i < 2; i++) {
      const randomIndex = Math.floor(Math.random() * specialLetters.length)
      code += specialLetters.charAt(randomIndex)
    }
    code = code
      .split("")
      .sort(function () {
        return 0.5 - Math.random()
      })
      .join("")
    return code
  }

  const handleGenerateRandomCode = () => {
    const randomCode = generateRandomCode()
    setPassword(randomCode)
  }

  useEffect(() => {
    handleGenerateRandomCode()
  }, [])

  const cleanForm = () => {
    setEmail("")
    setLastName("")
    setFirstName("")
    setPhone("")
    setAvatar("")
    setPassword("")
    setClinicName("")
    setGender("")
    setUserTypeId("")
    setClinicId("")
    setBirthday(null)
    setAssignedPhysicians([])
    setFormErrors({ phone: "", email: "" });
  }

  const handleCheckChange = (id: string) => {
    if (assignedPhysicians.includes(id)) {
      setAssignedPhysicians(assignedPhysicians.filter((item) => item !== id))
    } else {
      setAssignedPhysicians([...assignedPhysicians, id])
    }
  }

  const formatFieldNameToLabel = (fieldName: string, currentLang: Record<string, string>) => {
    if (fieldName === "user_type_id") {
      return currentLang["user_role"] || "User Role"
    }
    const translatedFieldName = currentLang[fieldName.toLowerCase()] || fieldName
    return translatedFieldName
  }

  const getUser = async (id: string | undefined) => {
    try {
      if (id) {
        const data: any = await fetchUser(id)
        console.log(data)
        if (data?.status === "success") {
          setClinicName(data["clinic_name"])
          setClinicId(data["clinic_id"])
        } else {
          console.error("Error fetching user data")
        }
      }
    } catch (error) {
      console.error("Error fetching user data", error)
    }
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setFormErrors({ phone: "", email: "" });
  
    const emailError = await handleEmailChange(email);
    const phoneError = await handlePhoneChange(phone);
  
    if (emailError === 0 && phoneError === 0) {
      if (currentUserType === USER_TYPE.moderator) {
        getUser(currentUserId);
      }
  
      if (
        userTypeId === String(USER_TYPE.patient) &&
        assignedPhysicians.length === 0 &&
        (currentUserType === USER_TYPE.moderator || currentUserType === USER_TYPE.admin)
      ) {
        alert(currentLang["please_assign_at_least_one_physician_to_a_patient"]);
        return;
      }
  
      console.log(
        email,
        firstName,
        lastName,
        phone,
        clinicName,
        gender,
        clinicId,
        birthday,
        parseInt(userTypeId),
        assignedPhysicians
      );
  
      try {
        // Add new item
        const responseDB = await addUser(
          email,
          firstName,
          lastName,
          phone,
          clinicName,
          gender,
          clinicId,
          birthday,
          parseInt(userTypeId),
          assignedPhysicians,
          password
        );
        alert(`A user with email ${email} was added to the list`);
        if (props.onAdd) {
          props.onAdd();
        }
        cleanForm();
        props.setOpen(false);
      } catch (error: any) {
        if (error.response && error.response.status === 409) {
          alert(error.response.data.message || "Conflict error: User already exists");
        } else {
          alert("Error adding item: " + error);
        }
      }
    }
  };
  

  const fetchData = () => {
    if (
      (currentUserType === USER_TYPE.admin || currentUserType === USER_TYPE.moderator) &&
      props.checkbox
    ) {
      const physiciansData = props.checkbox.filter(
        (user) => user.user_type_id === USER_TYPE.physician
      )
      const formattedPhysicians = physiciansData.map((physician) => ({
        id: physician.id.toString(),
        name: `${physician.first_name} ${physician.last_name}`,
      }))
      setOptions(formattedPhysicians)
    }
  }

  const userTypes = [
    ...(currentUserType === USER_TYPE.admin ? [{ id: 3, label: currentLang["admin"] }] : []),
    { id: 2, label: currentLang["physician"] },
    { id: 1, label: currentLang["patient"] },
    { id: 5, label: currentLang["moderator"] },
  ]

  useEffect(() => {
    if (currentUserType === USER_TYPE.physician) {
      const updatedAssignedPhysicians = [...assignedPhysicians, currentUserId]
      setAssignedPhysicians(updatedAssignedPhysicians)
      setUserTypeId(String(USER_TYPE.patient))
    }
    fetchData()
  }, [currentUserType, props.checkbox])

  useEffect(() => {
    if (currentUserType === USER_TYPE.moderator) {
      getUser(currentUserId)
    }
  }, [currentUserType])

  return (
    <>
      <Dialog>
        <div className="add-modal">
          <div className="sectionStyles">
            <div className="divStyles">
              <h1 className={`${currentLangID === 2 ? "rtl" : ""}`}>
                {currentUserType === USER_TYPE.physician
                  ? currentLang["new_patient"]
                  : currentLang["new_user"]}
              </h1>

              <form onSubmit={handleSubmit}>
                {props.columns
                  .filter(
                    (item) =>
                      item.field !== "id" && item.field !== "img" && item.field !== "signature"
                  )
                  .map((column) => (
                    <div className="item" key={column.field}>
                      {column.field === "gender" ? (
                        <select value={gender} onChange={(e) => setGender(e.target.value)}>
                          <option value="male" style={{ color: "black" }}>
                            {currentLang["male"]}
                          </option>
                          <option value="female" style={{ color: "black" }}>
                            {currentLang["female"]}
                          </option>
                          <option value="other" style={{ color: "black" }}>
                            {currentLang["other"]}
                          </option>
                          <option value="" style={{ color: "black" }}></option>
                        </select>
                      ) : column.field === "birthday" ? (
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <MuiDatePicker
                            value={birthday}
                            onChange={(date: Date | null) => setBirthday(date)}
                          />
                        </LocalizationProvider>
                      ) : column.field === "user_type_id" ? (
                        <>
                          <Box sx={{ marginBottom: "16px", width: "absolute" }}>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: isRtl ? "flex-end" : "flex-start",
                              }}
                            >
                              <Typography.Detail
                                sx={{
                                  fontSize: 14,
                                  fontWeight: 510,
                                  lineHeight: "1.25rem",
                                  color: "text.secondary",
                                  ml: "1rem",
                                  mb: "0.25rem",
                                }}
                              >
                                 {currentLang["user_role"]}
                              </Typography.Detail>
                            </Box>
                            <FormControl>
                              <Select
                                value={userTypeId}
                                onChange={(e) => {
                                  setUserTypeId(e.target.value)
                                  // console.log(e.target.value)
                                }}
                                required
                                sx={{
                                  width: "500px",
                                  borderRadius: "40px",
                                  color: "black",
                                  backgroundColor: "#F2F4F7",
                                  height: "40px",
                                  borderColor: "#F2F4F7",
                                }}
                              >
                                {userTypes.map((type) => (
                                  <MenuItem key={type.id} value={type.id}>
                                    {type.label}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Box>
                        </>
                      ) : (
                        <>
                          {column.field === "clinic_name" && (
                            <p className={`pb-4 pt-4 ${currentLangID === 2 ? "rtl" : ""}`}>
                              <b>{currentLang["clinics"]}</b>
                              <br />
                              <p style={{ color: "grey", paddingTop: "3px" }}>
                                {
                                  currentLang[
                                    "*this_will_define_which_clinic_this_physician_can_see_and_be_seen_in"
                                  ]
                                }
                              </p>
                            </p>
                          )}
                          <TextFieldWithLabel
                            label={formatFieldNameToLabel(column.field, currentLang)}
                            placeholder={`${currentLang["type"]} ${formatFieldNameToLabel(
                              column.field,
                              currentLang
                            ).toLowerCase()}`}
                            forceLtr={column.field === "phone_number" ? true : false}
                            required={true}
                            type="email"
                            value={
                              column.field === "email"
                                ? email
                                : column.field === "first_name"
                                ? firstName
                                : column.field === "last_name"
                                ? lastName
                                : column.field === "phone_number"
                                ? phone
                                : column.field === "avatar"
                                ? avatar
                                : column.field === "clinic_name"
                                ? clinicName
                                : column.field === "clinic_id"
                                ? clinicId
                                : password
                            }
                            onChange={(e) => {
                              switch (column.field) {
                                case "email":
                                  setFormErrors(prev => ({ ...prev, email: "" }));
                                  setEmail(e.target.value)
                                  // {handleEmailChange(e)}
                                  break
                                case "first_name":
                                  setFirstName(e.target.value)
                                  break
                                case "last_name":
                                  setLastName(e.target.value)
                                  break
                                case "phone_number":
                                  setFormErrors(prev => ({ ...prev, phone: "" }));
                                  setPhone(e.target.value)
                                  // {handlePhoneChange(e)}
                                  break
                                case "avatar":
                                  setAvatar(e.target.value)
                                  break
                                case "password":
                                  setPassword(e.target.value)
                                  break
                                case "clinic_name":
                                  setClinicName(e.target.value)
                                  break
                                case "gender":
                                  setGender(e.target.value)
                                  break
                                case "clinic_id":
                                  setClinicId(e.target.value)
                                  break
                              }
                            }}
                            // error={((column.field === "email")&&formErrors.email)||((column.field === "phone")&&formErrors.phone) ? true : false}
                            inputProps={column.field === "password" ? defaultInputProps : null}
                          />
                          {(column.field === "phone_number")&&(formErrors.phone) && <p style={{ color: 'red' }} className="error">{formErrors.phone}</p>}
                          {(column.field === "email")&&(formErrors.email) && <p style={{ color: 'red' }} className="error">{formErrors.email}</p>}
                        </>
                      )}
                    </div>
                  ))}
                {parseInt(userTypeId) === USER_TYPE.patient &&
                  currentUserType != USER_TYPE.physician && (
                    <div className="item">
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: isRtl ? "flex-end" : "flex-start",
                        }}
                      >
                        <Typography.Detail
                          sx={{
                            fontSize: 14,
                            fontWeight: 510,
                            lineHeight: "1.25rem",
                            color: "text.secondary",
                            ml: "1rem",
                            mb: "0.25rem",
                          }}
                        >
                          {currentLang["assigned_physicians"]}
                        </Typography.Detail>
                      </Box>
                      <FormControl>
                        <Select
                          multiple
                          value={assignedPhysicians}
                          onChange={(e) => setAssignedPhysicians(e.target.value as string[])}
                          required
                          sx={{
                            width: "500px",
                            borderRadius: "40px",
                            color: "black",
                            backgroundColor: "#F2F4F7",
                            height: "40px",
                            borderColor: "#F2F4F7",
                          }}
                          renderValue={(selected) =>
                            selected
                              .map((id) => options.find((option) => option.id === id)?.name)
                              .join(", ")
                          }
                        >
                          {options.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                              <Checkbox
                                checked={assignedPhysicians.includes(option.id)}
                                onChange={() => handleCheckChange(option.id)}
                              />
                              <ListItemText primary={option.name} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  )}

                <div className="flex justify-between">
                  <Button role="normal" onClick={() => props.setOpen(false)}>
                    {currentLang["cancel"]}
                  </Button>
                  <Button role="submit" type="submit">
                    {currentLang["create_user"]}
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  )
}

export default Add

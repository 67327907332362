import { useState } from "react"
import { IChatFeedsContext, TChatFeed, TChatMessage } from "../chatContext.types"

export const useChatFeeds = (): IChatFeedsContext => {
  const [chatFeeds, setChatFeeds] = useState<TChatFeed[]>([])

  const appendChatFeeds = (groupIds: string[]) => {
    setChatFeeds((prev) => {
      const list = groupIds
        .filter((id) => !prev.find((feed) => feed.groupId === id))
        .map((groupId: string) => ({
          groupId,
          messages: [],
        }))
      return [...prev, ...list]
    })
  }

  const appendChatFeed = (groupId: string) => {
    setChatFeeds((prev) => {
      if (prev.find((feed) => feed.groupId === groupId)) {
        return prev
      }

      return [
        ...prev,
        {
          groupId,
          messages: [],
        },
      ]
    })
  }

  const getChatFeed = (groupId: string) => {
    return chatFeeds.find((chatFeed) => chatFeed.groupId === groupId)
  }

  const appendChatMessage = (
    groupId: string,
    message: TChatMessage | TChatMessage[],
    isOlderMessages: boolean = false
  ) => {
    setChatFeeds((state: TChatFeed[]) => {
      const chatFeed = state.find((chatFeed) => chatFeed.groupId === groupId)
      if (chatFeed) {
        const messages = Array.isArray(message) ? message : [message]
        chatFeed.messages = isOlderMessages
          ? chatFeed.messages.concat(messages)
          : messages.concat(chatFeed.messages)
      }
      return [...state]
    })
  }

  const clearChatMessages = (groupId: string) => {
    const chatFeed = chatFeeds.find((chatFeed) => chatFeed.groupId === groupId)
    if (chatFeed) {
      chatFeed.messages = []
    }
    setChatFeeds([...chatFeeds])
  }

  return {
    chatFeeds,
    appendChatFeeds,
    appendChatFeed,
    getChatFeed,
    appendChatMessage,
    clearChatMessages,
  }
}

import React, { useState, useEffect } from "react"
import { Button } from "src/UILibrary"
import { useChat } from "../context/chatContext"
import { useRecoilValue } from "recoil"
import { currentLangIDtate, currentLangState } from "src/states/signup"
import * as speechsdk from "microsoft-cognitiveservices-speech-sdk"

const ConversationMode = ({ avatarExpanded, setAvatarExpanded, sendMessage, group_name }: any) => {
  const {
    playBeep,
    isAudioPlaying,
    stopCurrentAudio,
    setAudioMode,
    getGroup,
    isRecordingConversationMode,
    setIsRecordingConversationMode,
    isRecordingAudioMode,
    audioMode,
    tokenObj
  } = useChat()
  const currentLangID = useRecoilValue(currentLangIDtate)
  const [isAITalking, setIsAITalking] = useState(false)
  const currentLang = useRecoilValue(currentLangState)
  const [speechRecognizer, setSpeechRecognizer] = useState<any>(null)
  const group = getGroup(group_name)

  const setupRecognizer = async () => {
    try {
      if (!tokenObj) {
        console.error("Error fetching token")
        return
      }
      const speechConfig = speechsdk.SpeechConfig.fromAuthorizationToken(
        tokenObj.authToken,
        tokenObj.region
      )
      speechConfig.speechRecognitionLanguage = currentLangID === 2 ? "he-IL" : "en-US"

      const audioConfig = speechsdk.AudioConfig.fromDefaultMicrophoneInput()
      const recognizer = new speechsdk.SpeechRecognizer(speechConfig, audioConfig)

      recognizer.recognizing = (s, e) => {
        console.log(`RECOGNIZING: Text=${e.result.text}`)
      }

      recognizer.recognized = (s, e) => {
        if (e.result.reason === speechsdk.ResultReason.RecognizedSpeech) {
          console.log(`RECOGNIZED: Text=${e.result.text}`)
          sendMessage(e.result.text)
          setIsRecordingConversationMode(false)
        }
      }

      recognizer.canceled = (s, e) => {
        console.log(`CANCELED: Reason=${e.reason}`)
        if (e.reason === speechsdk.CancellationReason.Error) {
          console.log(`CANCELED: ErrorCode=${e.errorCode}`)
          console.log(`CANCELED: ErrorDetails=${e.errorDetails}`)
          console.log(`CANCELED: Did you update the subscription info?`)
        }

        recognizer.stopContinuousRecognitionAsync()
        setIsRecordingConversationMode(false)
      }

      recognizer.sessionStopped = (s, e) => {
        console.log("Session stopped.")
        recognizer.stopContinuousRecognitionAsync()
        setIsRecordingConversationMode(false)
      }

      setSpeechRecognizer(recognizer)
    } catch (err) {
      console.error("Error setting up recognizer", err)
    }
  }

  useEffect(() => {
    setupRecognizer()

    return () => {
      speechRecognizer?.close()
    }
  }, [currentLangID])

  useEffect(() => {
    return () => {
      speechRecognizer?.close()
    }
  }, [])

  useEffect(() => {
    if (isAudioPlaying) {
      setIsAITalking(true)
    } else if (!isAudioPlaying && isAITalking && audioMode) {
      setIsAITalking(false)
      setIsRecordingConversationMode(true)
    }
  }, [isAudioPlaying])

  useEffect(() => {
    if (isRecordingConversationMode) {
      speechRecognizer?.startContinuousRecognitionAsync()
      if (!speechRecognizer) {
        setupRecognizer();
      }
    } else {
      speechRecognizer?.stopContinuousRecognitionAsync()
    }
  }, [isRecordingConversationMode, speechRecognizer])

  const toggleListening = () => {
    setIsRecordingConversationMode(!isRecordingConversationMode)
    setAudioMode(!isRecordingConversationMode)
    isRecordingConversationMode ? playBeep("end") : playBeep("start")
    if (!isRecordingConversationMode) {
      stopCurrentAudio()
    }
  }

  return (
    <Button
      className="voiceModeButton"
      fullWidth={!avatarExpanded}
      sx={{ borderRadius: "12px" }}
      role="voiceMode"
      disabled={
        group?.status === "pause" ||
        isRecordingAudioMode ||
        (!isRecordingConversationMode && avatarExpanded) ||
        false
      }
      // endIcon={!avatarExpanded && <img src={Icons.voice} />}
      onClick={() => {
        toggleListening()
        setAvatarExpanded(!avatarExpanded)
      }}
    >
      {avatarExpanded ? "X" : currentLang["voice_mode"]}
    </Button>
  )
}

export default ConversationMode


import "./users.scss"
import { GridColDef } from "@mui/x-data-grid"
import React, { useState, useEffect } from "react"

import { StaticImage } from "src/UILibrary"
import { fetchPatientsByPhysicianID, fetchUser, fetchUsers, fetchUsersByModeratorID } from "src/api/backoffice"
import Icons from "src/assets/icons"
import Add from "src/components/backoffice/Analytics/Add"
import DataTable from "src/components/backoffice/Analytics/DataTableUser"
import { IconButton } from "src/components/iconButton"
import { useUserAuth } from "src/pages/context/userAuth/userAuthContext"
import { Button } from "src/UILibrary"
import { useRecoilValue } from "recoil"
import { currentLangIDtate, currentLangState } from "src/states/signup"
import { USER_TYPE } from "src/constants/common"
import User from "../single_user/SingleUser"

interface UserInterface {
  id: string;
  first_name: string;
  last_name: string;
  avatar: string;
  user_type_id: number;
  phone_number: string;
  email: string;
  clinic_name: string;
  signature: boolean;
  gender: string;
}

const Users = () => {
  const currentLang = useRecoilValue(currentLangState)
  const currentLangID = useRecoilValue(currentLangIDtate)
  const [selectedUserId, setSelectedUserId] = useState();
  const {user} = useUserAuth();
  const currentUserId = user.id;
  const currentUserType = user.type;
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false)
  const [users, setUsers] = useState<UserInterface[]>([]);

const columns: GridColDef[] = [
 {
    field: "user_type_id",
    headerName: currentLang["user_type"],
    type: "string",
    width: 150,
    editable: false,
    valueFormatter: (params) => {
        if (params.value === 3) return currentLang["admin"];
        if (params.value === 2) return currentLang["physician"];
        if (params.value === 1) return currentLang["patient"];
        if (params.value === 5) return currentLang["moderator"];
        return params.value;
      },
  },
  { field: "id", headerName: "ID", width: 90 },
  {
   field: "first_name",
    headerName: currentLang["first_name"],
    type: "string",
    width: 150,
    editable: false,
  },
  {
    field: "last_name",
    headerName: currentLang["last_name"],
    type: "string",
    width: 150,
    editable: false,
  },
//   {
//     field: "password",
//     headerName: "Password",
//     type: "string",
//     width: 110,
//     editable: false,
//   },
  // {
  //   field: "birthday",
  //   headerName: "Birthday",
  //   type: "date",
  //   width: 110,
  //   editable: true,
  //   valueGetter: (params) => new Date(params.row.birthday),
  // },
  // {
  //   field: "phone_number",
  //   headerName: "Phone number",
  //   description: "This column has a value getter and is not sortable.",
  //   type: "string",
  //   sortable: false,
  //   width: 160,
  // },
  // {
  //   field: "email",
  //   headerName: "Email",
  //   description: "This column has a value getter and is not sortable.",
  //   type: "string",
  //   sortable: false,
  //   width: 160,
  // },
  // {
  //   field: "clinic_name",
  //   headerName: "Clinic Name",
  //   description: "This column has a value getter and is not sortable.",
  //   type: "string",
  //   sortable: true,
  //   width: 160,
  // },
  // {
  //   field: "signature",
  //   headerName: "Signature",
  //   width: 80,
  //   type: "boolean",
  //   valueGetter: (params) => params.row.signature ? true : false
  // },
  // {
  //   field: "gender",
  //   headerName: "Gender",
  //   description: "This column has a value getter and is not sortable.",
  //   type: "singleSelect",
  //   valueOptions: ["male", "female", "other"],
  //   editable: true,
  //   valueFormatter: (params) => {
  //     if (params.value === ("male" || "Male")) return "M";
  //     if (params.value === ("female" || "Female")) return "F";
  //     if (params.value === ("other" || "Other")) return "O";
  //     return params.value;
  //   },
  // },
]

const add_columns: GridColDef[] = [
   {
    field: "first_name",
     headerName: currentLang["first_name"],
     type: "string",
     width: 150,
     editable: false,
   },
   {
     field: "last_name",
     headerName: currentLang["last_name"],
     type: "string",
     width: 150,
     editable: false,
   },
   {
     field: "phone_number",
     headerName: currentLang["phone_number"],
     description: "This column has a value getter and is not sortable.",
     type: "string",
     sortable: false,
     width: 160,
   },
   {
    field: "password",
    headerName: currentLang["password"],
    description: "password",
    type: "string",
    sortable: false,
    width: 160,
  },
   {
     field: "email",
     headerName: currentLang["email"],
     description: "This column has a value getter and is not sortable.",
     type: "string",
     sortable: false,
     width: 160,
   },
   ...(currentUserType === USER_TYPE.admin ? [{
     field: "clinic_name",
     headerName: currentLang["clinic_name"],
     description: "This column has a value getter and is not sortable.",
     type: "string",
     sortable: true,
     width: 160,
    }] : []),
    ...(currentUserType === (USER_TYPE.admin) || currentUserType === (USER_TYPE.moderator) ? [{
    field: "user_type_id",
    headerName: currentLang["user_type"],
    type: "string",
    width: 150,
    editable: false,
    valueFormatter: (params: any) => {
        if (params.value === USER_TYPE.admin) return currentLang["admin"];
        if (params.value === USER_TYPE.physician) return currentLang["physician"];
        if (params.value === USER_TYPE.patient) return currentLang["patient"];
        if (params.value === USER_TYPE.moderator) return currentLang["moderator"];
        return params.value;
      },
    }] : [])
 ]
  const getUsers = async () => {
    try {
      const data: any = await fetchUsers()
      if (data.status === "success") {
        const fetchedUserRows = data.users
        console.log('users amount ' + fetchedUserRows.length)
        console.log(fetchedUserRows)
        setUsers(fetchedUserRows)
      } else {
        console.error("Error fetching users:", data.message)
      }
    } catch (error) {
      console.error("Error fetching users")
    }
  }

  const getPatients = async () => {
    try {
      const data:any = await fetchPatientsByPhysicianID(currentUserId);
        const fetchedUserRows = data;
        console.log('patients amount ' + fetchedUserRows.length);
        console.log(fetchedUserRows);
        setUsers(fetchedUserRows);
    } catch (error) {
      console.error("Error fetching patients:", error);
    }
  };

  const getModeratorUsers = async () => {
    try {
      const data = await fetchUsersByModeratorID(currentUserId);
      const fetchedUserRows = data; 
      console.log('users amount ' + fetchedUserRows.length);
      
      console.log(fetchedUserRows);
      // setUsers(fetchedUserRows);
  
      for (const user of fetchedUserRows) {
        if (user.user_type_id === USER_TYPE.physician) {
          try {
            const patientData = await fetchPatientsByPhysicianID(user.id);
            console.log("Moderator physicians' patients' were fetched");
            console.log(patientData);
            setUsers(currentUsers => [...currentUsers, ...patientData]);
          } catch (error) {
            console.error("Error fetching patients for user " + user.id, error);
          }
        }
        if (user.user_type_id === USER_TYPE.moderator) {
          console.log("Moderator user's were fetched");
          console.log(user);
          setUsers(currentUsers => [...currentUsers, user]);
        }
      }
    } catch (error) {
      console.error("Error fetching users by moderator ID:", error);
    }
  };
  

  const fetchData = async () => {
    setLoading(true);
    try {
      let data;
      if (currentUserType === USER_TYPE.admin) {
        data = await getUsers();
      } else if (currentUserType === USER_TYPE.physician) {
        data = await getPatients();
      } else if (currentUserType === USER_TYPE.moderator) {
        data = await getModeratorUsers(); 
      }
      
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [currentUserType]);

  if (selectedUserId) {
    return (
      <User
        id={selectedUserId} 
        setSelectedUserId={setSelectedUserId}
        {...(currentUserType === USER_TYPE.admin || currentUserType === USER_TYPE.moderator ? { checkbox: users } : {})}
      />
    )
  }

  return (
    <div className="users">
      <div className="sectionStyles">
        <div className="divStyles">
          <div className={`containerStyles_2 ${currentLangID === 2 ? 'rtl' : ''}`}>
            <div className="info">
              <h1>{currentLang["users"]}</h1>
              <Button role="submit" type="submit" onClick={() => setOpen(true)}>
                <StaticImage 
                  width={20} 
                  height={20} 
                  src={Icons.userAddPlus} 
                  alt="logo" 
                  style={{ 
                    paddingRight: currentLangID === 2 ? '0px' : '4px', 
                    paddingLeft: currentLangID === 2 ? '4px' : '0px' 
                  }} 
                />
                {currentUserType === USER_TYPE.physician ? currentLang["new_patient"] : currentLang["new_user"]}
              </Button>
 </div>
            {loading ? (
              <div className="loading-spinner"></div>
            ) : (
              <>
                <DataTable setSelectedUserId={setSelectedUserId} currentUserId={currentUserId} currentUserType={currentUserType} slug="user" columns={columns} rows={users} onDelete={fetchData} />

              </>
            )}
          </div>
          {open && (
                  <Add
                    slug="user"
                    columns={add_columns}
                    setOpen={setOpen}
                    onAdd={fetchData}
                    {...(currentUserType === USER_TYPE.admin || currentUserType === USER_TYPE.moderator ? { checkbox: users } : {})}
                  />
                )}
        </div>
      </div>
    </div>
  );
};

export default Users

import React from "react"
import { useRecoilValue } from "recoil"
import { Button, StaticImage } from "src/UILibrary"
import { Dialog } from "src/UILibrary/Dialog/Dialog"
import Icons from "src/assets/icons"
import Container from "src/components/container"
import { currentLangState } from "src/states/signup"

export function SaveDialog({ close, leave }: any) {
  const currentLang = useRecoilValue(currentLangState)

  return (
    <Dialog>
      <div className="physcianCodeInput">
        <Container.Column sx={{ gap: "16px" }}>
          <div className="userPlusBlueWrapper">
            <div className="userPlusBlueContainer">
              <StaticImage src={Icons.x} alt="logo" width={24} height={24} />
            </div>
          </div>
          <div>
            <h4>{currentLang["leave_without_saving"]}</h4>
            <p>{currentLang["your_changes_wont_be_saved"]}</p>
          </div>
        </Container.Column>
        <Container.Row justifyContent="center" sx={{ gap: "14px" }}>
          <Button fullWidth role="normal" onClick={close}>
            {currentLang["cancel"]}
          </Button>
          <Button fullWidth role="submit" onClick={leave}>
            {currentLang["leave"]}
          </Button>
        </Container.Row>
      </div>
    </Dialog>
  )
}

import React from "react"

interface ILoadingBar {
  loadedLoadingPrecentage: number
}

export const LoadingBar = ({ loadedLoadingPrecentage }: ILoadingBar) => {
  return (
    <div className="loadingBar">
      <div
        className="loadingBarLoaded"
        style={{ width: loadedLoadingPrecentage + "%" }}
      ></div>
      <div className="loadingBarTotal"></div>
    </div>
  )
}

import { useRef, useState } from "react"
import { IChatWindow, IChatWindowContext, IDoubleGroup } from "../chatContext.types"
import { ChatPointOfView, USER_TYPE } from "src/constants/enums"

export const useChatWindows = (): IChatWindowContext => {
  const [chatWindows, setChatWindows] = useState<IChatWindow[]>([])
  const [selectedChatWindow, setSelectedChatWindow] = useState<IDoubleGroup | null>(null)
  const [selectedChatGroupName, setSelectedChatGroupName] = useState<string>("")
  const chatWindowsRef = useRef<IChatWindow[]>([])
  chatWindowsRef.current = chatWindows

  const appendChatWindow = (chatWindow: IChatWindow) => {
    setChatWindows([...chatWindows, chatWindow])
  }

  const setChatWindow = (chatWindow: IChatWindow, index?: number) => {
    if (index != undefined) {
      setChatWindows((prev) => {
        prev[index] = chatWindow
        return prev
      })
    } else {
      setChatWindows([chatWindow])
    }
    setSelectedChatWindow({
      groupOne: chatWindow.pointOfViews[0].groupId,
      groupTwo: chatWindow.pointOfViews[1].groupId,
    })
    setSelectedChatGroupName(chatWindow.group_name)
  }

  const clearChatWindow = () => {
    setChatWindows([])
    setSelectedChatWindow(null)
    setSelectedChatGroupName("")
  }

  const isSelectedChatWindow = (group_name: string) => {
    return (
      selectedChatWindow?.groupOne === group_name || selectedChatWindow?.groupTwo === group_name
    )
  }

  const isSelectedChatGroupName = (group_name: string) => {
    return selectedChatGroupName === group_name
  }

  const getEmptyChatWindow = () => {
    return {
      userIDLocation: "",
      userType: USER_TYPE.patient,
      activeChatGroupIndex: 0,
      currentClient: null,
      currentUserNameInitials: "",
      theirName: "",
      theirNameInitials: "",
      theirId: "",
      group_name: "",
      pointOfViews: [],
      pointOfView: ChatPointOfView.AI_AND_PATIENT,
      isTalking: false,
      setIsTalking: () => {},
      isTalkingMessage: "",
      isPinnedPatient: false,
      isPinnedPhysician: false,
      showAvatar: false,
      lastOnline: new Date(),
      typing: false,
      isAIPaused: false,
      isConnected: false,
      unreadMessages: 0,
      audioMode: false,
      chatGroupSelection: ChatPointOfView.MY_AI,
      isActive: true,
      typingUserID: "",
      typingUserName: "",
      examRoomId: "",
      physicianRoomId: "",
      setPinnedPatient: () => {},
      setPinnedPhysician: () => {},
    }
  }

  const isActiveWindow = (group_name: string) => {
    return !!chatWindowsRef?.current?.find((w) => w.group_name === group_name)
  }

  return {
    chatWindows,
    appendChatWindow,
    setChatWindows,
    setChatWindow,
    clearChatWindow,
    setSelectedChatWindow,
    isSelectedChatWindow,
    isSelectedChatGroupName,
    getEmptyChatWindow,
    isActiveWindow,
    selectedChatGroupName,
    setSelectedChatGroupName
  }
}

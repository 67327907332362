import { StaticImage } from "src/UILibrary"
import React from "react"
import Icons from "src/assets/icons"
import Container from "src/components/container"

export const DownloadAppPage = () => {
  return (
    <div className="downloadAppPageWrapper">
      <div className="downloadAppPageContainer">
        <StaticImage src={Icons.unsupported} />
        <p className="Body2Semibold downloadAppPageText">
          Sorry, this site is not accessible on mobile browsers. For the best experience, please
          download our app.
        </p>
        <div className="storesContainer">
          <a href="https://apps.apple.com/us/app/life-medic/id6479247133" target="_blank">
            <StaticImage src={Icons.appleStore} />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.lifemedicapp.lifemedicapp&pli=1"
            target="_blank"
          >
            <StaticImage src={Icons.googleStore} />
          </a>
        </div>
      </div>
    </div>
  )
}

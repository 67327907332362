import React from "react"
import { Link as MuiLink, LinkProps } from "@mui/material"
import { useNavigate } from "react-router-dom"
import { BASE_COLORS } from "src/themes/colors"

interface ILinkProps extends LinkProps {
  isOuterLink?: boolean
  openNewTab?: boolean
}
const LinkComponentWithStyles = (defaultStyles: LinkProps) => {
  const LinkComponent = ({
    children,
    href,
    isOuterLink = false,
    openNewTab = false,
    ...rest
  }: ILinkProps) => {
    const navigate = useNavigate()

    return (
      <MuiLink
        underline="none"
        component="button"
        href={href}
        {...defaultStyles}
        target={isOuterLink && openNewTab ? "_blank" : undefined}
        // {...(isOuterLink && openNewTab && { target: "blanks" })}
        onClick={(evt: React.MouseEvent<HTMLAnchorElement>) => {
          if (!isOuterLink) {
            evt.preventDefault()
            if (href && !href.startsWith("#")) {
              navigate(href)
            }
          }
        }}
        {...rest}
      >
        {children}
      </MuiLink>
    )
  }

  return LinkComponent
}

export const Link = {
  Dashboard: LinkComponentWithStyles({}),
  HeaderLink: LinkComponentWithStyles({
    color: "background.default",
    fontWeight: 500,
    fontSize: "0.75rem",
    lineHeight: "1.125rem",
    px: "1.25rem",
  }),
  FormLink: LinkComponentWithStyles({
    color: BASE_COLORS.primary[700],
    fontWeight: 590,
    fontSize: "0.875rem",
    lineHeight: "1.25rem",
    fontStyle: "normal",
  }),
  CategoryLink: LinkComponentWithStyles({
    color: "text.primary",
    fontWeight: 400,
    fontSize: "0.75rem",
    lineHeight: "1.125rem",
  }),
  BreadcrumbLink: LinkComponentWithStyles({
    color: "text.secondary",
    fontWeight: 500,
    fontSize: "0.625rem",
    lineHeight: "0.875rem",
  }),
}

import React from "react"
import { Link as MuiLink } from "@mui/material"
import { Box } from "src/UILibrary"
import { IconButton } from "src/components/iconButton"
import { socialSignUp } from "src/api/signupService"
import FacebookIcon from "src/assets/icons/facebook.svg"
import AppleIcon from "src/assets/icons/apple.png"
import GoogleIcon from "src/assets/icons/google.svg"
import LinkedinIcon from "src/assets/icons/linkedin.svg"
import { axiosInstance } from "src/modules/axios"

export const SocialLoginForm: React.FC = () => {
  const handleSocialSignUp = (type: string) => {
    socialSignUp(type)
      .then((res: any) => console.log(res))
      .catch((err) => console.log(err))
  }

  // const handleAppleSignIn = async () => {
  //   try {
  //    ... get json data from passport something to apple sign in.
  //     const { identityToken, email, fullName } = appleAuthRequestResponse;

  //     // Send the identity token to your server for validation and further processing
  //     const response = await fetch('http://10.100.102.51:3000/api/registration/auth/apple', {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify({ identityToken, email, fullName }),
  //     });

  //     const data = await response.json();
  //     setUserInfo(data.user);
  //     console.log(data.user)
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  return (
    <Box sx={{ display: "flex", justifyContent: "center", gap: "2rem" }}>
      <MuiLink
        href={`${axiosInstance.defaults.baseURL}/registration/auth/google`}
        rel="noopener noreferrer"
      >
        <IconButton
          sx={{ borderRadius: "50%", border: "1px solid #EAECF0" }}
          src={GoogleIcon}
          alt="google"
          onClick={() => handleSocialSignUp("google")}
        />
      </MuiLink>
      {/* <MuiLink
        href={`${axiosInstance.defaults.baseURL}/registration/auth/apple`}
        rel="noopener noreferrer"
      >
        <IconButton
          sx={{ borderRadius: "50%", border: "1px solid #EAECF0" }}
          src={AppleIcon}
          alt="apple"
          onClick={() => handleSocialSignUp("apple")}
        />
      </MuiLink> */}
      {/* <MuiLink
        href={`${axiosInstance.defaults.baseURL}/registration/auth/facebook`}
        rel="noopener noreferrer"
      >
        <IconButton
          src={FacebookIcon}
          alt="facebook"
          onClick={() => handleSocialSignUp("facebook")}
        />
      </MuiLink> */}
      {/* <MuiLink
        href={`${axiosInstance.defaults.baseURL}/registration/auth/linkedin`}
        rel="noopener noreferrer"
      >
        <IconButton
          sx={{ borderRadius: "50%", border: "1px solid #EAECF0" }}
          src={LinkedinIcon}
          alt="linkedin"
          onClick={() => handleSocialSignUp("linkedin")}
        />
      </MuiLink> */}
    </Box>
  )
}

import React, { useEffect, useState } from "react"
import { ChatMessages } from "../components/ChatMessages"
import { useChat } from "../context/chatContext"
import { useRecoilValue } from "recoil"
import { currentLangState } from "src/states/signup"
import Container from "src/components/container"
import { StaticImage } from "src/UILibrary"
import Icons from "src/assets/icons"
import { ChatPointOfView, USER_TYPE } from "src/constants/enums"

interface IMultiChat {
  setTyping: any
  refreshChatGroups: () => void
  setPinnedPhysician: any
  setPinnedPatient: any
}

export function MultiChat({
  setTyping,
  refreshChatGroups,
  setPinnedPhysician,
  setPinnedPatient,
}: IMultiChat) {
  const { chatWindows, chatGroups, userType, setChatWindows } = useChat()
  const [maximizedChat, setMaximizedChat] = useState<any>(null)
  const [isInit, setIsInit] = useState<boolean>(false)
  const currentLang = useRecoilValue(currentLangState)

  const handleMaximizeChat = (group: any) => {
    if (maximizedChat) {
      refreshChatGroups()
      setMaximizedChat(null)
    } else {
      setChatWindows([group])
      setMaximizedChat(group)
    }
  }

  useEffect(() => {
    if (!isInit && chatGroups.length) {
      refreshChatGroups()
      setIsInit(true)
    }
  }, [chatGroups])

  return (
    <div className="multiChatWrapper">
      <div className="multiChatTitle">
        <h4 className="bold colorGrey700">{currentLang["chat_multiview"]}</h4>
      </div>
      <div className="chatWindowWrapper">
        {chatWindows && chatWindows.length > 0 ? (
          chatWindows.map((chatWindow: any, index) => (
            <ChatMessages
              key={chatWindow.group_name}
              index={index}
              {...chatWindow}
              mode="multi"
              data={chatWindow}
              setTyping={setTyping}
              examRoomId={chatWindow.pointOfViews[0].groupId}
              physicianRoomId={chatWindow.pointOfViews[1].groupId}
              setPinnedPhysician={setPinnedPhysician}
              setPinnedPatient={setPinnedPatient}
              setMaximizedChat={handleMaximizeChat}
            />
          ))
        ) : (
          <div className="multiChatEmptyWrapper">
            <Container.Column justifyContent="center" alignItems="center" className="illustration">
              <StaticImage src={Icons.personIllustration} alt="logo" width={175} />
              <p className="illustrationTitle">
                {userType === USER_TYPE.patient
                  ? currentLang["add_physicians"]
                  : currentLang["add_patients"]}
              </p>
              <p className="illustrationParagrath">
                {userType === USER_TYPE.patient
                  ? currentLang["no_physicians_added_yet"]
                  : currentLang["no_patients_added_yet"]}
              </p>
            </Container.Column>
          </div>
        )}
      </div>
    </div>
  )
}

import React, { useState } from "react"
import { Box, Typography, Button } from "src/UILibrary"
import { useRecoilValue } from "recoil"
import { currentLangState } from "src/states/signup"
import { ForgotPhoneNumber } from "./forgotPhoneNumber"
import { ForgotEmail } from "./forgotEmail"
import { VerificationType } from "src/types/forgotPassword"

interface IForgotPasswordForm {
  setStep: Function
  verificationType: VerificationType
  setVerificationType: (type: VerificationType) => void
}

export const ForgotPasswordForm: React.FC<IForgotPasswordForm> = ({
  setStep,
  setVerificationType,
  verificationType,
}) => {
  const currentLang = useRecoilValue(currentLangState)

  const onSubmit = () => {
    setStep((step: number) => step + 1)
  }

  const handleSetMode = () => {
    setVerificationType(verificationType === "email" ? "phone" : "email")
  }

  return (
    <Box>
      <Typography.SubHeading
        sx={{ textAlign: "center", color: "text.primary", mb: "2rem", textDecoration: "underline" }}
      >
        {currentLang["forgot_password?"]}
      </Typography.SubHeading>
      <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <Typography.DetailHeading sx={{ mb: "1rem", width: "18.75rem", textAlign: "center" }}>
          {verificationType === "email"
            ? currentLang["dont_worry_email"]
            : currentLang["dont_worry_phone"]}
        </Typography.DetailHeading>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {verificationType === "email" ? (
          <ForgotEmail onSubmit={onSubmit} />
        ) : (
          <ForgotPhoneNumber onSubmit={onSubmit} />
        )}

        <Button fullWidth role="link" onClick={handleSetMode} className="sendCodeViaButton Body3Semibold">
          {verificationType === "email"
            ? currentLang["send_code_via_sms"]
            : currentLang["send_code_via_email"]}
        </Button>
      </Box>
    </Box>
  )
}

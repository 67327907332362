import { axiosInstance } from "src/modules/axios"

export const sendVerificationCodeToEmail = (email: string) =>
  axiosInstance.post("/forgot_password", {
    email,
  })

export const sendVerificationCodeToPhone = (phone_number: string) =>
  axiosInstance.post("/forgot_password/otpsms_phone", {
    phone_number,
  })

export const submitEmailVerificationCode = (data: Record<string, string>) => {
  const { email, otp } = data
  return axiosInstance.post("/forgot_password/otp", {
    email,
    otp,
  })
}

export const submitEmailVerificationPhone = (data: Record<string, string>) => {
  const { phone_number, otp } = data
  return axiosInstance.post("/forgot_password/otp_phone", {
    phone_number,
    otp,
  })
}

export const resetPassword = (data: Record<string, string>) => {
  const { password: new_password, email, otp } = data

  return axiosInstance.post("/forgot_password/otp/new_password", {
    new_password,
    email,
    otp,
  })
}

export const resetPasswordByPhone = (data: Record<string, string>) => {
  const { password: new_password, phoneNumber: phone_number, otp } = data

  return axiosInstance.post("/forgot_password/otp/new_password_phone", {
    new_password,
    phone_number,
    otp,
  })
}

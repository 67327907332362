import { Box, Grid } from "@mui/material"
import React, { PropsWithChildren } from "react"
import { BackgroundPane } from "../backgroundPane"
import Container from "../container"
import { LanguageMenu } from "../selectors/LanguageMenu"

interface IProps extends PropsWithChildren {
  showLanguageMenu?: boolean
}

const LoginReg = ({ showLanguageMenu = false, children }: IProps) => {
  return (
    <Grid container justifyContent="center">
      <Container.Row
        sx={{
          justifyContent: "center !important",
          alignItems: "center !important",
          bgcolor: "white",
          borderRadius: "1.5rem",
          overflow: "hidden",
          minWidth: "320px",
          width: "100%",
          height: '100%',
          overflowY: "auto",
          paddingLeft: { lg: "0px", xs: "20px" },
          paddingRight: { lg: "0px", xs: "20px" },
        }}
      >
        <Container.Flex
          sx={{
            justifyContent: { xs: "center" },
            width: "100%",
            height: "100%",
            position: "relative",
          }}
        >
          <Container.Row
            sx={{
              flex: 1,
              justifyContent: "center",
              width: "100%",
              position: "relative",
            }}
          >
            {showLanguageMenu && <LanguageMenu />}
            <BackgroundPane />
            <Container.Column
              sx={{
                flex: 1,
                maxWidth: "500px",
                alignItems: "center",
                pt: "32px",
                justifyContent: "flex-start",
                pb: "40px",
                scale: '0.8',
              }}
            >
              {children}
            </Container.Column>
          </Container.Row>
        </Container.Flex>
      </Container.Row>
    </Grid>
  )
}

export default LoginReg

import { InputAdornment } from "@mui/material"
import React from "react"
import { useRecoilValue } from "recoil"
import { StaticImage, TextField } from "src/UILibrary"
import Icons from "src/assets/icons"
import Container from "src/components/container"
import { currentLangState } from "src/states/signup"

interface IFile {
  message: string
  time: string
  downloadFileName: string
  element: any
}

export function FilesGallery({
  searchFile,
  setSearchFile,
  setShowFiles,
  isRtl,
  files,
  downloadFile,
  group_name,
  userID,
}: any) {
  const currentLang = useRecoilValue(currentLangState)

  return (
    <Container.Column
      justifyContent="start"
      sx={{ paddingBlock: "1rem" }}
      className="searchMessagesContainer"
    >
      <Container.Row alignItems="center" sx={{ width: "100%", gap: ".5rem" }}>
        <button onClick={() => setShowFiles(false)}>
          <StaticImage src={Icons.xBlack} alt="logo" width={16} height={16} />
        </button>
        <p className="Body3Semibold">{currentLang["search_files"]}</p>
      </Container.Row>
      <div className="searchInputContainer">
        <TextField
          type="search"
          initialType="search"
          placeholder={currentLang["search"]}
          value={searchFile}
          onChange={(evt) => setSearchFile(evt.target.value)}
          fullWidth
          sx={{
            "& input": {
              fontSize: "1rem",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "1.5rem",
              padding: "0.4rem 0.875rem",
              borderRadius: "50px",
              bgcolor: "white",
              color: "#98A2B3",
              direction: isRtl ? "rtl" : "ltr",
            },
          }}
          // InputProps={{
          //   endAdornment: (
          //     <InputAdornment position="end">
          //       <StaticImage src={Icons.search} alt="logo" width={16} height={16} />
          //     </InputAdornment>
          //   ),
          // }}
        />
      </div>
      <ul className="foundMessages">
        {files
          .filter((file: IFile) => file.message.toLowerCase().includes(searchFile.toLowerCase()))
          .map((file: IFile, index: number) => (
            <li onClick={() => downloadFile(file.downloadFileName, group_name, userID)} key={index}>
              <p className="foundMessageTime">{file.time}</p>
              <p className="Body3 ellipsis">{file.message}</p>
            </li>
          ))}
      </ul>
    </Container.Column>
  )
}

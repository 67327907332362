export type AccountType = "private" | "owner" | "admin"

export enum Gender {
  male = "male",
  female = "female",
  other = "other",
}

export type TGender = Gender | null | undefined
export interface IPersonalDetails {
  email: string
  phoneNumber: string
  // id: string
  dob: Date
  gender: TGender
  firstName: string
  lastName: string
}

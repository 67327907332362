import { useEffect } from "react"
import { useRecoilValue } from "recoil"
import { currentLangIDtate } from "src/states/signup"

const useIsRtl = () => {
  const currentLangID = useRecoilValue(currentLangIDtate)

  switch (currentLangID) {
    case 1:
      return false
    case 2:
      return true
    default:
      return false
  }
}
export default useIsRtl

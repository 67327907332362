import React from "react"
import { useNavigate } from "react-router"
import { Box, Button, Image, SvgIcon, Typography } from "src/UILibrary"
import CircleCheck from "src/assets/icons/circle-check.svg"
import { useRecoilValue } from "recoil"
import { currentLangState } from "src/states/signup"

export const ResetSuccessForm: React.FC = () => {
  const currentLang = useRecoilValue(currentLangState)
  const navigate = useNavigate()
  return (
    <Box
      sx={{
        width: "22.625rem",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography.SubHeading sx={{ textAlign: "center", color: "text.primary", mb: "2rem" }}>
        {currentLang["password_changed"]}
      </Typography.SubHeading>
      <Typography.DetailHeading sx={{ width: "18.75rem", textAlign: "center" }}>
        {currentLang["password_changed_succesfully"]}
      </Typography.DetailHeading>
      <Image src={CircleCheck} alt="circle-check" sx={{ mt: "4.5625rem", mb: "3.8125rem" }} />
      <Button role="submit" type="submit" onClick={() => navigate("/signin-patient")} fullWidth>
        {currentLang["back_to_login"]}
      </Button>
    </Box>
  )
}

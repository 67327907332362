import * as React from 'react';
import Box from '@mui/material/Box';
import {
  DataGridPremium,
  GridColDef,
  GridRowGroupingModel,
  GridToolbar,
  useGridApiRef,
  useKeepGroupedColumnsHidden,
} from '@mui/x-data-grid-premium';
import rtlPlugin from 'stylis-plugin-rtl';
import { arSD } from '@mui/x-data-grid/locales';
import { CacheProvider, useTheme } from "@emotion/react";
import { createTheme } from "@mui/material";
import { prefixer } from 'stylis';
import createCache from '@emotion/cache';
import { ThemeProvider } from "styled-components";
import { useRecoilValue } from 'recoil';
import { currentLangIDtate } from 'src/states/signup';

type Props = {
    columns: GridColDef[],
    rows: object[],
    aggr_column: [string],
    aggr_func: string,
}

const cacheRtl = createCache({
  key: 'data-grid-rtl-demo',
  stylisPlugins: [prefixer, rtlPlugin],
});

const DataGridPremiumDemo = (props: Props) => {
  const apiRef = useGridApiRef();
  const currentLangID = useRecoilValue(currentLangIDtate)

  const existingTheme = useTheme();
  const theme = React.useMemo(
      () =>
        createTheme({}, arSD, existingTheme, {
          direction: 'rtl',
        }),
      [existingTheme],
    );

  const initialState = useKeepGroupedColumnsHidden({
    apiRef,
    initialState: {
    //   ...data.initialState,
    //   {columns: {columnVisibilityModel:}}
      rowGrouping: {
        // ...data.initialState?.rowGrouping,
        model: props.aggr_column,
      },
      sorting: {
        sortModel: [{ field: '__row_group_by_columns_group__', sort: 'asc' }],
      },
      aggregation: {
        model: {
          [props.aggr_func]: 'sum',
        },
      },
    },
  });

  return (
    <CacheProvider value={cacheRtl}>
      <ThemeProvider theme={theme}>
        <Box dir={currentLangID === 2 ? "rtl" : "ltr"} sx={{ height: 520, width: '100%' }}>
          <DataGridPremium
            rows={props.rows}
            columns={props.columns}
            apiRef={apiRef}
            // loading={loading}
            disableRowSelectionOnClick
            initialState={initialState}
            slots={{ toolbar: GridToolbar }}
          />
        </Box>
      </ThemeProvider>
    </CacheProvider>
  );
}
export default DataGridPremiumDemo
import React from "react";
import { Image } from "src/UILibrary";
import { Button as MuiButton, ButtonProps } from "@mui/material";
import { BASE_COLORS } from "src/themes/colors";

interface IconButtonProps {
  src: string
  alt: string
}

export const IconButton: React.FC<IconButtonProps & ButtonProps> = ({
  sx,
  src,
  alt,
  onClick,
  dir,
}) => {
  return (
    <MuiButton
      dir={dir}
      onClick={onClick}
      sx={{
        alignSelf: "center",
        height: "44px",
        borderRadius: "0.5rem",
        p: "0.625rem",
        border: "1px solid",
        borderColor: BASE_COLORS.gray[300],
        bgcolor: "white",
        minWidth: "44px",
        "& .MuiBox-root": {
          "& span": {
            height: "24px",
          },
        },
        "&:hover": {
          bgcolor: BASE_COLORS.gray[50],
        },
        "&:focus": {
          boxShadow: "0px 0px 0px 4px #F2F4F7, 0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
          bgcolor: "white",
        },
        ...sx,
      }}
      disableRipple
    >
      <Image
        src={src}
        alt={alt}
        sx={{
          justifyContent: "center",
          alignSelf: "center",
          width: "24px",
          height: "24px",
        }}
      />
    </MuiButton>
  )
}

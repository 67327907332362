import React from "react"
import Container from "src/components/container"
import { TGroupStatus } from "../context/chatContext.types"
import { ChatPointOfView, USER_TYPE } from "src/constants/enums"
import { SendToPhysicianAction } from "./dynamicActions/SendToPhysicianAction"
import { FinalizeIssueAction } from "./dynamicActions/FinalizeIssueAction"
import { NoAction } from "./dynamicActions/NoAction"
import { PrescriptionAction } from "./dynamicActions/PrescriptionAction"
import { ReferralAction } from "./dynamicActions/ReferralAction"
import { RequestMoreDetailsAction } from "./dynamicActions/RequestMoreDetailsAction"
import { SendMoreDetailsAction } from "./dynamicActions/SendMoreDetailsAction"
import { useChat } from "../context/chatContext"
import { SendGuideLinesAction } from "./dynamicActions/SendGuidelinesAction"
import { useRecoilValue } from "recoil"
import { currentLangState } from "src/states/signup"

interface IDynamicActions {
  pointOfView: ChatPointOfView
  status: TGroupStatus
  userType: USER_TYPE
  setShowReferalDialog: (show: boolean) => void
  setShowPrescriptionDialog: (show: boolean) => void
  examRoomId: string
  physicianRoomId: string
}

export const DynamicActions = ({
  pointOfView,
  status,
  userType,
  setShowReferalDialog,
  setShowPrescriptionDialog,
  examRoomId,
  physicianRoomId,
}: IDynamicActions) => {
  const { getChatFeed } = useChat()
  const currentLang = useRecoilValue(currentLangState)
  const examRoom = getChatFeed(examRoomId)
  const physicianRoom = getChatFeed(physicianRoomId)
  let dynamicActionsComponent = <></>

  if (!status) return dynamicActionsComponent

  if (userType === USER_TYPE.patient) {
    switch (status) {
      case "active":
        {
          examRoom &&
            examRoom.messages.length > 10 &&
            (dynamicActionsComponent = (
              <Container.Column className="dynamicActionsWrapper" gap={1} alignItems="center">
                <SendToPhysicianAction examRoomID={examRoomId} physicianRoomID={physicianRoomId} />
                <p className="Caption2">
                  {currentLang["click_in_case_you_finished_filling_all_the_medical_details"]}
                </p>
              </Container.Column>
            ))
        }
        break
      case "issue_finalized":
        dynamicActionsComponent = (
          <Container.Row className="dynamicActionsWrapper" gap={1} justifyContent="center">
            <FinalizeIssueAction />
          </Container.Row>
        )
        break
      case "pause":
        dynamicActionsComponent = (
          <Container.Row className="dynamicActionsWrapper" gap={1}>
            <NoAction />
          </Container.Row>
        )
        break
      default:
        break
    }
  } else if (userType === USER_TYPE.physician && pointOfView === ChatPointOfView.MY_AI) {
    switch (status) {
      case "active":
        dynamicActionsComponent = (
          <Container.Row className="dynamicActionsWrapper" gap={1} justifyContent="center">
            <RequestMoreDetailsAction examRoomID={examRoomId} physicianRoomID={physicianRoomId} />
            <ReferralAction setShowReferalDialog={setShowReferalDialog} />
            <PrescriptionAction setShowPrescriptionDialog={setShowPrescriptionDialog} />
            <SendGuideLinesAction examRoomID={examRoomId} physicianRoomID={physicianRoomId} />
          </Container.Row>
        )
        break
      case "physician_more_details":
        dynamicActionsComponent = (
          <Container.Row className="dynamicActionsWrapper" gap={1} justifyContent="center">
            <SendMoreDetailsAction examRoomID={examRoomId} physicianRoomID={physicianRoomId} />
          </Container.Row>
        )
        break
      case "pause":
        dynamicActionsComponent = (
          <Container.Row className="dynamicActionsWrapper" gap={1}>
            <NoAction />
          </Container.Row>
        )
        break
      default:
        break
    }
  }

  return dynamicActionsComponent
}

import React, { useEffect, useState } from "react"
// import "../../../pages/backoffice/single_user/singleUser.scss";
import "../Analytics/code.scss"
import { addMedicalHistory, fetchPhysiciansFromPatient, fetchUser } from "src/api/backoffice"
import { Button, StaticImage } from "src/UILibrary"
import Icons from "src/assets/icons"
import LinearDeterminate from "./linear"
import { useRecoilValue } from "recoil"
import { currentLangIDtate, currentLangState } from "src/states/signup"
import { USER_TYPE } from "src/constants/common"
import { useUserAuth } from "src/pages/context/userAuth/userAuthContext"
import { FormControl, InputLabel, ListItemText, MenuItem, Select } from "@mui/material"
import { useSocketIo } from "src/pages/home/context/hooks/useSocketIO"
import { ConversationHandle, ConversationMessageTypes } from "src/constants/enums"
import { axiosInstance } from "src/modules/axios"
import { removePaFromGroupName } from "src/pages/home/utils"

interface MedicalHistoryProps {
  isOpen: boolean
  onRequestClose: () => void
  id: string
  // checkbox?: User[];
}

interface User {
  id: string
  first_name: string
  last_name: string
  avatar: string
  user_type_id: number
  phone_number: string
  email: string
  clinic_name: string
  signature: boolean
  gender: string
}

interface PhysicianOption {
  id: string
  name: string
}

const UploadMedicalHistory = ({ isOpen, onRequestClose, id }: MedicalHistoryProps) => {
  const { user } = useUserAuth()
  const currentUserId = user.id
  const currentUserType = user.type
  const currentLang = useRecoilValue(currentLangState)
  const currentLangID = useRecoilValue(currentLangIDtate)
  const [isCodeCopied, setCodeCopied] = useState(false)
  const [fileUploaded, setFileUploaded] = useState(false)
  const [fileName, setFileName] = useState(false)
  const [showForm, setShowForm] = useState(false)
  const [showPatientData, setShowPatientData] = useState(false)
  const [newPatient, setNewPatient] = useState(false)
  const [uploadHistory, setUploadHistory] = useState(false)
  const [uploading, setUploading] = useState(false)
  const [selectedFiles, setSelectedFiles] = useState<File[]>([])
  const [options, setOptions] = useState<PhysicianOption[]>([])
  const [assignedPhysician, setAssignedPhysician] = useState("")
  const { sendMessage: sendSocketIOMessage, createGroup, joinGroup } = useSocketIo()

  const uploadFile = async () => {
    console.log(selectedFiles)
    for (let file of selectedFiles) {
      try {
        const response = await addMedicalHistory(id, assignedPhysician, file)
        console.log(response)
      } catch {
        console.error(`Error uploading file ${file}`)
      }
    }
  }

  const uploadFileNewTestIdan = async () => {
    console.log(selectedFiles)
    const response = await axiosInstance.post(
      "/authenticated/conversation/get_patient_group_names",
      {
        user_id: id,
        lang: currentLangID === 1 ? "en" : "he",
      }
    )
    const group_names = []
    for (let group of response.data.final_all_groups) {
      group_names.push(group.group.group_name)
    }
    for (let group_name of group_names) {
      connect(group_name);
      for (let file of selectedFiles) {
        try {
          const userName =
            user.type === USER_TYPE.physician
              ? "physician"
              : user.type === USER_TYPE.moderator
              ? "moderator"
              : user.type === USER_TYPE.admin
              ? "admin"
              : ""
          const response = await addMedicalHistoryNewTestIdan(
            file,
            file.size,
            user.id,
            userName,
            group_name,
            currentLangID
          )
          console.log(response)
        } catch {
          console.error(`Error uploading file ${file}`)
        }
      }
    }
  }

  const addMedicalHistoryNewTestIdan = async (
    file: any,
    file_size: number,
    userID: string,
    userName: string,
    group_name: string,
    currentLangID: number
  ) => {
    try {
      const indexOfLastDotInFile = file.name.lastIndexOf(".")
      const file_ending = file.name.substring(indexOfLastDotInFile, file.name.length)
      const message_type_id =
        file_ending === ".pdf"
          ? ConversationMessageTypes.pdf
          : file_ending === ".doc"
          ? ConversationMessageTypes.doc
          : file_ending === ".docx"
          ? ConversationMessageTypes.docx
          : "invalid"
      if (file_ending === "invalid") {
        throw new Error("Invalid file type")
      }
      const formData = new FormData()
      formData.append("file", file)
      formData.append("user_id", userID)
      formData.append("group_name", group_name)
      formData.append("OCR", "True")
      formData.append("lang", currentLangID === 1 ? "en" : "he")
      const headers = {
        "Content-Type": "multipart/form-data",
      }
      const data = await axiosInstance.post("authenticated/conversation/upload", formData, {
        headers: headers,
      })
      // in the future don't send the file itself
      // and instead of send ocr send medical history only without file but with the ocr of the file
      const socketIoMessage = {
        date_time: new Date().toUTCString(),
        user_name: userName,
        user_id: userID,
        group_name: group_name,
        message_type_id: message_type_id,
        message: data.data.file_name,
        file_name: data.data.file_name,
        lang: currentLangID === 1 ? "en" : "he",
        size: file_size,
        conversation_handler: ConversationHandle.SKIP_AI,
      }
      sendSocketIOMessage(socketIoMessage)
      console.log("data sendFileToServer", data)
      if (data.data.ocr_response) {
        const ocrMessage = {
          date_time: new Date().toUTCString(),
          user_name: userName,
          user_id: userID,
          group_name: group_name,
          message_type_id: ConversationMessageTypes.sendFileOcr,
          message: data.data.ocr_response,
          file_name: data.data.file_name,
          lang: currentLangID === 1 ? "en" : "he",
          size: file_size,
          conversation_handler: ConversationHandle.SKIP_UI,
        }
        sendSocketIOMessage(ocrMessage)
      }
    } catch (error) {
      console.error("Error:", error)
      throw error
    }
  }

  async function connect(group_name: string) {
    try {
      createGroup(group_name)
      joinGroup(group_name)
      if (user.type === USER_TYPE.patient) {
        createGroup(removePaFromGroupName(group_name))
        joinGroup(removePaFromGroupName(group_name))
      }
    } catch (err) {
      console.error(err)
    }
  }

  const getPhysiciansFromPatients = async (patientId: string) => {
    let tempPhysicians = []
    try {
      if (patientId) {
        const physician_ids = await fetchPhysiciansFromPatient(patientId)
        if (physician_ids) {
          for (const physician_id of physician_ids) {
            const physician_name = await fetchUser(physician_id)
            if (physician_name && physician_name["first_name"] && physician_name["last_name"]) {
              tempPhysicians.push({
                name: `${physician_name["first_name"]} ${physician_name["last_name"]}`,
                id: physician_id,
                // clinicName: physician_name["clinic_name"]
              })
            }
          }
        }
      }
    } catch (error) {
      console.error("Error fetching physicians from patient", error)
    }
    console.log("LIST OF PHYSICIANS: ", tempPhysicians)
    setOptions(tempPhysicians)
  }

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFileUploaded(true)
    if (e.target.files) {
      const newFiles = Array.from(e.target.files)
      setSelectedFiles((prevFiles) => [...prevFiles, ...newFiles])
    } else {
      console.error("No files selected")
    }
  }

  const handleDeleteFile = (index: any) => {
    const updatedFiles = [...selectedFiles]
    updatedFiles.splice(index, 1)
    setSelectedFiles(updatedFiles)
  }

  const selectFiles = () => {
    const input = document.createElement("input")
    input.type = "file"
    input.multiple = true
    input.accept = ".pdf, .docx, .doc"

    input.onchange = (e: Event) => {
      const target = e.target as HTMLInputElement
      if (target.files) {
        const fileArray = Array.from(target.files) as File[]
        console.log("newFiles", fileArray)
        setSelectedFiles((prevFiles) => [...prevFiles, ...fileArray])
      } else {
        console.log("No files selected")
      }
    }

    input.click()
  }

  // const fetchData = () => {
  //   if ((currentUserType === (USER_TYPE.admin) || currentUserType === (USER_TYPE.moderator))&&(checkbox)) {
  //     const physiciansData = checkbox.filter(user => user.user_type_id === USER_TYPE.physician);
  //     const formattedPhysicians = physiciansData.map(physician => ({
  //       id: physician.id.toString(),
  //       name: `${physician.first_name} ${physician.last_name}`
  //     }));
  //     setOptions(formattedPhysicians);
  //   }
  // };

  useEffect(() => {
    if (currentUserType !== USER_TYPE.physician) {
      // fetchData();
      getPhysiciansFromPatients(id)
    } else {
      setAssignedPhysician(currentUserId)
    }
  }, [])

  return (
    <div className={`code ${isOpen ? "visible" : "hidden"}`}>
      <div className="modal">
        <div className="sectionStyles">
          <span className="close pt-6 pr-6" onClick={onRequestClose}>
            <StaticImage width={24} height={24} src={Icons.closecross} alt="close" />
          </span>
          {!showForm && !uploadHistory && !newPatient && (
            <>
              <img src={Icons.upload} width="48px" className="m-auto"></img>
              {currentUserType === USER_TYPE.physician && (
                <h2 className="header mb-4">{currentLang["want_to_upload_medical_history?"]}</h2>
              )}
              {currentUserType !== USER_TYPE.physician && (
                <>
                  <h2 className="header mb-4">
                    What physician do you want to have an access to the medical history files?
                  </h2>
                  <div className="item">
                    <InputLabel>{currentLang["assigned_physicians"]}</InputLabel>
                    <FormControl fullWidth>
                      <Select
                        value={assignedPhysician}
                        onChange={(e) => setAssignedPhysician(e.target.value)}
                        displayEmpty
                        required
                        sx={{
                          width: "40vw",
                          borderRadius: "40px",
                          color: "black",
                          backgroundColor: "#F2F4F7",
                          height: "40px",
                          borderColor: "#F2F4F7",
                        }}
                        renderValue={(selected) =>
                          options.find((option) => option.id === selected)?.name || ""
                        }
                      >
                        {options.map((option) => (
                          <MenuItem key={option.id} value={option.id}>
                            <ListItemText primary={option.name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </>
              )}
              <div className="flex justify-between gap-5">
                <Button role="normal" type="submit" onClick={onRequestClose} fullWidth>
                  {currentLang["close"]}
                </Button>
                <Button
                  role="submit"
                  type="submit"
                  onClick={() => setUploadHistory(true)}
                  fullWidth
                >
                  {currentLang["continue"]}
                </Button>
              </div>
            </>
          )}
          {!showForm && uploadHistory && !showPatientData && (
            <div className="flex flex-col">
              <img src={Icons.upload} width="48px" className="m-auto"></img>
              <h2>{currentLang["upload_medical_history"]}</h2>
              <p className="text-center leading-5">
                {currentLang["tap_to_choose_and_upload_files_extracted_from_the_medical_systems"]}
              </p>
              <p className="text-center pb-10 leading-5">
                {currentLang["you_can_upload_multiple_files_here"]}
              </p>
              <input type="file" accept=".pdf, .docx, .doc" onChange={handleFileChange} multiple />
              {selectedFiles.length > 0 && (
                <div>
                  <p className="pb-2" style={{ direction: currentLangID === 2 ? "rtl" : "ltr" }}>
                    <b>{currentLang["selected_files:"]}</b>
                  </p>
                  <ul className="pb-10">
                    {selectedFiles.map((file, index) => (
                      <li
                        key={index}
                        className="pb-2"
                        style={{ direction: currentLangID === 2 ? "rtl" : "ltr" }}
                      >
                        <button onClick={() => handleDeleteFile(index)}>
                          <StaticImage width={15} height={15} src={Icons.deleteBin} alt="delete" />
                        </button>
                        {"   "}
                        {file.name}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              <Button
                role="submit"
                type="submit"
                onClick={() => {
                  setShowPatientData(true)
                  uploadFileNewTestIdan()
                  setUploading(true)
                }}
                disabled={selectedFiles.length === 0}
              >
                {currentLang["upload"]}
              </Button>
            </div>
          )}
          {showPatientData && !showForm && uploading && (
            <div className="flex flex-col">
              <img src={Icons.upload} width="48px" className="m-auto"></img>
              <h2>Uploading</h2>
              <LinearDeterminate setUploading={setUploading} />
            </div>
          )}
          {showPatientData && !showForm && !uploading && (
            <div className="flex flex-col">
              <img src={Icons.upload} width="48px" className="m-auto"></img>
              <h2>{currentLang["patient_medical_history_file_was_successfully_uploaded"]}</h2>
              {selectedFiles.length > 0 && (
                <div className="pb-6 pt-6">
                  <p className="pb-4 text-center">
                    <b>{currentLang["sucessfully_uploaded_files:"]}</b>
                  </p>
                  <ul>
                    {selectedFiles.map((file, index) => (
                      <li key={index} className="pb-1 text-center">
                        {file.name}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              <Button
                role="submit"
                type="submit"
                sx={{ paddingTop: "10px" }}
                onClick={() => {
                  setShowForm(true)
                  onRequestClose()
                }}
                fullWidth
              >
                {currentLang["understand"]}
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default UploadMedicalHistory

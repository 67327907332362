import React, { useState } from "react"
import * as Validator from "src/modules/validation"
import * as Yup from "yup"
import { useRecoilState } from "recoil"
import { Box, Button, SvgIcon } from "src/UILibrary"
import { Controller, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { TextFieldWithLabel } from "src/components/textfieldWithLabel"
import { signinEmailState } from "src/states/signin"
import { MAX_INPUT_LENGTH } from "src/constants/common"
import { useRecoilValue } from "recoil"
import { currentLangState } from "src/states/signup"
import { sendVerificationCodeToEmail } from "src/api/forgotPasswordService"
import Icons from "src/assets/icons"

interface IForgotEmailProps {
  onSubmit: () => void;
}

const validationSchema = Yup.object().shape({
  email: Validator.emailSchema(),
})

type TForgotForm = {
  email: string;
}

export const ForgotEmail: React.FC<IForgotEmailProps> = ({
  onSubmit
}) => {
  const currentLang = useRecoilValue(currentLangState)
  const [email, setEmail] = useRecoilState(signinEmailState)
  const [isSending, setIsSending] = useState(false)

  const {
    handleSubmit,
    control,
    setError,
    watch,
    getValues,
    formState: { errors },
  } = useForm<TForgotForm>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      email: email || "",
    },
  })

  const watchEmail = watch("email", "")

  const onSubmitCode = (
    data: TForgotForm
  ) => {
    setIsSending(true)
    sendVerificationCodeToEmail(data.email)
      .then(() => {
        onSubmit()
      })
      .catch(() => {
        setError("email", {
          type: "email",
          message: "no_user_found",
        })
      })
      .finally(() => setIsSending(false))
  }


  const handleError = (a: any) => {
    console.error(a);
  }

  const isDisabled = Object.values(getValues()).some((value) => !value) || isSending;

  return (
    <form
      onSubmit={handleSubmit(onSubmitCode, handleError)}
    >
      <Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Controller
            name="email"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextFieldWithLabel
                type="email"
                label={currentLang["email"]}
                placeholder={currentLang["email_placeholder"]}
                value={watchEmail || ""}
                onChange={(e) => {
                  if (e.target.value.length > MAX_INPUT_LENGTH) return
                  onChange(e.target.value)
                  setEmail(e.target.value)
                }}
                error={!!errors.email}
                startIcon={<SvgIcon src={Icons.email} alt="email" sx={{ width: "16px" }} />}
                helperText={errors.email && currentLang[`${errors.email.message}`]}
                sx={{ width: "320px" }}
              />
            )} />

          <Button
            role="submit"
            fullWidth
            type="submit"
            sx={{ mt: "3.375rem", mb: "1.375rem" }}
            disabled={isDisabled}
            isLoading={isSending}
          >
            {currentLang["send"]}
          </Button>

         
        </Box>
      </Box>
    </form>
  )
}

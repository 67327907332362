import React, { useEffect, useState } from "react"
import { Route, Routes, useLocation, useNavigate } from "react-router-dom"
import SignUp from "src/pages/signup"
import SignIn from "src/pages/signin"
import ForgotPassword from "src/pages/forgotPassword"
import { SocialAdditinoalInfo } from "src/pages/socialAdditionalInfo"
import BackOfficeRoute from "./BackOfficeRoute"
import PrivacyPolicy from "src/pages/PrivacyPolicy"
import HomeIndex from "src/pages/home"
import { SelectUserType } from "src/pages/selectUserType/selectUserType"
import { ErrorPage } from "src/pages/error/ErrorPage"
import { SiteRoute } from "src/pages/context/userAuth/userAuthContext"
import { SocialLogin } from "src/pages/socialLogin/SocialLogin"
import { useRecoilState } from "recoil"
import {
  currentIsRtlState,
  currentLangIDtate,
  currentLangSelectedState,
  currentLangState,
  langsArrayState,
} from "src/states/signup"
import { axiosInstance } from "src/modules/axios"
import { Idle } from "src/components/idle/Idle"
import { DownloadAppPage } from "src/pages/downloadApp/downloadAppPage"

const RootRoute = () => {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const [, setLang] = useRecoilState(currentLangSelectedState)
  const [, setLangID] = useRecoilState(currentLangIDtate)
  const [, setIsRtl] = useRecoilState(currentIsRtlState)
  const [langsArray, setLangsArray] = useRecoilState(langsArrayState)
  const [, setCurrentLang] = useRecoilState(currentLangState)
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  useEffect(() => {
    fetchLangsIds()
    window.addEventListener("resize", () => {
      setWindowWidth(window.innerWidth)
    })
    return () => {
      window.removeEventListener("resize", () => {
        setWindowWidth(window.innerWidth)
      })
    }
  }, [])

  useEffect(() => {
    if (windowWidth < 600) {
      navigate('/download-app')
    }
  }, [windowWidth]);

  const getSelectedLang = (value: string) => {
    return langsArray.find((lang) => lang.display_name === value)
  }

  const fetchLangsIds = async () => {
    const res = await axiosInstance.post("/languages")
    setLangsArray(res.data)
  }

  useEffect(() => {
    if (langsArray.length) {
      const localStorageLang = localStorage.getItem("lang") || "English"
      const selectedLang = getSelectedLang(localStorageLang)
      if (!selectedLang) return
      // alert(
      //   "localStorageLang: " +
      //     localStorageLang +
      //     " selectedLang.language_id: " +
      //     selectedLang.language_id +
      //     " selectedLang.isrtl: " +
      //     selectedLang.isrtl
      // )
      getTranslation(selectedLang.language_id)
      setLang(localStorageLang)
      setIsRtl(selectedLang.isrtl)
      setLangID(selectedLang.language_id)
    }
  }, [langsArray])

  const getTranslation = async (index: number) => {
    const res = await axiosInstance.post("/languages/translations", {
      language_id: index,
    })
    setCurrentLang(res.data)
  }

  useEffect(() => {
    const rootEl = document.getElementById("root")
    const appEl = document.getElementById("app")
    if (rootEl && appEl) {
      if (pathname.toLowerCase().includes("malkishua")) {
        rootEl.className = "backOfficeRoot"
        appEl.className = "backOfficeApp"
      } else if (pathname.toLowerCase().includes("termsofuse")) {
        rootEl.className = "root"
        appEl.className = "termsOfUseApp"
      } else {
        rootEl.className = "root"
        appEl.className = "App"
      }
    }
  }, [pathname])

  const route = (elem: JSX.Element, auth?: boolean) => {
    return <SiteRoute auth={auth}>{elem}</SiteRoute>
  }

  return (
    <>
      <Idle />
      <Routes>
        <Route path="/" element={route(<SelectUserType />)} />
        <Route path="/signin-physician" element={route(<SignIn />)} />
        <Route path="/signin-patient" element={route(<SignIn />)} />
        <Route path="/forgot-password" element={route(<ForgotPassword />)} />
        <Route path="/signup" element={route(<SignUp />)} />
        <Route path="/home" element={route(<HomeIndex />, true)} />
        <Route path="/additional-social-information" element={route(<SocialAdditinoalInfo />)} />
        <Route path="/social-login" element={route(<SocialLogin />)} />
        {/* <Route path="/TermsOfUse" element={route(<TermsOfUse />, true)} /> */}
        <Route path="/PrivacyPolicy" element={route(<PrivacyPolicy />)} />
        <Route path="/malkishua/*" element={route(<BackOfficeRoute />, true)} />
        <Route path="/error/:type" element={<ErrorPage />} />
        <Route path="download-app" element={<DownloadAppPage />} />
      </Routes>
    </>
  )
}

export default RootRoute

import "./instructionsDemo.scss"
import React, { Ref } from "react"
import { useState, useEffect } from "react"
import { fetchInstructionsAlpha, fetchUser, UpdateInstructions, UpdateInstructionsAlpha } from "src/api/backoffice"
import { Button } from "src/UILibrary"
import { currentLangIDtate, currentLangState } from "src/states/signup"
import { useRecoilValue } from "recoil"

const PhysicianInstructions = ({ id, setSelectedUserId }: any) => {
  const currentLang = useRecoilValue(currentLangState)
  const currentLangID = useRecoilValue(currentLangIDtate)
  const [open, setOpen] = useState(false)
  const [physicianName, setPhysicianName] = useState("")
  const [instructions, setInstructions] = useState('');
  const [doctorInitialInstructions, setDoctorInitialInstructions] = useState('');
  const [values, setValues] = useState(instructions);
  const [DIIvalues, setDIIValues] = useState(doctorInitialInstructions);
  const [editingInstructions, setEditingInstructions] = useState(false);
  const [editingDoctorInitialInstructions, setEditingDoctorInitialInstructions] = useState(false);
 
  const getPhysicianName = async() => {
    const physician = await fetchUser(id);
    setPhysicianName(`${physician["first_name"]} ${physician["last_name"]}`);
  }

  const handleSaveClick = () => {
    const updatedText = values;
    updateInstructions(updatedText);
    setEditingInstructions(!editingInstructions)
  };
  const handleSaveDIIClick = () => {
    const updatedText = DIIvalues;
    updateDIInstructions(updatedText);
    setEditingDoctorInitialInstructions(!editingDoctorInitialInstructions)
  };
  

  const getInstructions = async () => {
    try {
      const data = await fetchInstructionsAlpha(id)
      console.log(data)
      setInstructions(data.instructions)
      setDoctorInitialInstructions(data.doctor_initial_instruction)
    } catch (error) {
      console.error("Error fetching instructions")
    }
  }

  const updateInstructions = async (text: string) => {
    try {
      const data = await UpdateInstructionsAlpha("instructions" ,text, id)
    } catch (error) {
      console.error("Error updating instructions")
    }
  }

  const updateDIInstructions = async (text: string) => {
    try {
      const data = await UpdateInstructionsAlpha("doctor_initial_instruction" ,text, id)
    } catch (error) {
      console.error("Error updating doctor initial instruction")
    }
  }

  const handleChange = (value: string) => {
    setValues(value);
    setInstructions((value));
  };

  const handleDoctorInitialInstructionsChange = (value: string) => {
    setDIIValues(value);
    setDoctorInitialInstructions((value));
  };

  useEffect(() => {
    getPhysicianName();
    getInstructions();
  }, [])

  return (
    <>
        <div className="main w-5/6">
            <div className="user">
                <div className="sectionStyles">
                    <div className="divStyles">
                         <div className={`info flex flex-col ${currentLangID === 2 ? 'rtl' : ''}`}>
                         <div className='flex flex-row justify-between gap-10'>
                            <h1 className="text-5xl font-bold pb-4">{physicianName}</h1>
                            <Button role="normal" type="submit" onClick={() => setSelectedUserId(null)}>
                              {currentLang["cancel"]}
                            </Button>
                          </div>
                                <div className='flex flex-col pt-4'>
                                  <p className="pb-2 pl-2" style={{color:"#344054"}}>{currentLang["instructions"]}</p>
                                    <textarea className="rounded-lg"
                                        cols={30}
                                        rows={15}
                                        placeholder={currentLang["enter_instructions"]}
                                        value={instructions}
                                        onChange={(e) => {handleChange(e.target.value);setEditingInstructions(true)}}
                                        // readOnly={!editingInstructions}
                                    />
                                </div>
                                <div className='flex flex-row justify-end gap-10'>
                                    <Button 
                                      role="submit"
                                      type="submit" 
                                      onClick={handleSaveClick}
                                      disabled={!editingInstructions}
                                    >
                                      {currentLang["save_instruction"]}
                                    </Button>
                                </div>
                        
                        <div className='flex flex-col pt-4'>
                                  <p className="pb-2 pl-2" style={{color:"#344054"}}>{currentLang["doctor_initial_instructions"]}</p>
                                    <textarea className="rounded-lg"
                                        cols={30}
                                        rows={15}
                                        placeholder={currentLang["enter_doctor_initial_instructions"]}
                                        value={doctorInitialInstructions}
                                        onChange={(e) => {handleDoctorInitialInstructionsChange(e.target.value);setEditingDoctorInitialInstructions(true)}}
                                        // readOnly={!editingInstructions}
                                    />
                                </div>
                                <div className='flex flex-row justify-end gap-10'>
                                    <Button 
                                      role="submit"
                                      type="submit" 
                                      onClick={handleSaveDIIClick}
                                      disabled={!editingDoctorInitialInstructions}
                                    >
                                      {currentLang["save_doctor_initial_instruction"]}
                                    </Button>
                                </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}
export default PhysicianInstructions

import React from "react"
import { useRecoilValue } from "recoil"
import { Button } from "src/UILibrary"
import { currentLangState } from "src/states/signup"

interface IReferralAction {
    setShowPrescriptionDialog: (show: boolean) => void
}

export const PrescriptionAction = ({ setShowPrescriptionDialog }: IReferralAction) => {
  const currentLang = useRecoilValue(currentLangState)

  return <Button className="dynamicActionButton Body3Medium" onClick={() => setShowPrescriptionDialog(true)}>{currentLang["prescription"]}</Button>
}

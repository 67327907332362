import React from "react"
import { Button, StaticImage, TextField } from "src/UILibrary"
import { Dialog } from "src/UILibrary/Dialog/Dialog";
import Icons from "src/assets/icons"
import Container from "src/components/container"

interface IShowDialogProps {
    title: string;
    msg: string;
    onOk: () => void;
    okText?: string;
    onCancel?: () => void;
    cancelText?: string;
}
export function ShowDialog({ 
    title,
    msg,
    onCancel, 
    onOk,
    okText = "OK",
    cancelText = "Cancel"
}: IShowDialogProps) {
    return (
        <Dialog>
            <div className="physcianCodeInput">
                <Container.Column sx={{ gap: "16px" }}>
                    <div className="userPlusBlueWrapper">
                        <div className="userPlusBlueContainer">
                            <StaticImage src={Icons.x} alt="logo" width={24} height={24} />
                        </div>
                    </div>
                    <div>
                        <h4><b>{title}</b></h4>
                        <p>{msg}</p>
                    </div>
                </Container.Column>
                <Container.Row justifyContent="center" sx={{ gap: "14px" }}>
                    {onCancel && (
                        <Button fullWidth role="normal" onClick={onCancel}>
                            {cancelText}
                        </Button>
                    )}
                    {onOk && (
                        <Button fullWidth role="submit" onClick={onOk}>
                            {okText}
                        </Button>
                    )}
                </Container.Row>
            </div>
        </Dialog>
    )
}

import React, { useState } from "react"
import { Box, Button, Divider, Link, SvgIcon, Typography } from "src/UILibrary"
import { Controller, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { IUserSignInFormInputs } from "src/types/signin"
import * as Validator from "src/modules/validation"
import * as Yup from "yup"
import { TextFieldWithLabel } from "src/components/textfieldWithLabel"
import { useRecoilState } from "recoil"
import { signinEmailState, signinPasswordState } from "src/states/signin"
import { loginByPatient } from "src/api/signinService"
import { SocialLoginForm } from "src/pages/signup/components/socialSignupForm"
import { MAX_INPUT_LENGTH, USER_TYPE } from "src/constants/common"
import { useRecoilValue } from "recoil"
import { currentLangState } from "src/states/signup"
import useIsRtl from "src/hooks/useIsRtl"
import Icons from "src/assets/icons"
import { useUserAuth } from "src/pages/context/userAuth/userAuthContext"

const validationSchema = Yup.object().shape({
  email: Validator.emailSchema(),
  password: Validator.passwordSchema(),
})

export const LoginPatientForm: React.FC = () => {
  const isRtl = useIsRtl()
  const currentLang = useRecoilValue(currentLangState)
  const [email, setEmail] = useRecoilState(signinEmailState)
  const [password, setPassword] = useRecoilState(signinPasswordState)
  const [errorMessage, setErrorMessage] = useState<string>("")
  const [isLogginIn, setIsLogginIn] = useState<boolean>(false)
  const { login } = useUserAuth()

  const {
    handleSubmit,
    control,
    getValues,
    watch,
    formState: { errors },
  } = useForm<IUserSignInFormInputs>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      email: "",
      password: "",
    },
  })
  const watchAllFields = watch()

  const onSubmit = () => {
    setIsLogginIn(true)
    loginByPatient({ email, password })
      .then((res: any) => {
        login(
          {
            token: res.data.jwt_token,
            csrfToken: res.data.csrf_token,
            csrfRefreshToken: res.data.refresh_csrf_token,
            refreshToken: res.data.refresh_token,
          },
          {
            id: res.data.user_id,
            email: email,
            type: USER_TYPE.patient,
          }
        )
      })
      .catch((err: any) => {
        console.log(err)
        setErrorMessage(err)
        setIsLogginIn(false)
      })
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLFormElement>) => {
    if (event.key === "Enter") {
      event.preventDefault()
      handleSubmit(onSubmit)()
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} onKeyDown={handleKeyDown}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "320px",
          gap: "1.25rem",
        }}
      >
        <Controller
          name="email"
          control={control}
          render={({ field: { onChange, value } }) => (
            <TextFieldWithLabel
              type="email"
              label={currentLang["email"]}
              placeholder={currentLang["email_placeholder"]}
              value={value}
              onChange={(e) => {
                if (e.target.value.length > MAX_INPUT_LENGTH) return
                onChange(e.target.value)
                setEmail(e.target.value)
              }}
              startIcon={<SvgIcon src={Icons.email} alt="email" sx={{ width: "16px" }} />}
              error={!!errors.email}
              helperText={errors.email ? currentLang[`${errors.email.message}`] : undefined}
              sx={{ width: "320px" }}
            />
          )}
        />

        <Controller
          name="password"
          control={control}
          render={({ field: { onChange, value } }) => (
            <TextFieldWithLabel
              label={currentLang["password"]}
              placeholder={currentLang["type_password"]}
              type="password"
              value={value}
              onChange={(e) => {
                if (e.target.value.length > MAX_INPUT_LENGTH) return
                onChange(e.target.value)
                setPassword(e.target.value)
              }}
              error={!!errors.password}
              helperText={errors.password ? currentLang[`${errors.password.message}`] : undefined}
            />
          )}
        />
        <Link.FormLink
          sx={{ textAlign: "right", textDecoration: "underline" }}
          href="/forgot-password"
        >
          {currentLang["forgot_password?"]}
        </Link.FormLink>
        {!!errorMessage && (
          <Typography.Detail color="error" align="center">
            {currentLang["invalid_email_or_password"]}
          </Typography.Detail>
        )}
        <Button
          role="submit"
          fullWidth
          type="submit"
          isLoading={isLogginIn}
          disabled={Object.values(getValues()).some((value) => !value) || isLogginIn}
        >
          {currentLang["log_in"]}
        </Button>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Divider
            sx={{
              mt: "2rem",
              mb: "1.75rem",
              minWidth: "113px",
              width: "100%",
            }}
          >
            <Typography.Detail>{currentLang["or_login_with"]}</Typography.Detail>
          </Divider>
          <SocialLoginForm />
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "0.5625rem",
              mt: "2rem",
              flexDirection: isRtl ? "row-reverse" : "row",
            }}
          >
            <Typography.Detail>{currentLang["dont_have_an_account?"]}</Typography.Detail>
            <Link.FormLink href="/signup" sx={{ textDecoration: "underline" }}>
              {currentLang["sign_up"]}
            </Link.FormLink>
          </Box>
        </Box>
      </Box>
    </form>
  )
}

import { useEffect, useRef, useState } from "react"
import {
  IChatGroup,
  IChatGroupsContext,
  IGroupItem,
  TGroupItemUnreadMessages,
  TGroupStatus,
} from "../chatContext.types"
import { ChatPointOfView } from "src/constants/enums"
import { isUserOnline } from "../../utils"

export const useChatGroups = (): IChatGroupsContext => {
  const [chatGroups, setChatGroups] = useState<IChatGroup[]>([])
  const timerRef = useRef<NodeJS.Timeout>()

  useEffect(() => {
    setLastOnline()
  }, [chatGroups])

  const setLastOnline = () => {
    clearTimeout(timerRef.current)
    timerRef.current = setTimeout(() => {
      chatGroups.forEach((chatGroup) => {
        chatGroup.isConnected = isUserOnline(chatGroup.lastOnline)
      })
      setChatGroups([...chatGroups])
      setLastOnline()
    }, 1000 * 60)
  }

  const setPaused = (group_name: string, paused: boolean) => {
    setChatGroups((state) => {
      const chatGroup = state.find((w) => w.group_name === group_name)
      if (chatGroup) {
        chatGroup.isAIPaused = paused
      }
      return [...state]
    })
  }

  const setGroupStatus = (group_name: string, status: TGroupStatus) => {
    setChatGroups((state) => {
      const chatGroup = state.find((w) => w.group_name === group_name)
      if (chatGroup) {
        chatGroup.status = status
      }
      return [...state]
    })
  }

  const getChatGroupStatus = (group_name: string) => {
    const chatGroup = chatGroups.find((c) => c.group_name === group_name) || {
      isAIPaused: false,
      isConnected: false,
      isPinnedPhysician: false,
      isPinnedPatient: false,
      unreadMessages: 0,
      chatGroupSelection: ChatPointOfView.MY_AI,
      lastOnline: new Date(),
    }
    chatGroup.isConnected = isUserOnline(chatGroup.lastOnline)
    console.log("status updated", chatGroup)
    return chatGroup
  }

  const getGroup = (group_name: string) => {
    if (!group_name) return;
    return chatGroups.find((c) => c.group_name === group_name)
  }

  const getGroupByView = (group: IGroupItem, pointOfView: ChatPointOfView) => {
    const view = group?.pointOfViews.find((v) => v.view === pointOfView)
    if (view) {
      return getGroup(view.groupId)
    }
  }

  const setGroup = (group: IGroupItem, onSet: (group: IChatGroup) => void) => {
    setChatGroups((prev) => {
      const chatGroup = prev.find((g) => g.group_name === group.group_name)
      if (chatGroup) {
        onSet(chatGroup)
      }
      return [...prev]
    })
  }

  const getUnreadMessages = (group?: IGroupItem): TGroupItemUnreadMessages => {
    const resp: TGroupItemUnreadMessages = {
      patient: 0,
      ai: 0,
      total: 0,
    }

    if (group) {
      resp.patient = getGroupByView(group, ChatPointOfView.AI_AND_PATIENT)?.unreadMessages || 0
      resp.ai = getGroupByView(group, ChatPointOfView.MY_AI)?.unreadMessages || 0
    }

    resp.total = resp.patient + resp.ai

    console.log("group", group, "resp", resp)

    return resp
  }

  return {
    chatGroups,
    setChatGroups,
    getChatGroupStatus,
    setPaused,
    getGroup,
    setGroup,
    getUnreadMessages,
    setGroupStatus
  }
}

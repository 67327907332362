export const USER_TYPE = {
  guest: 0,
  patient: 1,
  physician: 2,
  admin: 3,
  ai: 4,
  moderator: 5,
}
export const MAX_INPUT_LENGTH = 64;
export const MAX_INPUT_PHONE_LENGTH = 14;
export const MAX_INPUT_ID_SSN_LENGTH = 9;
export const MAX_MESSAGE_GROUP_LENGTH_BEFORE_HIDING = 30;
export const MAX_MESSAGE_LENGTH_BEFORE_HIDING = 768;
export const MIN_AGE_FOR_REGISTER = 18;
export const MAX_AGE_FOR_REGISTER = 150;

export const SPEECH_WAIT_TIME = 2000;

import React from "react"
import { StaticImage } from "src/UILibrary"
import Icons from "src/assets/icons"
import Container from "src/components/container"
import { CHAT_SIDE_MENU_OPTIONS, USER_TYPE } from "src/constants/enums"
import LogoWhite from "src/assets/icons/logo-white.svg"
import { useChat } from "../context/chatContext"

interface ISideMenu {
  userType: USER_TYPE
  userIDLocation: string
  setShowSaveDialog: React.Dispatch<React.SetStateAction<boolean>>
  settingsChanged: boolean
  refreshChatGroups: () => void
}

export function SideMenu({
  userIDLocation,
  userType,
  settingsChanged,
  setShowSaveDialog,
  refreshChatGroups,
}: ISideMenu) {
  const {
    setSelectedSideMenu,
    selectedSideMenu,
    setChatWindows,
    setSelectedChatWindow,
    setSelectedChatGroupName,
    audioMode,
    isRecordingAudioMode
  } = useChat()

  const handleSelection = (selection: CHAT_SIDE_MENU_OPTIONS) => {
    console.log("settingsChanged", settingsChanged)
    if (settingsChanged) {
      setShowSaveDialog(true)
    } else {
      setSelectedSideMenu(selection)
    }
  }

  return (
    <Container.Column
      justifyContent="space-between"
      alignItems="center"
      className={
        selectedSideMenu === CHAT_SIDE_MENU_OPTIONS.users ||
        selectedSideMenu === CHAT_SIDE_MENU_OPTIONS.analytics ||
        selectedSideMenu === CHAT_SIDE_MENU_OPTIONS.instructions ||
        selectedSideMenu === CHAT_SIDE_MENU_OPTIONS.logout
          ? "sideMenu sideMenuBO"
          : "sideMenu"
      }
    >
      <Container.Column sx={{ gap: ".5rem", alignItems: "center" }}>
        <StaticImage src={LogoWhite} alt="logo" width={40} height={40} />
        {(userType === USER_TYPE.patient || userType === USER_TYPE.physician) && (
          <>
            <div
              className={selectedSideMenu === "chat" ? "sideMenuIcon selected" : "sideMenuIcon"}
              style={{ marginTop: "1rem", opacity: audioMode || isRecordingAudioMode ? 0.5 : 1 }}
              onClick={() => {
                if (audioMode || isRecordingAudioMode) return
                handleSelection(CHAT_SIDE_MENU_OPTIONS.chat)
                setChatWindows([])
                setSelectedChatWindow(null)
                setSelectedChatGroupName("")
              }}
            >
              <StaticImage
                src={Icons.chat}
                alt="logo"
                width={24}
                height={24}
                // sx={{ marginTop: "1rem" }}
              />
            </div>
            {userType === USER_TYPE.physician && (
              <div
                className={
                  selectedSideMenu === CHAT_SIDE_MENU_OPTIONS.multiChat
                    ? "sideMenuIcon selected"
                    : "sideMenuIcon"
                }
                onClick={() => {
                  if (audioMode || isRecordingAudioMode) return
                  handleSelection(CHAT_SIDE_MENU_OPTIONS.multiChat)
                  setSelectedChatWindow(null)
                  setSelectedChatGroupName("")
                  if (refreshChatGroups) {
                    refreshChatGroups()
                  }
                }}
                style={{ opacity: audioMode || isRecordingAudioMode ? 0.5 : 1 }}
              >
                <StaticImage src={Icons.grid} alt="logo" width={24} height={24} />
              </div>
            )}
            <div
              className={selectedSideMenu === "settings" ? "sideMenuIcon selected" : "sideMenuIcon"}
              style={{ opacity: audioMode || isRecordingAudioMode ? 0.5 : 1 }}
              onClick={() => {
                if (audioMode || isRecordingAudioMode) return
                handleSelection(CHAT_SIDE_MENU_OPTIONS.settings)
              }}
            >
              <StaticImage src={Icons.settings} alt="logo" width={24} height={24} />
            </div>
          </>
        )}
      </Container.Column>
      {userType !== USER_TYPE.patient && (
        <Container.Column sx={{ gap: ".5rem", alignItems: "center", paddingBottom: ".5rem" }}>
          <div
            className={selectedSideMenu === "users" ? "sideMenuIcon selected" : "sideMenuIcon"}
            style={{ marginTop: "1rem", opacity: audioMode || isRecordingAudioMode ? 0.5 : 1 }}
            onClick={() => {
              if (audioMode || isRecordingAudioMode) return
              handleSelection(CHAT_SIDE_MENU_OPTIONS.users)
            }}
          >
            <StaticImage
              src={Icons.users}
              alt="logo"
              width={24}
              height={24}
              // sx={{ marginTop: "1rem" }}
            />
          </div>
          {userType === USER_TYPE.admin && (
            <>
              <div
                className={
                  selectedSideMenu === "analytics" ? "sideMenuIcon selected" : "sideMenuIcon"
                }
                style={{ marginTop: "1rem", opacity: audioMode || isRecordingAudioMode ? 0.5 : 1 }}
                onClick={() => {
                  if (audioMode || isRecordingAudioMode) return
                  handleSelection(CHAT_SIDE_MENU_OPTIONS.analytics)
                }}
              >
                <StaticImage
                  src={Icons.analytics}
                  alt="logo"
                  width={24}
                  height={24}
                  // sx={{ marginTop: "1rem" }}
                />
              </div>
              <div
                className={
                  selectedSideMenu === "instructions" ? "sideMenuIcon selected" : "sideMenuIcon"
                }
                style={{ marginTop: "1rem", opacity: audioMode || isRecordingAudioMode ? 0.5 : 1 }}
                onClick={() => {
                  if (audioMode || isRecordingAudioMode) return
                  handleSelection(CHAT_SIDE_MENU_OPTIONS.instructions)
                }}
              >
                <StaticImage
                  src={Icons.instructions}
                  alt="logo"
                  width={24}
                  height={24}
                  // sx={{ marginTop: "1rem" }}
                />
              </div>
            </>
          )}
          {(userType === USER_TYPE.admin || userType === USER_TYPE.moderator) && (
            <div
              className={selectedSideMenu === "logout" ? "sideMenuIcon selected" : "sideMenuIcon"}
              style={{ marginTop: "1rem", opacity: audioMode || isRecordingAudioMode ? 0.5 : 1 }}
              onClick={() => {
                if (audioMode || isRecordingAudioMode) return
                handleSelection(CHAT_SIDE_MENU_OPTIONS.logout)
              }}
            >
              <StaticImage
                src={Icons.logout}
                alt="logout"
                width={24}
                height={24}
                // sx={{ marginTop: "1rem" }}
              />
            </div>
          )}
        </Container.Column>
      )}
    </Container.Column>
  )
}

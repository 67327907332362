import React from "react";
import "./singleDialog.scss"

type Props = {
    id: number;
    date: string;
    patientMessages: {"created at": string; sender: string; text: string}[];
    doctorMessages: {"created at": string; sender: string; text: string}[];
    setDialogOpen: React.Dispatch<React.SetStateAction<boolean>> 
};


const SingleDialog = (props: Props) => {
    return (
            <div className='activities'>
                <div className="modal">
                <button className="button-close" onClick={() => props.setDialogOpen(false)}>[X]</button>
                <h2>Conversation on {props.date}</h2>
                {props.patientMessages && (
                    <ul className="patient">
                        {props.patientMessages.map((message) => (
                            <li key={message.text}>
                                <div>
                                <time>{new Date(message["created at"]).toLocaleTimeString()}</time>
                                    <h4>{message.sender}</h4>
                                    <p>{message.text}</p>

                                </div>
                            </li>
                        ))}
                    </ul>
                )}
                {props.patientMessages && (
                    <ul className="doctor">
                        {props.doctorMessages.map((message) => (
                            <li key={message.text}>
                                <div>
                                    <time>{new Date(message["created at"]).toLocaleTimeString()}</time>
                                    <h4>{message.sender}</h4>
                                    <p>{message.text}</p>
                                    
                                </div>
                            </li>
                        ))}
                    </ul>
                )}
            </div>
            </div>
    )
}

export default SingleDialog
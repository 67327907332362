import React, { useState } from "react"
import { Box, Typography, Divider, Link, Grid } from "src/UILibrary"
import { CreateAccount } from "./components/createAccount"
import { SocialLoginForm } from "./components/socialSignupForm"
import LoginReg from "src/components/cards/LoginReg"
import Container from "src/components/container"
import BackArrow from "./components/BackArrow"
import { useRecoilValue } from "recoil"
import { currentLangState } from "src/states/signup"
import useIsRtl from "src/hooks/useIsRtl"

const SignUp: React.FC = () => {
  const isRtl = useIsRtl()
  const currentLang = useRecoilValue(currentLangState)
  const [step, setStep] = useState(0);
  const _setStep = (addition: number) => () => setStep((val: number) => val + addition)
  const onBackClick = _setStep(-1)
  const onContinue = _setStep(1)


  return (
    <LoginReg showLanguageMenu>
      <Container.Row
        sx={{
          maxWidth: "320px",
          width: "100%",
          height: "50px",
          alignSelf: "center",
          ...(step === 0 && { justifyContent: "flex-end" }),
        }}
      >
        {step > 0 && <BackArrow onClick={onBackClick} />}
      </Container.Row>
      <CreateAccount step={step} setStep={onContinue} />
      <Box sx={{ width: "100%", mt: "25px" }}>
        {step < 2 && (
          <>
            <Grid container justifyContent={"center"}>
              <Divider
                sx={{
                  mb: "25px",
                  width: "260px",
                }}
              >
                <Typography.Detail>{currentLang["or_register_with"]}</Typography.Detail>
              </Divider>
            </Grid>
            <SocialLoginForm />
          </>
        )}
      </Box>
      {step < 2 && (
        <Container.Flex
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "0.5625rem",
            mt: "25px",
            flexDirection: isRtl ? "row-reverse" : "row",
          }}
        >
          <Typography.Detail>{currentLang["already_have_an_account?"]}</Typography.Detail>
          <Link.FormLink href="/signin-patient" sx={{textDecoration: "underline"}}>{currentLang["log_in"]}</Link.FormLink>
        </Container.Flex>
      )}
    </LoginReg>
  )
}

export default SignUp

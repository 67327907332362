import React, { useEffect, useState } from "react"
import Icons from "src/assets/icons"
import Container from "src/components/container"
import * as Yup from "yup"
import * as Validator from "src/modules/validation"
import { Switch } from "@mui/material"
import { Button, StaticImage } from "src/UILibrary"
import { USER_TYPE } from "src/constants/enums"
import { TextFieldWithLabel } from "src/components/textfieldWithLabel"
import { MAX_INPUT_LENGTH } from "src/constants/common"
import { useLogOut } from "src/hooks/useLogOut"
import { axiosInstance } from "src/modules/axios"
import { LogoutDialog } from "../components/LogoutDialog"
import { SaveDialog } from "../components/SaveDialog"
import { useNavigate } from "react-router-dom"
import { DeleteUserView } from "../components/DeleteUserView"
import { DragDropFile } from "../components/DragDropFile"
import { LanguageMenu } from "src/components/selectors/LanguageMenu"
import { useChat } from "../context/chatContext"
import { useRecoilValue } from "recoil"
import { currentLangIDtate, currentLangState } from "src/states/signup"
import packageJson from "../../../../package.json"

const emailValidationSchema = Yup.object().shape({
  email: Validator.emailSchema(),
})

const phoneNumberValidationSchema = Yup.object().shape({
  PhoneNumber: Validator.phoneNumberSchema(),
})

interface ISettings {
  userType: USER_TYPE
  userIDLocation: string
  currentUserNameInitials: string
  setSettingsChanged: React.Dispatch<React.SetStateAction<boolean>>
  showSaveDialog: boolean
  setShowSaveDialog: React.Dispatch<React.SetStateAction<boolean>>
  leaveType: any
  setShowSignatureCanvas: React.Dispatch<React.SetStateAction<boolean>>
}

export function Settings({
  userType,
  userIDLocation,
  currentUserNameInitials,
  setSettingsChanged,
  showSaveDialog,
  setShowSaveDialog,
  leaveType,
  setShowSignatureCanvas,
}: ISettings) {
  const logout = useLogOut()
  const navigate = useNavigate()
  const { setSelectedSideMenu } = useChat()
  const [selectedSettingsTab, setSelectedSettingsTab] = useState("MyDetails")
  const [firstName, setFirstName] = useState("")
  const [firstNameError, setFirstNameError] = useState("")
  const [lastName, setLastName] = useState("")
  const [lastNameError, setLastNameError] = useState("")
  const [email, setEmail] = useState("")
  const [emailError, setEmailError] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")
  const [phoneNumberError, setPhoneNumberError] = useState("")
  const [currentPassword, setCurrentPassword] = useState("")
  const [newPassword, setNewPassword] = useState("")
  const [confirmNewPassword, setConfirmNewPassword] = useState("")
  const [passwordError, setPasswordError] = useState("")
  const [photo, setPhoto] = useState<any>()
  const [allowNotifications, setAllowNotifications] = useState(true)
  const [originalData, setOriginalData] = useState<any>({})
  const [changedMyDetailsPhoto, setChangedMyDetailsPhoto] = useState(false)
  const [changedMyDetailsFirstOrLastName, setChangedMyDetailsFirstOrLastName] = useState(false)
  const [changedAccountEmailOrPhoneNumber, setChangedAccountEmailOrPhoneNumber] = useState(false)
  const [changedAccountPassword, setChangedAccountPassword] = useState(false)
  const [selectedFile, setSelectedFile] = useState<any>(null)
  const [loading, setLoading] = useState(false)
  const [loadingLogout, setLoadingLogout] = useState(false)
  const [showLogoutDialog, setShow] = useState(false)
  const [showTermsOfService, setShowTermsOfService] = useState(false)
  const [showDeleteUser, setShowDeleteUser] = useState(false)
  const currentLang = useRecoilValue(currentLangState)
  const currentLangID = useRecoilValue(currentLangIDtate)

  const isValidEmail = () => {
    try {
      emailValidationSchema.validateSync({
        email: email,
      })
      return true
    } catch (err: any) {
      setEmailError(currentLang["the_format_of_the_email_address_is_incorrect"])
    }
    return false
  }

  const isValidPhoneNumber = () => {
    try {
      phoneNumberValidationSchema.validateSync({
        PhoneNumber: phoneNumber,
      })
      return true
    } catch (err: any) {
      if (err.message === "invalid_phone_number_length") {
        setPhoneNumberError(currentLang["invalid_phone_number_length"])
      }
      if (err.message === "invalid_phone_number_shape") {
        setPhoneNumberError(currentLang["invalid_phone_number_shape"])
      }
    }
    return false
  }

  useEffect(() => {
    fetchUserData()
  }, [])

  const fetchUserData = async () => {
    try {
      const res = await axiosInstance.post("authenticated/settings/get_user_settings_data", {
        user_id: userIDLocation,
        lang: currentLangID === 1 ? "en" : "he",
      })
      setFirstName(res.data.first_name.trim())
      setLastName(res.data.last_name.trim())
      setEmail(res.data.email)
      setPhoneNumber(res.data.phone_number)
      setAllowNotifications(res.data.push_notification_enabled)
      if (res.data.photo) {
        getPhoto()
      }
      setOriginalData(res.data)
    } catch (err) {
      console.error(err)
    }
  }

  const getPhoto = () => {
    // add a skeleton loading here
    axiosInstance
      .post(
        "authenticated/settings/get_profile_photo",
        {
          user_id: userIDLocation,
        },
        {
          responseType: "blob",
        }
      )
      .then((data: any) => {
        const url = URL.createObjectURL(data.data)
        setPhoto(url)
      })
      .catch((err) => console.log(err))
  }

  function requestNotifications() {
    alert(currentLang["please_change_your_browser_notifications_permission"])
  }

  // const handleAllowNotifications = (user_id: string, enabled: boolean) => {
  //   setAllowNotifications(enabled)
  //   setLoading(true)
  //   axiosInstance
  //     .post("authenticated/settings/push_notifications_enabling", {
  //       user_id: user_id,
  //       enabled: enabled,
  //     })
  //     .catch((err) => console.error(err))
  //     .finally(() => setLoading(false))
  // }

  const updateFirstAndLastName = async () => {
    setFirstNameError("")
    setLastNameError("")
    if (!firstName) {
      setFirstNameError(currentLang["type_first_name"])
      return
    }
    if (!lastName) {
      setLastNameError(currentLang["type_last_name"])
      return
    }
    setLoading(true)
    await axiosInstance
      .post("authenticated/settings/change_name", {
        user_id: userIDLocation,
        first_name: firstName,
        last_name: lastName,
      })
      .then(() => {
        setOriginalData({
          ...originalData,
          first_name: firstName,
          last_name: lastName,
        })
      })
      .catch((err) => console.error(err))
      .finally(() => setLoading(false))
  }

  const updateEmailAndPhoneNumber = () => {
    // todo validate email and password
    setEmailError("")
    setPhoneNumberError("")
    if (!email) {
      setEmailError(currentLang["email_is_missing"])
      return
    }
    if (!phoneNumber) {
      setPhoneNumberError(currentLang["please_enter_a_phone_number"])
      return
    }
    if (!isValidEmail()) {
      return
    }
    if (!isValidPhoneNumber()) {
      return
    }
    setLoading(true)
    axiosInstance
      .post("authenticated/settings/change_email_and_phone_number", {
        user_id: userIDLocation,
        email: email,
        phone_number: phoneNumber,
      })
      .then(() => {
        setOriginalData({
          ...originalData,
          email: email,
          phone_number: phoneNumber,
        })
      })
      .catch((err) => {
        if (email !== originalData.email) {
          setEmailError(currentLang["something_went_wrong"])
        }
        if (phoneNumber !== originalData.phone_number) {
          setPhoneNumberError(currentLang["something_went_wrong"])
        }
      })
      .finally(() => setLoading(false))
  }

  const updatePassword = () => {
    setPasswordError("")
    if (newPassword !== confirmNewPassword) {
      setPasswordError(currentLang["invalid_confirm_password"])
      return
    }
    setLoading(true)
    axiosInstance
      .post("authenticated/settings/change_and_validate_password", {
        user_id: userIDLocation,
        old_password: currentPassword,
        new_password: newPassword,
      })
      .then(() => {
        setCurrentPassword("")
        setNewPassword("")
        setConfirmNewPassword("")
      })
      .catch((err) => {
        setPasswordError(currentLang["failed_to_change_password"])
        console.error(err)
      })
      .finally(() => setLoading(false))
  }

  const handleLogout = async () => {
    setLoadingLogout(true)
    const res = await axiosInstance
      .post("authenticated/settings/patient_logout")
      .catch((err) => console.error(err))
      .finally(() => setLoadingLogout(false))
    if (!res) return
    logout(userType, res.data.crsf_token)
  }

  const deletePhoto = async () => {
    try {
      const res = await axiosInstance.post("authenticated/settings/delete_photo", {
        user_id: userIDLocation,
      })
      console.log(res)
    } catch (err) {
      console.error(err)
    }
  }

  const updatePhoto = async () => {
    if (photo) {
      await deletePhoto()
    }
    const formData = new FormData()
    formData.append("file", selectedFile)
    formData.append("user_id", userIDLocation)
    const headers = {
      "Content-Type": "multipart/form-data",
    }
    setLoading(true)
    axiosInstance
      .post("authenticated/settings/change_photo", formData, {
        headers: headers,
      })
      .then((data) => {
        setChangedMyDetailsPhoto(false)
        console.log(data)
      })
      .catch((err) => console.error(err))
      .finally(() => setLoading(false))
  }

  const handleChangedPhoto = () => {
    const input = document.createElement("input")
    // check for max size as well
    input.type = "file"
    input.accept = ".png, .jpeg, .jpg, .gif"
    input.onchange = (e: any) => {
      const fileTemp = new FileReader()
      fileTemp.onload = () => {
        console.log(fileTemp.result)

        setPhoto(fileTemp.result)
        setChangedMyDetailsPhoto(true)
      }
      setSelectedFile(e.target.files[0])
      fileTemp.readAsDataURL(e.target.files[0])
    }
    input.click()
  }

  const handleDroppedPhoto = (file: any) => {
    // check for size and type here
    const fileTemp = new FileReader()
    fileTemp.onload = () => {
      console.log(fileTemp.result)

      setPhoto(fileTemp.result)
      setChangedMyDetailsPhoto(true)
    }
    setSelectedFile(file)
    fileTemp.readAsDataURL(file)
  }

  const resetMyDetailsInputs = () => {
    setChangedMyDetailsPhoto(false)
    setChangedMyDetailsFirstOrLastName(false)
    setPhoto(originalData?.photo)
    setFirstName(originalData?.first_name)
    setLastName(originalData?.last_name)
  }

  const resetAccountInputs = () => {
    setChangedAccountEmailOrPhoneNumber(false)
    setChangedAccountPassword(false)
    setEmail(originalData?.email)
    setPhoneNumber(originalData?.phone_number)
    setCurrentPassword("")
    setNewPassword("")
    setConfirmNewPassword("")
  }

  const updateMyDetails = () => {
    if (!!changedMyDetailsFirstOrLastName) {
      updateFirstAndLastName()
    }
    if (!!changedMyDetailsPhoto) {
      updatePhoto()
    }
  }

  const updateAccount = () => {
    if (!!changedAccountEmailOrPhoneNumber) {
      updateEmailAndPhoneNumber()
    }
    if (userType === USER_TYPE.patient && !!changedAccountPassword) {
      updatePassword()
    }
  }

  const handleChangedFirstOrLastName = () => {
    if (!originalData || !firstName || !lastName) return
    // console.log('originalData', originalData)
    setChangedMyDetailsFirstOrLastName(
      firstName !== originalData?.first_name || lastName !== originalData?.last_name
    )
  }

  const handleChangedEmailOrPhoneNumber = () => {
    if (!originalData || !email || !phoneNumber) return
    // console.log('originalData', originalData)
    setChangedAccountEmailOrPhoneNumber(
      email !== originalData?.email || phoneNumber !== originalData?.phone_number
    )
  }

  const handleChangedPassword = () => {
    setChangedAccountPassword(!!currentPassword || !!newPassword || !!confirmNewPassword)
  }
  useEffect(() => {
    handleChangedFirstOrLastName()
  }, [firstName, lastName])

  useEffect(() => {
    handleChangedPassword()
  }, [currentPassword, newPassword, confirmNewPassword])

  useEffect(() => {
    handleChangedEmailOrPhoneNumber()
  }, [email, phoneNumber])

  useEffect(() => {
    console.log(
      changedMyDetailsPhoto,
      changedMyDetailsFirstOrLastName,
      changedAccountEmailOrPhoneNumber,
      changedAccountPassword
    )
    setSettingsChanged(
      changedMyDetailsPhoto ||
        changedMyDetailsFirstOrLastName ||
        changedAccountEmailOrPhoneNumber ||
        changedAccountPassword
    )
  }, [
    changedMyDetailsPhoto,
    changedMyDetailsFirstOrLastName,
    changedAccountEmailOrPhoneNumber,
    changedAccountPassword,
  ])

  const leave = () => {
    setSettingsChanged(false)
    setShowSaveDialog(false)
    if (leaveType.type === "navigate") {
      navigate(leaveType.url, leaveType.options)
    } else {
      setSelectedSideMenu(leaveType.selection)
    }
  }

  return (
    <div className="page" style={{ padding: ".5rem 1rem" }}>
      {showSaveDialog && (
        <SaveDialog close={() => setShowSaveDialog(false)} leave={() => leave()} />
      )}
      {/* {showTermsOfService && <TermsOfServiceView close={() => setShowTermsOfService(false)} />} */}
      {showDeleteUser && <DeleteUserView close={() => setShowDeleteUser(false)} />}
      {showLogoutDialog && (
        <LogoutDialog
          loadingLogout={loadingLogout}
          logout={handleLogout}
          close={() => setShow(false)}
        />
      )}
      <Container.Row
        justifyContent="space-between"
        alignItems="center"
        sx={{ marginTop: "32px", marginBottom: "28px" }}
      >
        <h2 className="pageTitle">{currentLang["settings"]}</h2>
        <Container.Row gap={1} alignItems="center" justifyContent="center">
          <LanguageMenu staticMode={true} />
          <Button onClick={() => setShow(true)} endIcon={<img src={Icons.logOut} />}>
            <p className="Body3Medium">{currentLang["logout"]}</p>
          </Button>
        </Container.Row>
      </Container.Row>
      <ul className="settingsSelectList">
        <li
          onClick={() => setSelectedSettingsTab("MyDetails")}
          className={selectedSettingsTab === "MyDetails" ? "liSelected" : undefined}
        >
          {currentLang["my_details"]}
        </li>
        <li
          onClick={() => setSelectedSettingsTab("Account")}
          className={selectedSettingsTab === "Account" ? "liSelected" : undefined}
        >
          {currentLang["account"]}
        </li>
        <li
          onClick={() => setSelectedSettingsTab("Notifications")}
          className={selectedSettingsTab === "Notifications" ? "liSelected" : undefined}
        >
          {currentLang["notifications"]}
        </li>
      </ul>
      <div className="overflowY">
        {selectedSettingsTab === "MyDetails" ? (
          <>
            <Container.Column sx={{ marginBottom: "40px", gap: "27px" }}>
              <div className="personalInformationTextContainer">
                <h3>{currentLang["personal_information"]}</h3>
                <p>
                  {userType === USER_TYPE.patient
                    ? currentLang["update_your_photo_physician_view"]
                    : currentLang["update_your_photo_patients_view"]}
                </p>
              </div>
              <Container.Row sx={{ gap: "18px" }}>
                {!photo ? (
                  <div
                    className="avatar"
                    style={{
                      minWidth: "90px",
                      minHeight: "90px",
                      maxWidth: "90px",
                      maxHeight: "90px",
                      fontSize: "2rem",
                    }}
                  >
                    {currentUserNameInitials}
                  </div>
                ) : (
                  <StaticImage
                    src={photo}
                    alt="background"
                    sx={{
                      minWidth: "90px",
                      minHeight: "90px",
                      maxWidth: "90px",
                      maxHeight: "90px",
                      borderRadius: "50%",
                    }}
                  />
                )}
                <DragDropFile
                  handleChangedPhoto={handleChangedPhoto}
                  handleDroppedPhoto={handleDroppedPhoto}
                />
              </Container.Row>
              <Container.Row sx={{ width: "fit-content", gap: "24px" }}>
                <TextFieldWithLabel
                  type="text"
                  label={currentLang["first_name"]}
                  placeholder={currentLang["sam"]}
                  value={firstName}
                  disabled
                  onChange={(e) => {
                    if (e.target.value.length > MAX_INPUT_LENGTH) return
                    setFirstName(e.target.value)
                  }}
                  error={!!firstNameError}
                  helperText={firstNameError}
                />
                <TextFieldWithLabel
                  type="text"
                  label={currentLang["last_name"]}
                  placeholder={currentLang["samson"]}
                  value={lastName}
                  disabled
                  onChange={(e) => {
                    if (e.target.value.length > MAX_INPUT_LENGTH) return
                    setLastName(e.target.value)
                  }}
                  error={!!lastNameError}
                  helperText={lastNameError}
                />
              </Container.Row>
              <span
                style={{
                  direction: currentLangID === 2 ? "rtl" : "ltr",
                }}
              >
                {currentLang["for_any_issue_please_contact"]}{" "}
                <a className="supportEmaillink">Support@life-medic.com</a>
              </span>
              {userType === USER_TYPE.physician && (
                <Button
                  onClick={() => setShowSignatureCanvas(true)}
                  sx={{ width: "185px" }}
                  endIcon={<img src={Icons.signatureBlack} />}
                >
                  {currentLang["update_signature"]}
                </Button>
              )}
            </Container.Column>
            <Container.Row justifyContent="end" alignItems="center" sx={{ gap: "1rem" }}>
              <Button
                onClick={resetMyDetailsInputs}
                disabled={!(!!changedMyDetailsFirstOrLastName || !!changedMyDetailsPhoto)}
              >
                {currentLang["cancel"]}
              </Button>
              <Button
                role="submit"
                onClick={updateMyDetails}
                isLoading={loading}
                disabled={!(!!changedMyDetailsFirstOrLastName || !!changedMyDetailsPhoto)}
              >
                {currentLang["save"]}
              </Button>
            </Container.Row>
          </>
        ) : selectedSettingsTab === "Account" ? (
          <>
            <Container.Column sx={{ marginBottom: "40px", gap: "27px" }}>
              {userType === USER_TYPE.physician && (
                <>
                  <div className="personalInformationTextContainer">
                    <h3>{currentLang["email"]}</h3>
                    <p>
                      {
                        currentLang[
                          "confirmation_code_will_be_send_to_this_email_address/phone_number"
                        ]
                      }
                    </p>
                  </div>
                  <Container.Column sx={{ width: "fit-content", gap: "24px" }}>
                    <TextFieldWithLabel
                      type="email"
                      label={currentLang["email"]}
                      disabled
                      placeholder="sam.samson@gmail.com"
                      value={email}
                      onChange={(e) => {
                        if (e.target.value.length > MAX_INPUT_LENGTH) return
                        setEmail(e.target.value)
                      }}
                      sx={{ width: "276px" }}
                      error={!!emailError}
                      helperText={emailError}
                    />
                    {/* <PhoneInput
                  label="Phone number"
                  placeholder="+972 - 051999998"
                  disabled
                  value={phoneNumber}
                  onChange={(val: string) => {
                    if (val.length > MAX_INPUT_PHONE_LENGTH) return
                    setPhoneNumber(val)
                  }}
                  setPhoneNumber={(val: string) => {
                    setPhoneNumber(val)
                  }}
                  error={!!phoneNumberError}
                  helperText={phoneNumberError}
                  sx={{ width: "276px" }}
                /> */}
                  </Container.Column>
                </>
              )}
              {userType === USER_TYPE.patient && (
                <>
                  <div className="personalInformationTextContainer">
                    <h3>{currentLang["change_password"]}</h3>
                    <p>
                      {
                        currentLang[
                          "password_is_not_legal_must_be_between_8_and_20_characters_and_include_at_least_one_uppercase_letter_one_lowercase_letter_one_digit_and_one_special_character"
                        ]
                      }
                    </p>
                  </div>
                  <Container.Column sx={{ width: "260px", gap: "24px" }}>
                    <TextFieldWithLabel
                      type="password"
                      label={currentLang["type_your_current_password"]}
                      placeholder={currentLang["current_password"]}
                      value={currentPassword}
                      autoComplete="off"
                      onChange={(e) => {
                        if (e.target.value.length > MAX_INPUT_LENGTH) return
                        setCurrentPassword(e.target.value)
                      }}
                      sx={{ width: "276px" }}
                    />
                    <TextFieldWithLabel
                      type="password"
                      autoComplete="off"
                      label={currentLang["type_your_new_password"]}
                      placeholder={currentLang["new_password"]}
                      value={newPassword}
                      onChange={(e) => {
                        if (e.target.value.length > MAX_INPUT_LENGTH) return
                        setNewPassword(e.target.value)
                      }}
                      sx={{ width: "276px" }}
                    />
                    <TextFieldWithLabel
                      type="password"
                      autoComplete="off"
                      label={currentLang["confirm_password"]}
                      placeholder={currentLang["confirm_password"]}
                      value={confirmNewPassword}
                      onChange={(e) => {
                        if (e.target.value.length > MAX_INPUT_LENGTH) return
                        setConfirmNewPassword(e.target.value)
                      }}
                      sx={{ width: "276px" }}
                    />
                  </Container.Column>
                </>
              )}
              {passwordError && <p style={{ color: "red" }}>{passwordError}</p>}
            </Container.Column>
            <Container.Row justifyContent="end" alignItems="center" sx={{ gap: "1rem" }}>
              <Button
                onClick={resetAccountInputs}
                disabled={!(!!changedAccountEmailOrPhoneNumber || !!changedAccountPassword)}
              >
                {currentLang["cancel"]}
              </Button>
              <Button
                role="submit"
                onClick={updateAccount}
                isLoading={loading}
                disabled={!(!!changedAccountEmailOrPhoneNumber || !!changedAccountPassword)}
              >
                {currentLang["save"]}
              </Button>
            </Container.Row>
            <Container.Column gap={2}>
              {/* <p onClick={() => setShowTermsOfService(true)} className="link">*/}
              <p className="link">
                <a
                  href={
                    userType === USER_TYPE.patient
                      ? "https://www.life-medic.com/terms-clinic"
                      : "https://www.life-medic.com/terms-clinic-webforce"
                  }
                  target="_blank"
                  className="flex gap-1"
                >
                  {currentLang["terms_and_conditions"]}
                  <img src={Icons.externalIcon} width="15px" alt=""></img>
                </a>
              </p>
              {userType === USER_TYPE.patient && (
                <p className="link flex gap-1">
                  <a
                    href="https://www.life-medic.com/delete-data"
                    target="_blank"
                    className="flex gap-1"
                  >
                    {currentLang["delete_user_data"]}
                    <img src={Icons.externalIcon} width="15px" alt=""></img>
                  </a>
                </p>
              )}
            </Container.Column>
          </>
        ) : selectedSettingsTab === "Notifications" ? (
          <div className="personalInformationTextContainer">
            <h3>{currentLang["allow_notifications"]}</h3>
            <Container.Row alignItems="center" sx={{ gap: "1rem" }}>
              <p>{currentLang["allow"]}</p>
              <Switch onClick={requestNotifications} checked={allowNotifications} />
            </Container.Row>
          </div>
        ) : null}
      </div>
      <p className="versionText Caption2">
        Life-Medic, Version: {`${packageJson.version}.${process.env.REACT_APP_COMMIT_ID}`}
      </p>
    </div>
  )
}

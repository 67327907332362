import React, { useState } from "react"
import { Box, Button, SvgIcon, Typography } from "src/UILibrary"
import { TextFieldWithLabel } from "src/components/textfieldWithLabel"
import { useRecoilState } from "recoil"
import { signInPhoneNumberState, signinEmailState } from "src/states/signin"
import { CodeInput } from "src/components/codeInput"
import {
  sendVerificationCodeToEmailByPhysicianOrModeratorOrAdmin,
  sendVerificationCodeToPhoneNumberByPhysicianOrModeratorOrAdmin,
  submitEmailVerificationCodeByPhysicianOrModeratorOrAdmin,
  submitPhoneNumberVerificationCodeByPhysicianOrModeratorOrAdmin,
} from "src/api/signinService"
import { MAX_INPUT_LENGTH, MAX_INPUT_PHONE_LENGTH } from "src/constants/common"
import { useRecoilValue } from "recoil"
import { currentLangIDtate, currentLangState } from "src/states/signup"
import {
  sendVerificationCodeToEmail,
  sendVerificationCodeToPhone,
} from "src/api/forgotPasswordService"
import { useUserAuth } from "src/pages/context/userAuth/userAuthContext"
import { PhoneInput } from "src/UILibrary/input/PhoneInput"
import Icons from "src/assets/icons"

export const LoginPhysicianForm: React.FC = () => {
  const currentLang = useRecoilValue(currentLangState)
  const [userType, setUserType] = useState<number>(-1)
  const [email, setEmail] = useRecoilState(signinEmailState)
  const [phoneNumber, setPhoneNumber] = useRecoilState(signInPhoneNumberState)
  const [errorMessage, setErrorMessage] = useState<string>("")
  const [isLoggingIn, setIsLoggingIn] = useState<boolean>(false)
  const [step, setStep] = useState(0)
  const [verificationType, setVerificationType] = useState<"email" | "phone">("email")
  const { login } = useUserAuth()
  const currentLangID = useRecoilValue(currentLangIDtate)

  const onSubmit = (e: any) => {
    e.preventDefault()
    setIsLoggingIn(true)
    if (verificationType === "phone") {
      sendVerificationCodeToPhoneNumberByPhysicianOrModeratorOrAdmin(phoneNumber)
        .then((res) => {
          setUserType(res.data.user_type)
          setStep(1)
        })
        .catch((error) => {
          setErrorMessage(
            error.response.status === 403
              ? currentLang["not_a_physician"]
              : currentLang["no_physician_found"]
          )
          setIsLoggingIn(false)
        })
    } else {
      sendVerificationCodeToEmailByPhysicianOrModeratorOrAdmin(email)
        .then((res) => {
          setUserType(res.data.user_type)
          setStep(1)
        })
        .catch((error) => {
          setErrorMessage(
            error.response.status === 403
              ? currentLang["not_a_physician"]
              : currentLang["no_physician_found"]
          )
          setIsLoggingIn(false)
        })
    }
  }

  const submitCode = async (otp: string) => {
    let res
    if (verificationType === "email") {
      res = await submitEmailVerificationCodeByPhysicianOrModeratorOrAdmin({ otp, email })
    } else {
      res = await submitPhoneNumberVerificationCodeByPhysicianOrModeratorOrAdmin({
        otp,
        phoneNumber,
      })
    }

    login(
      {
        token: res.data.jwt_token,
        csrfToken: res.data.csrf_token,
        csrfRefreshToken: res.data.refresh_csrf_token,
        refreshToken: res.data.refresh_token,
      },
      {
        id: res.data.user_id,
        email: email,
        type: userType,
      }
    )
  }

  const handleResend = () => {
    if (verificationType === "email") {
      sendVerificationCodeToEmail(email)
    } else {
      sendVerificationCodeToPhone(phoneNumber)
    }
  }

  const handleSwitch = () => {
    verificationType === "email" ? setVerificationType("phone") : setVerificationType("email")
    setErrorMessage("")
  }

  return (
    <form onSubmit={onSubmit}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
          gap: "1.25rem",
        }}
      >
        {!step ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {verificationType === "email" ? (
              <TextFieldWithLabel
                type="email"
                label={currentLang["email"]}
                placeholder={currentLang["email_placeholder"]}
                value={email}
                onChange={(e) => {
                  if (e.target.value.length > MAX_INPUT_LENGTH) return
                  setEmail(e.target.value)
                }}
                startIcon={<SvgIcon src={Icons.email} alt="email" sx={{ width: "16px" }} />}
                error={!!errorMessage}
                helperText={errorMessage}
                sx={{ width: "320px" }}
              />
            ) : (
              <PhoneInput
                label={currentLang["phone_number"]}
                placeholder={currentLang["phone_number_example"]}
                value={phoneNumber}
                onChange={(val: string) => {
                  if (val.length> MAX_INPUT_PHONE_LENGTH) return
                  setPhoneNumber(val)
                }}
                setPhoneNumber={(val: string) => {
                  setPhoneNumber(val)
                }}
                error={!!errorMessage}
                helperText={errorMessage}
                sx={{ ...(currentLangID === 2 && { direction: "rtl" }), width: "320px" }}
              />
            )}
            {!!errorMessage && (
              <Typography.Detail color="error" align="center" sx={{ mt: 1 }}>
                {currentLang["invalid_login"]}
              </Typography.Detail>
            )}
            <Button
              sx={{ mt: "1.25rem" }}
              role="submit"
              fullWidth
              type="submit"
              disabled={
                (!email && verificationType === "email") ||
                (!phoneNumber && verificationType === "phone") ||
                isLoggingIn
              }
              isLoading={isLoggingIn}
            >
              {currentLang["log_in"]}
            </Button>
            <Button fullWidth role="link" className="sendCodeViaButton Body3Semibold" sx={{ mt: "1.25rem" }} onClick={handleSwitch}>
              {verificationType === "email" ? currentLang["send_code_via_sms"]: currentLang["send_code_via_email"]}
            </Button>
          </Box>
        ) : (
          <CodeInput
            serviceCall={submitCode}
            onResend={handleResend}
            verificationType={verificationType}
            switchToOther={() => {
              handleSwitch()
              setStep(0)
              setIsLoggingIn(false)
            }}
            target={verificationType === "email" ? email : phoneNumber}
          />
        )}
      </Box>
    </form>
  )
}

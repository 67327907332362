import { AxiosResponse } from "axios"
import React, { useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router"
import { useLocation } from "react-router-dom"
import { useRecoilValue } from "recoil"
import {
  Alert,
  AlertColor,
  Box,
  Button,
  Grid,
  Link,
  Snackbar,
  TextField,
  Typography,
} from "src/UILibrary"
import { currentLangState } from "src/states/signup"
import { BASE_COLORS } from "src/themes/colors"
import { VerificationType } from "src/types/forgotPassword"

interface ICodeInput {
  serviceCall: Function
  redirectUrl?: Function | string
  verificationType: VerificationType
  target: string
  switchToOther?: () => void
  onResend: () => void
}

export const CodeInput: React.FC<ICodeInput> = ({
  serviceCall,
  redirectUrl,
  verificationType,
  target,
  switchToOther,
  onResend,
}) => {
  const currentLang = useRecoilValue(currentLangState)
  const [code, setCode] = useState(Array(6).fill(""))
  const inputRefs = useRef<HTMLInputElement[]>([])
  const [open, setOpen] = React.useState(false)
  const [timeLeft, setTimeLeft] = useState<number>(20)
  const [alertType, setAlertType] = useState<AlertColor>("success")
  const [alertMessage, setAlertMessage] = useState<string>("Sign up was successfull")
  const [isSending, setIsSending] = useState(false)
  const [hasError, setHasError] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    const id = setTimeout(() => {
      !!timeLeft && setTimeLeft(timeLeft - 1)
    }, 1000)

    return () => {
      clearTimeout(id)
    }
  }, [timeLeft])

  const resendVerificationCode = () => {
    onResend()
    !timeLeft && setTimeLeft(20)
  }

  const addToRefs = (el: HTMLInputElement) => {
    if (el && !inputRefs.current.includes(el)) {
      inputRefs.current.push(el)
    }
  }

  const handleChange = (index: number, value: string) => {
    const newCode = [...code]

    if(value.length > 1) {
      value.split("").filter(c => !isNaN(+c)).forEach((num, i) => {
        handleChange(index + i, num)
      })
      return
    }

   if(!inputRefs.current[index]) {
      return;
    }

    if(isNaN(+value)) { 
      inputRefs.current[index].value = '';
      return;
    }

    newCode[index] = value
    setCode(newCode)
    setHasError(false)
    inputRefs.current[index].value = value

    const joined = inputRefs.current.map((t) => t.value)
    const otpStr = joined.join("");
    if (otpStr.length === 6 && value) {
      setIsSending(true)
      serviceCall(otpStr)
        .then(() => {
          setAlertType("success")
          setAlertMessage("Code verified successfully")
          setTimeout(() => {
            redirectUrl && typeof redirectUrl === "string"
              ? navigate(redirectUrl, { state: location.pathname })
              : typeof redirectUrl === "function" && redirectUrl(otpStr)
          }, 1500)
        })
        .catch(() => {
          inputRefs.current.map((ref) => (ref.value = ""))
          setHasError(true)
          setAlertType("error")
          setAlertMessage("Verification code is incorrect")
        })
        .finally(() => {
          setOpen(true)
          setIsSending(false)
          setCode(Array(6).fill(""))
        })
    } else if (value && index < code.length - 1) {
      inputRefs.current[index + 1].focus()
      inputRefs.current[index + 1].select()
    }
  }

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return
    }
    setOpen(false)
  }

  const handleKeyDown = (index: number) => (event: React.KeyboardEvent) => {
    if (event.key === "Backspace" && !code[index]) {
      const prevIndex = index - 1
      if (prevIndex >= 0) {
        inputRefs.current[prevIndex].focus()
      }
    }
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <Typography.DetailHeading sx={{ mb: "1rem", textAlign: "center", width: "18.75rem" }}>
            {verificationType === "email"
              ? currentLang["weve_sent_an_activation_code_to_your_email_address"]
              : currentLang["weve_sent_an_sms_with_an_activation_code_to_your_phone_number"]}{" "}
            {target}
          </Typography.DetailHeading>
        </Box>

        <Grid container spacing={1} justifyContent="left">
          {code.map((_, index) => (
            <Grid item key={`grid-${index}`}>
              <TextField
                inputRef={addToRefs}
                type="number"
                sx={{
                  bgColor: "transparent",
                  marginBottom: 1,
                  "& .MuiOutlinedInput-root": {
                    width: { md: "64px", xs: "45px" },
                    height: { md: "64px", xs: "45px" },
                    borderRadius: "0.5rem",
                    bgcolor: "white",
                  },
                  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                    display: "none",
                  },
                  "& input": {
                    MozAppearance: "textfield",
                    fontSize: { md: "3rem", xs: "2rem" },
                    fontWeight: 500,
                    lineHeight: "5rem",
                    fontStyle: "normal",
                    letterSpacing: "-0.96px",
                    color: BASE_COLORS.gray[900],
                    textAlign: "center",
                  },
                }}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChange(index, event.target.value || "")}
                onKeyDown={handleKeyDown(index)}
                error={hasError}
                disabled={isSending}
              />
            </Grid>
          ))}
        </Grid>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "0.4375rem",
            mb: "1.375rem",
            mt: "3.375rem",
          }}
        >
          <Link.FormLink
            sx={{
              color: timeLeft ? BASE_COLORS.gray[800] : BASE_COLORS.primary[700],
              cursor: timeLeft ? "default" : "pointer",
              textDecoration: "underline"
            }}
            className="Body3Semibold"
            onClick={resendVerificationCode}
          >
            {currentLang["send_code_again"]}
          </Link.FormLink>
          {currentLang["in"]}
          <Typography.normalLabel>00:{timeLeft.toString().padStart(2, "0")}</Typography.normalLabel>
        </Box>
      </Box>

      <Button fullWidth role="link" onClick={switchToOther} className="sendCodeViaButton Body3Semibold">
        {verificationType === "email"
          ? currentLang["send_code_via_sms"]
          : verificationType === "phone"
          ? currentLang["send_code_via_email"]
          : null}
      </Button>

      <Snackbar
        open={open}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={alertType} sx={{ width: "100%" }}>
          {alertMessage}
        </Alert>
      </Snackbar>
    </>
  )
}

import { axiosInstance } from "src/modules/axios"

export const loginByPatient = async ({ email, password }: Record<string, string>) => {
  return axiosInstance.post("/login", {
    email,
    password,
  })
}

export const sendVerificationCodeToPhoneNumberByPhysicianOrModeratorOrAdmin = async (
  phoneNumber: string
) => {
  return axiosInstance.post("/login/physician/sms", { phone_number: phoneNumber })
}

export const submitPhoneNumberVerificationCodeByPhysicianOrModeratorOrAdmin = async (
  data: Record<string, string>
) => {
  const { otp, phoneNumber } = data
  return axiosInstance.post("/login/physician/otp", {
    otp,
    phone_number: phoneNumber,
  })
}

export const sendVerificationCodeToEmailByPhysicianOrModeratorOrAdmin = async (email: string) => {
  return axiosInstance.post("/login/physician/email", { email })
}

export const submitEmailVerificationCodeByPhysicianOrModeratorOrAdmin = async (
  data: Record<string, string>
) => {
  const { otp, email } = data
  return axiosInstance.post("/login/physician/otp", {
    otp,
    email,
  })
}

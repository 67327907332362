import { USER_TYPE } from "src/constants/enums"
import { axiosInstance } from "src/modules/axios"
import { IPersonalDetails } from "src/types/account"
import { IUserSignUpFormInputs } from "src/types/signup"

export const checkEmailDuplication = async (data: IUserSignUpFormInputs) => {
  const { email, firstName, lastName, password } = data

  return axiosInstance.post("/registration", {
    email,
    first_name: firstName,
    last_name: lastName,
    password: password,
  })
}

export const sendVerificationCodeToPhone = async (personalDetails: IPersonalDetails) =>
  _sendVerificationCode("/registration/mobile", personalDetails)

export const sendVerificationCodeToEmail = async (personalDetails: IPersonalDetails) =>
  _sendVerificationCode("/registration/emailotp", personalDetails)

const _sendVerificationCode = async (url: string, personalDetails: IPersonalDetails) => {
  const { email, phoneNumber: fullNumber, dob, gender, firstName, lastName } = personalDetails
  try {
    const response = await axiosInstance.post(url, {
      email,
      phone_number: fullNumber,
      // id_social_number: id,
      birthday: dob,
      gender,
      first_name: firstName,
      last_name: lastName,
    })
    return response.data
  } catch (error) {
    console.error("Error:", error)
    throw error
  }
}

export const submitVerificationCode = async (personalDetails: any) => {
  const {
    id: id_social_number,
    email,
    password,
    phoneNumber: full_number,
    firstName: first_name,
    lastName: last_name,
    dob: birthday,
    gender,
    otp,
  } = personalDetails
  return axiosInstance.post("/registration/otp", {
    id_social_number,
    email,
    password,
    phone_number: full_number,
    language: "en",
    first_name,
    last_name,
    birthday,
    gender,
    new_password: password,
    otp,
    // user_type_id: USER_TYPE["physician"],
    // user_permission_id: 1,
  })
}

export const socialSignUp = async (socialType: string) => {
  try {
    const response = await axiosInstance.get(`/registration/auth/${socialType}`)
  } catch (error) {
    console.error("Error:", error)
    throw error
  }
}

export const socialSignUpWithDetails = async (personalDetails: any) => {
  const {
    id: id_social_number,
    password,
    phoneNumber: phone_number,
    dob: birthday,
    gender,
  } = personalDetails
  const queryParams = new URLSearchParams(window.location.search)
  const email = queryParams.get("email")

  return axiosInstance.post("/registration/social", {
    id_social_number,
    email,
    password,
    phone_number,
    language: "en",
    birthday,
    gender,
  })
}

import axios from "axios"
import { deleteUserStorage } from "src/pages/context/userAuth/storage"
import { getStatusCode } from "src/pages/home/utils"

export const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api`,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
  },
})

export const setAuthHeaders = ({ jwtToken, csrfToken }: IHeaders) => {
  axiosInstance.defaults.headers.common["Authorization"] = "Bearer " + jwtToken
  axiosInstance.defaults.headers.common["X-CSRF-TOKEN"] = csrfToken
}

export let responseCodes: any = {};
const initResponseCodes = async () => {
  const resp = await axiosInstance.post("/authenticated/enums/list_enums")
  responseCodes = resp.data?.server_response_code;
}
initResponseCodes();

axiosInstance.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  const status_code = getStatusCode(error);
  console.log("axiosInstance interceptors error", status_code)
  switch (status_code) {
    case responseCodes.token_is_expired_need_refresh_token:
    case responseCodes.csrf_token_is_expired_need_refresh_token:
    case responseCodes.Something_went_wrong_login_again:
    case responseCodes.invalid_JWTtoken_or_CSRF_token:
    case responseCodes.token_is_invalid:
      deleteUserStorage();
      if (window.location.href !== "/") {
        window.location.href = '/';
      }
      break;
    default:
      break;
  }
  return Promise.reject(error);
});

interface IHeaders {
  jwtToken: string
  csrfToken: string
  refreshJwtToken?: string
  refreshCsrfToken?: string
  email?: string
  userID?: string
}


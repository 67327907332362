import { setAuthHeaders } from "src/modules/axios";
import { emptyCredentials, emptyUser } from "./userAuthContext.empty";
import { IUserAuthState } from "./userAuthContext.types";

const USER_DATA_STORAGE = 'user-data';

export const getUserStorage = (): IUserAuthState => {
    const storage = sessionStorage.getItem(USER_DATA_STORAGE);
    if (!storage) {
        return {
            user: emptyUser,
            credentials: emptyCredentials
        }
    }

    const data = JSON.parse(storage) as IUserAuthState;
    resetAuthHeaders(data);

    return data;
}

export const setUserStorage = (state: IUserAuthState) => {
    sessionStorage.setItem(USER_DATA_STORAGE, JSON.stringify(state));
    resetAuthHeaders();
}

export const deleteUserStorage = () => {
    sessionStorage.removeItem(USER_DATA_STORAGE);
    resetAuthHeaders();
}

const resetAuthHeaders = (data?: IUserAuthState) => {
    const state = data || getUserStorage();

    setAuthHeaders({ 
         jwtToken: state.credentials.token, 
        csrfToken: state.credentials.csrfToken 
    })
}
import { ConversationMessageTypes } from "src/constants/enums"

export const getUserInitialsFromFullName = (fullName: string) => {
  try {
    const fullNameArr = fullName.split(" ")

    let initials = ""

    for (let index = 0; index < fullNameArr.length; index++) {
      const element = fullNameArr[index]
      initials += element[0]
    }

    return initials.toUpperCase()
  } catch (err) {
    console.log(err)
    return ""
  }
}

export const getUserGroupName = (userID: string) => {
  return "user_" + userID
}

export const getLastMessagePrefix = (date: Date) => {
  const lastOnlineTemp = date
  const lastOnlineTempDay = lastOnlineTemp.getDay()
  const lastOnlineTempHours = lastOnlineTemp.getHours()

  const lastOnlineTempMinutes =
    lastOnlineTemp.getMinutes().toString().length < 2
      ? "0" + lastOnlineTemp.getMinutes()
      : lastOnlineTemp.getMinutes()

  const today = new Date().getDay()
  if (lastOnlineTempDay === today) {
    return lastOnlineTempHours + ":" + lastOnlineTempMinutes
  } else if (isUtcMillisFromYesterday(lastOnlineTemp)) {
    return "Yesterday"
  } else {
    const [dd, mm, ffff] = [
      lastOnlineTemp.getDate(),
      lastOnlineTemp.getMonth() + 1,
      lastOnlineTemp.getFullYear(),
    ]
    return `${dd}/${mm}/${ffff}`
  }
}

export function isUtcMillisFromYesterday(date: Date) {
  const yesterdayUtc = new Date()
  yesterdayUtc.setUTCHours(0, 0, 0, 0)
  yesterdayUtc.setUTCDate(yesterdayUtc.getUTCDate() - 1)
  date.setUTCHours(0, 0, 0, 0)
  return yesterdayUtc.toUTCString() === date.toUTCString()
}

export const extractIdsFromGroupName = (group_name: string) => {
  const arr = group_name.match(/ai_([a-fA-F\d]+)?|pa_([a-fA-F\d]+)?|ph_([a-fA-F\d]+)?/g) || []

  const [aiId, paId, phId] = arr.map((id: string) => id.split("_")[1])

  return {
    aiId,
    paId,
    phId,
  }
}

export function getMessageTypeId(message: string) {
  if (message.includes("pdf")) {
    return ConversationMessageTypes.pdf
  } else if (message.includes("png")) {
    return ConversationMessageTypes["image/png"]
  } else if (message.includes("jpg")) {
    return ConversationMessageTypes["image/jpg"]
  } else if (message.includes("gif")) {
    return ConversationMessageTypes["image/gif"]
  }
  return ConversationMessageTypes.text
}

export const getDatePlusTime = (minutes: number) => {
  return new Date(new Date().setMinutes(new Date().getMinutes() + minutes))
}

export const getDatePlusYears = (years: number) => {
  return new Date(new Date().setFullYear(new Date().getFullYear() + years))
}

export const normalize = (
  val: number,
  oldMin: number,
  oldMax: number,
  newMin: number,
  newMax: number
) => {
  // Rescale to 0-1
  let normalized = (val - oldMin) / (oldMax - oldMin)

  // Scale to new range
  normalized = normalized * (newMax - newMin) + newMin

  return normalized
}

export const isUserOnline = (date: Date | string) => {
  const latestOnline = getDatePlusTime(-3)
  return new Date(date) > latestOnline
}

export const getStatusCode = (error: any) => {
  const status_code = error?.response?.data.status_code || error?.response?.status
  return status_code
}

export function removePaFromGroupName(group_name: string) {
  const regex = /_pa_[^_]+/
  return group_name.replace(regex, "")
}

import React from "react"
import { Box, Typography } from "src/UILibrary"
import { CreateIndividual } from "./createIndividual"
import { PersonalDetailsForm } from "./personalDetailsForm"
import { SelectOTP } from "./selectOTP"
import { useRecoilValue } from "recoil"
import { currentLangState } from "src/states/signup"

interface CreateAccountProps {
  setStep: Function
  step: number
}

export const CreateAccount: React.FC<CreateAccountProps> = ({ step, setStep }) => {
  const currentLang = useRecoilValue(currentLangState)

  return (
    <Box>
      <Typography.SubHeading sx={{ textAlign: "center", color: "text.primary", mb: "2rem" }}>
        {currentLang["create_account"]}
      </Typography.SubHeading>

      {!step ? (
        <CreateIndividual setStep={setStep} />
      ) : step === 1 ? (
        <PersonalDetailsForm setStep={setStep} />
      ) : step === 2 ? (
        <SelectOTP />
      ) : null}
    </Box>
  )
}

import React from "react"
import { DatePicker as MuiDatePicker, DatePickerProps } from "@mui/x-date-pickers"
import { BASE_COLORS } from "src/themes/colors"
import { Typography } from "./typography"
import CalendarIcon from "src/assets/icons/calendar.svg"
import LeftArrow from "src/assets/icons/left-arrow.svg"

import { SvgIcon } from "./svgIcon"
import Container from "src/components/container"
import useIsRtl from "src/hooks/useIsRtl"
import "./styles/datePicker.scss"
import BackArrow, { NextArrow } from "src/pages/signup/components/BackArrow"

interface IDatePickerProps extends DatePickerProps<Date> {
  error?: boolean;
}

export const DatePicker = ({ sx, label, error = false, ...rest }: IDatePickerProps) => {
  const isRtl = useIsRtl()

  return (
    <Container.Column sx={{ width: "100%" }}>
      <Typography.Detail
        sx={{
          fontSize: 14,
          fontWeight: 510,
          lineHeight: "1.25rem",
          color: "text.secondary",
          ml: "1rem",
          mb: "0.25rem",
        }}
      >
        {label}
      </Typography.Detail>
      <MuiDatePicker
        slots={{
          openPickerIcon: () => (
            <Container.Row
              alignItems="center"
              justifyContent="center"
              style={{ width: "16px", height: "16px", marginRight: "3px" }}
            >
              <SvgIcon
                src={CalendarIcon}
                alt="calendar"
                sx={{
                  width: "16px",
                  height: "16px",
                }}
              />
            </Container.Row>
          ),
          nextIconButton: ({ onClick }) => <NextArrow onClick={onClick} />,
          previousIconButton: ({ onClick }) => <BackArrow onClick={onClick} />,
        }}
        sx={{
          width: "100%",
          direction: "ltr",
          "& input": {
            fontSize: "1rem",
            p: "0.625rem 0.875rem",
            borderRadius: "0.5rem",
            fontWeight: 400,
            lineHeight: "1.5rem",
            color:
              rest.value != null && !isNaN(new Date(rest.value!).getTime())
                ? "black"
                : BASE_COLORS.gray[400],
            direction: isRtl ? "rtl" : "ltr",
          },
          "& fieldset": {
            border: "1px solid",
            borderColor: error ? BASE_COLORS.error[500] : BASE_COLORS.gray[300],
          },
          "& .MuiOutlinedInput-root": {
            height: "2.75rem",
            borderRadius: "50px",
            justifyContent: "flex-start",
            textAlign: "center",
            backgroundColor: "#F2F4F7 !important",
            // "&:hover": {
            //   boxShadow: "0px 0px 0px 4px #E8F8FF, 0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
            // },
            // "&:hover.Mui-error": {
            //   boxShadow: "0px 0px 0px 4px #FEE4E2, 0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
            // },
            "&.Mui-focused.Mui-error": {
              boxShadow: "0px 0px 0px 4px #FEE4E2, 0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
            },

            "&.Mui-focused fieldset": {
              border: "1px solid",
              borderColor: BASE_COLORS.primary[300],
              color: "black",
            },

            "& fieldset": {
              borderWidth: "0",
              borderStyle: "solid",
              borderColor: BASE_COLORS.gray[300],
            },
            // "&:hover fieldset": {
            //   border: "1px solid",
            //   borderColor: BASE_COLORS.primary[300],
            // },
            // "&:hover.Mui-error fieldset": {
            //   border: "1px solid",
            //   borderColor: BASE_COLORS.error[300],
            //   color: "black",
            // },
            
            "&.Mui-error fieldset": {
              border: "1px solid",
              borderColor: BASE_COLORS.error[500],
              color: "black",
            },
          },
          "&.Mui-error": {
            fontSize: "30px !important"
          },
          [".MuiPickersLayout-contentWrapper"]: {
            bgcolor: "red",
          },
          ...sx,
        }}
        {...rest}
      />
    </Container.Column>
  )
}

import React, { useState } from "react"
import { Box, Button, DatePicker, MenuItem, Select, SvgIcon, Typography } from "src/UILibrary"
import { Controller, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { IPersonalDetailsFormInputs } from "src/types/signup"
import * as Validator from "src/modules/validation"
import * as Yup from "yup"
import { TextFieldWithLabel } from "src/components/textfieldWithLabel"
import { useRecoilState, useRecoilValue } from "recoil"
import {
  currentLangIDtate,
  signupDOBState,
  signupEmailState,
  signupFirstNameState,
  signupGenderState,
  signupIDState,
  signupLastNameState,
  signupPhoneNumberState,
} from "src/states/signup"
import { sendVerificationCodeToEmail } from "src/api/signupService"
import { Gender, TGender } from "src/types/account"
import { PhoneInput } from "src/UILibrary/input/PhoneInput"
import { MAX_AGE_FOR_REGISTER, MAX_INPUT_ID_SSN_LENGTH, MAX_INPUT_PHONE_LENGTH, MIN_AGE_FOR_REGISTER } from "src/constants/common"
import { currentLangState } from "src/states/signup"
import Container from "src/components/container"
import Icons from "src/assets/icons"
import { responseCodes } from "src/modules/axios"
import dayjs from 'dayjs';


interface IPersonalDetailsProps {
  setStep?: Function
  onSocialSignUp?: Function
}

const validationSchema = Yup.object().shape({
  phoneNumber: Validator.phoneNumberSchema(),
  id: Validator.idSchema(),
  dob: Validator.dateSchema(),
  gender: Validator.genderSchema(),
})

export const PersonalDetailsForm: React.FC<IPersonalDetailsProps> = ({
  setStep,
  onSocialSignUp,
}) => {
  const currentLang = useRecoilValue(currentLangState)
  const currentLangID = useRecoilValue(currentLangIDtate)
  const email = useRecoilValue(signupEmailState)
  const firstName = useRecoilValue(signupFirstNameState)
  const lastName = useRecoilValue(signupLastNameState)
  const [phoneNumber, setPhoneNumber] = useRecoilState(signupPhoneNumberState)
  // const [id, setID] = useRecoilState(signupIDState)
  const [dob, setDOB] = useRecoilState(signupDOBState)
  const [gender, setGender] = useRecoilState(signupGenderState)
  const [isRegistering, setIsRegistering] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>("")


  const {
    handleSubmit,
    control,
    getValues,
    formState: { errors },
  } = useForm<IPersonalDetailsFormInputs>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      phoneNumber: phoneNumber || "",
      // id: id || "",
      dob: dob || "",
      gender: gender || "",
    },
  })
  
  const onSubmit = async (data: IPersonalDetailsFormInputs) => {
    try {
      setIsRegistering(true)

      if (!!onSocialSignUp) return await onSocialSignUp()
      
      await sendVerificationCodeToEmail({
        email,
        phoneNumber,
        dob: dob!,
        gender,
        firstName,
        lastName,
      })
      setStep && setStep()
    } catch (error: any) {
      const statusCode = error?.response?.data?.status_code;
      if(statusCode && responseCodes) {
        const code = Object.keys(responseCodes).find(c => responseCodes[c] === statusCode);
        if(code) {
          setErrorMessage(code)
        }
      } else {
        setErrorMessage('an_error_occurred')
      }
    } finally {
      setIsRegistering(false)
    }
    
  }

  const isDisabled = !getValues().dob || !getValues().gender || !getValues().phoneNumber;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "320px",
          gap: "1.25rem",
          ...(currentLangID === 2 && { textAlign: "right" })
        }}
      >
        <Controller
          name="phoneNumber"
          control={control}
          render={({ field: { onChange, value } }) => (
            <PhoneInput
              label={currentLang["phone_number"]}
              value={value}
              onChange={(val: string) => {
                if (val.length > MAX_INPUT_PHONE_LENGTH) return
                onChange(val)
                setPhoneNumber(val)
              }}
              setPhoneNumber={(val: string) => {
                onChange(val)
                setPhoneNumber(val)
              }}
              error={!!errors.phoneNumber}
              helperText={
                errors.phoneNumber ? currentLang[`${errors.phoneNumber?.message}`] : undefined
              }
              sx={{...(currentLangID === 2 && { direction: "rtl"})}}
            />
          )}
        />
        {/* <Controller
          name="id"
          control={control}
          render={({ field: { onChange, value } }) => (
            <TextFieldWithLabel
              type={"id"}
              forceLtr={true}
              label={currentLang["id/social_security_number"]}
              placeholder={currentLang['ssn_placeholder']}
              value={value}
              onChange={(e) => {
                if (e.target.value.length > MAX_INPUT_ID_SSN_LENGTH) return
                onChange(e.target.value)
                setID(e.target.value)
              }}
              error={!!errors.id}
              helperText={
                errors.id ? currentLang[`${errors.id.message}`] : currentLang['ssn_helper_text']
              }
            />
          )}
        /> */}

        <Controller
          name="dob"
          control={control}
          render={({ field: { onChange, value } }) => (
            <DatePicker
              label={currentLang["date_of_birth"]}
              value={value}
              maxDate={dayjs().add(MIN_AGE_FOR_REGISTER * -1, 'years') as any}
              minDate={dayjs().add(MAX_AGE_FOR_REGISTER * -1, 'years') as any}
              onChange={(value) => {
                onChange(value)
                setDOB(value)
              }}
              slotProps={{
                textField: {
                  error: !!errors.dob,
                  helperText: errors.dob && currentLang[`${errors.dob?.message}`],
                },
              }}
               sx={{...(currentLangID === 2 && { textAlign: "right" })}}
            />
          )}
          
        />

        <Controller
          name="gender"
          control={control}
          render={({ field: { onChange, value } }) => (
            <Select
              placeholder={currentLang["select"]}
              label={currentLang["select_gender"]}
              value={value}
              onChange={(e) => {
                onChange(e.target.value)
                setGender(e.target.value as TGender)
              }}
              renderValue={(val) => currentLang[`${val}`]}
            >
              {Object.values(Gender).map((val, index) => (
                <MenuItem key={index} value={val}>
                  <Container.Row>
                    {currentLang[`${val}`]}
                    {val === value && (
                      <SvgIcon
                        sx={{ width: "15px", position: 'absolute', right: "10px" }}
                        src={Icons.check}
                        alt={`${val}-flag`}
                      />
                    )}
                  </Container.Row>
                </MenuItem>
              ))}
            </Select>
          )}
        />

        <Button
          role="submit"
          type="submit"
          fullWidth
          sx={{ marginTop: "12px" }}
          onClick={() => handleSubmit(onSubmit)}
          isLoading={isRegistering}
          disabled={isDisabled || isRegistering}
        >
          {currentLang["continue"]}
        </Button>


        {!!errorMessage && (
          <Typography.Detail color="error" mt={1} mb={1.5} align="center">
            {currentLang[`${errorMessage}`]}
          </Typography.Detail>
        )}
      </Box>
    </form>
  )
}

import React, { useEffect, useState } from "react"
import { CHAT_SIDE_MENU_OPTIONS } from "src/constants/enums"
import { useChat } from "src/pages/home/context/chatContext"

import { createContext, useContext } from "react"

// Create the context
export const MaximizeChatContext = createContext<any>(null)

// Create a provider component
export const MaximizeChatProvider = ({ children }: any) => {
  const [payload, setPayload] = useState({ groupId: "", isFullScreen: false })

  return (
    <MaximizeChatContext.Provider value={{ payload, setPayload }}>
      {children}
    </MaximizeChatContext.Provider>
  )
}

// Custom hook to consume the context
export const useMaximizeChatContext = () => {
  const { payload, setPayload } = useContext(MaximizeChatContext)
  return {
    payload,
    setPayload,
  }
}

export const useMaximizeChat = () => {
  const { setChatWindow, getGroup, setAudioMode, setSelectedSideMenu, selectedSideMenu } = useChat()
  const { payload, setPayload } = useMaximizeChatContext()

  useEffect(() => {
    if (!payload) return
    if (payload.groupId && payload.isFullScreen) {
      const group = getGroup(payload.groupId)
      if (!group) return
      setChatWindow(group)
      setAudioMode(false)
    }
  }, [selectedSideMenu])

  const handleSelectChat = (groupId: string) => {
    setSelectedSideMenu(CHAT_SIDE_MENU_OPTIONS.chat)
    setPayload({
      groupId: groupId,
      isFullScreen: true,
    })
  }

  return {
    handleSelectChat,
  }
}

import React from 'react';
import { Grid } from "@mui/material"
import Container from 'src/components/container';
import { BackgroundPane } from 'src/components/backgroundPane';
import { useParams } from 'react-router-dom';

export const ErrorPage = () => {
    const { type } = useParams(); 

    let message = 'Error accord';

    switch (type) {
        case 'hub':
            message = 'Error connecting hub'
            break;
        case 'signature':
            message = 'Error getting signature'
            break;
        default:
            break;
    }
    return (
        <Grid container justifyContent="center">
            <Container.Row
                sx={{
                    justifyContent: "center !important",
                    alignItems: "center !important",
                    bgcolor: "white",
                    borderRadius: "1.5rem",
                    overflow: "hidden",
                    minWidth: "320px",
                    width: "100%",
                    height: '100%',
                    overflowY: "auto",
                    paddingLeft: { lg: "0px", xs: "20px" },
                    paddingRight: { lg: "0px", xs: "20px" },
                }}
            >
                <Container.Flex
                    sx={{
                        justifyContent: { xs: "center" },
                        width: "100%",
                        height: "100%",
                        position: "relative",
                    }}
                >
                    <Container.Row
                        sx={{
                            flex: 1,
                            justifyContent: "center",
                            width: "100%",
                            position: "relative",
                        }}
                    >
                        <BackgroundPane />
                        <Container.Column
                            sx={{
                                flex: 1,
                                maxWidth: "500px",
                                alignItems: "center",
                                pt: "32px",
                                justifyContent: "flex-start",
                                pb: "40px",
                                scale: '0.8',
                            }}
                        >
                            <h1 style={{
                                fontSize: '1.5rem',
                                color: '#ef4444'
                            }}>{message}</h1>
                        </Container.Column>
                    </Container.Row>
                </Container.Flex>
            </Container.Row>
        </Grid>
    )
}
// import React, { useState, useEffect } from 'react';
// import StatusAI from './StatusAI';
// import { fetchPhysiciansFromPatient, fetchUser } from 'src/api/backoffice';

// const PhysicianStatusList = ({ patient_id, userType, currentUserId, currentUserType }) => {
//   const [physicians, setPhysicians] = useState([]);


//   const getPhysiciansFromPatients = async (patientId) => {
//     let tempPhysicians = [];
//     try {
//         if (patientId) {
//             const physician_ids = await fetchPhysiciansFromPatient(patientId);
//             if (physician_ids) {
//               for (const physician_id of physician_ids) {
//                 const physician_name = await fetchUser(physician_id);
//                 if (physician_name && physician_name["first_name"] && physician_name["last_name"]) {
//                     tempPhysicians.push({ 
//                       name: `${physician_name["first_name"]} ${physician_name["last_name"]}`, 
//                       id: physician_id 
//                     });
//                 }
//               }
//             }
//         }
//     } catch (error) {
//         console.error("Error fetching physicians from patient", error);
//     }
//     setPhysicians(tempPhysicians);
//   };

//   useEffect(() => {
//     getPhysiciansFromPatients(patient_id);
//   }, [patient_id]);

//   return (
//     <ul>
//       {physicians.map((physician) => (
//         <li key={physician.id}>
//           {physician.name}
//           <StatusAI 
//             currentUserType={2}
//             userType={userType} 
//             currentUserId={physician.id} 
//             id={patient_id}
//           />
//         </li>
//       ))}
//     </ul>
//   );
// };

// export default PhysicianStatusList;

import React, { useState, useEffect } from 'react';
import StatusAI from './StatusAI';
import { fetchPhysiciansFromPatient, fetchUser } from 'src/api/backoffice';
import { TextFieldWithLabel } from 'src/components/textfieldWithLabel';
import { useRecoilValue } from 'recoil';
import { currentLangIDtate, currentLangState } from 'src/states/signup';
import { USER_TYPE } from 'src/constants/common';

const PhysicianStatusList = ({ patient_id, userType, currentUserId, currentUserType, refresh }) => {
  const currentLang = useRecoilValue(currentLangState)
  const currentLangID = useRecoilValue(currentLangIDtate)
  const [physicians, setPhysicians] = useState([]);
  const [clinicName, setClinicName] = useState("");

  useEffect(() => {
    const fetchModeratorInfo = async () => {
      if (currentUserType === USER_TYPE.moderator) {
        const moderator = await fetchUser(currentUserId);
        if (moderator && moderator["clinic_name"]) {
          setClinicName(moderator["clinic_name"]);
        }
      }
    };

    fetchModeratorInfo();
  }, [currentUserId, currentUserType, refresh]);


  const getPhysiciansFromPatients = async (patientId) => {
    let tempPhysicians = [];
    try {
      if (patientId) {
        const physician_ids = await fetchPhysiciansFromPatient(patientId);
        if (physician_ids) {
          for (const physician_id of physician_ids) {
            const physician_name = await fetchUser(physician_id);
            if (physician_name && physician_name["first_name"] && physician_name["last_name"]) {

              if (currentUserType === USER_TYPE.admin || (currentUserType === USER_TYPE.moderator && physician_name["clinic_name"] === clinicName)) {
                tempPhysicians.push({
                  name: `${physician_name["first_name"]} ${physician_name["last_name"]}`,
                  id: physician_id,
                  clinicName: physician_name["clinic_name"]
                });
              }
            }
          }
        }
      }
    } catch (error) {
      console.error("Error fetching physicians from patient", error);
    }
    console.log("LIST OF PHYSICIANS: ",tempPhysicians)
    setPhysicians(tempPhysicians);
  };

  useEffect(() => {
    getPhysiciansFromPatients(patient_id);
  }, [patient_id, clinicName, currentUserType,refresh]);

    return (
      <ul className="pt-5 pb-5 mb-12" style={{direction: currentLangID === 2 ? 'rtl' : 'ltr', width: 'fit-content'}}>
        {physicians.map((physician) => (
          <li className = "flex flex-row items-center gap-8 pb-5 w-1/2" key={physician.id} style={{direction: currentLangID === 2 ? 'rtl' : 'ltr'}}>
            <TextFieldWithLabel
              label={currentLang["physician"]}
              type="email"
              value={physician.name}
              sx={{minWidth: "158px", maxWidth: "fit-content"}}
            />    
            <TextFieldWithLabel
              label={currentLang["clinic"]}
              type="email"
              value={physician.clinicName}
              sx={{minWidth: "158px", maxWidth: "fit-content"}}
            />
            <StatusAI
              currentUserType={2}
              userType={userType}
              currentUserId={physician.id}
              id={patient_id}
            />
          </li>
        ))}
      </ul>
    )
};

export default PhysicianStatusList;


import React from "react"
import { Box, StaticImage } from "src/UILibrary"
import LoadingSpinner from "src/assets/icons/spinner-blue.svg"

export const LoadingChat: React.FC = () => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        backgroundColor: "white",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "2rem",
        borderRadius: "20px",
      }}
    >
      <Box sx={{ position: "relative" }}>
        <StaticImage src={LoadingSpinner} alt="spinner" />
      </Box>
    </Box>
  )
}

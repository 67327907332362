import React from "react"
import { BrowserRouter } from "react-router-dom"
import { RecoilRoot } from "recoil"
import { DefaultTheme } from "src/themes/default"
import { ThemeProvider } from "./UILibrary"
import { LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import RootRoute from "./route/RootRoute"
import "src/App.scss";
import "src/Fonts.scss";
import "src/Colors.scss";
import { UserAuthProvider } from "./pages/context/userAuth/userAuthContext"

function App() {
  return (
    <div id="app">
      <BrowserRouter>
        <UserAuthProvider>
          <ThemeProvider theme={DefaultTheme}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <RecoilRoot>
                <RootRoute />
              </RecoilRoot>
            </LocalizationProvider>
          </ThemeProvider>
        </UserAuthProvider>
      </BrowserRouter>
    </div >
  )
}

export default App

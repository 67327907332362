import { USER_TYPE } from "src/constants/common";
import { TCredentials, TUser } from "./userAuthContext.types";

export const emptyUser: TUser = {
  email: "",
  id: "",
  type: USER_TYPE.guest
}


export const emptyCredentials: TCredentials = {
  csrfToken: "",
  csrfRefreshToken: "",
  token: "",
  refreshToken: ""
}
import React, { useEffect, useRef, useState } from "react"
import Container from "src/components/container"
import { axiosInstance } from "src/modules/axios"
import { SideMenu } from "./components/SideMenu"
import { ChatGroups } from "./subpages/ChatGroups"
import { Splash } from "src/components/splash"
import { MultiChat } from "./subpages/multichat"
import { Settings } from "./subpages/settings"
import { getUserInitialsFromFullName } from "./utils"
import { useConnect } from "./context/hooks/useConnect"
import {
  CHAT_SIDE_MENU_OPTIONS,
  ChatPointOfView,
  ConversationHandle,
  ConversationMessageTypes,
  USER_TYPE,
} from "src/constants/enums"
import { useChat } from "./context/chatContext"
import { requestNotifications } from "./notifications"
import "./home.scss"
import "src/Fonts.scss"
import "src/Colors.scss"
import { Signature } from "./components/Signature"
import Users from "../backoffice/all_users/all_users"
import AnalyticsBO from "../backoffice/analytics/AnalyticsBO"
import { useSocketIo } from "./context/hooks/useSocketIO"
import DemoAlphaPage from "../backoffice/Demo-Alpha"
import { LogoutDialog } from "./components/LogoutDialog"
import { useLogOut } from "src/hooks/useLogOut"
import { currentLangIDtate } from "src/states/signup"
import { useRecoilValue } from "recoil"
import { MediaRecorder, register } from "extendable-media-recorder"
import { connect } from "extendable-media-recorder-wav-encoder"
import { IChatGroup } from "./context/chatContext.types"

const Home = () => {
  const { isLoading, setTyping, fetchChatGroups, fetchChatGroupsNoLoading } = useConnect()
  const { joinUser } = useSocketIo()
  const currentLangID = useRecoilValue(currentLangIDtate)
  const {
    userID,
    chatGroups,
    userType,
    signature,
    userName,
    setSelectedSideMenu,
    selectedSideMenu,
    setChatWindows,
    setChatGroups
  } = useChat()
  const lastOnlineTimerRef = useRef<NodeJS.Timeout>()
  const [currentUserName, setCurrentUserName] = useState("")
  const [currentUserNameInitials, setCurrentUserNameInitials] = useState("")
  const [showSaveDialog, setShowSaveDialog] = useState(false)
  const [settingsChanged, setSettingsChanged] = useState(false)
  const [leaveType] = useState<any>() // what happend here why is there no setLeaveType?
  const [showSignatureCanvas, setShowSignatureCanvas] = useState(false)
  const [showLogoutDialog, setShowLogoutDialog] = useState(false)
  const [loadingLogout, setLoadingLogout] = useState(false)
  const { sendMessage } = useSocketIo()
  const logout = useLogOut()

  useEffect(() => {
    if (!userID) {
      return
    }
    switch (userType) {
      case USER_TYPE.patient:
      case USER_TYPE.physician:
        setSelectedSideMenu(CHAT_SIDE_MENU_OPTIONS.chat)
        break
      case USER_TYPE.moderator:
      case USER_TYPE.admin:
        setSelectedSideMenu(CHAT_SIDE_MENU_OPTIONS.users)

        break
      default:
        break
    }
  }, [userID])

  useEffect(() => {
    console.log("signature", signature)
    if (signature === null) {
      //specifily null to distinguish from undefined
      setShowSignatureCanvas(true)
    }
  }, [signature])

  useEffect(() => {
    // console.log(
    //   "%cHey developer, please make sure react strict mode is not enabled for getting correct chat behivaior! also make sure you are not connected to local host! or it wont work!",
    //   "color: blue; font-size: 20px"
    // )
    requestNotifications()
    fetchUserName()
    window.getUserSession = () => {
      return {
        userID: userID,
        userType: userType,
      }
    }
    if (userType === USER_TYPE.patient || userType === USER_TYPE.physician) {
      joinUser(userID)
      fetchChatGroups()
    }
  }, [])

  useEffect(() => {
    if (chatGroups.length && !isLoading) {
      notifyLastOnline()
    }
  }, [isLoading])

  useEffect(() => {
    lastOnlineTimerRef.current = setTimeout(notifyLastOnline, 1000 * 60 * 3) //3 minutes

    return () => {
      clearTimeout(lastOnlineTimerRef.current)
    }
  }, [chatGroups])

  useEffect(() => {
    const temp = getUserInitialsFromFullName(currentUserName)
    setCurrentUserNameInitials(temp)
  }, [currentUserName])

  useEffect(() => {
    if (selectedSideMenu === CHAT_SIDE_MENU_OPTIONS.logout) {
      setShowLogoutDialog(true)
    }
  }, [selectedSideMenu])

  const fetchUserName = async () => {
    console.log("userID", userID)
    console.log("userType", userType)
    axiosInstance
      .post("authenticated/conversation/get_info_by_user_id", {
        user_id: userID,
        lang: currentLangID === 1 ? "en" : "ar",
      })
      .then((data) => {
        if (data.data.first_name && data.data.last_name) {
          setCurrentUserName(data.data.first_name.trim() + " " + data.data.last_name.trim())
        } else if (data.data.first_name && !data.data.last_name) {
          setCurrentUserName(data.data.first_name.trim())
        } else if (!data.data.first_name && data.data.last_name) {
          setCurrentUserName(data.data.last_name.trim())
        }
      })
      .catch((err) => console.log(err))
  }

  const notifyLastOnline = () => {
    for (let group of chatGroups) {
      sendClientEvent(ConversationMessageTypes.last_online, group.group_name)
    }
  }

  const sendClientEvent = (typeId: ConversationMessageTypes, group_name: string) => {
    const socketIoMessage = {
      user_name: userName,
      user_id: userID,
      group_name: group_name,
      message_type_id: typeId,
      message: "",
      file_name: "",
      conversation_handler: ConversationHandle.DEFAULT,
    }

    console.log(socketIoMessage)

    sendMessage(socketIoMessage)
  }

  const handleLogout = async () => {
    setLoadingLogout(true)
    const res = await axiosInstance
      .post("authenticated/settings/patient_logout")
      .catch((err) => console.error(err))
      .finally(() => setLoadingLogout(false))
    if (!res) return
    logout(userType, res.data.crsf_token)
  }

  useEffect(() => {
    if (currentLangID) {
      fetchChatGroupsNoLoading()
    }
  }, [currentLangID])

  const refreshChatGroups = () => {
    const groups = chatGroups.filter(
      (g) => g.pointOfView === ChatPointOfView.AI_AND_PATIENT
      // && g.isPinnedPhysician
    )
    setChatWindows(groups)
  }

  const setPinnedPatient = (group_name: string, pinned: boolean) => {
    setChatGroups((state: IChatGroup[]) => {
      const chatGroup = state.find((w) => w.group_name === group_name)
      if (chatGroup) {
        chatGroup.isPinnedPatient = pinned
      }
      // setMultiChatWindows([...state].filter((g) => g.pointOfView === ChatPointOfView.AI_AND_PATIENT))
      return [...state]
    })
  }

  const setPinnedPhysician = (group_name: string, pinned: boolean) => {
    setChatGroups((state: IChatGroup[]) => {
      const chatGroup = state.find((w) => w.group_name === group_name)
      if (chatGroup) {
        chatGroup.isPinnedPhysician = pinned
      }
      // setMultiChatWindows([...state].filter((g) => g.pointOfView === ChatPointOfView.AI_AND_PATIENT))
      return [...state]
    })
  }

  if (isLoading || !userID) {
    return <Splash />
  }

  return (
    <>
      <Container.Row sx={{ height: "100%", display: isLoading ? "none" : "flex" }}>
        {showSignatureCanvas && userType === USER_TYPE.physician && (
          <Signature closeCanvas={() => setShowSignatureCanvas(false)} />
        )}
        <SideMenu
          userType={userType}
          userIDLocation={userID}
          settingsChanged={settingsChanged}
          setShowSaveDialog={setShowSaveDialog}
          refreshChatGroups={refreshChatGroups}
        />
        {selectedSideMenu === "chat" ? (
          <ChatGroups setTyping={setTyping} setPinnedPhysician={setPinnedPhysician} setPinnedPatient={setPinnedPatient} fetchChatGroupsNoLoading={fetchChatGroupsNoLoading} />
        ) : selectedSideMenu === CHAT_SIDE_MENU_OPTIONS.multiChat ? (
          <MultiChat setTyping={setTyping} refreshChatGroups={refreshChatGroups} setPinnedPhysician={setPinnedPhysician} setPinnedPatient={setPinnedPatient} />
        ) : selectedSideMenu === "settings" ? (
          <Settings
            userType={userType}
            userIDLocation={userID}
            currentUserNameInitials={currentUserNameInitials}
            setSettingsChanged={setSettingsChanged}
            showSaveDialog={showSaveDialog}
            setShowSaveDialog={setShowSaveDialog}
            leaveType={leaveType}
            setShowSignatureCanvas={setShowSignatureCanvas}
          />
        ) : selectedSideMenu === CHAT_SIDE_MENU_OPTIONS.users ? (
          <Users />
        ) : selectedSideMenu === CHAT_SIDE_MENU_OPTIONS.analytics ? (
          <AnalyticsBO />
        ) : selectedSideMenu === CHAT_SIDE_MENU_OPTIONS.instructions ? (
          // <InstructionsPage />
          <DemoAlphaPage />
        ) : null}
      </Container.Row>

      {showLogoutDialog && (
        <LogoutDialog
          loadingLogout={loadingLogout}
          logout={handleLogout}
          close={() => {
            setShowLogoutDialog(false)
            setSelectedSideMenu(CHAT_SIDE_MENU_OPTIONS.users)
          }}
        />
      )}
    </>
  )
}

export default Home

import React, { useState } from "react";
import "./datatable.scss";
import { Link, useNavigate } from "react-router-dom";
import { DataGrid, GridColDef, GridFilterModel, GridLogicOperator, GridToolbar } from '@mui/x-data-grid';
import { deleteUser } from "src/api/backoffice";
import { FaArrowCircleRight } from "react-icons/fa";
import User from "src/pages/backoffice/single_user/SingleUser";
import { useUserAuth } from "src/pages/context/userAuth/userAuthContext";
import { useRecoilValue } from "recoil";
import { currentLangIDtate, currentLangState } from "src/states/signup";   
import rtlPlugin from 'stylis-plugin-rtl';
import { arSD } from '@mui/x-data-grid/locales';
import { CacheProvider, useTheme } from "@emotion/react";
import { createTheme } from "@mui/material";
import { createCatchClause } from "typescript";
import { prefixer } from 'stylis';
import createCache from '@emotion/cache';
import { ThemeProvider } from "styled-components";
import { heIL } from '@mui/x-data-grid/locales';

type Props = {
    setSelectedUserId: any,
    columns: GridColDef[],
    rows: object[],
    slug: string;
    currentUserId: string;
    currentUserType: number;
    onDelete?: Function
}

interface GridRowParams {
    row: {
      id: string | number;
      [key: string]: any;
    };
  }
  
  const cacheRtl = createCache({
    key: 'data-grid-rtl-demo',
    stylisPlugins: [prefixer, rtlPlugin],
  });

const DataTable = (props: Props) => {
    const currentLang = useRecoilValue(currentLangState)
    const currentLangID = useRecoilValue(currentLangIDtate)
    const navigate = useNavigate();
    const { user } = useUserAuth();
    const [selectedUser, setSelectedUser] = useState<string | null>(null);
    
    const existingTheme = useTheme();
    const theme = React.useMemo(
        () =>
          createTheme({}, arSD, existingTheme, {
            direction: 'rtl',
          }),
        [existingTheme],
      );
    // const handleDelete = async (email:string)=> {
    //     try {
    //         const shouldDelete = window.confirm(`Are you sure you want to delete the user with email ${email}?`);
    //         if (!shouldDelete) {
    //           return;
    //         }
    //         await deleteUser(email, props.slug)
    //         alert(props.slug + " user with email " + email + " has been deleted!");
    //         if (props.onDelete) {
    //             props.onDelete();
    //         }
    //     } catch (error) {
    //         console.error("Error deleting user:", error);
    //         alert("Error deleting user. Please try again.");
    //     }
    // }
        
    // const actionColumn: GridColDef = {
    //     field:"action",
    //     headerName:"Action",
    //     width:200,
    //     renderCell:(params)=>{
    //         return (
    //             <div className="action flex justify-center items-center justify-items-center">
    //                 <Link to={`/malkishua/${props.slug}/${params.row.email}`}>
    //                     <img src="/view.svg"/>
    //                 </Link>
    //                 {/* <div className="delete">
    //                     <img src="/delete.svg" onClick={()=>handleDelete(params.row.email)}/>
    //                 </div> */}
    //                 {/* <Link to={`/malkishua/${props.slug}/${params.row.email}`}>
    //                     <FaArrowCircleRight style={{ fontSize: '30px' }}/>
    //                 </Link> */}
    //             </div>
    //         )
    //     }
    // }

    // const detailsColumn: GridColDef = {
    //     field:"details",
    //     headerName:"More Details",
    //     width:200,
    //     renderCell:(params)=>{
    //         return (
    //             <div className="action flex justify-center items-center justify-items-center">
    //                 <Link to={`/malkishua/users/${props.currentUserId}/${props.currentUserType}/${props.slug}/${params.row.id}`}>
    //                 <FaArrowCircleRight style={{ fontSize: '30px' }}/>
    //                 </Link>
    //             </div>
    //         )
    //     }
    // }

    const filterModel: GridFilterModel = {
        items: [
          { id: 3, field: 'user_type_id', operator: 'is', value: currentLang['admin'] },
          { id: 1, field: 'user_type_id', operator: 'is', value: currentLang['patient'] },
          { id: 2, field: 'user_type_id', operator: 'is', value: currentLang['physician'] },
          { id: 5, field: 'user_type_id', operator: 'is', value: currentLang['moderator'] }
        ],
        logicOperator: GridLogicOperator.And,
      };

      const handleRowClick = (params: GridRowParams) => {
        props.setSelectedUserId(params.row.id);
      };

      return (  
        <CacheProvider value={cacheRtl}>
            <ThemeProvider theme={theme}>
                <div dir={currentLangID === 2 ? "rtl" : "ltr"} className="dataTable">
                <DataGrid {...(currentLangID === 2 && {localeText: heIL.components.MuiDataGrid.defaultProps.localeText})}
                        className="dataGrid"
                        rows={props.rows}
                        columns={[...props.columns]}
                        onRowClick={handleRowClick}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 10,
                                },
                            },
                        }}
                        slots={{toolbar: GridToolbar}}
                        slotProps={{
                            toolbar:{
                                showQuickFilter: true,
                                quickFilterProps: { debounceMs: 500 },
                            },
                        }}
                        pageSizeOptions={[10]}
                        disableRowSelectionOnClick
                        disableColumnSelector
                    />
                </div>
            </ThemeProvider>
        </CacheProvider>
    )   
}

export default DataTable

import React, { useEffect, useState } from "react"
import Container from "src/components/container"
import Icons from "src/assets/icons"
import useIsRtl from "src/hooks/useIsRtl"
import { ChatGroup } from "../components/ChatGroup"
import { ChatPointOfView, USER_TYPE } from "src/constants/enums"
import { StaticImage, TextField } from "src/UILibrary"
import { ChatMessages } from "../components/ChatMessages"
import { useChat } from "../context/chatContext"
import { IChatGroup } from "../context/chatContext.types"
import { PhysicianCodeInput } from "../components/PhysicianCodeInput"
import { UserAvatar } from "../components/UserAvatar"
import { useRecoilValue } from "recoil"
import { currentLangState } from "src/states/signup"

export function ChatGroups({ setTyping, setPinnedPhysician, setPinnedPatient, fetchChatGroupsNoLoading }: any) {
  const {
    chatGroups,
    userType,
    userName,
    userNameInitials,
    userID,
    chatWindows,
    clearChatWindow,
    getEmptyChatWindow,
    showPhysicianCodeInput,
    setShowPhysicianCodeInput,
  } = useChat()
  const isRtl = useIsRtl()
  const [searchInput, setSearchInput] = useState("")
  const [showSearchInput, setShowSearchInput] = useState(false)
  const [showCheckbox, setShowCheckbox] = useState(false)
  const currentLang = useRecoilValue(currentLangState)

  useEffect(() => {
    clearChatWindow()
  }, [])

  return (
    <>
      <Container.Row sx={{ width: "100%" }}>
        {showPhysicianCodeInput && <PhysicianCodeInput fetchChatGroupsNoLoading={fetchChatGroupsNoLoading} />}
        <Container.Column className="usersArea">
          <Container.Row className="usersAreaHeader">
            <UserAvatar
              small={true}
              user_name={userName}
              user_name_initials={userNameInitials}
              userId={userID}
              userType={userType}
            />
            <Container.Row gap={3}>
              <div className="searchButton" onClick={() => setShowSearchInput(!showSearchInput)}>
                <StaticImage
                  src={Icons.search}
                  className="cursorHover"
                  alt="logo"
                  width={20}
                  height={20}
                />
              </div>
              {userType === USER_TYPE.patient && (
                <div className="addUser" onClick={() => setShowPhysicianCodeInput(true)}>
                  <StaticImage src={Icons.userPlus} className="cursorHover" alt="logo" />
                </div>
              )}
            </Container.Row>
          </Container.Row>
          {showSearchInput && (
            <div className="searchInputContainer">
              <TextField
                type="search"
                initialType="search"
                placeholder={currentLang["search"]}
                value={searchInput}
                onChange={(evt) => setSearchInput(evt.target.value)}
                fullWidth
                sx={{
                  "& input": {
                    fontSize: "1rem",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "1.5rem",
                    padding: "0.4rem 0.875rem",
                    borderRadius: "50px",
                    bgcolor: "white",
                    color: "#98A2B3",
                    direction: isRtl ? "rtl" : "ltr",
                  },
                }}
                // InputProps={{
                //   endAdornment: (
                //     <InputAdornment position="end">
                //       <StaticImage src={Icons.search} alt="logo" width={16} height={16} />
                //     </InputAdornment>
                //   ),
                // }}
              />
            </div>
          )}
          <p className="myUsersText">
            {userType === USER_TYPE.physician
              ? currentLang["my_patients"]
              : currentLang["my_avatars"]}
          </p>
          <ul className="chatGroupsList">
            {[...chatGroups]
              .filter((chatGroups) => chatGroups.pointOfView === ChatPointOfView.AI_AND_PATIENT)
              .filter((chatGroup) => {
                return chatGroup.theirName
                  .toLocaleLowerCase()
                  .includes(searchInput.toLocaleLowerCase())
              })
              .sort((chatGroupA: IChatGroup, chatGroupB: IChatGroup) => {
                const pinnedValue =
                  userType === USER_TYPE.patient ? "isPinnedPatient" : "isPinnedPhysician"
                const bothPinned = chatGroupB[pinnedValue] && chatGroupA[pinnedValue]
                if (bothPinned) {
                  return chatGroupA.lastMessage < chatGroupB.lastMessage ? 1 : -1
                }

                if (chatGroupA[pinnedValue]) return -1
                if (chatGroupB[pinnedValue]) return 1

                return chatGroupA.lastMessage < chatGroupB.lastMessage ? 1 : -1
              })
              .map((chatGroup: IChatGroup) => (
                <ChatGroup key={chatGroup.group_name} {...chatGroup} />
              ))}
          </ul>
        </Container.Column>
        {chatWindows.length > 0 ? (
          chatWindows.map((chatWindow, index) => (
            <ChatMessages
              key={chatWindow.group_name}
              index={index}
              {...chatWindow}
              setTyping={setTyping}
              examRoomId={chatWindow.pointOfViews[0].groupId}
              physicianRoomId={chatWindow.pointOfViews[1].groupId}
              setPinnedPhysician={setPinnedPhysician}
              setPinnedPatient={setPinnedPatient}
            />
          ))
        ) : (
          <ChatMessages
            {...getEmptyChatWindow()}
            setTyping={setTyping}
            setPinnedPhysician={setPinnedPhysician}
            setPinnedPatient={setPinnedPatient}
          />
        )}
      </Container.Row>
    </>
  )
}

import React from "react"
import Flex from "./Flex"
import { BoxProps } from "@mui/material"

const Row = ({ sx, ...rest }: BoxProps) => {
  return (
    <Flex {...rest} sx={{ ...sx }} />
  )
}
export default Row

import React from "react"
import { Box, Button, Typography } from "src/UILibrary"
import { useRecoilValue } from "recoil"
import { currentLangState } from "src/states/signup"
import LoginReg from "src/components/cards/LoginReg"
import Container from "src/components/container"
import { grey } from "@mui/material/colors"
import { useNavigate } from "react-router-dom"

export const SelectUserType: React.FC = () => {
  const currentLang = useRecoilValue(currentLangState)
  const navigate = useNavigate()

  return (
    <LoginReg showLanguageMenu>
      <Container.Column style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
        <Typography.SubHeading
          className="Body1Semibold"
          sx={{ textAlign: "center", color: grey[500] }}
        >
          {currentLang["please_tell_us_who_you_are"]}
        </Typography.SubHeading>
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
              gap: "1.25rem",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button sx={{ mt: "1.25rem", color: "white", width: "19rem" }} role="action" onClick={() => navigate('/signin-physician')}>
                {currentLang["i_am_a_physician"]}
              </Button>
              <Button sx={{ mt: "1.25rem", width: "19rem" }} role="submit" onClick={() => navigate('/signin-patient')}>
                {currentLang["i_am_a_patient"]}
              </Button>
            </Box>
          </Box>
        </>
      </Container.Column>
    </LoginReg>
  )
}

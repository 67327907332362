import React from "react"
import { Box, StaticImage, Typography } from "src/UILibrary"
import LogoWhite from "src/assets/icons/logo-white.svg"
import Container from "src/components/container"
import Images from "src/assets/imgs"
import { useRecoilValue } from "recoil"
import { currentLangState } from "src/states/signup"

export const BackgroundPane = () => {
  const currentLang = useRecoilValue(currentLangState)

  return (
    <Box
      sx={{
        display: { xl: "flex", xs: "none" },
        flex: 1,
        overflow: "hidden",
        position: "relative",
        backgroundColor: "#3CC3E2",
        minWidth: "520px",
        height: "100%",
      }}
    >
      {/* <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          overflow: "hidden",
        }}
      >
        <StaticImage
          src={Images.backgroundFlow}
          alt="background"
          sx={{ objectFit: "fill", width: "100%" }}
        />
      </Box> */}
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flex: 1,
          position: "absolute",
          top: "99px",
          bottom: "198px",
          justifyContent: "center",
          // right: "188px",
          alignItems: "center",
        }}
      >
        <StaticImage
          src={Images.illustration}
          alt="background"
          sx={{ maxWidth: "50%", height: "511px" }}
        />
      </Box>
      <Container.Row
        sx={{
          gap: "1rem",
          position: "absolute",
          bottom: "55px",
          left: "55px",
        }}
      >
        <StaticImage
          src={LogoWhite}
          alt="logo"
          width={60}
          height={60}
          sx={{ objectFit: "cover" }}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "self-start",
          }}
        >
          <Typography.SubTitle dir={"ltr"} color="white">
            {currentLang["welcome"]}
          </Typography.SubTitle>
          <Typography.Subscription color="white">
            {currentLang["begin_your_health_journey_with_life_medic"]}
          </Typography.Subscription>
        </Box>
      </Container.Row>
    </Box>
  )
}

import { WebPubSubClient } from "@azure/web-pubsub-client"
import { atom } from "recoil"
import { Language } from "src/constants/types"
import { AccountType, TGender } from "src/types/account"

export const accountTypeState = atom<AccountType>({
  key: "accountType",
  default: "private",
})

export const registeredEmailState = atom<string>({
  key: "registeredEmail",
  default: "",
})

export const signupEmailState = atom<string>({
  key: "signupEmail",
  default: "",
})

export const signupPasswordState = atom<string>({
  key: "signupPassword",
  default: "",
})

export const signupFirstNameState = atom<string>({
  key: "signupFirstName",
  default: "",
})

export const signupLastNameState = atom<string>({
  key: "signupLastName",
  default: "",
})

export const signupPhoneNumberState = atom<string>({
  key: "signupPhoneNumber",
  default: "+1",
})

export const signupBusinessNameState = atom<string>({
  key: "signupBusinessName",
  default: "",
})

export const signupIDState = atom<string>({
  key: "signupID",
  default: "",
})

export const signupDOBState = atom<Date | null>({
  key: "signupDOB",
  default: null,
})

export const signupGenderState = atom<TGender>({
  key: "signupGender",
  default: null,
})

export const interceptorsActiveState = atom<boolean>({
  key: "interceptorsActive",
  default: false,
})

export const currentLangSelectedState = atom<string>({
  key: "currentLangSelected",
  default: "English",
})

export const currentLangIDtate = atom<number>({
  key: "currentLangID",
  default: 1,
})

export const currentLangState = atom<any>({
  key: "currentLang",
  default: {},
})


export const langsArrayState = atom<Language[]>({
  key: "langsArray",
  default: [],
})

export const currentUserAvatarState = atom<Record<string, string>>({
  key: "currentUserAvatar",
  default: {},
})

export const currentIsRtlState = atom<boolean>({
  key: "isRtl",
  default: false,
})

export const currentChatGroupIndexState = atom<number>({
  key: "currentChatGroupIndex",
  default: -1,
})

export const activeClientState = atom<WebPubSubClient | null>({
  key: "activeClient",
  default: null,
})

export const chatGroupsState = atom<any>({
  key: "chatGroups",
  default: [],
})

export const chatMessagesState = atom<any>({
  key: "chatMessages",
  default: [],
})
export default function Dates({billNumber, billDate, dueDate}) {
    return(
        <article className="mt-10 mb-14 flex items-center justify-end">
            <ul>
                <li className="py-1">
                    <span className="font-bold">Bill number: </span>{billNumber}
                </li>
                <li className="py-1 bg-gray-100">
                    <span className="font-bold">Bill date: </span>{billDate}
                </li>
                <li className="py-1">
                    <span className="font-bold">Due date: </span>{dueDate}
                </li>
            </ul>
        </article>
    )
}
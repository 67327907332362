import * as Yup from "yup"
import valid from "card-validator"
import {
  MAX_PASSWORD_LENGTH,
  MAX_PHONE_NUMBER_LENGTH,
  MIN_PASSWORD_LENGTH,
  MIN_PHONE_NUMBER_LENGTH,
} from "src/constants/password"
import { Gender } from "src/types/account"
import { matchIsValidTel } from "mui-tel-input"
import { getDatePlusYears } from "src/pages/home/utils"
import { MAX_AGE_FOR_REGISTER, MIN_AGE_FOR_REGISTER } from "src/constants/common"

export const idSchema = () => {
  return Yup.string()
    .nullable()
    .matches(/^$|^\d{9}$/, "invalid_id/ssn_length")
}

export const dateSchema = () => {
  return Yup.date()
    .max(getDatePlusYears(MIN_AGE_FOR_REGISTER * -1), "dob_error_min_age")
    .min(getDatePlusYears(MAX_AGE_FOR_REGISTER * -1), "dob_error_max_age")
    .required("required")
    .typeError("dob_error")
}

export const genderSchema = () => {
  return Yup.string().required("required").oneOf(Object.values(Gender), "Invalid gender selection")
}

export const emailSchema = () => {
  return Yup.string()
    .email("invalid_email_shape")
    .max(150, "invalid_email_length")
    .required("required")
}

export const fullNameSchema = () => {
  return Yup.string()
    .required("required")
    .min(1, "invalid_fullname_length")
    .max(50, "invalid_fullname_length")
    .matches(/^[a-zA-Z\u0590-\u05FF\u0600-\u06FF\u00C0-\u024F\s]*$/, "invalid_characters")
};

export const registerNameSchema = () => {
  return Yup.string()
    .required("required")
    .min(1, "invalid_register_name_length")
    .max(50, "invalid_register_name_length")
}

export const fullNameKatakanaSchema = () => {
  return Yup.string()
    .required("required")
    .min(1, "invalid_fullname_katakana_length")
    .max(50, "invalid_fullname_katakana_length")
    .matches(
      // [\u0020,\u00A0,\u1680,\u180E,\u2000-\u200A,\u202F,\u205F,\u3000] is space character.
      /^[\u30A0-\u30FF,\u0020,\u00A0,\u1680,\u180E,\u2000-\u200A,\u202F,\u205F,\u3000]+$/,
      "invalid_fullname_katakana_character"
    )
}

// Tests phone number format
const phoneNumberCustomValidation = (value: string | undefined, context: Yup.TestContext) => {
  const originalValue: string = context.originalValue || ""

  const isValid = matchIsValidTel(originalValue)

  if (!isValid) {
    throw new Yup.ValidationError("phone_number_is_not_valid", value, context.path)
  }

  return true
}

// ,
// /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
export const phoneNumberSchema = () => {
  return Yup.string()
    .required("required")
    .min(MIN_PHONE_NUMBER_LENGTH, "invalid_phone_number_length")
    .max(MAX_PHONE_NUMBER_LENGTH, "invalid_phone_number_length")
    .matches(
      /^\s*(\+?(\d{1,3}))?[\s-]*(\d{1,4})?[\s-]*(\d{1,4})?[\s-]*(\d{1,9})?\s*$/,
      "invalid_phone_number_shape"
    )
  // .test("isValidPhoneNumber", "Invalid phone number format", phoneNumberCustomValidation)
}

export const passwordSchema = () => {
  return Yup.string()
    .min(MIN_PASSWORD_LENGTH, "invalid_password_length")
    .max(MAX_PASSWORD_LENGTH, "invalid_password_length")
    .matches(
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$ %^&*-]).{8,20}$/,
      "invalid_password_character"
    )
    .required("required")
}

export const confirmPasswordSchema = () => {
  return Yup.string()
    .oneOf([Yup.ref("password"), ""], "invalid_confirm_password")
    .required("required")
}

export const confirmNewPasswordSchema = () => {
  return Yup.string()
    .oneOf([Yup.ref("newPassword"), ""], "invalid_confirm_password")
    .required("required")
}

export const agreeRulesSchema = () => {
  return Yup.boolean().oneOf([true], "invalid_agree_value")
}

export const mailVerificationKeySchema = () => {
  return Yup.string().length(6, "invalid_mail_verify_key_shape").required("required")
}

export const businessNameSchema = () => {
  return Yup.string().required("required")
}

export const businessFormatSchema = () => {
  return Yup.string().required("required")
}

export const billingAddressTypeSchema = () => {
  return Yup.string()
}

export const postalCodeSchema = () => {
  return Yup.string()
    .required("required")
    .matches(/^[0-9]{3}-[0-9]{4}$/, "invalid_postal_code_shape")
}

export const accTermsNConditionsScheme = () =>
  Yup.boolean()
    .oneOf([true], "Checkbox must be checked")
    .required("Terms and conditions must be accepted")
// export const conditionalPostalSchema = () => {
//   return Yup.string().when("billingAddressType", {
//     is: (billingAddressType: string) => billingAddressType === "shipping",
//     then: postalCodeSchema(),
//   })
// }

// export const prefectureSchema = () => {
//   return Yup.string().required("required")
// }

// export const conditionalPrefectureSchema = () => {
//   return Yup.string().when("billingAddressType", {
//     is: (billingAddressType: string) => billingAddressType === "shipping",
//     then: prefectureSchema(),
//   })
// }

// export const citySchema = () => {
//   return Yup.string().required("required")
// }

// export const conditionalCitySchema = () => {
//   return Yup.string().when("billingAddressType", {
//     is: (billingAddressType: string) => billingAddressType === "shipping",
//     then: citySchema(),
//   })
// }

// export const streetAddressSchema = () => {
//   return Yup.string().required("required")
// }

// export const conditionalStreetAddressSchema = () => {
//   return Yup.string().when("billingAddressType", {
//     is: (billingAddressType: string) => billingAddressType === "shipping",
//     then: streetAddressSchema(),
//   })
// }

// export const remarksSchema = () => {
//   return Yup.string()
// }

// export const conditionalRemarksSchema = () => {
//   return Yup.string().when("billingAddressType", {
//     is: (billingAddressType: string) => billingAddressType === "shipping",
//     then: remarksSchema(),
//   })
// }

export const creditCardSchema = () => {
  return Yup.string()
    .max(16, "invalid_card_number")
    .test("test-card-number", "invalid_card_number", (value) => valid.number(value).isValid)
    .required("required")
}

export const expYearSchema = () => {
  return Yup.number().required("required")
}

export const expMonthSchema = () => {
  return Yup.number().required("required")
}

export const cvcSchema = () => {
  return Yup.string()
    .test("test-card-cvc", "invalid_cvc_number", (value) => valid.cvv(value).isValid)
    .required("required")
}

export const holderNameSchema = () => {
  return Yup.string().required("required")
}

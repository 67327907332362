import React, { useEffect, useState } from "react"
import { StaticImage } from "src/UILibrary"
import Icons from "src/assets/icons"
import Container from "src/components/container"
import { USER_TYPE } from "src/constants/enums"
import { useChat } from "../context/chatContext"
import { IChatGroup } from "../context/chatContext.types"
import { UserAvatar } from "./UserAvatar"
import { axiosInstance } from "src/modules/axios"
import { useRecoilValue } from "recoil"
import { currentLangState } from "src/states/signup"
import useIsRtl from "src/hooks/useIsRtl"

export function ChatGroup(chatGroup: IChatGroup) {
  const {
    isPinnedPhysician,
    isPinnedPatient,
    isAIPaused,
    group_name,
    lastMessage,
    theirNameInitials,
    theirName,
    theirId,
  } = chatGroup
  const currentLang = useRecoilValue(currentLangState)
  const isRtl = useIsRtl()
  const {
    getGroup,
    getUnreadMessages,
    setChatWindow,
    isSelectedChatGroupName,
    userType,
    getChatGroupStatus,
    setAudioMode,
    isRecordingAudioMode,
    audioMode,
  } = useChat()
  const isConnected = getChatGroupStatus(group_name).isConnected
  const group = getGroup(group_name)
  const examRoomGroup = getGroup(group?.pointOfViews[0].groupId!)
  const physicianRoomGroup = getGroup(group?.pointOfViews[1].groupId!)
  const unreadMessages = getUnreadMessages(group)
  const lastMessageOfCorrectGroup = userType === USER_TYPE.patient ? examRoomGroup?.lastMessage : physicianRoomGroup?.lastMessage 
  const newPatient = !lastMessage?.message

  const handleSelectChat = () => {
    if (group) {
      const groupTemp =
        userType == USER_TYPE.patient
          ? getGroup(group.pointOfViews[0].groupId)
          : getGroup(group.pointOfViews[1].groupId)
      if (groupTemp) {
        setChatWindow(groupTemp)
        setAudioMode(false)
      }
    }
  }

  const isSelected = () => {
    return group?.pointOfViews.find((v) => isSelectedChatGroupName(v.groupId))
  }

  return (
    <li
      onClick={() => {
        if (audioMode || isRecordingAudioMode) return
        handleSelectChat()
      }}
      style={{ opacity: audioMode || isRecordingAudioMode ? 0.5 : 1 }}
      // onDoubleClick={() => setShowCheckbox(!showCheckbox)}
      className={isSelected() ? "chatGroup selected" : "chatGroup"}
    >
      {/* {showCheckbox && <Checkbox sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }} />} */}
      <div className="avatarWrapper">
        <div className={isConnected && userType === USER_TYPE.physician ? "avatarOnline" : ""} />
        <UserAvatar
          user_name={theirName}
          user_name_initials={theirNameInitials}
          userId={theirId}
          userType={userType}
        />
      </div>
      <Container.Column className="chatGroupInformation">
        <Container.Row justifyContent="space-between" className="chatGroupHeaderWrapper">
          <p className="userName Body3Semibold">
            {userType === USER_TYPE.patient ? currentLang["dr"] + " " + theirName : theirName}
          </p>
          <p className="lastOnline Body3">{lastMessageOfCorrectGroup?.date_time_prefix || lastMessage.date_time_prefix}</p>
        </Container.Row>
        <Container.Row justifyContent="space-between" className="lastMessageWrapper">
          <p className="lastMessage Body3" style={{ direction: isRtl ? "rtl" : "ltr" }}>
            {lastMessageOfCorrectGroup?.message || lastMessage.message}
          </p>
          <Container.Row sx={{ gap: "0.38rem" }}>
            {newPatient && userType === USER_TYPE.physician ? (
              <>
                <div className="newPatient Caption2Semibold">{currentLang["new_patient"]}</div>
              </>
            ) : (
              <>
                {userType === USER_TYPE.physician && isPinnedPhysician && (
                  <StaticImage src={Icons.pinSelectedChatGroup} alt="logo" />
                )}
                {userType === USER_TYPE.patient && isPinnedPatient && (
                  <StaticImage src={Icons.pinSelectedChatGroup} alt="logo" />
                )}
                {isAIPaused && userType === USER_TYPE.physician && (
                  <StaticImage src={Icons.pausedRed} alt="logo" />
                )}
                {userType === USER_TYPE.patient && unreadMessages.patient > 0 ? (
                  <div className="unReadMessages">
                    <p>{unreadMessages.patient}</p>
                  </div>
                ) : userType === USER_TYPE.physician && unreadMessages.total > 0 ? (
                  <div className="unReadMessages">
                    <p>{unreadMessages.total}</p>
                  </div>
                ) : null}
              </>
            )}
          </Container.Row>
        </Container.Row>
      </Container.Column>
    </li>
  )
}

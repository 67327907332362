import React, { useState, useEffect } from "react"
import {AiOutlineDelete, AiOutlineEdit} from "react-icons/ai"
import { fetchPrice } from "src/api/backoffice"
import {v4 as uuidv4} from "uuid"

export default function TableForm({description, setDescription, amount, setAmount, price, setPrice, quantity, setQuantity, list, setList, total, setTotal, fee}) {
    const [isEditing, setIsEditing] = useState(false)

    const handleSubmit = (e) => {
        e.preventDefault()

        if (!description || !quantity ) {
            alert ("Please fill treatment details")
        } else if (!price) {
            alert ("Treatment was not found")
        } else {
            const newItems = {
                id: uuidv4(),
                description,
                quantity,
                price,
                amount
            }
            setDescription("")
            setPrice("")
            setQuantity("")
            setAmount("")
            setList([...list, newItems])
            setIsEditing(false)
        }
    }

    const fetchTreatmentPrice = async () => {
        try {
          const data = await fetchPrice(description);
          console.log(data)
          setPrice(data.price);
        } catch (error) {
          console.error('Error fetching treatment price:', error);
        }
    };
    //fetch price from mongo
    useEffect(() => {
        fetchTreatmentPrice();
    }, [description]);
    
    useEffect(() =>{
        const calculateAmount = (amount) => {
            setAmount(quantity * price)
        }
        calculateAmount(amount)
    },[amount, price, quantity])

    // calculate total amount of treatment
    function calculateTotalSum() {
        let rows = document.querySelectorAll(".amount")
        let sum = 0
    
        for(let i = 0; i < rows.length; i++) {
            if (rows[i].className === "amount") {
                sum += isNaN(rows[i].innerHTML) ? 0 : parseInt(rows[i].innerHTML)
                setTotal(sum * fee)
            }
        }
        if (!rows.length) {
            setTotal(0)
        }
    }
    useEffect(() => {
        calculateTotalSum();
    }, [list]);

    // edit func
    const editRow = (id) => {
        const editingRow = list.find((row) => row.id === id)
        setList(list.filter((row) => row.id !== id))
        setIsEditing(true)
        setDescription(editingRow.description)
        setQuantity(editingRow.quantity)
        setPrice(editingRow.price)
    }
    // delete func
    const deleteRow = (id) => setList(list.filter((row) => row.id !== id))

    return (
        <>
            <form onSubmit={handleSubmit}>
                <div className="flex flex-col md:mt-16">
                    <label htmlFor="decription">Treatment code description</label>
                    <input 
                        type="text" 
                        name="description" 
                        id="description" 
                        placeholder="Treatment code description" 
                        value={description}
                        onChange={(e) => setDescription(e.target.value)} 
                    />
                </div>
                <div className="md:grid grid-cols-3 gap-10">
                    <div className="flex flex-col">
                        <label htmlFor="quantity">Quantity</label>
                        <input 
                            type="text" 
                            name="quantity" 
                            id="quantity" 
                            placeholder="quantity" 
                            value={quantity}
                            onChange={(e) => setQuantity(e.target.value)} 
                        />
                    </div>

                    <div className="flex flex-col">
                        <label htmlFor="price">Price</label>
                        <p>{price}</p>
                    </div>

                    <div className="flex flex-col">
                        <label htmlFor="amount">Amount</label>
                            <p>{amount}</p>
                    </div>
                </div>
                <button type="submit" 
                    className="mb-5 bg-blue-500 text-white
                    font-bold py-2 px-8 rounded shadow 
                    border-2 border-blue-500 
                    hover:bg-transparent 
                    hover:text-blue-500 transition-all 
                    duration-300"
                >
                    {isEditing ? "Editing Treatment" : "Add Treatment"}
                </button>
            </form>
            <table width="100%" className="mb-10">
                <thead>
                    <tr className="bg-gray-100 p-1">
                        <td className="font-bold">Treatment description</td>
                        <td className="font-bold">Quantity</td>
                        <td className="font-bold">Price</td>
                        <td className="font-bold">Amount</td>
                        <td className="font-bold">20% Fee</td>
                    </tr>
                </thead>
                {list.map(({id, description, quantity, price, amount}) => (
                    <React.Fragment key={id}>
                        <tbody>
                            <tr>
                                <td>{description}</td>
                                <td>{quantity}</td> 
                                <td>{price}</td>
                                <td className="amount">{amount}</td>
                                <td>{amount * fee}</td>
                                <td>
                                    <button onClick={() => deleteRow(id)}>
                                        <AiOutlineDelete className="text-red-500 font-bold text-xl"/>
                                    </button>
                                </td>
                                <td>
                                    <button onClick={() => editRow(id)}>
                                        <AiOutlineEdit className="text-green-500 font-bold text-xl"/>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </React.Fragment>
                ))}
            </table>
            <div>
                <h2 className="flex items-end justify-end text-gray-800 text-4xl font-bold">
                    NIS. {total.toLocaleString()}
                </h2>
            </div>
        </>   
    )
}
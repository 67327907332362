import React, { useEffect, useState } from "react"
import { useRecoilValue } from "recoil"
import { StaticImage } from "src/UILibrary"
import { currentLangIDtate } from "src/states/signup"
import * as speechsdk from "microsoft-cognitiveservices-speech-sdk"
import Icons from "src/assets/icons"
import { useChat } from "../context/chatContext"

const VoiceMode = ({ setChatInput, isDisabled }: any) => {
  const { isRecordingAudioMode, setIsRecordingAudioMode, playBeep } = useChat()
  const currentLangID = useRecoilValue(currentLangIDtate)
  const [speechRecognizer, setSpeechRecognizer] = useState<any>(null)
  const { getTokenOrRefresh } = useChat()

  useEffect(() => {
    try {
      const setupRecognizer = async () => {
        const tokenObj = await getTokenOrRefresh() // Implement this function to fetch auth token
        if (!tokenObj) {
          console.error("Error fetching token")
          return
        }
        const speechConfig = speechsdk.SpeechConfig.fromAuthorizationToken(
          tokenObj.authToken,
          tokenObj.region
        )
        speechConfig.speechRecognitionLanguage = currentLangID === 2 ? "he-IL" : "en-US"

        const audioConfig = speechsdk.AudioConfig.fromDefaultMicrophoneInput()
        const recognizer = new speechsdk.SpeechRecognizer(speechConfig, audioConfig)

        recognizer.recognizing = (s, e) => {
          console.log(`RECOGNIZING: Text=${e.result.text}`)
        }

        recognizer.recognized = (s, e) => {
          if (e.result.reason === speechsdk.ResultReason.RecognizedSpeech) {
            console.log(`RECOGNIZED: Text=${e.result.text}`)
            setChatInput((prev: string) => prev + " " + e.result.text)
          }
        }

        recognizer.canceled = (s, e) => {
          console.log(`CANCELED: Reason=${e.reason}`)
          if (e.reason === speechsdk.CancellationReason.Error) {
            console.log(`CANCELED: ErrorCode=${e.errorCode}`)
            console.log(`CANCELED: ErrorDetails=${e.errorDetails}`)
            console.log(`CANCELED: Did you update the subscription info?`)
          }

          recognizer.stopContinuousRecognitionAsync()
          setIsRecordingAudioMode(false)
        }

        recognizer.sessionStopped = (s, e) => {
          console.log("Session stopped.")
          recognizer.stopContinuousRecognitionAsync()
          setIsRecordingAudioMode(false)
        }

        setSpeechRecognizer(recognizer)
      }

      if (!speechRecognizer) {
        setupRecognizer()
      }
    } catch {
      console.error("Error setting up recognizer")
    }

    return () => {
      speechRecognizer?.close()
    }
  }, [currentLangID])

  useEffect(() => {
    if (isRecordingAudioMode) {
      speechRecognizer?.startContinuousRecognitionAsync()
    } else {
      speechRecognizer?.stopContinuousRecognitionAsync()
    }
  }, [isRecordingAudioMode, speechRecognizer])

  const toggleListening = () => {
    setIsRecordingAudioMode(!isRecordingAudioMode)
    isRecordingAudioMode ? playBeep("end") : playBeep("start")
  }

  return (
    <div className="sendMessageButtonWrapper">
      {isRecordingAudioMode && <div className="micAnimationOne"></div>}
      {isRecordingAudioMode && <div className="micAnimationTwo"></div>}
      <StaticImage
        src={Icons.mic}
        alt="logo"
        width={20}
        height={20}
        onClick={toggleListening}
        className={isDisabled ? "disabled cursorHover" : "cursorHover"}
      />
    </div>
  )
}

export default VoiceMode

import React, { useState } from "react"
import {
  Box,
  Button,
  Typography,
} from "src/UILibrary"
import {
  signupDOBState,
  signupEmailState,
  signupFirstNameState,
  signupGenderState,
  signupIDState,
  signupLastNameState,
  signupPasswordState,
  signupPhoneNumberState,
} from "src/states/signup"
import { useRecoilState } from "recoil"
import { CodeInput } from "src/components/codeInput"
import { sendVerificationCodeToEmail, sendVerificationCodeToPhone, submitVerificationCode } from "src/api/signupService"
import { useRecoilValue } from "recoil"
import { currentLangState } from "src/states/signup"
import { IPersonalDetails } from "src/types/account"
import { useUserAuth } from "src/pages/context/userAuth/userAuthContext"
import { USER_TYPE } from "src/constants/common"

type TOtpTypes = 'emailOTP' | 'phoneOTP';
export const SelectOTP = () => {
  const currentLang = useRecoilValue(currentLangState)
  const [phoneNumber] = useRecoilState(signupPhoneNumberState)
  const [email] = useRecoilState(signupEmailState)
  const [firstName] = useRecoilState(signupFirstNameState)
  const [password] = useRecoilState(signupPasswordState)
  const [lastName] = useRecoilState(signupLastNameState)
  const [id] = useRecoilState(signupIDState)
  const [dob] = useRecoilState(signupDOBState)
  const [gender] = useRecoilState(signupGenderState)
  const [mode, setMode] = useState<TOtpTypes>("emailOTP")
  const [isBtnSent, setIsBtnSent] = useState<boolean>(true);
  const { login } = useUserAuth();

  const personalDetails: IPersonalDetails = {
    // id,
    email,
    phoneNumber,
    firstName,
    lastName,
    dob: dob || new Date(),
    gender
  }

  const submitCode = async (otp: string) => {
    try {
      const res = await submitVerificationCode({
        ...personalDetails,
        password,
        otp,
      })

      login(
        {
          token: res.data.jwt_token,
          csrfToken: res.data.csrf_token,
          csrfRefreshToken: res.data.refresh_csrf_token,
          refreshToken: res.data.refresh_token
        },
        {
          id: res.data.user_id,
          email: email,
          type: USER_TYPE.patient
        }
      );

    } catch (e) {
      console.error("Err in Submit", e)
      throw new Error("Err in submit")
    }
  }

  const handleResend = () => {
    if (mode === "emailOTP") {
      sendVerificationCodeToEmail(personalDetails);
    } else {
      sendVerificationCodeToPhone(personalDetails);
    }
    setIsBtnSent(true);
  }

  const handleSwitch = () => {
    setIsBtnSent(false);
    setMode(mode === "emailOTP" ? "phoneOTP" : "emailOTP")
  }

  const renderCodeInputs = () => {
    switch (mode) {
      case "emailOTP":
        return (
          <CodeInput
            serviceCall={submitCode}
            verificationType="email"
            target={email}
            redirectUrl="/home"
            onResend={handleResend}
            switchToOther={handleSwitch}
          />
        )
      case "phoneOTP":
        return (
          <CodeInput
            serviceCall={submitCode}
            verificationType="phone"
            target={phoneNumber}
            redirectUrl="/home"
            onResend={handleResend}
            switchToOther={handleSwitch}
          />
        )
      default:
        return <></>
    }
  }

  return (
    <>
      {isBtnSent ? (
        renderCodeInputs()
      ) : (
        <>
          <Box>
            <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
              <Typography.DetailHeading sx={{ mb: "1rem", width: "18.75rem", textAlign: "center" }}>
                {mode === "emailOTP"
                  ? currentLang["we_will_send_an_activation_code_to_your_email_address"]
                  : mode === "phoneOTP"
                    ? currentLang["we_will_send_an_sms_with_a_activation_code_to_your_phone_number"]
                    : null}{" "}
                {mode === "emailOTP" ? email : mode === "phoneOTP" ? phoneNumber : null}
              </Typography.DetailHeading>
            </Box>
            <Button
              role="submit"
              fullWidth
              type="submit"
              sx={{ mt: "3.375rem", mb: "1.375rem" }}
              onClick={handleResend}
            >
              {currentLang["send"]}
            </Button>

            <Button
              fullWidth
              role="link"
              onClick={handleSwitch}
              className="sendCodeViaButton Body3Semibold"
            >
              {mode === "emailOTP"
                ? currentLang["send_code_via_sms"]
                : mode === "phoneOTP"
                  ? currentLang["send_code_via_email"]
                  : null}
            </Button>
          </Box>
        </>
      )}
    </>
  )
}

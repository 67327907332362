import React from "react"
import { TextField as MuiTextField, TextFieldProps } from "@mui/material"
import { BASE_COLORS } from "src/themes/colors"
import useIsRtl from "src/hooks/useIsRtl"

export interface IInitialType {
  initialType?: string
  forceLtr?: boolean
  startIcon?: any
}

export const TextField = ({
  sx,
  size,
  value,
  initialType,
  forceLtr = false,
  disabled,
  error,
  inputRef,
  type,
  startIcon,
  autoComplete = "on",
  ...rest
}: TextFieldProps & IInitialType) => {
  const isRtl = useIsRtl()

  return (
    <MuiTextField
      inputRef={inputRef}
      sx={{
        "& .MuiOutlinedInput-root": {
          borderRadius: "50px",
          backgroundColor: "#F2F4F7 !important",
          // border: "1px solid",
          // borderColor: error ? BASE_COLORS.error[500] : BASE_COLORS.gray[300],
          color: "black",
          "& fieldset": {
            border: "none !important",
            borderWidth: "0",
            borderStyle: "solid",
            borderColor: BASE_COLORS.gray[300],
          },
          // "&:hover fieldset": {
          //   boxShadow: disabled
          //     ? "none"
          //     : "0px 0px 0px 4px #E8F8FF, 0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
          // },
          // "&.Mui-focused fieldset": {
          //   border: "1px solid",
          //   borderColor: BASE_COLORS.primary[300],
          //   boxShadow: "0px 0px 0px 4px #E8F8FF, 0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
          // },
          "&.MuiInputBase-multiline": {
            p: "0.5rem",
          },
          "&.Mui-error": {
            margin: 0,
          },
          // "&.Mui-error fieldset": {
          //   border: "1px solid",
          //   borderColor: BASE_COLORS.error[300],
          // },
          // "&.Mui-focused.Mui-error fieldset": {
          //   boxShadow: "0px 0px 0px 4px #FEE4E2, 0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
          // },
          // "&:hover.Mui-error fieldset": {
          //   boxShadow: "0px 0px 0px 4px #FEE4E2, 0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
          // },
        },
        "& p": {
          margin: 0,
        },
        "& input": {
          fontSize: "1rem",
          p: startIcon && !isRtl ? "0.625rem 0" : "0.625rem 1rem",
          borderRadius: "0.5rem",
          fontWeight: 400,
          lineHeight: "1.5rem",
          direction: isRtl && !forceLtr ? "rtl" : "ltr",
          textAlign: isRtl ? "right" : "left",
        },
        "& textarea": {
          fontSize: "0.875rem",
          lineHeight: "1.25rem",
          fontWeight: 400,
        },
        "& .MuiFormHelperText-root": {
          fontSize: "0.8125rem",
          fontStyle: "normal",
          fontWeight: error ? 900 : 400,
          lineHeight: "1.25rem",
          ml: "1rem",
          color: error ? BASE_COLORS.error[500] : BASE_COLORS.gray[400],
          textAlign: isRtl ? "right" : "left",
          direction: isRtl ? "rtl" : "ltr",
        },

        ...sx,
      }}
      variant="outlined"
      value={value}
      disabled={disabled}
      autoComplete={autoComplete}
      error={error}
      type={type}
      {...rest}
    />
  )
}

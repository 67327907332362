import { axiosInstance } from "src/modules/axios"
import { useChat } from "../chatContext"
import { IActionsContext, TActions } from "../chatContext.types"

export const useActions = (): IActionsContext => {
  const { userID } = useChat()

  const send = async (action: TActions, group_name: string): Promise<void> => {
    await axiosInstance
      .post("/authenticated/conversation/" + action, {
        group_name: group_name,
        user_id: userID,
      })
      .catch((err) => console.error(err))
  }

  return {
    send,
  }
}

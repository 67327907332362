import React, { useEffect, useState } from "react";
import "./singleUser.scss";
import { UpdateUserData, fetchUser, showMedicalHistory, validateCode } from "src/api/backoffice";
import GeneratedCodeModal from "../../../components/backoffice/Analytics/generated_code";

import StatusAI from "./StatusAI";
import PhysicianStatusList from "./PhysicianStatusList";
import { Button, FormControl, SelectChangeEvent, StaticImage } from "src/UILibrary";
import Icons from "src/assets/icons";
import { useUserAuth } from "src/pages/context/userAuth/userAuthContext";
import { TextFieldWithLabel } from "src/components/textfieldWithLabel";
import { InputLabel, MenuItem, Select } from "@mui/material";
import UploadMedicalHistory from "src/components/backoffice/Analytics/uploadMedicalHistory";
import { useRecoilValue } from "recoil";
import { currentLangIDtate, currentLangState } from "src/states/signup";
import { USER_TYPE } from "src/constants/common";
import GenerateCode from "src/components/backoffice/Analytics/medicalHistory";

const initialUserData: UserData = {
  id: '',
  first_name: '',
  last_name: '',
  user_type_id: 0,
  phone_number: '',
  email: '',
  clinic_name: '',
};

interface UserData {
  id: string;
  first_name: string;
  last_name: string;
  user_type_id: number;
  phone_number: string;
  email: string;
  clinic_name: string;
}

interface UserInterface {
  id: string;
  first_name: string;
  last_name: string;
  avatar: string;
  user_type_id: number;
  phone_number: string;
  email: string;
  clinic_name: string;
  signature: boolean;
  gender: string;
}

interface UserProps {
  id: string;
  setSelectedUserId: any;
  checkbox?: UserInterface[];
}

const User: React.FC<UserProps> = ({ id, setSelectedUserId, checkbox }) => {
    const currentLang = useRecoilValue(currentLangState)
    const currentLangID = useRecoilValue(currentLangIDtate)
    const [edit, setEdit] = useState(false);
    const [userData, setUserData] = useState<UserData>(initialUserData);
    const {user} = useUserAuth();
    const currentUserId = user.id;
    const currentUserType = user.type;
    const [newEmail, setNewEmail] = useState("")
    const [firstName, setFirstName] = useState("")
    const [clinicName, setClinicName] = useState("")
    const [lastName, setLastName] = useState("")
    const [phone, setPhone] = useState("")
    const [userType, setUserType] = useState<number>();
    const [isModalOpen, setModalOpen] = useState(false);
    const [isUploadOpen, setIsUploadOpen] = useState(false); 
    const [generatedCode, setGeneratedCode] = useState('');
    const [isDirty, setIsDirty] = useState(false);
    const [medicalFiles, setMedicalFiles] = useState<string[]>([]);
  
  
    const formatFieldNameToLabel = (fieldName: string, currentLang: Record<string, string>) => {
      if (fieldName === 'user_type_id') {
        return currentLang['user_role'] || 'User Role';
      }
      const translatedFieldName = currentLang[fieldName.toLowerCase()] || fieldName;
      return translatedFieldName
    };

    const handleEditClick = () => {
      setEdit(!edit)
    };

    const medicalHistoryList = async() => {
      try {
        const list = await showMedicalHistory(id)
        setMedicalFiles(list)
      } catch (error) {
        console.error("Error uploading medical history list")
      }
    };

    const handleUserTypeChange = (newUserType:number) => {
      setUserData((prevUserData) => ({
        ...prevUserData,
        "user_type_id": newUserType,
      }));
    };
  
    const handleSaveClick = () => {
      const newUserType = userData["user_type_id"];
      setUserType(newUserType)
      console.log(id,firstName, lastName, newEmail,  phone, clinicName, userType)
      updateUserData(id, firstName, lastName, newEmail, phone, clinicName, userType)
        .then(() => {setEdit(!edit); setIsDirty(false)})
        .catch(error => {console.error("Error updating user data:", error)});
    };

    const updateUserData = async (id: string, first_name: string, last_name: string, new_email: string, phone: string, clinic_name: string | undefined, user_type: number | undefined ) => {
      try {
        const data = await UpdateUserData(id, first_name, last_name, new_email, phone, clinic_name, user_type)
      } catch (error) {
        console.error("Error updating user data")
      }
    }
  
    const getUser = async (id: string | undefined) => {
      try {
        if (id) {
          const data: any = await fetchUser(id);
          console.log("DATA",data)
          console.log("CHECKBOX",checkbox)
          if (data?.status === "success") {
            setUserData(data);
            setFirstName(data["first_name"]);
            setLastName(data["last_name"]);
            setNewEmail(data["email"]);
            setPhone(data["phone_number"]);
            setClinicName(data["clinic_name"]);
            setUserType(parseInt(data["user_type_id"], 10));
          } else {
            console.error("Error fetching user data");
          }
        }
      } catch (error) {
        console.error("Error fetching user data", error);
      }
    };

    const generateRandomCode = () => {
      const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
      let code = '';
    
      for (let i = 0; i < 6; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        code += characters.charAt(randomIndex);
      }
      return code;
    };
    
    const handleGenerateClick = async (id: string) => {
      const randomCode = generateRandomCode();
      try {
        if (randomCode) {
          const data: any = await validateCode(id, randomCode);
          console.log(data);
          if (data?.status_code === 1027) {
            setGeneratedCode(randomCode);
            setModalOpen(true);
          } else {
            setGeneratedCode("error");
            setModalOpen(true)
            console.error("Error validating code");
          }
        }
      } catch (error) {
        setGeneratedCode("error");
        setModalOpen(true)
        console.error("Error validating code", error);
      }
    };

    useEffect(() => {
      getUser(id);
      medicalHistoryList();
    }, []);
    

    useEffect(() => {
      getUser(id);
    }, [edit]);

    useEffect(() => {
      medicalHistoryList();
    }, [isUploadOpen, isModalOpen]);

    const desiredOrder = ["first_name", "last_name", "email", "phone_number", "clinic_name", "user_type_id"];

    const UserTypeDropdown: React.FC<{ edit: any; userType: number | undefined; onChange: (newUserType: number) => void }> = ({ userType, onChange }) => {

      const handleClick = (event: SelectChangeEvent<number>) => {
        if (edit && currentUserType === 3) {
          const newUserType = parseInt(event.target.value as string, 10)
          setUserType(newUserType)
          onChange(newUserType);
        }
      };

      const userTypes = [
        { id: 3, label: currentLang["admin"] },
        { id: 2, label: currentLang["physician"] },
        { id: 1, label: currentLang["patient"] },
        { id: 5, label: currentLang["moderator"] },
      ];
    
      return (
        <FormControl sx={{marginTop: '20px'}}>
          <InputLabel sx={{marginTop: '-10px',fontWeight: "510", fontSize: "18px", color:"#344054", right: `${currentLangID === 2 ? '0 !important' : ''}`, left: `${currentLangID === 2 ? 'auto !important' : ''}` }}>{currentLang["user_role"]}</InputLabel>
            <Select value={userType} onChange={handleClick} sx={{ borderRadius: '100px', color: 'black',backgroundColor: "#F2F4F7", height: "40px", boarderColor: "#F2F4F7", marginTop: '8px', direction: `${currentLangID === 2 ? 'rtl' : 'ltr'}`}}>
              {userTypes.map((type) => (
                <MenuItem key={type.id} value={type.id} disabled={!edit}>
                  {type.label}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      );
    }
    return (
      <>
        <div className="main w-5/6">
        <div className={`user`}>
          <div className="sectionStyles">
          <div className="divStyles">
              <div>
              <h1 className={`pb-6 flex ${currentLangID === 2 ? 'flex-row-reverse' : ''}`}>
                {userData["user_type_id"] === USER_TYPE.admin
                  ? currentLang["admin"]
                  : userData["user_type_id"] === USER_TYPE.physician
                  ? currentLang["physician"]
                  : userData["user_type_id"] === USER_TYPE.moderator
                  ? currentLang["moderator"]
                  : currentLang["patient"]}
              </h1>
              <div className={`mt-10 flex justify-between ${currentLangID === 2 ? 'flex-row-reverse' : ''}`}>
                <div style={{width:"40%"}}>
                {desiredOrder.map((fieldName) => (
                  <div className='grid grid-cols-1 items-baseline pb-4 border-y' key={fieldName}>
                    {edit && 
                    fieldName !== "user_type_id" ? (
                      <TextFieldWithLabel
                        label={formatFieldNameToLabel(fieldName, currentLang)}
                        placeholder={`${currentLang["type"]} ${formatFieldNameToLabel(fieldName, currentLang).toLowerCase()}`}
                        type="email"
                        value={fieldName === "first_name"
                                ? firstName
                                : fieldName === "last_name"
                                ? lastName
                                : fieldName === "email"
                                ? newEmail
                                : fieldName === "phone_number"
                                ? phone
                                : clinicName}
                        onChange={(e:any) => {
                                switch (fieldName) {
                                  case "email":
                                    setNewEmail(e.target.value)
                                    break
                                  case "first_name":
                                    setFirstName(e.target.value)
                                    break
                                  case "last_name":
                                    setLastName(e.target.value)
                                    break
                                  case "phone_number":
                                    setPhone(e.target.value)
                                    break
                                  case "clinic_name":
                                    setClinicName(e.target.value)
                                    break
                                }
                                setIsDirty(true)}}
                                className={fieldName === "phone_number" ? "ltr-direction" : ""}
                    /> 
                    ) : !edit && (
                    <div key={fieldName}>
                        <TextFieldWithLabel
                          label={formatFieldNameToLabel(fieldName, currentLang)}
                          placeholder={`${currentLang['type']} ${formatFieldNameToLabel(fieldName, currentLang).toLowerCase()}`}
                          type="email"
                          value={
                            fieldName === "user_type_id"
                              ? userData["user_type_id"] === USER_TYPE.admin
                                ? currentLang["admin"]
                                : userData["user_type_id"] === USER_TYPE.physician
                                ? currentLang["physician"]
                                : userData["user_type_id"] === USER_TYPE.moderator
                                ? currentLang["moderator"]
                                : currentLang["patient"]
                              : userData[fieldName as keyof UserData]
                          }
                          forceLtr={fieldName === "phone_number" ? true : false}
                        />    
                    </div>
                    )} 
                    {(currentUserType == USER_TYPE.admin) && fieldName === "user_type_id" && edit &&
                      <UserTypeDropdown edit={edit} userType={userType} onChange={handleUserTypeChange} />
                    }
                  </div>
                ))}
                 {((userData["user_type_id"] === USER_TYPE.patient)) ? (      
                  <Button role="submit" type="submit" onClick={() => handleGenerateClick(userData["id"])} style={{marginTop: "20px"}}>
                    <StaticImage width={20} height={20} src={Icons.flash} alt="flash" style={{ paddingRight: "4px" }} />
                    {currentLang["generate_code"]}
                  </Button>
                ) : (<></>)}
                <div style={{direction: `${currentLangID === 2 ? 'rtl' : 'ltr'}`, height: "90%"}}>
                {(!edit && currentUserType == USER_TYPE.physician && userType == USER_TYPE.patient) && 
                <>
                  <StatusAI 
                    currentUserType={currentUserType} 
                    userType={userType} 
                    currentUserId={currentUserId} 
                    id={id}
                    refresh={isModalOpen} />
                  <p className="text-black pt-10" style={{direction: currentLangID === 2 ? 'rtl' : 'ltr'}}>{currentLang["medical_files"]}</p>
 <ul style={{ width: currentLangID === 2 ? 'fit-content' : 'auto' }}>
                    {medicalFiles.map((file, index) => (
                      <li key={index}>{file}</li>
                    ))}
                  </ul>
                  <Button role="submit" type="submit" onClick={() => setIsUploadOpen(true)} style={{marginTop: "20px", direction: currentLangID === 2 ? 'rtl' : 'ltr'}}>
                    <StaticImage width={20} height={20} src={Icons.filePlus} alt="flash" style={{ paddingRight: currentLangID === 2 ? '' : '4px', paddingLeft: currentLangID === 2 ? '4px' : '' }} />
                    {currentLang["upload_medical_history"]}
                  </Button>
                  </>
                  }

                {(!edit && (currentUserType == USER_TYPE.admin || currentUserType == USER_TYPE.moderator)  && userType == USER_TYPE.patient) && 
                <>
                  <PhysicianStatusList 
                    patient_id={id}
                    userType={userType} 
                    currentUserId={currentUserId} 
                    currentUserType={currentUserType}
                    refresh={isModalOpen} 
                  />
                  <p className="text-black" style={{direction: currentLangID === 2 ? 'rtl' : 'ltr'}}>{currentLang["medical_files"]}</p>
                  <ul style={{ width: currentLangID === 2 ? 'fit-content' : 'auto' }}>
                    {medicalFiles.map((file, index) => (
                      <li key={index}>{file}</li>
                    ))}
                  </ul>
                  <Button role="submit" type="submit" onClick={() => setIsUploadOpen(true)} style={{marginTop: "20px", direction: currentLangID === 2 ? 'rtl' : 'ltr'}}>
                    <StaticImage width={20} height={20} src={Icons.filePlus} alt="flash" style={{ paddingRight: currentLangID === 2 ? '' : '4px', paddingLeft: currentLangID === 2 ? '4px' : '' }} />
                    {currentLang["upload_medical_history"]}
                  </Button>
                </>}
                </div>
                </div>
                <div className="flex">
                  {!edit &&
                  <div className="flex flex-col justify-between">
                    <Button role="normal" type="submit" onClick={() => handleEditClick()}>
                      <StaticImage width={20} height={20} src={Icons.pen} alt="pen" style={{ paddingRight: "4px" }} />
                      {currentLang["edit"]}
                    </Button>  
                    <Button role="normal" type="submit" onClick={() => setSelectedUserId(null)}>
                      {currentLang["close"]}
                    </Button>
                  </div>}
                  {edit &&
                    <div className="flex gap-5 self-end">
                      <Button role="normal" type="submit" onClick={() => handleEditClick()}>
                        {currentLang["cancel"]}
                      </Button>
                      <Button role="submit" type="submit" onClick={() => handleSaveClick()} disabled={!isDirty}>
                        {currentLang["save"]}
                      </Button>
                    </div>}
                </div>
                </div>
               
              </div>
            </div>
          </div>
        </div>
        {isModalOpen && 
                    <GenerateCode
                      isOpen={isModalOpen}
                      onRequestClose={() => setModalOpen(false)}
                      generatedCode={generatedCode}
                      id={userData["id"]}
                      {...(currentUserType === USER_TYPE.admin || currentUserType === USER_TYPE.moderator ? { checkbox: checkbox } : {})}
                    />
        }
        {isUploadOpen && 
            <UploadMedicalHistory
              isOpen={isUploadOpen}
              onRequestClose={() => setIsUploadOpen(false)}
              id={userData["id"]}
              // {...(currentUserType === USER_TYPE.admin || currentUserType === USER_TYPE.moderator ? { checkbox: checkbox } : {})}
            />
        }
        </div>
      </>
    );
  };
  
  export default User;

import React, { useState } from "react"
import { Button, StaticImage } from "src/UILibrary"
import Icons from "src/assets/icons"
import Container from "src/components/container"
import { TextFieldWithLabel } from "src/components/textfieldWithLabel"
import { axiosInstance } from "src/modules/axios"
import { useChat } from "../context/chatContext"
import { useConnect } from "../context/hooks/useConnect"
import { BASE_COLORS } from "src/themes/colors"
import { getUserGroupName, getUserInitialsFromFullName } from "../utils"
import { Dialog } from "src/UILibrary/Dialog/Dialog"
import { currentLangIDtate, currentLangState } from "src/states/signup"
import { useRecoilValue } from "recoil"
import { useSocketIo } from "../context/hooks/useSocketIO"
import { ConversationHandle, ConversationMessageTypes } from "src/constants/enums"
import { useUserAuth } from "src/pages/context/userAuth/userAuthContext"

export function PhysicianCodeInput({fetchChatGroupsNoLoading}: any) {
  const { chatGroups, setShowPhysicianCodeInput, userID, userName, userType } = useChat()
  const { user } = useUserAuth()
  const [stage, setStage] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const { sendMessage } = useSocketIo()
  const [loading, setLoading] = useState<boolean>(false)
  const currentLang = useRecoilValue(currentLangState)
  const currentLangID = useRecoilValue(currentLangIDtate)
  const [photo, setPhoto] = useState<any>()
  const [doctorName, setDoctorName] = useState("")
  const [physicianCodeInput, setPhysicianCodeInput] = useState("")
  const [physicianCodeHasError, setPhysicianCodeHasError] = useState(false)
  const [physicianCodeHelperText, setPhysicianCodeHelperText] = useState(
    currentLang["enter_the_code_provided_by_your_physician/clinic"]
  )

  const fetchUserName = async (userID: string) => {
    try {
    } catch (err) {
      console.log(err)
    }
    const res = await axiosInstance
      .post("authenticated/conversation/get_info_by_user_id", {
        user_id: userID,
        lang: currentLangID === 1 ? "en" : "ar",
      })
      .catch((err) => console.log(err))
    if (res) {
      let finalName
      if (res.data.first_name && res.data.last_name) {
        setDoctorName(res.data.first_name.trim() + " " + res.data.last_name.trim())
        finalName = res.data.first_name.trim() + " " + res.data.last_name.trim()
      } else if (res.data.first_name && !res.data.last_name) {
        setDoctorName(res.data.first_name.trim())
        finalName = res.data.first_name.trim()
      } else if (!res.data.first_name && res.data.last_name) {
        setDoctorName(res.data.last_name.trim())
        finalName = res.data.last_name.trim()
      }
      for (let i = 0; i < chatGroups.length; i++) {
        const chatGroup = chatGroups[i]
        if (chatGroup.theirName.trim() === finalName.trim()) {
          return false
        }
      }
    }
    return true
  }

  const getPhoto = (userID: string) => {
    axiosInstance
      .post(
        "authenticated/settings/get_profile_photo",
        {
          user_id: userID,
        },
        {
          responseType: "blob",
        }
      )
      .then((data: any) => {
        const url = URL.createObjectURL(data.data)
        setPhoto(url)
      })
      .catch((err) => console.error(err))
  }

  const validatePhysicianCodeInput = (codeInput: string) => {
    setLoading(true)
    axiosInstance
      .post("authenticated/conversation/is_patient_code_exist", {
        patient_email: user.email,
        patient_code: codeInput,
      })
      .then(async (data) => {
        const res = await fetchUserName(data.data.user_id_physician)
        if (res) {
          getPhoto(data.data.user_id_physician)
          setStage(1)
          setPhysicianCodeHasError(false)
          setPhysicianCodeHelperText(
            currentLang["enter_the_code_provided_by_your_physician_or_clinic"]
          )
        } else {
          setPhysicianCodeHasError(true)
          setPhysicianCodeHelperText(currentLang["doctor_already_exists"])
        }
      })
      .catch((err) => {
        console.error(err)
        setPhysicianCodeHasError(true)
        setPhysicianCodeHelperText(currentLang["wrong_code_try_again"])
      })
      .finally(() => setLoading(false))
  }

  const processPhysicianCodeInput = (codeInput: string) => {
    if (!codeInput) return
    console.log(codeInput, user.id)

    setIsLoading(true)
    axiosInstance
      .post("authenticated/conversation/use_patient_code", {
        patient_code: codeInput,
        patient_email: user.email,
      })
      .then((response) => {
        // const firstGroupFetchGroups = {
        //   date_time: new Date().toUTCString(),
        //   user_name: userName,
        //   user_id: userID,
        //   group_name: getUserGroupName(user.id),
        //   message_type_id: ConversationMessageTypes.fetchGroups,
        //   message: "",
        //   file_name: "",
        //   lang: currentLangID === 1 ? "en" : "he",
        //   conversation_handler: ConversationHandle.DEFAULT,
        // }
                // sendMessage(firstGroupFetchGroups)
        fetchChatGroupsNoLoading();
        const secondGroupFetchGroups = {
          date_time: new Date().toUTCString(),
          user_name: userName,
          user_id: userID,
          group_name: getUserGroupName(response.data.user_id_physician),
          message_type_id: ConversationMessageTypes.fetchGroups,
          message: "",
          file_name: "",
          lang: currentLangID === 1 ? "en" : "he",
          conversation_handler: ConversationHandle.DEFAULT,
        }
        sendMessage(secondGroupFetchGroups)
      })
      .catch((error) => {
        console.error(error)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return (
    <>
      {stage === 0 ? (
        <Dialog>
          <div className="physcianCodeInput">
            <Container.Column sx={{ gap: "16px" }}>
              <div className="userPlusBlueWrapper">
                <div className="userPlusBlueContainer">
                  <StaticImage src={Icons.userPlusBlue} alt="logo" />
                </div>
              </div>
              <div>
                <h2>{currentLang["add_physician_code"]}</h2>
                <TextFieldWithLabel
                  type="text"
                  label={currentLang["enter_code"]}
                  placeholder={currentLang["enter_code"]}
                  value={physicianCodeInput}
                  onChange={(e) => setPhysicianCodeInput(e.target.value)}
                  error={!!physicianCodeHasError}
                  helperText={physicianCodeHelperText}
                />
              </div>
            </Container.Column>
            <Container.Column sx={{ gap: "14px" }}>
              <Button
                role="submit"
                disabled={loading}
                isLoading={loading}
                onClick={() => validatePhysicianCodeInput(physicianCodeInput)}
              >
                {currentLang["continue"]}
              </Button>
              <Button role="normal" onClick={() => setShowPhysicianCodeInput(false)}>
                {currentLang["cancel"]}
              </Button>
            </Container.Column>
          </div>
        </Dialog>
      ) : (
        <Dialog>
          <div className="physcianCodeInput" style={{ position: "relative" }}>
            <StaticImage
              src={Icons.backArrow}
              alt="logo"
              width={6}
              height={12}
              sx={{ position: "absolute", top: "1rem", left: "1rem", cursor: "pointer" }}
              onClick={() => setStage(0)}
            />
            <Container.Column sx={{ gap: "16px" }} alignItems="center">
              {photo ? (
                <StaticImage className="avatar" src={photo} alt="logo" width={48} height={48} />
              ) : (
                <div className="avatar">{getUserInitialsFromFullName(doctorName)}</div>
              )}
              <Container.Column gap={2}>
                <h2>{currentLang["dr"] + " " + doctorName}</h2>
                <p>{currentLang["will_be_added_to_your_chat_list"]}</p>
              </Container.Column>
            </Container.Column>
            <Button
              role="submit"
              disabled={isLoading}
              isLoading={isLoading}
              onClick={() => processPhysicianCodeInput(physicianCodeInput)}
            >
              {currentLang["add"]}
            </Button>
            {physicianCodeHasError && (
              <p style={{ color: BASE_COLORS.error[300] }}>{physicianCodeHelperText}</p>
            )}
          </div>
        </Dialog>
      )}
    </>
  )
}


import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useUserAuth } from "../context/userAuth/userAuthContext";
import { USER_TYPE } from "src/constants/common";

export const SocialLogin = () => {
    const location = useLocation()
    const navigate = useNavigate();
    const { login } = useUserAuth();

    useEffect(() => {
        if (location.search) {
            setUserHeaders();
        } else {
            navigate('error/social')
        }
    }, [])

    const setUserHeaders = async () => {
        const searchParams = new URLSearchParams(location.search);
        if (searchParams.has("token") && searchParams.has("user_id")) {

            login(
                {
                    token: searchParams.get("token") || "",
                    refreshToken: searchParams.get("refresh_token") || "",
                    csrfToken: searchParams.get("csrf-token")?.replace(/ /g, '+') || "",
                    csrfRefreshToken: searchParams.get("refresh_csrf_token")?.replace(' ', '+') || "",
                },
                {
                    id: searchParams.get("user_id") || "",
                    email: searchParams.get("email") || "",
                    type: USER_TYPE.patient
                }
            );
        }
    }

    return <></>
}
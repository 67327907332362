import React from "react"
import { PersonalDetailsForm } from "../signup/components/personalDetailsForm"
import { socialSignUpWithDetails } from "src/api/signupService"
import { useRecoilState } from "recoil"
import { useLocation } from "react-router-dom"
import {
  signupDOBState,
  signupEmailState,
  signupGenderState,
  signupIDState,
  signupPhoneNumberState,
} from "src/states/signup"
import LoginReg from "src/components/cards/LoginReg"
import { useUserAuth } from "../context/userAuth/userAuthContext"
import { USER_TYPE } from "src/constants/common"

export const SocialAdditinoalInfo = () => {
  const [phoneNumber] = useRecoilState(signupPhoneNumberState)
  const [email] = useRecoilState(signupEmailState)
  const [id] = useRecoilState(signupIDState)
  const [dob] = useRecoilState(signupDOBState)
  const [gender] = useRecoilState(signupGenderState)
  const { login } = useUserAuth();
  const location = useLocation();

  const onSocialSignUpWithDetails = async () => {
    const searchParams = new URLSearchParams(location.search);
    const res = await socialSignUpWithDetails({ id, email, dob, gender, phoneNumber })

    login(
      {
        token: res.data.jwt_token,
        csrfToken: res.data.csrf_token,
        csrfRefreshToken: res.data.refresh_csrf_token,
        refreshToken: res.data.refresh_token
      },
      {
        id: res.data.user_id,
        email: searchParams.get('email') || email,
        type: USER_TYPE.patient
      }
    );
    
  }

  return (
    <LoginReg showLanguageMenu>
      <PersonalDetailsForm onSocialSignUp={onSocialSignUpWithDetails} />
    </LoginReg>
  )
}

// import "./users.scss"
import { GridColDef } from "@mui/x-data-grid"
import React, { useState, useEffect } from "react"
import { useLocation, useParams } from "react-router-dom"
import { useRecoilValue } from "recoil"
import { Button } from "src/UILibrary"
import { fetchPhysicians } from "src/api/backoffice"
import DataTable from "src/components/backoffice/Analytics/DataTableUser"
import Instructions from "src/pages/backoffice/instructions_demo/InstructionDemo"
import PhysicianInstructions from "src/pages/backoffice/instructions_demo/PhysicianInstructions"
import { useUserAuth } from "src/pages/context/userAuth/userAuthContext"
import { currentLangIDtate, currentLangState } from "src/states/signup"


interface User {
  id: string;
  first_name: string;
  last_name: string;
  // avatar: string;
  user_type_id: number;
  // phone_number: string;
  // email: string;
  // clinic_name: string;
  // signature: boolean;
  // gender: string;
}


const InstructionsPage = ({setDemo, setAlpha}:any) => {
  const currentLang = useRecoilValue(currentLangState)
  const currentLangID = useRecoilValue(currentLangIDtate)
  const { user } = useUserAuth();
  const currentUserId = user.id;
  const currentUserType = user.type;
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState<User[]>([]);
  const [selectedUserId, setSelectedUserId] = useState();
  const [generalInstructions, setGeneralInstructions] = useState(false);

  const columns: GridColDef[] = [
    {
      field: "user_type_id",
      headerName: currentLang["user_type"],
      type: "string",
      width: 150,
      editable: false,
      valueFormatter: (params) => {
          if (params.value === 3) return currentLang["admin"];
          if (params.value === 2) return currentLang["physician"];
          if (params.value === 1) return currentLang["patient"];
          if (params.value === 5) return currentLang["moderator"];
          return params.value;
        },
    },
    { field: "id", headerName: "ID", width: 90 },
    // {
    //   field: "avatar",
    //   headerName: "Avatar",
    //   width: 100,
    //   renderCell: (params) => {
    //     return <img src={params.row.img || "/noavatar.png"} alt="" />
    //   },
    // },
    {
      field: "first_name",
      headerName: currentLang["first_name"],
      type: "string",
      width: 150,
      editable: false,
    },
    {
      field: "last_name",
      headerName: currentLang["last_name"],
      type: "string",
      width: 150,
      editable: false,
    },
  
    // {
    //   field: "phone_number",
    //   headerName: "Phone number",
    //   description: "This column has a value getter and is not sortable.",
    //   type: "string",
    //   sortable: false,
    //   width: 160,
    // },
    // {
    //   field: "email",
    //   headerName: "Email",
    //   description: "This column has a value getter and is not sortable.",
    //   type: "string",
    //   sortable: false,
    //   width: 160,
    // },
    // {
    //   field: "clinic_name",
    //   headerName: "Clinic Name",
    //   description: "This column has a value getter and is not sortable.",
    //   type: "string",
    //   sortable: true,
    //   width: 160,
    // },
    // {
    //   field: "signature",
    //   headerName: "Signature",
    //   width: 80,
    //   type: "boolean",
    //   valueGetter: (params) => params.row.signature ? true : false
    // },
    // {
    //   field: "gender",
    //   headerName: "Gender",
    //   description: "This column has a value getter and is not sortable.",
    //   type: "singleSelect",
    //   valueOptions: ["male", "female", "other"],
    //   editable: true,
    //   valueFormatter: (params) => {
    //     if (params.value === ("male" || "Male")) return "M";
    //     if (params.value === ("female" || "Female")) return "F";
    //     if (params.value === ("other" || "Other")) return "O";
    //     return params.value;
    //   },
    // },
  ]

  const getUsers = async () => {
    try {
      setLoading(true);
      const data: any = await fetchPhysicians();
      console.log(data);
      if (data.status === "success") {
        const fetchedUserRows = data.physicians;
        setUsers(fetchedUserRows);
      } else {
        console.error("Error fetching users:", data.message);
      }
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getUsers();
  }, [currentUserType]);

  if (selectedUserId) {
    return <PhysicianInstructions id={selectedUserId} setSelectedUserId={setSelectedUserId}/>
  }
  if (generalInstructions) {
    return <Instructions setGeneralInstructions={setGeneralInstructions}/>
  }

  return (
    <div className="users">
      <div className="sectionStyles">
        <div className="divStyles">
          <div className="containerStyles_2">
            <div className={`info flex justify-between ${currentLangID === 2 ? 'rtl' : ''}`}>
              <h1>{currentLang["physicians"]}</h1>
              <Button role="submit" type="submit" onClick={() => setGeneralInstructions(true)} sx={{ marginBottom: "1rem" }}>
                  {currentLang["general_instructions"]}
              </Button>
              <Button role="normal" type="submit" onClick={() => {setDemo(true); setAlpha(false)}}>
            Switch to Demo
          </Button>
            </div>
            {loading ? (
              <div className="loading-spinner"></div>
            ) : (
                <DataTable
                  setSelectedUserId={setSelectedUserId}
                  currentUserId={currentUserId}
                  currentUserType={currentUserType}
                  slug="physician"
                  columns={columns}
                  rows={users}
                  onDelete={getUsers}
                />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InstructionsPage

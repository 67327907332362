import { useEffect, useRef, useState } from "react"
import { IAudioContext } from "../chatContext.types"
import { axiosInstance } from "src/modules/axios"
import { SOUNDS } from "src/assets/sounds"

export const useAudio = (): IAudioContext => {
  const [isAudioPlaying, setIsAudioPlaying] = useState<boolean>(false)
  const [aiAudioStack, setAiAudioStack] = useState<any[]>([])
  const [isRecordingConversationMode, setIsRecordingConversationMode] = useState<boolean>(false)
  const [isRecordingAudioMode, setIsRecordingAudioMode] = useState<boolean>(false)
  const [audioMode, setAudioMode] = useState<boolean>(false)
  const currentAudioRef = useRef<any>()
  const [tokenObj, setTokenObj] = useState<any>();

  useEffect(() => {
    const fetchToken = async () => {
      const token = await getTokenOrRefresh()
      if (token) {
        setTokenObj(token)
      }
    }
    fetchToken()
  }, [])

  useEffect(() => {
    playNextAudio()
  }, [aiAudioStack, isAudioPlaying])

  const playNextAudio = async () => {
    if (!isAudioPlaying && aiAudioStack.length) {
      const audio = new Audio(aiAudioStack.pop())
      currentAudioRef.current = audio
      await currentAudioRef.current.play()
      setIsAudioPlaying(true)
      audio.onended = function () {
        setIsAudioPlaying(false)
      }
    }
  }

  const stopCurrentAudio = () => {
    if (currentAudioRef.current) {
      currentAudioRef.current.pause()
      setIsAudioPlaying(false)
    }
  }

  const appendAudioMessage = async (message: string) => {
    if (message.length === 0) return
    try {
      const audioLog = await axiosInstance.post(
        "/authenticated/conversation/get_openai-tts",
        {
          text: message,
        },
        {
          responseType: "blob",
        }
      )
      const audioUrl = URL.createObjectURL(audioLog.data)
      console.log("audioUrl", audioUrl)
      setAiAudioStack([...aiAudioStack, audioUrl])
    } catch (error) {
      console.error("Error fetching audio:", error)
    }
  }

  const playBeep = (type: string) => {
    let sound
    switch (type) {
      case "start":
        sound = new Audio(SOUNDS.startMic)
        break
      case "end":
        sound = new Audio(SOUNDS.stopMic)
        break
      default:
        break
    }
    sound && sound.play()
  }

  async function getTokenOrRefresh() {
    const subscriptionKey = "c7b16d1398724866b3420ee2ecb93c07"
    const region = "eastus"

    const fetchUrl = `https://${region}.api.cognitive.microsoft.com/sts/v1.0/issueToken`
    const headers = {
      "Ocp-Apim-Subscription-Key": subscriptionKey,
      "Content-Type": "application/x-www-form-urlencoded",
    }

    try {
      const response = await fetch(fetchUrl, {
        method: "POST",
        headers: headers,
      })

      if (!response.ok) {
        throw new Error("Failed to fetch the token, status: " + response.status)
      }

      const authToken = await response.text() // Token is returned as plain text
      return { authToken, region }
    } catch (error) {
      console.error("Error fetching token:", error)
      return false
    }
  }

  return {
    appendAudioMessage,
    aiAudioStack,
    isAudioPlaying,
    stopCurrentAudio,
    playBeep,
    getTokenOrRefresh,
    isRecordingConversationMode,
    setIsRecordingConversationMode,
    isRecordingAudioMode,
    setIsRecordingAudioMode,
    audioMode,
    setAudioMode,
    tokenObj
  }
}

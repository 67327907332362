import React, { useState } from "react"
import { useRecoilValue } from "recoil"
import { StaticImage } from "src/UILibrary"
import Icons from "src/assets/icons"
import Container from "src/components/container"
import { currentLangState } from "src/states/signup"

export function DragDropFile({ handleChangedPhoto, handleDroppedPhoto }: any) {
  const currentLang = useRecoilValue(currentLangState)
  const [dragActive, setDragActive] = useState(false)

  // handle drag events
  const handleDrag = function (e: any) {
    e.preventDefault()
    e.stopPropagation()
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true)
    } else if (e.type === "dragleave") {
      setDragActive(false)
    }
  }

  // triggers when file is dropped
  const handleDrop = function (e: any) {
    e.preventDefault()
    e.stopPropagation()
    setDragActive(false)
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleDroppedPhoto(e.dataTransfer.files[0])
    }
  }

  return (
    <Container.Column
      alignItems="center"
      className={dragActive ? "uploadPhotoContainer drop" : "uploadPhotoContainer"}
      onDragEnter={handleDrag}
      onDragLeave={handleDrag}
      onDragOver={handleDrag}
      onDrop={handleDrop}
    >
      <div className="fileIconWrapper">
        <div className="fileIconContainer">
          <StaticImage src={Icons.uploadCloud} alt="logo" />
        </div>
      </div>
      <span>
        <span className="link" onClick={handleChangedPhoto}>
          {currentLang["click_to_upload"]}
        </span>
        {currentLang["or_drag_and_drop"]}
      </span>
      <p>{currentLang["file_formats_and_size_limit)"]}</p>
    </Container.Column>
  )
}

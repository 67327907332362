import React from "react"
import { Button as MuiButton, ButtonProps } from "@mui/material"
import LoadingSpinner from "src/assets/icons/spinner.svg"
import { BASE_COLORS } from "src/themes/colors"
import { StaticImage } from "./staticImage"

export const Button = ({
  role = "normal",
  isLoading = false,
  children,
  sx,
  endIcon,
  ...rest
}: ButtonProps & {
  role?: "normal" | "submit" | "link" | "action" | "discard" | "voiceMode" | "error"
  isLoading?: boolean
}) => {
  return (
    <MuiButton
      sx={{
        borderRadius: "50px",
        borderWidth: role === "normal" ? "1px" : "0",
        borderStyle: "solid",
        borderColor:
          role === "normal" || role === "action"
            ? "secondary.light"
            : role === "discard"
            ? "text.primary"
            : "primary.main",
        padding: "0.625rem 1rem",
        fontSize: "0.875rem",
        lineHeight: "1.25rem",
        fontWeight: 600,
        textTransform: "none",
        color: role === "submit" || role === "voiceMode" ? "white" : BASE_COLORS.gray[700],
        backgroundColor:
          role === "submit"
            ? "primary.main"
            : role === "action"
            ? "secondary.light"
            : role === "discard"
            ? "text.primary"
            : role === "voiceMode"
            ? BASE_COLORS.blue[500]
            : role === "error"
            ? "#F63D68"
            : "transparent",
        "& .MuiButton-startIcon": {
          mr: 0,
        },
        "&:hover": {
          backgroundColor:
            role === "submit" || role === "voiceMode"
              ? BASE_COLORS.primary[600]
              : role !== "link" && role !== "error"
              ? BASE_COLORS.gray[300]
              : role === "error"
              ? "#F63D68"
              : undefined,
        },
        "&:focus": {
          boxShadow: role !== "link" ? "0px 0px 0px 4px #DEF5FF" : undefined,
          backgroundColor:
            role !== "link" && role !== "error"
              ? BASE_COLORS.primary[500]
              : role === "error"
              ? "#F63D68"
              : undefined,
        },
        "&:disabled": {
          opacity: 0.5,
          color: role === "submit" ? "white" : "#D0D5DD",
        },
        ...sx,
      }}
      disableRipple
      {...rest}
      endIcon={isLoading ? <StaticImage src={LoadingSpinner} alt="spinner" width={20} /> : endIcon}
    >
      {children}
    </MuiButton>
  )
}

import React, { useEffect, useState } from "react"
// import "../../../pages/backoffice/single_user/singleUser.scss";
import "../Analytics/code.scss"
import GeneratedCodeModal from "./generated_code"
import { addMedicalHistory, addPatientCode, fetchUsers } from "src/api/backoffice"
import { Button, StaticImage } from "src/UILibrary"
import Icons from "src/assets/icons"
import { TextFieldWithLabel } from "src/components/textfieldWithLabel"
import LinearDeterminate from "./linear"
import { useRecoilValue } from "recoil"
import { currentLangIDtate, currentLangState } from "src/states/signup"
import { USER_TYPE } from "src/constants/common"
import { useUserAuth } from "src/pages/context/userAuth/userAuthContext"
import { FormControl, InputLabel, ListItemText, MenuItem, Select } from "@mui/material"

interface User {
  id: string;
  first_name: string;
  last_name: string;
  avatar: string;
  user_type_id: number;
  phone_number: string;
  email: string;
  clinic_name: string;
  signature: boolean;
  gender: string;
};

interface PhysicianOption {
  id: string;
  name: string;
};

interface GenerateCodeProps {
  isOpen: boolean;
  onRequestClose: () => void;
  generatedCode: string;
  id: string;
  checkbox?: User[];
}

const GenerateCode = ({ isOpen, onRequestClose, generatedCode, id, checkbox}: GenerateCodeProps) => {
  const currentLang = useRecoilValue(currentLangState)
  const currentLangID = useRecoilValue(currentLangIDtate)
  const [isCodeCopied, setCodeCopied] = useState(false)
  const [options, setOptions] = useState<PhysicianOption[]>([]);
  const { user } = useUserAuth();
  const currentUserId = user.id;
  const currentUserType = user.type;
  const [assignedPhysician, setAssignedPhysician] = useState('');

  const [showForm, setShowForm] = useState(false)

  const addCode = async () => {
    try {
      const response = await addPatientCode(assignedPhysician, id, generatedCode)// user_id_physician, user_id_patient, generated_code
      console.log(response)
    } catch {
      alert(`Error adding a code ${generatedCode}`)
    }
  }

  const handleCopyClick = () => {
    navigator.clipboard
      .writeText(generatedCode)
      .then(() => setCodeCopied(true))
      .catch((error) => console.error("Copy failed", error))
  }

  const fetchData = () => {
    if ((currentUserType === (USER_TYPE.admin) || currentUserType === (USER_TYPE.moderator))&&(checkbox)) {
      const physiciansData = checkbox.filter(user => user.user_type_id === USER_TYPE.physician);
      const formattedPhysicians = physiciansData.map(physician => ({
        id: physician.id.toString(),
        name: `${physician.first_name} ${physician.last_name}`
      }));
      setOptions(formattedPhysicians);
    }
  };

  useEffect(() => {
    if (currentUserType !== USER_TYPE.physician) {
      fetchData();
    }else{
      setAssignedPhysician(currentUserId)
    }
  }, []); 

  return (
    <div className={`code ${isOpen ? "visible" : "hidden"}`}>
      <div className="modal">
        <div className="sectionStyles">
          <span className="close pt-6 pr-6" onClick={onRequestClose}>
            <StaticImage width={24} height={24} src={Icons.closecross} alt="close" />
          </span>
          {(!showForm) && 
            <>
              <img src={Icons.bigFlash} width="48px" className="m-auto"></img>
              <div className="flex justify-between gap-5 flex-col">
              {(currentUserType===USER_TYPE.admin || currentUserType===USER_TYPE.moderator) && 
                <>
                  <h2 className="header mb-4">{currentLang["choose_a_physician"]}</h2>
                  
                  <div className="item">
                        <InputLabel>{currentLang["assigned_physicians"]}</InputLabel>
                        <FormControl fullWidth>
                          <Select
                            value={assignedPhysician}
                            onChange={(e) => setAssignedPhysician(e.target.value)}
                            displayEmpty
                            required
                            sx={{
                              width: '40vw',
                              borderRadius: '40px',
                              color: 'black',
                              backgroundColor: "#F2F4F7",
                              height: "40px",
                              borderColor: "#F2F4F7"
                            }}
                            renderValue={selected => options.find(option => option.id === selected)?.name || ''}
                          >
                            {options.map((option) => (
                              <MenuItem key={option.id} value={option.id}>
                                <ListItemText primary={option.name} />
                              </MenuItem>
                            ))}
                          </Select>
                      </FormControl>
                  </div>  
            </>
            }
            {(currentUserType===USER_TYPE.physician) &&
              <h2 className="header">{currentLang["are_you_ready_to_generate_a_code?"]}</h2>
            }
          <Button
            role="submit"
            type="submit"
            onClick={() => {
              setShowForm(true)
              addCode()
            }}
            fullWidth
          >
            {currentLang["generate_code"]}
          </Button>
        </div>
      </>
      }
      {showForm &&
            <>
              <img src={Icons.bigFlash} width="48px" className="m-auto"></img>
              <h2>{currentLang["single_time_code_for_patient"]}</h2>
              <p className="text-center">{currentLang["share_this_code_with_the_patient"]}</p>
              <h1>{generatedCode}</h1>
              <Button
                role="submit"
                type="submit"
                onClick={() => {
                  handleCopyClick()
                  onRequestClose()
                }}
                disabled={isCodeCopied}
                fullWidth
              >
                {isCodeCopied ? "Code Copied!" : currentLang['copy_and_close']}
              </Button>
            </>
          }
        </div>
      </div>
    </div>
  )
}

export default GenerateCode

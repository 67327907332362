import React from "react"
import { useRecoilValue } from "recoil"
import { Dialog } from "src/UILibrary/Dialog/Dialog"
import { Button } from "src/UILibrary/button"
import Container from "src/components/container"
import { currentLangIDtate, currentLangState } from "src/states/signup"

export const DeleteUserView = ({ close }: any) => {
  const currentLang = useRecoilValue(currentLangState)
  const currentLangID = useRecoilValue(currentLangIDtate)

  return (
    <Dialog>
      <Container.Column className="deleteUserWrapper">
        <h4 className="bold" style={{ textAlign: currentLangID === 2 ? 'right' : 'left'}}>{currentLang["delete_user"]}</h4>
        <h5 className="bold" style={{ textAlign: currentLangID === 2 ? 'right' : 'left'}}>{currentLang["ready_to_say_goodbye"]}</h5>
        <p style={{ textAlign: currentLangID === 2 ? 'right' : 'left'}}>
          {currentLang[
            "we_are_sad_to_see_you_go_but_we_respect_your_decision_to_delete_your_account_and_personal_data_to_initiate_the_process_please_send_an_email_to"
          ] +
            <b>support@life-medic.com</b> +
            currentLang["with_the_subject_line_delete_my_account"]}
        </p>
        <Container.Column
  className="deleteUserContainer"
  style={{
    textAlign: currentLangID === 2 ? 'right' : 'left',
    overflowX: currentLangID === 2 ? "hidden" : undefined,
    // overflowY: currentLangID === 2 ? "clip" : "auto",
    width: currentLangID === 2 ? "100%" : "auto"
  }}
>
          <b>{currentLang["in_your_email_please_include"]}</b>
          <ul style={{ direction: currentLangID === 2 ? 'rtl' : 'ltr'}}>
            <li>{currentLang["your_full_name"]}</li>
            <li>{currentLang["the_email_address_associated_with_your_account"]}</li>
            <li>
              {
                currentLang[
                  "confirmation_of_irreversible_action_and_data_deletion"
                ]
              }
            </li>
          </ul>
          <p>{currentLang["well_handle_your_request_promptly_and_securely"]}</p>
          <b>{currentLang["additional_notes"]}</b>
          <ul style={{ direction: currentLangID === 2 ? 'rtl' : 'ltr'}}>
            <li>
              {
                currentLang[
                  "questions_or_concerns_in_email"
                ]
              }
            </li>
            <li>
              {
                currentLang[
                  "deletion_timeframe_notification"
                ]
              }
            </li>
            <li>
              {
                currentLang[
                  "more_information_data_practices_privacy_policy"
                ]
              }
            </li>
          </ul>
          <p>{currentLang["thank_you_for_using_life_medic"]}</p>
        </Container.Column>
        <Button role="submit" onClick={close}>
          {currentLang["understood"]}
        </Button>
      </Container.Column>
    </Dialog>
  )
}

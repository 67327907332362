import { useState, useRef } from "react"
import ReactToPrint from "react-to-print"
import html2canvas from "html2canvas"
import jsPDF from "jspdf"
import TableForm from "src/components/backoffice/Billing/TableForm"
import ClientDetails from "src/components/backoffice/Billing/ClientDetails"
import MainDetails from "src/components/backoffice/Billing/MainDetails"
import Header from "src/components/backoffice/Billing/Header"
import Dates from "src/components/backoffice/Billing/Dates"
import Table from "src/components/backoffice/Billing/Table"
import Notes from "src/components/backoffice/Billing/Notes"
import Footer from "src/components/backoffice/Billing/Footer"
import { addBill, sendEmailBill } from "src/api/backoffice"
import "./style.css"
import "./billing.scss"
import "src/styles/backoffice/backoffice.scss"
import { Link } from "react-router-dom"
import { IoMdArrowRoundBack } from "react-icons/io"

function BillingPage() {
  const [name, setName] = useState("Life-Medic")
  const [address, setAddress] = useState("Israel Tel-Aviv")
  const [email, setEmail] = useState("life-medic@gmail.com")
  const [phone, setPhone] = useState("+972 55 44 33 210")
  const [bankName, setBankName] = useState("Leumi")
  const [bankAccount, setBankAccount] = useState("777 888 999")
  const [website, setWebsite] = useState("life-medic.com")
  const [clientName, setClientName] = useState("")
  const [clientEmail, setClientEmail] = useState("")
  const [billNumber, setBillNumber] = useState("")
  const [billDate, setBillDate] = useState("")
  const [dueDate, setDueDate] = useState("")
  const [notes, setNotes] = useState("")
  const [description, setDescription] = useState("")
  const [quantity, setQuantity] = useState("")
  const [price, setPrice] = useState("")
  const [amount, setAmount] = useState("")
  const [list, setList] = useState([])
  const [total, setTotal] = useState(0)

  const fee = 0.2

  const componentRef = useRef()

  const cleanForm = () => {
    setDescription("")
    setClientName("")
    setClientEmail("")
    setNotes("")
    setBillNumber("")
    setBillDate("")
    setDueDate("")
    setPrice("")
    setQuantity("")
    setAmount("")
    setList([])
  }
  const handlePrint = () => {
    window.print()
  }
  const downloadPDF = () => {
    const input = componentRef.current
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png")
      const pdf = new jsPDF("p", "mm", "a4", true)
      const pdfWidth = pdf.internal.pageSize.getWidth()
      const pdfHeight = pdf.internal.pageSize.getHeight()
      const imgWidth = canvas.width
      const imgHeight = canvas.height
      const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight)
      const imgX = (pdfWidth - imgWidth * ratio) / 2
      const imgY = 30
      pdf.addImage(imgData, "PNG", imgX, imgY, imgWidth * ratio, imgHeight * ratio)
      pdf.save(`invoice_${billNumber}.pdf`)
    })
  }
  const sendEmail = async () => {
    try {
      const pdfBlob = downloadPDF()
      await new Promise((resolve) => setTimeout(resolve, 3000))
      const response = sendEmailBill(clientEmail, clientName, billNumber)
      alert(`Email with an invoice № ${billNumber} was successfully sent to ${clientEmail}`)
      console.log(response.data)
      console.log(total)
      const responseDB = addBill(clientEmail, clientName, billNumber, billDate, total)
      console.log(total)
      alert(`Data with an invoice № ${billNumber} was successfully stored in the DB`)
      console.log(responseDB.data)
      cleanForm()
    } catch (error) {
      console.error("Error sending emails:", error)
    }
  }
  return (
    <main>
      {/* <Link to="/malkishua/home" style={{ position: "absolute", left: "20px", top: "5px" }}>
        <p> <IoMdArrowRoundBack />to HomeAlpha page</p>
      </Link> */}
      <div className="sectionStyles">
        <div className="divStyles">
          <div className="containerStyles_2">
            <article className="articleStyles">
              <div className="containerStyles_3">
                <label htmlFor="name">Company name</label>
                <input
                  type="text"
                  name="text"
                  id="name"
                  placeholder="Enter your name"
                  autoComplete="off"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="containerStyles_3">
                <label htmlFor="address">Company address</label>
                <input
                  type="text"
                  name="address"
                  id="address"
                  placeholder="Enter your name"
                  autoComplete="off"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                />
              </div>
            </article>
            <article className="articleStyles_2">
              <div className="flex flex-col">
                <label htmlFor="email">Company email</label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Enter your email"
                  autoComplete="off"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="containerStyles_3">
                <label htmlFor="website">Company website</label>
                <input
                  type="url"
                  name="website"
                  id="website"
                  placeholder="Enter your website"
                  autoComplete="off"
                  value={website}
                  onChange={(e) => setWebsite(e.target.value)}
                />
              </div>
              <div className="containerStyles_3">
                <label htmlFor="phone">Contact phone</label>
                <input
                  type="text"
                  name="phone"
                  id="phone"
                  placeholder="Enter your phone"
                  autoComplete="off"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </div>
            </article>
            <article className="articleStyles_3 ">
              <div className="containerStyles_4">
                <label htmlFor="bankName">Bank name</label>
                <input
                  type="text"
                  name="bankName"
                  id="bankName"
                  placeholder="Enter your bank name"
                  autoComplete="off"
                  value={bankName}
                  onChange={(e) => setBankName(e.target.value)}
                />
              </div>
              <div className="containerStyles_4">
                <label htmlFor="bankAccount">Bank account</label>
                <input
                  type="text"
                  name="bankAccount"
                  id="bankAccount"
                  placeholder="Enter your bank account"
                  autoComplete="off"
                  value={bankAccount}
                  onChange={(e) => setBankAccount(e.target.value)}
                />
              </div>
            </article>
            <article className="articleStyles_4">
              <div className="containerStyles_4">
                <label htmlFor="clientName">Medical Instituation name</label>
                <input
                  type="text"
                  name="clientName"
                  id="clientName"
                  placeholder="Enter Medical Instituation name"
                  autoComplete="off"
                  value={clientName}
                  onChange={(e) => setClientName(e.target.value)}
                />
              </div>
              <div className="containerStyles_4">
                <label htmlFor="clientEmail">Medical Instituation email</label>
                <input
                  type="email"
                  name="clientEmail"
                  id="clientEmail"
                  placeholder="Enter Medical Instituation email"
                  autoComplete="off"
                  value={clientEmail}
                  onChange={(e) => setClientEmail(e.target.value)}
                />
              </div>
            </article>
            <article className="articleStyles_2">
              <div className="containerStyles_4">
                <label htmlFor="billNumber">Bill number</label>
                <input
                  type="number"
                  name="billNumber"
                  id="billNumber"
                  placeholder="Enter bill number"
                  autoComplete="off"
                  value={billNumber}
                  onChange={(e) => setBillNumber(e.target.value)}
                />
              </div>
              <div className="containerStyles_4">
                <label htmlFor="billDate">Bill date</label>
                <input
                  type="date"
                  name="billDate"
                  id="billDate"
                  placeholder="Enter bill date"
                  autoComplete="off"
                  value={billDate}
                  onChange={(e) => setBillDate(e.target.value)}
                />
              </div>
              <div className="containerStyles_4">
                <label htmlFor="dueDate">Bill due date</label>
                <input
                  type="date"
                  name="dueDate"
                  id="dueDate"
                  placeholder="Enter bill due date"
                  autoComplete="off"
                  value={dueDate}
                  onChange={(e) => setDueDate(e.target.value)}
                />
              </div>
            </article>
            <article>
              <TableForm
                description={description}
                setDescription={setDescription}
                price={price}
                setPrice={setPrice}
                quantity={quantity}
                setQuantity={setQuantity}
                amount={amount}
                setAmount={setAmount}
                list={list}
                setList={setList}
                total={total}
                setTotal={setTotal}
                fee={fee}
              />
            </article>
            <label htmlFor="notes">Additional notes</label>
            <textarea
              name="notes"
              id="notes"
              cols="30"
              rows="10"
              placeholder="Additional Notes to Medical Institution"
              value={notes}
              onChange={(e) => {
                if (e.target.value.length > 300) return
                setNotes(e.target.value)
              }}
            ></textarea>
          </div>
        </div>
      </div>
      <div className="sectionStyles_2">
        <ReactToPrint
          trigger={() => <button className="print">Print / Download</button>}
          content={() => componentRef.current}
        />
        <button className="email" onClick={sendEmail}>
          Send Email
        </button>
        <div ref={componentRef} className="email_pdf p-5">
          <Header handlePrint={handlePrint} />

          <MainDetails name={name} address={address} />

          <ClientDetails clientName={clientName} clientEmail={clientEmail} />

          <Dates billNumber={billNumber} billDate={billDate} dueDate={dueDate} />

          <Table fee={fee} list={list} total={total} setTotal={setTotal} />

          <Notes notes={notes} />

          <Footer
            name={name}
            address={address}
            website={website}
            email={email}
            phone={phone}
            bankAccount={bankAccount}
            bankName={bankName}
          />
        </div>
      </div>
    </main>
  )
}

export default BillingPage

import React from "react"
import { useRecoilValue } from "recoil"
import { USER_TYPE } from "src/constants/common"
import { currentLangState } from "src/states/signup"
import { useChat } from "../../context/chatContext"
import useIsRtl from "src/hooks/useIsRtl"

export const NoAction = () => {
  const currentLang = useRecoilValue(currentLangState)
  const { userType } = useChat();
  const isRtl = useIsRtl();

  return (
    <div className="pauseMessage" style={{direction: isRtl ? "rtl" : "ltr"}}>
      {userType === USER_TYPE.patient
        ? currentLang["patient_pause_message"]
        : currentLang["physician_pause_message"]}
    </div>
  )
}

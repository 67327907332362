import React from "react"
import { Button } from "src/UILibrary"
import { useSocketIo } from "../../context/hooks/useSocketIO"
import { ConversationHandle, ConversationMessageTypes } from "src/constants/enums"
import { useChat } from "../../context/chatContext"
import { useRecoilValue } from "recoil"
import { currentLangIDtate, currentLangState } from "src/states/signup"
import { TChatMessage } from "../../context/chatContext.types"
import { extractIdsFromGroupName } from "../../utils"

interface IRequestMoreDetailsActionProps {
  examRoomID: string
  physicianRoomID: string
}

export const RequestMoreDetailsAction = ({
  examRoomID,
  physicianRoomID,
}: IRequestMoreDetailsActionProps) => {
  const currentLang = useRecoilValue(currentLangState)
  const currentLangID = useRecoilValue(currentLangIDtate)
  const { selectedChatGroupName, getGroup, userID, userName, setGroupStatus } = useChat()
  const { sendMessage } = useSocketIo()
  const group = getGroup(selectedChatGroupName)

  const handleSendMessage = () => {
    console.log("before");
    if (!group) return
    console.log("after");
    const user_id_physician = extractIdsFromGroupName(examRoomID).phId
    setGroupStatus(group.group_name, "physician_more_details")
    const messageToServer: TChatMessage = {
      date_time: new Date().toUTCString(),
      user_id: user_id_physician,
      user_name: userName,
      message: "",
      message_type_id: ConversationMessageTypes.requestMoreDetails,
      group_name: group.group_name,
      conversation_handler: ConversationHandle.SKIP_UI,
      file_name: "",
    }
    if (currentLangID === 2) messageToServer.lang = "he"
    sendMessage(messageToServer)
  }

  return <Button className="dynamicActionButton Body3Medium" onClick={handleSendMessage}>{currentLang["request_more_details"]}</Button>
}

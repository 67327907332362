import { DatePicker as MuiDatePicker, LocalizationProvider, StaticDatePicker } from "@mui/x-date-pickers"
import "./conversationsDemo.scss"
import React, { useState, useEffect } from "react"
import { fetchChatWordCount, fetchChatWordCountByDate, fetchConversations, fetchSpeechWordCount, fetchSpeechWordCountByDate, fetchUser } from "src/api/backoffice"
import dayjs from "dayjs"
import { heIL } from '@mui/x-data-grid/locales';
import DataGridPremiumDemo from "src/components/backoffice/Analytics/AnalyticsTableAggr"
import { useRecoilValue } from "recoil"
import { currentLangIDtate, currentLangState } from "src/states/signup"
import { GridColDef } from "@mui/x-data-grid"
import { useUserAuth } from "src/pages/context/userAuth/userAuthContext"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"

interface Speech {
  id: number;
  clinic_name: string;
  physician_name: string;
  patient_name: string;
  word_count: number;
};

const ViewConversationsPageDaily = () => {
  const currentLang = useRecoilValue(currentLangState)
  const currentLangID = useRecoilValue(currentLangIDtate)
  const {user} = useUserAuth();
  const currentUserId = user.id;
  const currentUserType = user.type;
  const [selectedDate, setSelectedDate] = useState <Date | null>(new Date());
  const [chat, setChat] = useState<Speech[]>([]);
  const [speech, setSpeech] = useState<Speech[]>([]);

  const columns: GridColDef[] = [
    {
      field: "clinic_name",
      headerName: currentLang["clinic_name"],
      type: "string",
      width: 150,
      editable: false,
    },
    {
      field: "word_count",
      headerName: currentLang["word_count"],
      type: "number",
      width: 150,
      editable: false,
    },
    {
      field: "physician_name",
      headerName: currentLang["physician_name"],
      type: "string",
      width: 150,
      editable: false,
    },
    {
      field: "patient_name",
      headerName: currentLang["patient_name"],
      type: "string",
      width: 150,
      editable: false,
    },
];

  // const getConversations = async (selectedDate: Date | null) => {
  //   try {
  //     const data = await fetchConversations(selectedDate)
  //     const fetchedConversationsRows = data.map((conversation:any, index:any) => ({
  //       id: index + 1,
  //       ...conversation,
  //     }));
  //     console.log(fetchedConversationsRows)
  //     setConversations(fetchedConversationsRows)
  //     const singleConversation = fetchedConversationsRows
  //     console.log(singleConversation)
  //   } catch (error) {
  //     console.error("Error fetching conversations")
  //   }
  // }

  const getChats = async (selectedDate: Date | null) => {
    try {
        const originalObject: any = await fetchChatWordCountByDate(currentUserId,selectedDate)
        console.log(originalObject)
        const reformattedArray = [];
        let idCounter = 1;
        for (const clinicName in originalObject) {
            const physicians = originalObject[clinicName];
            for (const physicianId in physicians) {
                const patients = physicians[physicianId];
                for (const patientId in patients) {
                    const wordCount = patients[patientId];
                    const physician_name = await fetchUser(physicianId);
                    const patient_name = await fetchUser(patientId);
                    console.log(physician_name, patient_name)
                    reformattedArray.push({
                        id: idCounter++,
                        clinic_name: clinicName,
                        physician_name: `${physician_name["first_name"]} ${physician_name["last_name"]}`,
                        patient_name: `${patient_name["first_name"]} ${patient_name["last_name"]}`,
                        word_count: wordCount,
                    });
                }
            }
        }
        console.log(reformattedArray);
        setChat(reformattedArray)
    } catch (error) {
      console.error("Error fetching chats")
    }
}

const getSpeech = async (selectedDate: Date | null) => {
    try {
        const originalObject: any = await fetchSpeechWordCountByDate(currentUserId,selectedDate)
        console.log(originalObject)
        const reformattedArray = [];
        let idCounter = 1;
        for (const clinicName in originalObject) {
            const physicians = originalObject[clinicName];
            for (const physicianId in physicians) {
                const patients = physicians[physicianId];
                for (const patientId in patients) {
                    const wordCount = patients[patientId];
                    const physician_name = await fetchUser(physicianId);
                    const patient_name = await fetchUser(patientId);
                    console.log(physician_name, patient_name)
                    reformattedArray.push({
                        id: idCounter++,
                        clinic_name: clinicName,
                        physician_name: `${physician_name["first_name"]} ${physician_name["last_name"]}`,
                        patient_name: `${patient_name["first_name"]} ${patient_name["last_name"]}`,
                        word_count: wordCount,
                    });
                }
            }
        }
        console.log(reformattedArray);
        setSpeech(reformattedArray)
    } catch (error) {
      console.error("Error fetching chats")
    }
}

  useEffect(() => {
    getSpeech(selectedDate)
    getChats(selectedDate)
  }, [selectedDate])

  return (
    <>
      {/* <div className="users"> */}
      
          <div className="conversations">
          <div className="bg-white pl-5 mb-2 rounded-2xl border-4">
        <div className="bg-white p-5 rounded shadow">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <StaticDatePicker
            orientation="landscape"
            defaultValue={dayjs(selectedDate)}
            onChange={(date) => (date ? setSelectedDate(date.toDate()) : null)}
            slotProps={{
              actionBar: {
                actions: [],
              },
            }}
          />
          </LocalizationProvider>
          </div>
          </div>
                            <div className="divStyles flex flex-col justify-between gap-14">
                    <div className="containerStyles_2">
                        <div className="info">
                            <h1>{currentLang["daily"]}{" "}{currentLang["chat_word_count_analytics"]}</h1>
                        </div>
                                <div dir={currentLangID === 2 ? "rtl" : "ltr"} className="dataTable">
                                    <DataGridPremiumDemo columns={columns} rows={chat} aggr_column={["clinic_name"]} aggr_func={"word_count"} {...(currentLangID === 2 && {localeText: heIL.components.MuiDataGrid.defaultProps.localeText})}/>
                                </div>
                    </div>

                    <div className="containerStyles_2">
                        <div className="info">
                            <h1>{currentLang["daily"]}{" "}{currentLang["speech_word_count_analytics"]}</h1>
                        </div>
                                <div dir={currentLangID === 2 ? "rtl" : "ltr"} className="dataTable">
                                    <DataGridPremiumDemo columns={columns} rows={speech} aggr_column={["clinic_name"]} aggr_func={"word_count"} {...(currentLangID === 2 && {localeText: heIL.components.MuiDataGrid.defaultProps.localeText})}/>
                                </div>
                    </div>
                </div>
          </div>
        {/* </div> */}
      {/* </div> */}
       {/* </div> */}
    </>
  )
}
export default ViewConversationsPageDaily

import React, { useState } from "react"
import * as Validator from "src/modules/validation"
import * as Yup from "yup"
import { useRecoilState } from "recoil"
import { Box, Typography, Button } from "src/UILibrary"
import { Controller, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { TextFieldWithLabel } from "src/components/textfieldWithLabel"
import { changePasswordPhoneNumberState, signinEmailState } from "src/states/signin"
import { IResetPasswordFormInputs, VerificationType } from "src/types/forgotPassword"
import { resetPassword, resetPasswordByPhone } from "src/api/forgotPasswordService"
import { MAX_INPUT_LENGTH } from "src/constants/common"
import { useRecoilValue } from "recoil"
import { currentLangState } from "src/states/signup"

const validationSchema = Yup.object().shape({
  password: Validator.passwordSchema(),
  confirmPassword: Validator.confirmPasswordSchema(),
})

interface IResetPasswordForm {
  setStep: Function
  otp: string
  verificationType: VerificationType;
}

export const ResetPasswordForm: React.FC<IResetPasswordForm> = ({ setStep, otp, verificationType }) => {
  const currentLang = useRecoilValue(currentLangState)
  const [isSending, setIsSending] = useState(false)
  const [email] = useRecoilState(signinEmailState)
  const [phoneNumber] = useRecoilState(changePasswordPhoneNumberState)
  const [errorMessage, setErrorMessage] = useState<boolean>(false);

  const {
    handleSubmit,
    control,
    watch,
    getValues,
    formState: { errors },
  } = useForm<IResetPasswordFormInputs>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      password: "",
      confirmPassword: "",
    },
  })

  watch();

  const onResetPassword = async (data: IResetPasswordFormInputs) => {
    const { password } = data;
    if(!password) { return }

    setIsSending(true)
    setErrorMessage(false);

    try {
      if (verificationType === "email") {
        await handleResetByEmail(password);
      } else {
        await handleResetByPhone(password);
      }

      setStep((step: number) => step + 1)
    } catch (error) {
      console.error(error);
      setErrorMessage(true);
    } finally {
      setIsSending(false)
    }
  }

  const handleResetByEmail = async (password: string) => {
    await resetPassword({ password, email, otp })
  }

  const handleResetByPhone = async (password: string) => {
    await resetPasswordByPhone({ password, phoneNumber, otp })
  }

  return (
    <form onSubmit={handleSubmit(onResetPassword)}>
      <Box sx={{ width: "22.625rem" }}>
        <Typography.SubHeading sx={{ textAlign: "center", color: "text.primary", mb: "2rem" }}>
          {currentLang["reset_password"]}
        </Typography.SubHeading>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Typography.DetailHeading sx={{ mb: "1rem", width: "18.75rem", textAlign: "center" }}>
            {currentLang["please_type_something_you_will_remember"]}
          </Typography.DetailHeading>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            width: "100%",
            gap: "1.25rem",
          }}
        >
          <Controller
            name="password"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextFieldWithLabel
                label={currentLang["password"]}
                placeholder={currentLang["must_be_8_characters"]}
                type="password"
                value={value}
                onChange={(e) => {
                  if (e.target.value.length > MAX_INPUT_LENGTH) return;
                  onChange(e.target.value)
                }}
                error={!!errors.password}
                helperText={
                  errors.password
                    ? currentLang[`${errors.password.message}`]
                    : currentLang["password_requirments"]
                }
              />
            )}
          />
          <Controller
            name="confirmPassword"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextFieldWithLabel
                label={currentLang["confirm_password"]}
                placeholder={currentLang["confirm_password"]}
                type="password"
                value={value}
                onChange={(e) => {
                  if (e.target.value.length > MAX_INPUT_LENGTH) return;
                  onChange(e.target.value)
                }}
                error={!!errors.confirmPassword}
                helperText={
                  errors.confirmPassword ? currentLang[`${errors.confirmPassword.message}`] : undefined
                }
              />
            )}
          />
          <Button
            role="submit"
            fullWidth
            type="submit"
            disabled={Object.values(getValues()).some((value) => !value) || isSending}
          >
            {currentLang["reset_password"]}
          </Button>
        </Box>
        {!!errorMessage && (
          <Typography.Detail color="error" align="center" sx={{ mt: 1 }}>
            {currentLang["reset_password_error"]}
          </Typography.Detail>
        )}
      </Box>
    </form>
  )
}

import { atom } from "recoil"

export const signinEmailState = atom<string>({
  key: "signinEmail",
  default: "",
})

export const signInPhoneNumberState = atom<string>({
  key: "signinPhoneNumber",
  default: "+1",
})

export const signinPasswordState = atom<string>({
  key: "signinPassword",
  default: "",
})

export const changePasswordPhoneNumberState = atom<string>({
  key: "changePasswordPhoneNumber",
  default: "+1",
})

